/**
 * Subscription API wrapper
 */

const ENDPOINT = "/api/v1/subscriptions"
const QUOTE_ENDPOINT = "/api/v1/subscription-quote"
const LOG_ENDPOINT = "/api/v1/subscription-log"
const EMAIL_LOG_ENDPOINT = "/api/v1/subscription-email-log"
// subscription config
const SUBSCRIPTION_CONFIG_ENDPOINT = "/api/v1/subscription-configs"

const bbRateSubscription = {
  set_session_id(bb_sid) {
    this.bb_sid = bb_sid
    return this
  },
  get(params, callback = null) {
    if (this.bb_sid) params.bb_sid = this.bb_sid
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(subscriptionData, callback = null) {
    if (!subscriptionData.id) {
      console.log("subscription id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT + (this.bb_sid ? "?bb_sid=" + this.bb_sid : ""),
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(subscriptionData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  // subscriptionData: {
  //    email,
  //    loid,
  //    lead_id,
  //    first_name,
  //    last_name,
  //    phone,
  //    quote_info: {
  // 		  credit_score
  // 		  loan_purpose
  // 		  amortization_type
  // 		  arm_term
  // 		  aus
  // 		  lock_period
  // 		  loan_amount
  // 		  down_payment
  // 		  lead_source
  // 		  loan_term
  // 		  loan_type
  // 		  list_price
  //      location: {
  //        county
  //        street_address
  //        state
  //        zipcode
  //        city
  //      }
  //    }
  // }
  add(subscriptionData, callback = null) {
    if (!subscriptionData.email && !subscriptionData.lead_id) {
      console.log("lead_id or email is required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.ajax({
      url: ENDPOINT + (this.bb_sid ? "?bb_sid=" + this.bb_sid : ""),
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(subscriptionData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  delete(id, callback = null) {
    return $.ajax({
      url:
        ENDPOINT + "?id=" + id + (this.bb_sid ? "&bb_sid=" + this.bb_sid : ""),
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  quote(id, callback = null) {
    if (this.bb_sid) params.bb_sid = this.bb_sid
    return $.get(QUOTE_ENDPOINT, { id }, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  sendIntroEmail(id) {
    return $.ajax({
      url: "/api/v1/subscription-email",
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify({ id, type: "intro" }),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  getLog(params, callback = null) {
    return $.get(LOG_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  getEmailLog(params, callback = null) {
    return $.get(EMAIL_LOG_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  getSubscriptionConfigs: (callback = null) => {
    return $.get(SUBSCRIPTION_CONFIG_ENDPOINT, {}, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  updateSubscriptionConfig(config, callback) {
    if (!config.brand_id) {
      console.log("brand_id is required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.ajax({
      url: SUBSCRIPTION_CONFIG_ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(config),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  deleteSubscriptionConfig(id, callback) {
    return $.ajax({
      url: SUBSCRIPTION_CONFIG_ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  getSubscriptionConfig(params, callback = null) {
    return $.get(SUBSCRIPTION_CONFIG_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbRateSubscription
