<template>
  <div :class="className">
    <ul role="tablist" class="tabs-component-tabs">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
        class="tabs-component-tab"
        role="presentation"
        v-show="tab.isVisible"
      >
        <a
          v-html="tab.header"
          :aria-controls="tab.hash"
          :aria-selected="tab.isActive"
          @click="selectTab(tab.hash, $event)"
          :href="tab.hash"
          class="tabs-component-tab-a"
          role="tab"
        ></a>
      </li>
    </ul>
    <div class="tabs-component-panels">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialHash: {
      type: [String, Boolean],
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    useUrlFragment: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    tabs: [],
    activeTabHash: ""
  }),
  computed: {
    className: function() {
      let className = "tabs-component"
      if (this.outline) {
        className += " tabs-outline"
      }
      return className
    }
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    window.addEventListener("hashchange", () =>
      this.selectTab(window.location.hash)
    )

    if (this.findTab(window.location.hash)) {
      this.selectTab(window.location.hash)
      return
    }

    if (this.initialHash) {
      window.location.hash = `#${this.initialHash}`
    }

    if (this.tabs.length) {
      this.selectTab(this.tabs[0].hash)
    }
  },
  methods: {
    findTab(hash) {
      return this.tabs.find(tab => tab.hash === hash)
    },
    selectTab(selectedTabHash, event) {
      // See if we should store the hash in the url fragment.
      if (event && !this.useUrlFragment) {
        event.preventDefault()
      }
      const selectedTab = this.findTab(selectedTabHash)
      if (!selectedTab) {
        return
      }
      if (selectedTab.isDisabled) {
        return
      }
      if (selectedTab.linkHref) {
        if (event) event.preventDefault()
        window.location.href = selectedTab.linkHref
        return
      }
      this.tabs.forEach(tab => {
        tab.isActive = tab.hash === selectedTab.hash
      })
      this.$emit("changed", { tab: selectedTab })
      this.activeTabHash = selectedTab.hash
    },
    setTabVisible(hash, visible) {
      const tab = this.findTab(hash)
      if (!tab) {
        return
      }
      tab.isVisible = visible
      if (tab.isActive) {
        // If tab is active, set a different one as active.
        tab.isActive = visible
        this.tabs.every((tab, index, array) => {
          if (tab.isVisible) {
            tab.isActive = true
            return false
          }
          return true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-component-tabs {
  background: #fff;
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  box-shadow: 500px 0px 0px 0 #fff;
  height: 56px;
  overflow-x: scroll;

  .tabs-outline & {
    box-shadow: none;
    background: rgba(0, 0, 0, 0);
    border-bottom: 1px solid #cbd6e3;
    margin: 0 15px;
  }

  .modal-content & {
    box-shadow: none;
  }
}
.tabs-component-tab {
  color: #8998ab;
  font-size: 14px;
  font-weight: 400;
  margin: 0 15px;
  list-style: none;
  transition: all 0.25s ease;
  padding-left: 8px;
  padding-right: 8px;

  &:hover,
  &.is-active {
    color: #354052;
    box-shadow: inset 0px -3px 0px 0px #29c9c5;

    .tabs-outline & {
      box-shadow: none;
      position: relative;
      padding: 0 15px;
      margin: 0;

      &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: #cbd6e3;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 3px;
      }
    }
  }
  &.is-active {
    font-size: 16px;
  }
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

.tabs-component-tab-a {
  color: inherit;
  display: block;
  padding: 20px 2px;
}

.tabs-component-panels {
  padding: 10px 0 0;
  @include media-breakpoint-up(md) {
    padding-top: 38px;
  }
  .tabs-outline & {
    padding: 38px 15px 0;
  }

  section {
    margin: 0;
  }
}
</style>
