<template>
  <div class="reviews-wrapper">
    <div v-if="!reviews">
      No reviews...
    </div>
    <div
      v-else
      class="panel-group"
      id="accordion"
      role="tablist"
      aria-multiselectable="true"
    >
      <div
        v-for="(review, index) in reviews"
        class="panel panel-default"
        :key="index"
      >
        <div v-if="type == 'google'">
          <div class="panel-heading" role="tab" :id="'heading' + index">
            <h4 class="panel-title">
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#accordion"
                :href="'#accordian' + index + type"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                {{ review.author_name }}
              </a>
              <span class="rating pull-right"
                >{{ review.rating }}<i class="fas fa-star"></i
              ></span>
            </h4>
          </div>
          <div
            :id="'accordian' + index + type"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div class="panel-body">
              <p>{{ review.text }}</p>
            </div>
          </div>
        </div>
        <div v-if="type == 'zillow'">
          <div class="panel-heading" role="tab" :id="'heading' + index">
            <h4 class="panel-title">
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#accordion"
                :href="'#accordian' + index + type"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                {{
                  review.reviewerName.displayName
                    ? review.reviewerName.displayName
                    : review.reviewerName.screenName
                }}
              </a>
              <span class="rating pull-right"
                >{{ review.rating }}<i class="fas fa-star"></i
              ></span>
            </h4>
          </div>
          <div
            :id="'accordian' + index + type"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div class="panel-body">
              <div class="label">{{ review.title }}</div>
              <p>{{ review.content }}</p>
            </div>
          </div>
        </div>
        <div v-if="type == 'experience'">
          <div class="panel-heading" role="tab" :id="'heading' + index">
            <h4 class="panel-title">
              <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  :href="'#accordian' + index + type"
                  aria-expanded="true"
                  aria-controls="collapseOne"
              >
                {{ review.transactionInfo && review.transactionInfo.customer_first_name ? review.transactionInfo.customer_first_name+" "+review.transactionInfo.customer_last_name : "Verified Customer" }}
              </a>
              <span class="rating pull-right"
              >{{ review.review.rating }}<i class="fas fa-star"></i
              ></span>
            </h4>
          </div>
          <div
              :id="'accordian' + index + type"
              class="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingOne"
          >
            <div class="panel-body">
              <div class="label">{{ review.review.review }}</div>
              <p>{{ getExperienceReviewText(review) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManagerReviews",
  props: {
    reviews: Array,
    type: String
  },
  updated() {
    //console.log('updating reviews component...');
  },
  methods: {
    getExperienceReviewText(review){
      if(review.review.survey_responses && review.review.survey_responses[1]){
        return review.review.survey_responses[1].answer;
      }
      return review.review.summary
    }
  }
}
</script>

<style scoped lang="scss">
.reviews-wrapper {
  .label {
    text-align: left;
    display: block;
    margin-bottom: 5px;
  }
}
</style>
