var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className },
    [
      _c(
        "flex-box",
        {
          staticClass: "profile-title",
          attrs: { justify: "center", alignment: "center" }
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.program.title) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "profile-details",
          on: {
            click: function($event) {
              _vm.getProgramDetails(_vm.program)
            }
          }
        },
        [
          _c("span", { staticClass: "profile-toggle" }, [
            _vm._v("View details")
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "profile-number" }, [
        _c("strong", [_vm._v(_vm._s(_vm.profileNumber))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-07f02f14", { render: render, staticRenderFns: staticRenderFns })
  }
}