var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isLoading,
          expression: "!isLoading"
        }
      ],
      staticClass: "fts"
    },
    [
      _c(
        "grid-row",
        _vm._l(_vm.templates, function(template) {
          return _c("grid-column", { key: template.id }, [
            _c(
              "div",
              {
                class: _vm.wrapperClassName(template.id),
                on: {
                  click: function($event) {
                    _vm.setTemplate(template.id)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "fts__thumb",
                  attrs: { src: template.preview_url }
                }),
                _vm._v(" "),
                template.id === _vm.themeSelected
                  ? _c("icon", {
                      staticClass: "fts__check-icon",
                      attrs: { name: "check" }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        })
      ),
      _vm._v(" "),
      _vm.swatches
        ? _c(
            "div",
            { staticClass: "fts__color-selector" },
            _vm._l(_vm.swatches, function(swatch, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: _vm.swatchClassName(index),
                  on: {
                    click: function($event) {
                      _vm.setSwatch(index)
                    }
                  }
                },
                [
                  _c("span", {
                    style: "background-color: " + swatch.primaryColor
                  }),
                  _vm._v(" "),
                  _c("span", {
                    style:
                      "background-color: " +
                      (swatch.secondaryColor
                        ? swatch.secondaryColor
                        : swatch.primaryColor)
                  }),
                  _vm._v(" "),
                  index === _vm.swatchSelected
                    ? _c("icon", {
                        staticClass: "fts__check-icon is-small",
                        attrs: { name: "check" }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-732ba61a", { render: render, staticRenderFns: staticRenderFns })
  }
}