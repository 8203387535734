<doc>
  My Rates Vue.js component
</doc>

<template>
  <LoadingTree v-if="isLoading" />
  <RatesList v-else />
</template>

<script>
import { LoadingTree, RatesList } from "../components"
import { mapState} from "vuex"
import _isNull from "lodash/isNull"

export default {
  name: "MyRates",
  components: {
    LoadingTree: LoadingTree,
    RatesList: RatesList
  },
  computed: Object.assign(
    {},
    mapState(["originalItems"]),
    {
      isLoading: function() {
        return _isNull(this.originalItems)
      }
    }
  )
}
</script>

<style lang="scss" scoped>
</style>
