var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", [
    _c(
      "div",
      [
        _c("ImgUploader", {
          attrs: {
            editMode: _vm.editMode,
            previewAlt: _vm.logoAlt,
            previewSrc: _vm.org.logo,
            "use-s3": true,
            previewClass: "company-logo",
            label: "Upload company logo",
            uploadCallback: _vm.uploadCallback
          }
        }),
        _vm._v(" "),
        !_vm.editMode
          ? _c("h3", { staticClass: "company-name" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.org.name) + "\n\t\t\t")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.editMode
          ? _c("InputText", {
              attrs: { label: "Organization Name", name: "name" },
              model: {
                value: _vm.formData.name,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "name", $$v)
                },
                expression: "formData.name"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.editMode
          ? _c(
              "a",
              {
                staticClass: "company-link",
                attrs: { href: _vm.org.website, target: "_blank" }
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.website) + "\n\t\t\t")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.editMode
          ? _c("InputText", {
              attrs: {
                label: "Organization website",
                name: "website",
                type: "url"
              },
              model: {
                value: _vm.formData.website,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "website", $$v)
                },
                expression: "formData.website"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-right" },
          [
            _vm.editMode
              ? _c(
                  "BaseButton",
                  {
                    attrs: { type: "button", theme: "link", size: "sm" },
                    on: {
                      click: function($event) {
                        _vm.setEditMode(false)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\t\tCancel\n\t\t\t\t")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editMode
              ? _c(
                  "BaseButton",
                  {
                    attrs: { type: "button", size: "sm" },
                    on: {
                      click: function($event) {
                        _vm.saveFormData()
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\t\tSave\n\t\t\t\t")]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        !_vm.editMode
          ? _c(
              "BaseButton",
              {
                staticClass: "edit",
                attrs: { type: "button", theme: "link" },
                on: {
                  click: function($event) {
                    _vm.setEditMode(true)
                  }
                }
              },
              [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v("Edit program set")
                ]),
                _vm._v(" "),
                _c("icon", { attrs: { name: "pencil-alt" } })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7300b20c", { render: render, staticRenderFns: staticRenderFns })
  }
}