var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reviews-wrapper" }, [
    !_vm.reviews
      ? _c("div", [_vm._v("\n    No reviews...\n  ")])
      : _c(
          "div",
          {
            staticClass: "panel-group",
            attrs: {
              id: "accordion",
              role: "tablist",
              "aria-multiselectable": "true"
            }
          },
          _vm._l(_vm.reviews, function(review, index) {
            return _c(
              "div",
              { key: index, staticClass: "panel panel-default" },
              [
                _vm.type == "google"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "panel-heading",
                          attrs: { role: "tab", id: "heading" + index }
                        },
                        [
                          _c("h4", { staticClass: "panel-title" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  role: "button",
                                  "data-toggle": "collapse",
                                  "data-parent": "#accordion",
                                  href: "#accordian" + index + _vm.type,
                                  "aria-expanded": "true",
                                  "aria-controls": "collapseOne"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(review.author_name) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "rating pull-right" }, [
                              _vm._v(_vm._s(review.rating)),
                              _c("i", { staticClass: "fas fa-star" })
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "panel-collapse collapse",
                          attrs: {
                            id: "accordian" + index + _vm.type,
                            role: "tabpanel",
                            "aria-labelledby": "headingOne"
                          }
                        },
                        [
                          _c("div", { staticClass: "panel-body" }, [
                            _c("p", [_vm._v(_vm._s(review.text))])
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.type == "zillow"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "panel-heading",
                          attrs: { role: "tab", id: "heading" + index }
                        },
                        [
                          _c("h4", { staticClass: "panel-title" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  role: "button",
                                  "data-toggle": "collapse",
                                  "data-parent": "#accordion",
                                  href: "#accordian" + index + _vm.type,
                                  "aria-expanded": "true",
                                  "aria-controls": "collapseOne"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      review.reviewerName.displayName
                                        ? review.reviewerName.displayName
                                        : review.reviewerName.screenName
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "rating pull-right" }, [
                              _vm._v(_vm._s(review.rating)),
                              _c("i", { staticClass: "fas fa-star" })
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "panel-collapse collapse",
                          attrs: {
                            id: "accordian" + index + _vm.type,
                            role: "tabpanel",
                            "aria-labelledby": "headingOne"
                          }
                        },
                        [
                          _c("div", { staticClass: "panel-body" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(_vm._s(review.title))
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(review.content))])
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.type == "experience"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "panel-heading",
                          attrs: { role: "tab", id: "heading" + index }
                        },
                        [
                          _c("h4", { staticClass: "panel-title" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  role: "button",
                                  "data-toggle": "collapse",
                                  "data-parent": "#accordion",
                                  href: "#accordian" + index + _vm.type,
                                  "aria-expanded": "true",
                                  "aria-controls": "collapseOne"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      review.transactionInfo &&
                                      review.transactionInfo.customer_first_name
                                        ? review.transactionInfo
                                            .customer_first_name +
                                          " " +
                                          review.transactionInfo
                                            .customer_last_name
                                        : "Verified Customer"
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "rating pull-right" }, [
                              _vm._v(_vm._s(review.review.rating)),
                              _c("i", { staticClass: "fas fa-star" })
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "panel-collapse collapse",
                          attrs: {
                            id: "accordian" + index + _vm.type,
                            role: "tabpanel",
                            "aria-labelledby": "headingOne"
                          }
                        },
                        [
                          _c("div", { staticClass: "panel-body" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(_vm._s(review.review.review))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.getExperienceReviewText(review))
                              )
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          })
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-57df9571", { render: render, staticRenderFns: staticRenderFns })
  }
}