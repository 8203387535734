import Vue from "vue"
import _orderBy from "lodash/orderBy"
import initStore from "./store/bb-static-flyers"
import { MyStaticFlyers } from "./pages"

export default function() {
  if (!document.getElementById("vue-static-flyers-app")) {
    return
  }
  const store = initStore()
  // initial items
  store.dispatch("staticFlyers/initStaticFlyers", { bank_id, isBankAdmin, uid })
  store.dispatch("manualListing/initManualListing", { bank_id, isBankAdmin, uid })
  // Vuejs setup
  new Vue({
    el: "#vue-static-flyers-app",
    store,
    components: {
      MyStaticFlyers
    }
  })
}
