<doc>
	People details Vue.js component
</doc>

<template>
  <div :class="className">
    <div class="people-details-img">
      <div class="people-thumb-wrappar">
        <Thumb
          fullRounded
          :src="item.picture"
          :alt="alt"
          class="item-image mx-auto"
        />
        <icon :class="statusClassName" name="circle"></icon>
      </div>
      <div class="people-overlay">
        <SmartListItemOptions
          hasTogglePricing
          :pricing_status="item.pricing_status"
          :toggleMemberPricing="toggleMemberPricing"
          :itemIndex="itemIndex"
          :itemId="item.id || item.nid"
          :onDragEnd="onDragEnd"
          :isLast="isLast"
          :gridView="gridView"
          :showEditItemModal="showEditItemModal"
          :removeItem="removeItem"
          :hideOptions="['obProfiles','exportMembers']"
        />
      </div>
    </div>
    <div class="people-details">
      <div class="people-details-title">
        {{ item.first_name }} {{ item.last_name }}
      </div>
      <div class="people-details-meta">
        <span class="meta-title">{{ item.position }}</span>
        <a target="_blank" :href="`mailto:${item.email}`">{{ item.email }}</a><br>
        <div style="color: #abc0d3;font-size: 12px;margin-top:5px;">ID: {{ item.id || item.nid }}</div>
        <div style="margin-top:5px;" v-if="item.brand_admin"><span class="badge">admin</span></div>
        <Thumb noBorder :src="item.logo" :alt="altLogo" class="item-logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import Thumb from "../Thumb"
import SmartListItemOptions from "../SmartList/SmartListItemOptions"

const { mapState, mapActions } = createNamespacedHelpers("members")

export default {
  name: "PeopleDetails",
  components: {
    SmartListItemOptions: SmartListItemOptions,
    Thumb: Thumb
  },
  props: {
    item: { type: Object, required: true },
    itemIndex: { type: Number, required: true },
    isLast: { type: Boolean, default: false },
    onDragEnd: { type: Function },
    toggleMemberPricing: { type: Function }
  },
  computed: Object.assign({}, mapState(["gridView"]), {
    className: function() {
      let className = "people people-details-wrapper"
      if (!this.gridView) {
        className += " list-view d-flex"
      }
      if (this.$mq == "sm" || this.$mq == "md") {
        className += " not-desktop"
      }
      return className
    },
    statusClassName: function() {
      let className =
        this.item.pricing_status == "active" ? "text-success" : "text-danger"
      return `${className} people-status`
    },
    alt: function() {
      return `${this.item.name} thumb`
    },
    altLogo: function() {
      return `${this.item.bank} logo`
    }
  }),
  methods: Object.assign(
    {},
    mapActions([
      "showEditProgramDetailsModal",
      "showEditItemModal",
      "removeItem",
      "removeMember"
    ]),
    {
      showModal() {
        this.showEditProgramDetailsModal(this.item.id)
      },
      removeItem(){
        this.removeMember(this.item.nid);
      }
    }
  )
}
</script>

<style lang="scss" scoped>
.people {
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
  margin: 15px 0;

  &.list-view {
    border-left: 4px solid #58bec8;
    margin: 0 0 10px;
    padding: 8px 5px;
    background: #fff;

    .people-details {
      padding: 0;
      display: flex;
      align-items: center;
      text-align: left;
      width: 75%;

      &-title {
        margin-bottom: 0;
        padding: 0 15px 0 10px;
        min-width: 200px;
      }

      &-img {
        position: unset;
        padding: 0;
      }
      &-meta {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .meta-title {
          margin-bottom: 0;
        }
      }
    }
    .people-overlay {
      opacity: 1;
      background: none;
    }

    img {
      margin: 0;
      height: 38px;
    }

    .people-meta {
      .item-logo {
        margin-top: 0;
      }
    }

    .program-buttons-wrapper {
      top: 24px;
      right: 15px;
    }
  }
  .item-image {
    width: 70px;
    height: 70px !important;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
  &-details {
    text-align: center;
    background: #fff;
    padding: 12px 16px 16px;
    &-img {
      background: #fff;
      position: relative;
      padding: 35px 0 0;
    }
    &-title {
      color: #595959;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 9px;
    }
    &-meta {
      color: #354052;
      font-size: 12px;

      .meta-title {
        display: block;
        margin-bottom: 8px;
      }
      a {
        color: #abc0d3;
        font-size: 12px;
        text-transform: lowercase;
      }

      .item-logo {
        display: block;
        height: 22px;
        margin: 36px auto 0;
        width: auto;
      }
    }
  }
  &-overlay {
    z-index: 6;
    /*opacity: 0;
		transition: opacity 0.25s ease;*/
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    //background: rgba(53, 64, 82, 0.75);
  }

  &:hover {
    .people-overlay {
      body:not(.dragging) & {
        opacity: 1;
      }
    }
  }

  &-thumb-wrappar {
    position: relative;
    width: 70px;
    margin: 0 auto;
    .people-status {
      font-size: 9px;
      position: absolute;
      left: 9px;
      bottom: 5px;
    }
  }
}
.btn-view-program {
  top: calc(50% - 16px);
  position: relative;
  color: #fff;
  border-color: #fff;
  z-index: 2;
  &:hover {
    color: #fff;
  }
}

.not-desktop {
  .people-overlay {
    opacity: 1;
    background: rgba(0, 0, 0, 0);
  }
}
</style>
