var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c(
      "div",
      { staticClass: "company-details-img" },
      [
        _c("img", {
          attrs: { src: _vm.item.logo, alt: _vm.item.name + " logo" }
        }),
        _vm._v(" "),
        _c("SmartListItemOptions", {
          attrs: {
            itemIndex: _vm.itemIndex,
            itemId: _vm.item.id,
            onDragEnd: _vm.onDragEnd,
            isLast: _vm.isLast,
            gridView: _vm.gridView,
            showEditItemModal: _vm.showEditItemModal,
            listType: _vm.listType,
            "hide-options": _vm.hideOptions,
            removeItem: function() {
              _vm.console.info("TODO: Add real API")
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "company-details" },
      [
        _c("div", { staticClass: "company-details-title" }, [
          _vm._v(_vm._s(_vm.item.name))
        ]),
        _c("icon", { class: _vm.statusClassName, attrs: { name: "circle" } }),
        _vm._v(" "),
        _c("div", { staticClass: "company-details-meta company-id" }, [
          _c("a", { attrs: { target: "_blank" } }, [
            _vm._v("ID: " + _vm._s(_vm.item.id))
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-10784d70", { render: render, staticRenderFns: staticRenderFns })
  }
}