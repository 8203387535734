<template>
  <div
    class="badges"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      :class="
        `
        badges__inner
         ${limit > 0 && items.length > limit && showAll ? 'is-expanded' : ''}
         ${toggleable ? 'is-toggleable' : ''}`
      "
    >
      <Badge
        v-for="(item, index) in items"
        :key="index"
        :label="item.text"
        :isTag="isTag"
        :showIcon="showIcon"
        :hasClose="hasClose"
        :pill="true"
        :mr="4"
        :mb="4"
        v-if="showAll || index < limit"
        @removeBadge="deleteBadgeById(item.id)"
      />
      <span v-if="limit > 0 && items.length > limit && !showAll"
        >{{ items.length - limit }} tags +</span
      >
    </div>
  </div>
</template>

<script>
import Badge from "../Badge"
export default {
  components: { Badge },
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    },
    showIcon: { type: Boolean, default: false },
    isTag: { type: Boolean, default: false },
    hasClose: { type: Boolean, default: false },
    limit: { type: Number, default: 0 },
    toggleable: { type: Boolean, default: false }
  },
  data: function() {
    return {
      showAll: this.limit === 0,
      mouseOver: false
    }
  },
  methods: {
    deleteBadgeById(id) {
      this.$emit("deleteBadgeById", id)
    },
    handleMouseEnter() {
      this.mouseOver = true
      this.toggleBadges()
    },
    handleMouseLeave() {
      this.mouseOver = false
      this.toggleBadges()
    },
    toggleBadges() {
      if (this.limit !== 0) {
        setTimeout(() => {
          this.showAll = this.mouseOver
        }, 500)
      }
    }
  }
}
</script>

<style langs="scss" scoped>
.badges {
  position: relative;
  min-height: 28px;
}

.badges__inner.is-toggleable {
  position: absolute;
  top: 0;
  left: 0;
}

.badges__inner.is-expanded {
  left: -16px;
  background: #fff;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.07);
  padding: 0 16px 16px;
  right: -16px;
  min-height: 53px;
}
</style>
