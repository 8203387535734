<doc>
    Add/edit Program Modal Vue.js component
  </doc>

<template>
  <transition-group
    name="modal"
    v-on:before-enter="beforeEnter"
    v-on:after-enter="afterEnter"
    v-on:after-leave="afterLeave"
  >
    <div key="modal" class="flyer-modal" tabindex="-1">
      <div class="modal-dialog flyer-modal-dialog">
        <div class="modal-content flyer-modal-content">
          <div class="modal-header flyer-modal-header">
            <h4 class="modal-title flyer-modal-title">
              {{ title }}
            </h4>
            <button
              class="close"
              theme=""
              type="button"
              aria-label="Close"
              v-on:click="cancel"
            >
              <span class="closeBtn" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body flyer-modal-body">
            <label class="flyer-modal-label">Organizations</label>
            <Multiselect
              :max-height="500"
              v-model="orgValue"
              :options="orgOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              selectLabel="Click to select"
              deselectLabel="Click to remove"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  class="multiselect__single"
                  v-if="values.length && !isOpen"
                  >{{ values.length }} options selected</span
                >
              </template>
            </Multiselect>
            <ul v-for="v in orgValue" v-bind:key="v.id">
              {{
                v.name
              }}
            </ul>
            <label class="flyer-modal-label margin-top-16">Brands</label>
            <Multiselect
              v-for="v in orgValue"
              v-bind:key="v.id"
              v-model="brandValue"
              :options="getBrandsByOrgId(v.id)"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="v.name"
              selectLabel="Click to select"
              deselectLabel="Click to remove"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  class="multiselect__single"
                  v-if="values.length && !isOpen"
                  >{{ v.name }}</span
                >
              </template>
            </Multiselect>
            <ul v-for="v in brandValue" v-bind:key="v.name">
              {{
                v.name
              }}
            </ul>
          </div>
          <div class="modal-footer flyer-modal-footer">
            <button class="btn btn-modern" @click="save">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div key="backdrop" class="modal-backdrop in" v-if="isVisible"></div>
  </transition-group>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import Multiselect from "vue-multiselect"

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "flyerTemplateModal"
)

export default {
  name: "FlyerTemplateModal",
  components: { Multiselect },
  data() {
    return {
      id: null,
      orgValue: [],
      previousOrgValue: [],
      brandValue: [],
      orgOptions: [],
      orgToBrandsMap: new Map(),
      brandOptions: [],
      isVisible: false,
      templateData: null,
      orgAndBrandNamesMap: null,
      isActive: false
    }
  },
  props: {
    title: { type: String },
    orgAndBrandNames: { type: Array }
  },
  created() {},
  computed: Object.assign({}, mapState(["showItemModal"]), {
    showItemModalLocal: {
      get() {
        return this.showItemModal
      },
      set(value) {
        this.toggleItemModal(value)
      }
    },
    modalTitle() {
      return "Update Brand Permissions"
    }
  }),
  methods: Object.assign(
    {
      cancel() {
        this.$emit("cancel")
        this.isVisible = false
      }
    },
    mapMutations({
      setCurrentEditedItem: "SET_CURRENT_EDITED_ITEM"
    }),
    mapActions(["toggleItemModal"]),
    {
      async onModalClicked(templateData) {
        this.isVisible = true
        this.isActive = false
        this.templateData = templateData
        this.id = templateData.id
        this.buildOrgAndBrandNamesMap()
        this.clearData()
        this.updateOrgToBrandsMap(templateData)
        let options = []
        for (const node of this.orgAndBrandNames) {
          if (node.type == "lender_account") {
            options.push({
              id: node.nid,
              name: node.title,
              type: node.type
            })
          }
        }
        options.sort(this.compareNames)
        options.unshift({
          id: 0,
          name: "All Orgs",
          type: "lender_account"
        })
        this.orgOptions = options

        let values = []
        for (var i = 0; i < templateData.brandIds.length; i++) {
          let brandId = templateData.brandIds[i]
          values.push({
            id: brandId,
            name: templateData.brandNames[i]
          })
          this.brandValue = values
        }
      },
      async onOrgSelectionChange() {
        const orgsAdded = this.orgValue.filter(
          x => !this.previousOrgValue.includes(x)
        )
        const orgsRemoved = this.previousOrgValue.filter(
          x => !this.orgValue.includes(x)
        )
        for (var i = 0; i < orgsRemoved.length; i++) {
          this.orgToBrandsMap.delete(orgsRemoved[i].id)
        }
        for (var i = 0; i < orgsAdded.length; i++) {
          let orgId = orgsAdded[i].id
          if (orgId && orgId != 0) {
            let orgData = await BB.orgManage.getById(orgId)
            let brandArray = []
            for (const brand of orgData.brands) {
              brandArray.push(brand.id)
            }
            this.orgToBrandsMap.set(orgId, brandArray)
            this.addBrandsToDropdown()
          }
        }
        let brands = new Set()
        for (let [org, brand] of this.orgToBrandsMap.entries()) {
          for (const brandId of brand) {
            brands.add(brandId)
          }
        }
        let values = []
        for (const brand of this.brandValue) {
          if (brands.has(brand.id)) {
            values.push({
              id: brand.id,
              name: brand.name
            })
          }
        }
        this.brandValue = values
        this.previousOrgValue = this.orgValue
      },
      async updateOrgToBrandsMap(templateData) {
        let values = []
        let orgMap = new Map()
        for (var i = 0; i < templateData.orgIds.length; i++) {
          let orgId = templateData.orgIds[i]
          let orgName = templateData.orgNames[i]
          values.push({
            id: orgId,
            name: orgName
          })
          this.orgValue = values
          if (orgId && orgId != 0) {
            let brandArray = []
            let orgData = await BB.orgManage.getById(orgId)
            for (const brand of orgData.brands) {
              brandArray.push(brand.id)
            }
            orgMap.set(orgId, brandArray)
            // this.addBrandsToDropdown()
          }
        }
        this.orgToBrandsMap = orgMap
        this.addBrandsToDropdown()
        this.isActive = true
      },
      addBrandsToDropdown() {
        let options = []
        for (let [org, brand] of this.orgToBrandsMap.entries()) {
          for (const brandId of brand) {
            options.push({
              id: brandId,
              name: this.orgAndBrandNamesMap.get(brandId)
            })
          }
        }
        this.brandOptions = options
      },
      clearData() {
        this.orgValue = []
        this.orgOptions = []
        this.brandValue = []
        this.brandOptions = []
      },
      buildOrgAndBrandNamesMap() {
        let tempMap = new Map()
        for (const node of this.orgAndBrandNames) {
          tempMap.set(node.nid, node.title)
        }
        this.orgAndBrandNamesMap = tempMap
      },
      getCurrentItemLocal() {
        const currentItem = !this.currentEditedItem
          ? this.staticFlyer
          : this.currentEditedItem
        return Object.assign({}, currentItem)
      },
      getBrandsByOrgId(orgId) {
        let options = []
        let brandArray = this.orgToBrandsMap.get(orgId)
        if (brandArray != undefined) {
          for (const brandId of brandArray) {
            options.push({
              id: brandId,
              name: this.orgAndBrandNamesMap.get(brandId)
            })
          }
        } else {
          return []
        }
        options.sort(this.compareNames)
        return options
      },
      async save() {
        let values = []
        if (this.brandValue.length == 0) {
          values.push({
            id: 0,
            name: "All Brands"
          })
          this.brandValue = values
        }
        BB.templatePermissions
          .add({
            template_id: this.id,
            org_ids: this.orgValue,
            brand_ids: this.brandValue
          })
          .then(data => {
            if (data.status == "success") {
              BB.Toastr.success("Brand Permissions Updated")
              BB.Toastr.success("Refresh Page to View Permissions")
              this.cancel()
            } else {
              BB.Toastr.error("Update Failed")
            }
          })
      },
      compareNames(a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      },
      beforeEnter() {
        this.$emit("beforeEnter")
        document.body.classList.add("modal-open")
      },
      afterEnter() {
        this.$emit("afterEnter")
      },
      afterLeave() {
        document.body.classList.remove("modal-open")
      }
    }
  ),
  watch: {
    orgValue: function() {
      if (this.isVisible && this.isActive) {
        this.onOrgSelectionChange()
      }
    }
  }
}
</script>

<style lang="scss">
.flyer-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
}
.flyer-modal-header {
  background: linear-gradient(0deg, #58bec8 0%, #29c9c5 100%);
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 18px 25px;
}
.flyer-modal-title {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 21px;
  color: #fff;
  display: inline-block;
}
.flyer-modal-body {
  height: 600px;
  overflow-y: scroll;
}
.flyer-modal-label {
  font-weight: bold;
  margin-bottom: 8px;
}
.gridmultiselect {
  height: 500px;
}
.closeBtn {
  font-size: 33px;
  font-weight: 300;
  line-height: 0.75;
  color: #fff;
}
.multiselect {
  margin-bottom: 16px;
}
.margin-top-16 {
  margin-top: 16px;
}

@import "../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "~bootstrap/scss/modal";
</style>
