var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ConfigEditorManager", {
    attrs: {
      type: "Rate Table",
      getConfig: _vm.getConfig,
      getAllConfig: _vm.getAllConfig,
      addConfig: _vm.addConfig,
      deleteConfig: _vm.deleteConfig,
      updateConfig: _vm.updateConfig
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76b8c9c3", { render: render, staticRenderFns: staticRenderFns })
  }
}