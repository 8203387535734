<doc>
Org Status Vue.js component
</doc>

<template>
  <div>
    <h3>Pricing Status</h3>
    <ul v-if="org && org.brands && org.brands.length>0">
      <li v-for="brand in org.brands">{{brand.name}}<icon :class="statusClassName(brand)" name="circle"></icon></li>
    </ul>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { cleanURL } from "../../helpers"
import _isEmpty from "lodash/isEmpty"

const { mapState, mapActions } = createNamespacedHelpers("organization")

export default {
  name: "OrgStatus",
  components: {},
  data() {
    return {}
  },
  computed: Object.assign({}, mapState(["org"]), {
  }),
  watch: {},
  methods: Object.assign({}, mapActions(["saveOrg"]), {
    statusClassName: function(brand) {
      let className =
          brand.pricing_status === "active" ? "text-success" : "text-danger"
      return `${className} brand-pricing-status`
    },
  })
}
</script>

<style lang="scss" scoped>
  .brand-pricing-status{
    margin-left:5px;
    margin-bottom:-2px;
  }
</style>