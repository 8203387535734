<template>
  <div class="editorWrapper">
    <div style="height:calc(100% - 50px);">
      <Tabs>
        <Tab name="Cards">
          <div class="cardsWrapper">
            <LoaderPlaceholder v-if="isLoading" type="circle" />
            <div v-else>
              <div v-if="quote && quote.cards">
                <div class="col-sm-3 list-cards" v-for="(card, index) in quote.cards" :key="index">
                  <CalcCard
                      :rate="card.rate"
                      :price="card.price"
                      :term="parseInt(card.amortizationTerm)"
                      :loanAmount="( quote.full_request.loanInformation ? quote.full_request.loanInformation.baseLoanAmount : quote.full_request.loan_amount )"
                      :type="card.productName"
                      :fee="card.fee"
                      :principalAndInterest="card.principalAndInterest"
                      :investor="card.investor"
                  />
                </div>
              </div>
              <div v-else>
                Quote is empty
              </div>
            </div>
          </div>
        </Tab>
        <Tab name="Details">
          <div class="editorContainer">
            <LoaderPlaceholder v-if="isLoading" type="circle" />
            <vjsoneditor
                v-else
                v-model="activeRowLocal"
                :options="options"
                :plus="true"
            />
          </div>
        </Tab>
        <Tab name="History">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="event in historicEvents" :key="event.id">
              <label>{{ humanize(event.name) }}</label> @ <span>{{ formatDate(event.timestamp) }}</span>
            </li>
          </ul>
        </Tab>
      </Tabs>
      <div class="copy-wrapper" v-if="activeRowLocal.full_request">
        <input v-model="requestJSONString" type="text">
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-modern closeBtn" @click="closePanel">Close</button>
    </div>
  </div>

</template>

<script>
import vjsoneditor from "v-jsoneditor"
import { Tabs, Tab } from "../Tabs"
import CalcCard from "../CalcCard"

export default {
  name: "LeadPanel",
  components: {
        vjsoneditor,
        Tabs,
        Tab,
        CalcCard
    },
  data: function() {
    return {
      activeRowLocal: {},
      isLoading: true,
      historicEvents: null,
      quote: null
    }
  },
  props: {
    options: { type: Object },
    onCreated: { type: Function }
  },
  computed: {
    requestJSONString(){
        return JSON.stringify(this.activeRowLocal.full_request)
    }
  },
  created() {
    this.onCreated().then(activeRow => {
      this.activeRowLocal = activeRow
      this.quote = ( this.activeRowLocal && this.activeRowLocal.quote ? this.activeRowLocal.quote : null )
      this.isLoading = false
      this.getHistory();
    })
  },
  methods: {
    closePanel() {
      this.$emit('closePanel', {});
    },
    gotoPricingTool(){
      window.open("/pricing-tool?log_id="+this.activeRowLocal.id, '_blank');
      return;
    },
    async getHistory(){
      let result=await $.get("/api/v1/lead-history?lead_id="+this.activeRowLocal.id)
      if(!result.payload){
        return null
      }
      this.historicEvents = result.payload
    },
    humanize(str) {
      let i, frags = str.split('_');
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    },
    formatDate(timestamp){
      let d = new Date(timestamp*1000)
      return d.toLocaleString();
    }
  }

}
</script>

<style lang="scss">
  .editorWrapper{
    height: 100%;
    position: relative;
    margin: 0px;
    overflow: hidden;
  }
  .editorContainer{
    height: calc(100% - 50px);
    overflow: scroll;
  }
  .jsoneditor-container{
    div.jsoneditor{
      border: none;
    }
    .jsoneditor-menu{
      top: 0px;
      z-index: 900
    }
  }
  .footer{
    height: 50px;
    position: relative;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    width:100%;
    border-top:1px solid #eee;
    bottom: 0px;
    .btn{
      margin-top:5px;
      &.copyBtn{
        background: linear-gradient(0deg, #7e57c2 0%, #9883be 100%);
        border: 1px solid #9883be;
      }
    }
  }
  .tabs-component{
    height:100%;
  }
  .tabs-component-panels{
    padding: 0px !important;
    height: 100%;
    .tabs-component-panel{
      height:100%;
    }
  }
  .tabs-component-tabs{
    margin-left:0px !important;
  }
  .label{
    color: #7c7c8d;
  }
  .copy-wrapper{
    //display:none;
    position: absolute;
    right:-9999px;
  }
  .list-group-item label{
    font-weight: 500;
  }
</style>

