<doc>
	Date picker Range Vue.js component
</doc>

<template>
	<div class="datepicker-range no-input">
		<icon class="datepicker-icon" name="regular/calendar-alt"></icon>
		<v2-datepicker-range
			lang="en"
			:placeholder="placeholder"
			v-model="theModel"
			:format="dateFormat"
			:picker-options="pickerOptions" />
		<icon class="datepicker-chevron" name="chevron-down"></icon>
  </div>
</template>

<script>
import Vue from "vue"
import { DatePickerRange } from "v2-datepicker"
Vue.use(DatePickerRange)

export default {
	name: "DatePickerRange",
	props: {
		//dateFormat: { type: String, default: "MM dd, yy" },
		dateFormat: { type: String, default: "MM/dd/yyyy" },
		placeholder: { type: String, default: "Choose a date range…" }
	},
	data() {
		return {
			theModel: this.value,
			pickerOptions: {
				shortcuts: [
					{
						text: "Today",
						onClick(picker) {
							const today = new Date()
							picker.$emit("pick", [today, today])
						}
					},
					{
						text: "Yesterday",
						onClick(picker) {
							const date = new Date()
							date.setTime(date.getTime() - 3600 * 1000 * 24)
							picker.$emit("pick", [date, date])
						}
					},
					{
						text: "Latest Week",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						}
					},
					{
						text: "Latest Month",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						}
					}
				]
			}
		}
	},
	watch: {
		theModel(val) {
			this.$emit("input", val)
		}
	}
}
</script>

<style lang="scss" src="./DatePickerStyle.scss">
</style>
