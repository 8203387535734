<doc>
	Program details Vue.js component
</doc>

<template>
	<div>
			<h3>
				<BaseButton
				theme="link"
				type="button"
				v-on:click="resetCurrentProgram()"
				size="small"
				>
				<icon name="chevron-left"></icon>
				</BaseButton>
				{{currentProgram.title}}
			</h3>
			<div v-if="pricing.rate">
				<div class="footer">
					<div style="height:45px;">
						<div style="float:right;font-size:32px;clear:both;">{{pricing.rate}}%</div>
						<div style="float:right;clear:both;" class="subtitle small">
							{{pricingDate}}
						</div>
					</div>
					<div v-if="chart">
						<GChart
							type="ScatterChart"
							:data="chart.data"
							:options="chart.options"
							class="bbChart"
						/>
					</div>
                                        <div v-else>Loading...</div>
				</div>
			</div>
			<div style="text-align:center;" v-else>
				<span class="loader vertical-align-middle loader-circle"></span>
			</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import Moment from "moment"
import { GChart } from 'vue-google-charts'

export default {
	name: "ProgramProfileDetails",
		components: {
			GChart: GChart
		},
	computed: Object.assign(
			{
				pricingDate: function(){
					return Moment.unix(this.pricing.log_entry.timestamp).calendar(null, {
							sameElse: "MMM D, YYYY"
					})
				}
			},
			mapState(["currentProgram","pricing","chart"])
		),
	methods: Object.assign({}, mapActions(["resetCurrentProgram"]))
}
</script>

<style lang="scss" scoped>
.bbChart{
	width:100%;
}
</style>
