var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editorContainer" }, [
    _vm.monitorLoading
      ? _c("div", [_vm._v("\n        Loading...\n    ")])
      : _c("div", [
          _vm.monitorEntries && _vm.monitorEntries.length > 0
            ? _c(
                "div",
                _vm._l(_vm.monitorEntries, function(entry, i) {
                  return _c("div", { staticClass: "col-sm-6 col-md-4" }, [
                    _c("div", { staticClass: "thumbnail" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: entry.screen_shot_url
                          }
                        },
                        [_c("img", { attrs: { src: entry.screen_shot_url } })]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "caption" }, [
                      _c("h3", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(_vm._s(entry.datetime))
                      ])
                    ])
                  ])
                })
              )
            : _c("div", [
                _vm.monitor && _vm.monitor.id
                  ? _c("div", [
                      _vm._v(
                        "\n                No monitor entries yet\n            "
                      )
                    ])
                  : _c("div", [
                      _c("form", [
                        _c(
                          "fieldset",
                          [
                            _c("InputText", {
                              attrs: { label: "URL", name: "url" },
                              model: {
                                value: _vm.newMonitor.config.url,
                                callback: function($$v) {
                                  _vm.$set(_vm.newMonitor.config, "url", $$v)
                                },
                                expression: "newMonitor.config.url"
                              }
                            }),
                            _vm._v(" "),
                            _c("InputText", {
                              attrs: { label: "Delay", name: "delay" },
                              model: {
                                value: _vm.newMonitor.config.delay,
                                callback: function($$v) {
                                  _vm.$set(_vm.newMonitor.config, "delay", $$v)
                                },
                                expression: "newMonitor.config.delay"
                              }
                            }),
                            _vm._v(" "),
                            _c("InputText", {
                              attrs: { label: "Width", name: "width" },
                              model: {
                                value: _vm.newMonitor.config.width,
                                callback: function($$v) {
                                  _vm.$set(_vm.newMonitor.config, "width", $$v)
                                },
                                expression: "newMonitor.config.width"
                              }
                            }),
                            _vm._v(" "),
                            _c("InputText", {
                              attrs: { label: "Height", name: "height" },
                              model: {
                                value: _vm.newMonitor.config.height,
                                callback: function($$v) {
                                  _vm.$set(_vm.newMonitor.config, "height", $$v)
                                },
                                expression: "newMonitor.config.height"
                              }
                            }),
                            _vm._v(" "),
                            _c("InputText", {
                              attrs: { label: "Offset", name: "offset" },
                              model: {
                                value: _vm.newMonitor.config.offset,
                                callback: function($$v) {
                                  _vm.$set(_vm.newMonitor.config, "offset", $$v)
                                },
                                expression: "newMonitor.config.offset"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "BaseButton",
                              {
                                attrs: { type: "button" },
                                on: { click: _vm.createMonitor }
                              },
                              [
                                _vm._v(
                                  "\n                            Create\n                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
              ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-155d4c11", { render: render, staticRenderFns: staticRenderFns })
  }
}