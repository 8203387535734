/**
 * LO rates API wrapper
 */

const ENDPOINT = "/rateflow/manage.json"
const PRICE_ENDPOINT = "/rateflow/price.json"

const bbRateflow = {
  get(params, callback = null) {
    // check if params empty
    if (!Object.keys(params).length) {
      return $.get(ENDPOINT, { default: true }, function(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      })
    }
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  delete(id, callback) {
    return $.ajax({
      url: ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  // rateflowData={
  //      owner*
  //      loid
  //      id*
  //      settings:{
  //          target_price
  //          cache_ttl
  //      }
  //      pricing_set_id
  //      bank_id
  //      name
  // }
  update(rateflowData, callback) {
    if (!rateflowData.id) {
      console.log("rateflow id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(rateflowData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  // rateflowData={
  //      owner
  //      loid*
  //      id
  //      settings:{
  //          target_price
  //          cache_ttl
  //      }
  //      pricing_set_id
  //      bank_id
  //      name
  // }
  add(rateflowData, callback) {
    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(rateflowData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  // params = {
  //      id*
  //      loid
  //      include_trend
  //      max_results
  //      ignore_cache
  //      list_price
  //      min_credit
  // }
  price(params, callback) {
    if (!params.id && !params.loid) {
      console.log("rateflow id or loid is required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.get(PRICE_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  // params = {
  //      id*
  // 		credit_score
  // 		loan_purpose
  // 		amortization_type
  // 		arm_term
  // 		aus
  // 		lock_period
  // 		loan_amount
  // 		down_payment
  // 		lead_source
  // 		loan_term
  // 		loan_type
  // 		list_price
  // 		county
  // 		street
  // 		state
  //		zipcode
  // }
  priceCustom(params, callback) {
    if (!params.id && !params.loid) {
      console.log("rateflow id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.ajax({
      url: PRICE_ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(params),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  log(log_id, callback) {
    const url = "/api/v1/rateflow-log"
    var id_param = ""
    if (Array.isArray(log_id)) {
      id_param = log_id.join(",")
    } else {
      id_param = log_id
    }
    return $.get(
      url,
      { id: id_param, include_request: true, include_cards: true },
      function(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      }
    )
  },
  compare(quote_id, callback) {
    const url = "/api/v1/rateflow-compare"
    return $.get(url, { quote_id }, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  logSearch(params, callback) {
    const url = "/api/v1/rateflow-log"
    return $.get(url, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  find(params, callback = null) {
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  syncRequiredFields(rateflow_id, callback = null) {
    return $.get("/api/v1/sync-custom-fields?rateflow_id="+rateflow_id, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbRateflow
