import _isEmpty from "lodash/isEmpty"
import _forEach from "lodash/forEach"
import Moment from "moment"

const now = () => {
  let now = Moment()
  //const pastWeek = now.subtract(7, "d").format("X")
  now = now.format("X")
  return now
}

const state = {
  range_start: 0,
  range_end: (() => {
    return now()
  })(),
  limit: 5,
  prev_limit: 0,
  prev_length: 0,
  ids: null,
  showDetailsModal: false
}

const mutations = {
  UPDATE_LIMIT(state, newLinit) {
    state.limit = newLinit
  },
  UPDATE_PREV_LIMIT(state, newLinit) {
    state.prev_limit = newLinit
  },
  UPDATE_PREV_LENGTH(state, length) {
    state.prev_length = length
  },
  UPDATE_IDS(state, ids) {
    state.ids = ids
  },
  SET_SHOW_DETAILS_MODAL(state, showVal) {
    state.showDetailsModal = showVal
  },
  SET_RANGE_START(state, range_start) {
    state.range_start = range_start
  },
  SET_RANGE_END(state, range_end) {
    state.range_end = range_end
  }
}

const actions = {
  getInitalActivity({ commit, dispatch, state }, { brands }) {
    let ids = []
    if (state.ids) {
      ids = state.ids
    } else {
      _forEach(brands, function(value) {
        ids.push(value.id)
      })
      ids = ids.toString()
      commit("UPDATE_IDS", ids)
    }

    BB.orgActivity
      .get(ids, state.range_start, state.range_end, state.limit)
      .then(data => {
        if (data.status !== "error") {
          const length = state.originalItems ? state.originalItems.length : 0
          commit("UPDATE_PREV_LENGTH", length)
          commit("UPDATE_ORIGINAL_ITEMS", data)
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  loadByRange({ commit, dispatch, state }, { from, to }) {
    if (to === 0) {
      to = now()
    }
    commit("SET_RANGE_START", from)
    commit("SET_RANGE_END", to)
    BB.orgActivity
      .get(state.ids, from, to, state.limit)
      .then(data => {
        if (data.status !== "error") {
          const length = state.originalItems ? state.originalItems.length : 0
          commit("UPDATE_PREV_LENGTH", length)
          commit("UPDATE_ORIGINAL_ITEMS", data)
          commit("SET_SMARTLIST_LOADING", false)
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  loadMore({ commit, dispatch, state }) {
    let tempLimit = state.limit
    commit("UPDATE_PREV_LIMIT", tempLimit)
    tempLimit += 5
    commit("UPDATE_LIMIT", tempLimit)
    dispatch("getInitalActivity", [])
  },
  toggleDetailsModal({ commit, dispatch, state }, val) {
    if (val != state.showDetailsModal) commit("SET_SHOW_DETAILS_MODAL", val)
  }
}

export default {
  state,
  mutations,
  actions
}
