import Vue from "vue"
import { PricingTool } from "./pages"

export default function() {
    if (!document.getElementById("vue-pricing-tool-app")) {
        return
    }

    // Vuejs setup
    let test=new Vue({
        el: "#vue-pricing-tool-app",
        components: {
            PricingTool,
        }
    })
    // Do we need this? 25/4
    //window.dispatchEvent(new Event("resize"))
}

