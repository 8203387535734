<doc>
	Program profiles Modal Vue.js component
</doc>

<template>
	<BaseModal
		ref="modal"
		v-model="showLinkDetailsModal"
		:title="modalTitle"
		id="newLinksModal"
		:isLoading="isLoading"
		@ok="hideModal"
		:showCancelBtn="false"
		okLabel="Close">
		<h5>
			This is the header
		</h5>
		<p class="intro">This is the intro</p>

	</BaseModal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BaseModal from "../BaseModal"

export default {
	name: "LinkDetailsModal",
	components: { BaseModal: BaseModal },
	computed: Object.assign({}, mapState(["currentEditedItem", "isEdit"]), {
		showLinkDetailsModal: {
			get() {
				return this.$store.state.showLinkDetailsModal
			},
			set(value) {
				this.toggleLinkDetailsModal(value)
			}
		},
		modalTitle() {
			const prefix = this.isEdit ? "Edit" : "New"
			return `${prefix} Link Package`
		},
		isLoading() {
			return !this.currentEditedItem && this.isEdit
		}
	}),
	methods: Object.assign({}, mapActions(["toggleLinkDetailsModal"]), {
		hideModal() {
			this.toggleLinkDetailsModal()
		},
		submitForm: function() {
			//this.saveProfileList()
		}
	})
}
</script>

<style lang="scss">
#newProfilesModal {
	h5 {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.intro {
		margin-bottom: 25px;
	}
}
</style>
