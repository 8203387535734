var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "newPricingSetModal",
        okLabel: "Save",
        isLoading: _vm.isLoading
      },
      on: {
        beforeEnter: _vm.beforeEnter,
        afterEnter: _vm.afterEnter,
        ok: _vm.submitForm,
        cancel: _vm.hideModal,
        clickMask: _vm.hideModal
      },
      model: {
        value: _vm.showItemModalLocal,
        callback: function($$v) {
          _vm.showItemModalLocal = $$v
        },
        expression: "showItemModalLocal"
      }
    },
    [
      _c("h5", [_vm._v("Create Your New Flyer")]),
      _vm._v(" "),
      _c("p", { staticClass: "intro" }, [
        _vm._v(
          "Set up your flyer by uploading a static flyer image, adding helpful tags and assigning to the appropriate brands."
        )
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "newPricingSetForm",
          staticClass: "form",
          attrs: { id: "new-pricing-set-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("InputText", {
            attrs: { label: "Name of flyer template", name: "set-name" },
            on: { input: _vm.onInput },
            model: {
              value: _vm.staticFlyer.name,
              callback: function($$v) {
                _vm.$set(_vm.staticFlyer, "name", $$v)
              },
              expression: "staticFlyer.name"
            }
          }),
          _vm._v(" "),
          _c("ImgUploader", {
            attrs: {
              previewAlt: "Custom Flyer Image",
              previewSrc: _vm.flyerImgSrc,
              previewClass: "custom-flyer-image",
              label: "Upload Custom Flyer Image",
              uploadCallback: _vm.uploadCallback,
              editMode: true,
              useS3: ""
            }
          }),
          _vm._v(" "),
          _c("BaseAutocomplete", {
            attrs: {
              listItem: _vm.filteredTags,
              showListOptions: true,
              listOptions: [
                {
                  icon: "search",
                  text: "Press Enter or click to add a new tag",
                  type: "submit"
                }
              ],
              name: "search",
              label: "Add tag",
              fluidList: false,
              marBottom: "0"
            },
            on: {
              text: _vm.filterTags,
              selectedItem: _vm.saveItemSelected,
              submit: _vm.createTag
            }
          }),
          _vm._v(" "),
          _c("ManageBadge", {
            staticClass: "mt-2",
            attrs: {
              items: _vm.listTags,
              showIcon: "",
              isTag: "",
              hasClose: ""
            },
            on: { deleteBadgeById: _vm.deleteBadge }
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "select-brands" } }, [
            _c("h5", [_vm._v("Manage Brands")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.allBrands, function(brand, key) {
                return _c("li", { key: key }, [
                  _c("input", {
                    attrs: { id: "brand-" + key, type: "checkbox" },
                    domProps: { checked: _vm.brandIsActive(brand) },
                    on: {
                      change: function($event) {
                        _vm.manageBrands(brand)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "brand-" + key } }, [
                    _vm._v(_vm._s(brand.name))
                  ])
                ])
              })
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5c718118", { render: render, staticRenderFns: staticRenderFns })
  }
}