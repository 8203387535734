<template>
  <div v-if="isLoading" class="text-center">
    <LoaderPlaceholder class="loader" type="circle" />
  </div>
  <div v-else>
    <div class="d-flex">
      <div class="d-flex form-inline" v-if="!addingNew && !config">
        <div class="form-group">
          <label for="brand_id">Brand:</label>
          <select
            id="brand_id"
            class="form-control input-sm"
            v-model="brand_id"
            @change="changeSelectedBrand"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="brand in availableBrands"
              :value="brand.id"
              :key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
        </div>
        <div
          class="form-group ml-3"
          v-if="brand_id && availableWorkflows && !selectedConfigId"
        >
          <label>Workflow:</label>
          <select
            class="form-control input-sm"
            v-model="selectedConfigId"
            @change="changeSelectedConfig"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="workflow in availableWorkflows"
              :value="workflow.id"
              :key="workflow.id"
              >{{ workflow.name }}</option
            >
          </select>
        </div>
      </div>
      <ConfigNameInput
        v-else
        v-model.trim="configName"
        :inUse="inUse"
        :hasInUse="true"
        @input="configChanged"
      />

      <AddCancelBtn
        class="ml-auto"
        :onClick="addNew"
        :isCancel="addingNew || !!config"
      />
    </div>

    <div v-if="isLoadingConfig" class="text-center">
      <LoaderPlaceholder class="loader" type="circle" />
    </div>
    <div v-else-if="config">
      <div class="form-inline d-flex p-3">
        <div class="form-group">
          <label for="brand_id">Brand: </label>
          <select
            class="form-control input-sm"
            v-model="brand_id"
            id="brand_id"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="brand in availableBrands"
              :value="brand.id"
              :key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
        </div>
        <div class="form-group ml-3" v-if="addingNew">
          <label for="copyFrom">Copy from: </label>
          <select
            id="copyFrom"
            class="form-control input-sm"
            v-model="copyFrom"
            @change="copyFromWorkflow"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="workflow in allWorkflows"
              :value="workflow.id"
              :key="workflow.id"
              >{{ workflow.name }}</option
            >
          </select>
        </div>

        <div class="checkbox ml-3 pt-1">
          <label
            ><input
              type="checkbox"
              v-model="isDefault"
              @change="configChanged"
            />
            Default</label
          >
        </div>

        <div class="ml-auto form-group">
          <label for="selectedStep" class="sr-only">Add New Step: </label>
          <select
            class="form-control input-sm"
            id="selectedStep"
            v-model="selectedStep"
          >
            <option disabled :value="null">Add New Step</option>
            <option v-for="step in availableSteps" :key="step">{{
              step
            }}</option>
          </select>
          <BaseButton
            v-show="selectedStep !== null"
            size="sm"
            @click="addNewStep"
            >Add Step</BaseButton
          >
        </div>
      </div>

      <Tabs :useUrlFragment="false">
        <Tab name="Config">
          <div @mouseover="addPreview" @mouseout="removePreview">
            <vjsoneditor
              v-model="config"
              :options="options"
              :plus="true"
              :height="height"
              @error="onError"
              :key="componentKey"
            />
          </div>
        </Tab>
        <Tab name="Steps">
          <ul class="steps">
            <draggable v-model="config.steps" @change="changeSteps">
              <li v-for="(step, index) in config.steps" :key="index">
                <i class="fa fa-bars dragBtn" aria-hidden="true" />
                <span class="listId">{{ step.id }}</span>
                <i class="fas fa-pencil-alt editBtn" />
                <i
                  v-tooltip="tooltipContent(step)"
                  v-if="step.condition"
                  class="fas fa-random"
                />
              </li>
            </draggable>
          </ul>
        </Tab>
        <Tab name="Upload">
          <div>
            <div v-if="uploadUrl">
              Imgage URL:
              <div class="well well-sm">{{ uploadUrl }}</div>
            </div>
            <ImgUploader
              v-if="config"
              previewAlt="Uploaded Image"
              :editMode="true"
              :previewSrc="uploadUrl"
              previewClass="bg-preview"
              label=""
              :uploadCallback="uploadCallback"
              :labelTop="true"
              :hasRemove="true"
              :removePhoto="removePhoto"
              :use-s3="true"
            />
          </div>
        </Tab>
      </Tabs>

      <div class="d-flex pt-3">
        <div class="ml-auto">
          <BaseButton
            v-show="changed || (addingNew && brand_id)"
            class="mr-2"
            @click="saveConfig"
          >
            {{ addingNew ? "Add" : "Save" }}
          </BaseButton>

          <BaseButton
            v-show="config || addingNew"
            @click="deleteWorkflow"
            outline
          >
            Delete
          </BaseButton>
        </div>
      </div>
    </div>
    <div :id="tooltipID" class="ce_tooltip" role="tooltip"></div>
  </div>
</template>

<script>
import vjsoneditor from "v-jsoneditor"
import { Tabs, Tab } from "../Tabs"
import draggable from "vuedraggable"
import ImgUploader from "../ImgUploader"
import { VTooltip } from "v-tooltip"

import { createPopper } from "@popperjs/core"
import ConfigNameInput from "../ConfigNameInput"
import AddCancelBtn from "../AddCancelBtn"
import {
  addNewStepToArray,
  buildConditionStr,
  validateConfig
} from "./utilities"

let vm

export default {
  name: "LeadWorkflowManager",
  components: {
    ConfigNameInput,
    AddCancelBtn,
    vjsoneditor,
    Tab,
    Tabs,
    draggable,
    ImgUploader
  },
  data() {
    return {
      isLoading: true,
      isLoadingConfig: false,
      tooltipID: `prev-wrapper-${Date.now()}`,
      popperInstance: null,

      config: null,
      height: "400px",
      changed: false,
      selectedConfigId: "",
      addingNew: false,
      configName: null,
      isDefault: false,
      brand_id: "",
      selectedStep: null,
      inUse: false,
      options: {
        onChangeText(configJson) {
          vm.changed = true
        }
      },
      availableSteps: ["buttons", "list", "slider", "text", "lo_select"],
      availableBrands: null,
      availableWorkflows: null,
      allWorkflows: null,
      componentKey: 0,
      uploadUrl: "",
      copyFrom: null
    }
  },
  directives: {
    tooltip: VTooltip
  },
  async mounted() {
    vm = this
    const urlParams = new URLSearchParams(window.location.search)

    // TODO: review this conditional
    if (urlParams.has("brand_id")) {
      this.brand_id = urlParams.get("brand_id")
      await this.getAvailableWorkflows()
    } else {
      //await this.getAvailableBrands()
    }

    await this.getAvailableBrands()

    if (urlParams.has("id")) {
      this.selectedConfigId = urlParams.get("id")
      await this.getConfig(this.selectedConfigId)
      this.addingNew = false
    }
  },
  methods: {
    configChanged() {
      this.changed = true
    },
    addPreview(e) {
      // check event target class name
      if (e.target.className.includes("jsoneditor-url")) {
        const previewWrapper = document.getElementById(this.tooltipID)
        previewWrapper.innerHTML = `<img style="max-width:100%" src="${e.target.innerText}"><div class="ce_tooltip-arrow" data-popper-arrow></div>`
        this.popperInstance = createPopper(e.target, previewWrapper, {
          placement: "top"
        })
      }
    },
    removePreview(e) {
      if (e.target.className.includes("jsoneditor-url")) {
        this.popperInstance && this.popperInstance.destroy()
      }
    },
    uploadCallback(info) {
      if (window.CDN_HOST) {
        let urlParts = new URL(info.url)
        this.uploadUrl = window.CDN_HOST + urlParts.pathname
      } else {
        this.uploadUrl = info.url
      }
    },
    removePhoto() {
      this.uploadUrl = ""
    },
    onError() {
      console.log("error")
    },
    async getConfig(id) {
      this.isLoadingConfig = true
      const workflow = await BB.leadWorkflow.get({ id })

      this.config = workflow.config_json
      this.isDefault = workflow.is_default == 1
      if (id) {
        const res = await BB.leadWorkflow.checkIsActive({ id })
        this.inUse = res.status === "active"
      }
      this.configName = workflow.name ? workflow.name : "New Workflow"
      this.brand_id = workflow.brand_id
      this.isLoadingConfig = false
      this.changed = false
    },
    async saveConfig() {
      if (!validateConfig(this.config.steps)) {
        return
      }
      let payload = {}
      let key = "update"
      if (this.addingNew) {
        key = "add"
        payload = {
          name: this.configName,
          config: this.config,
          brand_id: this.brand_id,
          is_default: this.isDefault ? 1 : 0
        }
      } else {
        payload = {
          id: this.selectedConfigId,
          config: this.config,
          brand_id: this.brand_id,
          is_default: this.isDefault ? 1 : 0
        }
        if (this.configName) {
          payload.name = this.configName
        }
      }

      const json = await BB.leadWorkflow[key](payload)

      if (json.status === "success") {
        BB.Toastr.success("Configuration Saved")
        if (this.addingNew && json.workflow_id) {
          this.config.id = json.workflow_id
        }
        this.changed = false
        this.addingNew = false
      }
    },
    changeSelectedConfig() {
      this.getConfig(this.selectedConfigId)
      window.history.pushState(
        {},
        null,
        `?id=${this.selectedConfigId}${window.location.hash}`
      )
    },
    copyFromWorkflow() {
      this.config = JSON.parse(
        this.allWorkflows.find(item => item.id == this.copyFrom).config_json
      )
    },
    async addNew(e) {
      if (this.addingNew || this.config) {
        this.clearWorkflow()
      } else {
        await this.getAllWorkflows({ limit: 1000 })
        this.addingNew = true
        this.getConfig(null)
      }
    },
    clearWorkflow() {
      this.changed = false
      this.addingNew = false
      this.config = null
      this.brand_id = ""
      this.selectedConfigId = ""
      this.inUse = false
      window.history.pushState({}, null, `/config-admin${window.location.hash}`)
    },
    async syncDefaults() {
      let defaultConfig = await BB.leadWorkflow.get({})
      let currentConfig = this.config
      Object.keys(defaultConfig).forEach(key => {
        switch (key) {
          case "steps":
            break
          case "disclaimers":
            break
          default:
            if (!currentConfig[key]) {
              console.log(key + " is missing and needs to be added")
              currentConfig[key] = defaultConfig[key]
            }
            break
        }
      })
      return
    },
    async getAvailableBrands() {
      let brands= await BB.brandManage.find({})
      // sort brands by name
      this.availableBrands = brands.sort((a, b) => a.name.localeCompare(b.name))
      this.isLoading = false
    },
    async getAvailableWorkflows() {
      if (!this.brand_id) return
      let result = await BB.leadWorkflow.find({ brand_id: this.brand_id })
      this.availableWorkflows = result.workflows
    },
    async getAllWorkflows() {
      let result = await BB.leadWorkflow.find({})
      this.allWorkflows = result.workflows
    },
    addNewStep() {
      if (!this.selectedStep) return
      const type = this.selectedStep
      const steps = addNewStepToArray(this.config.steps, type)
      const config = Object.assign({}, this.config, { steps })
      this.config = config
      BB.Toastr.success(
        type.charAt(0).toUpperCase() + type.slice(1) + " Step Added"
      )
      this.changed = true
      this.selectedStep = null
    },
    changeSteps(info) {
      console.log(
        "item " + info.moved.oldIndex + " was moved to " + info.moved.newIndex
      )
      console.log(info.moved.element)
      this.saveConfig()
      this.componentKey += 1
    },

    tooltipContent(step) {
      return buildConditionStr(step.condition)
    },
    async changeSelectedBrand() {
      await this.getAvailableWorkflows()
    },
    async deleteWorkflow() {
      if (this.inUse) {
        BB.Toastr.warning("You cannot delete a workflow that is in use.")
        return
      }
      if (confirm("Do you really want to delete this?")) {
        await BB.leadWorkflow.delete(this.selectedConfigId)
        this.clearWorkflow()
      }
    }
  }
}
</script>

<style lang="scss" src="./LeadWorkflowManager.scss" scoped></style>
