var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("div", { staticClass: "program-details-img" }, [
      _c("img", {
        attrs: {
          src: _vm.details.pictures[0],
          alt: _vm.item.street_addr + " flyer thumb"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "program-overlay" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "btn-view-program",
              attrs: { outline: "" },
              on: { click: _vm.showPrintModal }
            },
            [_vm._v("Print Flyer")]
          ),
          _vm._v(" "),
          _c("SmartListItemOptions", {
            attrs: {
              gridView: _vm.gridView,
              itemIndex: _vm.itemIndex,
              itemId: _vm.item.id,
              onDragEnd: _vm.onDragEnd,
              isLast: _vm.isLast,
              showEditItemModal: _vm.showEditItemModal,
              "options-list": _vm.optList,
              removeItem: _vm.removeItem
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: ["program-details", this.programDetailsStyle] },
      [
        _c("div", { staticClass: "program-details-title mb-3" }, [
          _c("h4", [_vm._v(_vm._s(_vm.item.street_addr))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        $" +
                _vm._s(
                  new Intl.NumberFormat("en-EN").format(
                    _vm.details.listing_price
                  )
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.details.realtorData && _vm.details.realtorData.label)
            )
          ])
        ]),
        _vm._v(" "),
        _c("ManageBadge", {
          attrs: {
            toggleable: "",
            items: _vm.tagsItem,
            showIcon: "",
            isTag: "",
            limit: 3
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "agentconnect-extra-tools" }, [
          _c("div", { staticClass: "dropup" }, [
            _c("a", {
              staticClass:
                "btn btn-small-white glyphicon glyphicon-option-vertical dropdown-toggle",
              attrs: {
                type: "button",
                id: "dropdownMenu2",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            }),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu dropdown-menu-right",
                attrs: { "aria-labelledby": "dropdownMenu2" }
              },
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { role: "button" },
                      on: {
                        click: function($event) {
                          _vm.copyListingUrl(
                            "/listing?manual_listing_id=" +
                              _vm.item.id +
                              "&lo=" +
                              _vm.item.owner
                          )
                        }
                      }
                    },
                    [_vm._v("Copy Listing Url")]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/listing?manual_listing_id=" +
                          _vm.item.id +
                          "&lo=" +
                          _vm.item.owner,
                        target: "_blank"
                      }
                    },
                    [_vm._v("View Listing")]
                  )
                ])
              ]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5869ad94", { render: render, staticRenderFns: staticRenderFns })
  }
}