<doc>
  Image uploader Vue.js component
</doc>

<template>
  <div :class="wrapperClassName" :style="styles">
    <label
      v-show="!imgSrc || !preview || editMode"
      class="img-uploader__label"
      :for="idLocal"
    >
      <span v-show="!editMode" class="img-uploader__icon"></span>
      <span v-show="!editMode" class="img-uploader__label-txt">{{
        label
      }}</span>

      <BaseButton
        v-show="editMode"
        class="img-uploader__edit-btn"
        size="sm"
        @click="() => {}"
        outline
        >Replace</BaseButton
      >
    </label>
    <input
      class="sr-only"
      :id="idLocal"
      type="file"
      v-on:change="uploadPhoto"
      accept="image/png, image/jpeg"
    />
    <Thumb
      v-if="imgSrc && preview"
      :src="imgSrc"
      :type="fullRounded ? 'thumb' : 'preview'"
      :size="size"
      :fullRounded="fullRounded"
    />
  </div>
</template>

<script>
import Thumb from "../Thumb"
export default {
  name: "ThumbPrevImgUploader",
  components: { Thumb },
  props: {
    label: { type: String },
    uploadPhoto: { type: Function },
    imgSrc: { type: [String, Boolean] },
    hasError: { type: [Number, Boolean] },
    preview: { type: Boolean },
    fullRounded: { type: Boolean },
    id: { type: [String, Boolean] },
    size: { type: [String, Number] },
    editMode: { type: Boolean }
  },
  computed: {
    styles: function() {
      const styles = {}
      const size = this.size | 0
      if (size > 0) {
        styles.width = `${size}px`
        styles.height = `${size}px`
      }
      return styles
    },
    idLocal: function() {
      return this.id || `img-file-${Date.now()}`
    },
    wrapperClassName: function() {
      return `img-uploader__label-wrapper ${
        this.fullRounded ? "is-rounded" : ""
      } ${this.editMode ? "is-edit" : ""}`
    }
  }
}
</script>

<style lang="scss" scoped src="./ImgUploader.scss"></style>
