var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: "Edit Pricing",
        id: "newPricingSetModal",
        showCancelBtn: false,
        isLoading: _vm.isLoading,
        okLabel: "Close",
        closeWhenOK: true,
        large: true
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c("pricing-set-tool", {
        attrs: {
          "loan-info": _vm.loanInfo,
          "pricing-config": _vm.pricingConfig,
          "set-pricing": _vm.setPricing
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-305d3465", { render: render, staticRenderFns: staticRenderFns })
  }
}