/**
 * Brands API wrapper
 */
 const ENDPOINT = "/api/v1/template-permissions"

 const bbTemplatePermissions = {
     get(params, callback = null) {
         return $.get(ENDPOINT, function(data) {
             if (callback && typeof callback === "function") {
                 callback.call(this, data)
             }
         })
     },
     add(templateData, callback) { 
         return $.ajax({
             url: ENDPOINT,
             type: "POST",
             contentType: "application/json",
             dataType: "json",
             data: JSON.stringify(templateData),
             success(data) {
                 if (callback && typeof callback === "function") {
                     callback.call(this, data)
                 }
             },
             error(xhr, ajaxOptions, thrownError) {
                 if (callback && typeof callback === "function") {
                     callback.call(this, thrownError)
                 }
             }
         })
     }
 }
 
 export default bbTemplatePermissions
 