/**
 * LO API wrapper
 */

const ENDPOINT = "/lo-manage.json"
const REVIEWS_ENDPOINT = "/api/v1/reviews/lo"

const bbLOManage = {
	get(loid, callback) {
		if (!loid) {
			console.log("loid missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		return $.get(ENDPOINT, { loid: loid }, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
	// {

	// }
    add(loData, callback) {
        if (loData.loid) {
            console.log("you should update instead")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }

        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(loData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
	update(loData, callback) {
		if (!loData.loid) {
			console.log("loid missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(loData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
	activate(postData, callback) {
		if (!postData.loid) {
			console.error("loid missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		postData.activate_pricing = true
		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(postData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
    getFlyerPreference(loid, callback) {
        if (!loid) {
            console.log("loid missing")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.get(ENDPOINT, { loid: loid, flyer_preference:true }, function(data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
	reviews(loid, callback){
        if (!loid) {
            console.log("loid missing")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.get(REVIEWS_ENDPOINT, { id: loid }, function(data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
	},
	delete(loid, callback){
		return $.ajax({
			url: ENDPOINT + "?loid=" + loid,
			type: "DELETE",
			contentType: "application/json",
			dataType: "json",
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	}
}

export default bbLOManage
