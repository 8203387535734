<doc>
  Step 2 manual flyer form Vue.js component
</doc>

<template>
  <div>
    <h3 v-if="title">
      {{ title }}
    </h3>
    <p class="intro">{{ intro }}</p>
    <div class="form-step"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "FormStep",
  props: {
    title: { type: String, required: true },
    intro: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
h5 {
  font-size: 16px;
  color: #252525;
  margin-bottom: 10px;
}
.intro {
  font-size: 16px;
  color: #666;
}
.form-step {
  padding: 24px 0;
}
</style>
