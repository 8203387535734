<doc>
  Row Vue.js component
</doc>

<template>
  <div class="row"><slot /></div>
</template>

<script>
export default {
	name: "GridRow"
}
</script>

<style lang="scss" src="./Grid.scss" scoped>
</style>
