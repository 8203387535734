<template>
  <ConfigEditorManager
    type="Subscription"
    :getConfig="getConfig"
    :getAllConfig="getAllConfig"
    :addConfig="addConfig"
    :deleteConfig="deleteConfig"
    :updateConfig="updateConfig"
    :hideDefaultCheckbox="true"
  />
</template>

<script>
import { bbRateSubscription } from "../../bb-api"
import ConfigEditorManager from "../ConfigEditorManager"

export default {
  name: "SubscriptionManager",
  components: {
    ConfigEditorManager
  },
  methods: {
    async getAllConfig() {
      const data = await bbRateSubscription.getSubscriptionConfigs(
        {},
        res => res
      )
      return data
    },
    getConfig(id, callBack) {
      bbRateSubscription.getSubscriptionConfig({ id }, config => {
        if (id) {
          callBack(config)
        } else {
          callBack({
            config: {
              dashboard_config: {
                field_overrides: {
                  property_type: {
                    label: "Property Type",
                    options: [
                      {
                        label: "Single Family Home",
                        value: "single_family_home"
                      },
                      {
                        label: "Townhouse",
                        value: "townhouse"
                      },
                      {
                        label: "Condominium",
                        value: "condo"
                      },
                      {
                        label: "Multi Unit Family",
                        value: "multi_family"
                      },
                      {
                        label: "Manufactured",
                        value: "manufactured_home"
                      }
                    ]
                  }
                },
                mortgage_terms: [
                  {
                    type: "fixed",
                    term: 30,
                    label: "30yr Fixed"
                  },
                  {
                    type: "fixed",
                    term: 20,
                    label: "20yr Fixed"
                  },
                  {
                    type: "fixed",
                    term: 15,
                    label: "15yr Fixed"
                  },
                  {
                    type: "arm",
                    term: "10",
                    label: "10y/6m ARM"
                  },
                  {
                    type: "arm",
                    term: "7",
                    label: "7y/6m ARM"
                  },
                  {
                    type: "arm",
                    term: "5",
                    label: "5y/6m ARM"
                  }
                ]
              },
              rate_alert_config: {
                enabled: true,
                frequency: "weekly"
              }
            }
          })
        }
      })
    },
    addConfig(payload, callBack) {
      console.log("payload", payload)
      if (!payload.brand_id) return BB.Toastr.error("Please select brand.")

      bbRateSubscription.updateSubscriptionConfig(payload, function({ id }) {
        callBack(id)
      })
    },
    updateConfig(payload, callBack) {
      bbRateSubscription.updateSubscriptionConfig(payload, () => {
        callBack()
      })
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.getAllConfig()
    },
    async deleteConfig(id) {
      await bbRateSubscription.deleteSubscriptionConfig(id)
    }
  }
}
</script>

<style lang="scss"></style>
