var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex profile-thumbs",
      staticStyle: { "flex-wrap": "wrap" }
    },
    _vm._l(_vm.profiles, function(profile, index) {
      return _c(
        "GridColumn",
        {
          key: profile.id,
          attrs: {
            size: _vm.sizes,
            item: profile,
            "data-program-id": profile.id
          }
        },
        [
          _c("ProgramProfile", {
            attrs: { program: profile, index: index, isThumb: true }
          })
        ],
        1
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36ce5111", { render: render, staticRenderFns: staticRenderFns })
  }
}