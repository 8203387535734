<doc>
    Listing Pricing Set Tool Vue.js component
</doc>

<template>
  <BaseModal
      ref="modal"
      v-model:show-modal="showModal"
      title="Edit Pricing"
      id="newPricingSetModal"
      :showCancelBtn="false"
      :isLoading="isLoading"
      okLabel="Close"
      :closeWhenOK="true"
      :large="true"
  >
    <pricing-set-tool :loan-info="loanInfo" :pricing-config="pricingConfig" :set-pricing="setPricing" />
  </BaseModal>
</template>

<script>
    import PricingSetTool from "./PricingSetTool";
    import BaseModal from "../../components/BaseModal"

    export default {
        name: "ListingPricingSetModal",
        components: {
            PricingSetTool,
            BaseModal
        },
        data() {
            return {
                pricingConfig: {},
                loanInfo: null,
                showModal: false,
                modalTitle: "",
                isLoading: false
            }
        },
        props: {

        },
        updated(){

        },
        methods: {
            setPricing(info){
                // save/update pricing config
                $.ajax({
                  url: `/api/v1/listing-pricing-sets`,
                  type: "POST",
                  contentType: "application/json",
                  dataType: "json",
                  data: JSON.stringify({
                    details: info,
                    owner: $("body").data("user_id"),
                    listing_id: this.loanInfo.listing_id,
                    listing_type: "homescout"
                  })
                })
            },
            resetModal(){
                this.loanInfo=null
                this.pricingConfig={}
            },
            async init(){
                // get existing pricing config if available
                let result=await (await fetch(`/api/v1/listing-pricing-sets?listing_id=${this.loanInfo.listing_id}&listing_type=homescout`)).json()
                this.pricingConfig=result && result.details ? result.details : {}
            },
            async openModal(loanInfo) {
                this.isLoading = true
                this.resetModal()
                this.showModal = true
                this.modalTitle="Edit Pricing"
                this.loanInfo=loanInfo
                await this.init()
                this.isLoading = false
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>