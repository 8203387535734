/**
 * Subscription API wrapper
 */

const ENDPOINT = "/api/v1/lead_workflow"
const FIND_ENDPOINT = "/api/v1/lead-workflows"
const ACTIVE_ENDPOINT = "/api/v1/lead-workflow-active"

const bbLeadWorkflow = {
  get(params, callback = null) {
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  checkIsActive(params, callback = null) {
    return $.get(ACTIVE_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(leadWorkflowConfig, callback) {
    if (!leadWorkflowConfig.id) {
      console.error("Lead Workflow Config id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(leadWorkflowConfig),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  /*
    {
        disclaimers:{
            general: <string>,
            lead_verification: <string>,
            final_result: <string>,
        },
        steps:[
            {
                id: <string>,
                type: <string>,
                header: <string>,
                subheader: <string>,
                items:[
                    {
                        label,
                        value
                    },
                    ...
                ]
            },
            {
                id,
                type,
                title,
                range_start,
                range_end,
                point_size,
                step,
                prefix,
                condition:{
                    <key>: <value>
                }
            },
            ...
        ],
        primary_color: <string>,
        branded: <bool>,
        background_img: <string>,
        validation_required: <bool>,
        phone_required: <bool>,
        subscribe_enabled: <bool>
    }
    */
  add(leadWorkflowConfig, callback) {
    if (!leadWorkflowConfig.config.steps) {
      console.error("Steps are required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(leadWorkflowConfig),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  delete(id, callback) {
    return $.ajax({
      url: ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  find(params, callback = null) {
    return $.get(FIND_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbLeadWorkflow
