<template>
    <div class="editorContainer">
        <div v-if="initialLoad">
            Loading...
        </div>
        <div v-if="requests && !initialLoad" class="integration-status-table table-responsive" style="max-height:600px;overflow-y:scroll;">
            <table class="table">
                <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Referrer</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(request, i) in requests" :class="{validated:request.validated}">
                    <td class="date-time">{{request.dateTime}}</td>
                    <td>{{request.referrer}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default{
        name: "Status",
        props: {
            integration: {type: Object}
        },
        data(){
            return{
                pollingActive: true,
                requests: null,
                lastRequestTime: null,
                initialLoad: true
            }
        },
        created(){
            this.pollStatus()
            this.initialLoad=true
        },
        destroyed(){
            this.pollingActive=false
            this.initialLoad= true
        },
        methods:{
            async pollStatus(){
                let lastRequestTime=null
                while(this.pollingActive){
                    let requests=[];
                    let data=null;
                    let paramStr=( this.lastRequestTime ? "&min_timestamp="+this.lastRequestTime : "" )
                    if(this.integration)data=await $.get("/api/v1/embed-requests?integration_id=" + this.integration.id + "&limit=25"+paramStr)
                    this.initialLoad=false
                    if(data && data.length>0) {
                        data.forEach(request => {
                            requests.push(Object.assign(request, {
                                dateTime: new Date(request.timestamp * 1000).toLocaleString(),
                                validated: (request.validated == "1" ? true : false)
                            }))
                        });
                    }

                    if(requests && requests.length){
                        this.requests = ( this.requests ? this.requests : [] )
                        for(let i=requests.length-1; i>=0; i--){
                            this.requests.unshift(requests[i])
                        }
                    }

                    if(requests[0] && requests[0].timestamp)this.lastRequestTime=requests[0].timestamp
                    await this.wait(2000)
                }
            },
            async wait(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
        }
    }
</script>