<doc>
  Loader placeholder component
</doc>

<template>
<div class="bb-progress">
	<div
		:class="classNameWrapper"
		:style="`height: ${height}px; width: ${width}%; margin: 0 auto;`">
		<div
			:class="className"
			role="progressbar"
			:style="`width: ${progress}%`"
			:aria-valuenow="progress"
			aria-valuemin="0"
			aria-valuemax="100">
				<span v-if="showLabel">{{progress}}%</span>
			</div>
	</div>
	<div v-if="phrases.length" class="phrase">{{phrase}}</div>
</div>
</template>

<script>
export default {
	name: "ProgressBar",
	props: {
		progress: { type: [Number, String], default: 0 },
		height: { type: [Number, String], default: 25 },
		width: { type: [Number, String], default: 100 },
		showLabel: { type: Boolean, default: false },
		hasBorder: { type: Boolean, default: false },
		striped: { type: Boolean, default: false },
		animated: { type: Boolean, default: false },
		phrases: { type: Array, default: [] },
		theme: { type: String }
	},
	computed: {
		classNameWrapper: function() {
			let className = "progress"
			if (this.hasBorder) {
				className += ` has-border`
			}
			return className
		},
		className: function() {
			let className = "progress-bar"
			if (this.theme) {
				className += ` bg-${this.theme}`
			}
			if (this.striped) {
				className += " progress-bar-striped"

				if (this.animated) {
					className += " progress-bar-animated"
				}
			}
			return className
		},
		phrase: function() {
			let phrase = ""
			if (this.phrases.length) {
				const progress = Number(this.progress)
				if (progress <= 33) {
					phrase = this.phrases[0]
				} else if (progress > 33 && progress <= 66) {
					phrase = this.phrases[2]
				} else {
					phrase = this.phrases[3]
				}
			}
			return phrase
		}
	}
}
</script>

<style lang="scss" src="./ProgressBar.scss" scoped>
</style>
