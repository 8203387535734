<template>
  <div class="ce-wrapper">
    <div v-if="isLoading" class="text-center">
      <LoaderPlaceholder class="loader" type="circle" />
    </div>

    <div v-else>
      <div class="d-flex">
        <div class="ce-wrapper__add-new" v-if="addingNew || config">
          <ConfigNameInput
            :disabled="type === 'Subscription'"
            v-model.trim="configName"
            @input="configChanged"
          />
          <div class="d-flex form-inline p-3">
            <div class="form-group">
              <label for="brand-selector">Brand:</label>
              <select
                class="form-control input-sm"
                v-model="brand_id"
                id="brand-selector"
                @change="brandChanged"
              >
                <option disabled value="">Please select one</option>
                <option
                  v-for="brand in availableBrands"
                  :value="brand.id"
                  :key="brand.id"
                >
                  {{ brand.name }}
                </option>
              </select>
            </div>
            <div class="checkbox ml-3 pt-1" v-if="!hideDefaultCheckbox">
              <label
                ><input
                  type="checkbox"
                  v-model="isDefault"
                  @change="configChanged"
                />
                Default</label
              >
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex form-inline" v-if="availableConfig.length > 0">
            <div class="form-group">
              <label for="brand-selector">{{ type }} Config:</label>
              <select
                class="form-control input-sm"
                v-model="selectedConfigId"
                @change="changeSelectedConfig"
              >
                <option disabled value="">Please select one</option>
                <option
                  v-for="select in availableConfig"
                  :value="select.id"
                  :key="select.id"
                >
                  {{
                    type === "Subscription"
                      ? getBrandById(select.brand_id).name + " Config"
                      : select.name
                  }}
                </option>
              </select>
            </div>
          </div>

          <div v-else>
            <p>Please create a new config</p>
          </div>
        </div>
        <AddCancelBtn
          :key="type"
          class="ml-auto"
          :onClick="addNew"
          :isCancel="addingNew || !!config"
        />
      </div>

      <div v-if="config" class="editor-wrapper">
        <div v-if="isLoadingConfig" class="text-center">
          <LoaderPlaceholder class="loader" type="circle" />
        </div>
        <div v-else @mouseover="addPreview" @mouseout="removePreview">
          <vjsoneditor
            v-model="config"
            :plus="true"
            :options="options"
            :height="height"
            @error="onError"
          />
        </div>

        <div class="d-flex pt-3">
          <div class="ml-auto">
            <BaseButton
              v-if="changed && !addingNew"
              class="mr-2"
              @click="saveConfig"
            >
              Save
            </BaseButton>
            <BaseButton
              v-if="addingNew && configName && brand_id"
              class="mr-2"
              @click="handleAddConfig"
            >
              Add
            </BaseButton>

            <BaseButton
              v-if="config || addingNew"
              outline
              @click="handleDeleteConfig"
            >
              Delete
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div :id="tooltipID" class="ce_tooltip" role="tooltip"></div>
  </div>
</template>

<script>
import vjsoneditor from "v-jsoneditor"
import { createPopper } from "@popperjs/core"
import AddCancelBtn from "../AddCancelBtn"
import ConfigNameInput from "../ConfigNameInput"

export default {
  name: "ConfigEditorManager",
  components: {
    vjsoneditor,
    AddCancelBtn,
    ConfigNameInput
  },
  props: {
    type: { type: String, default: "Config" },
    getAllConfig: { type: Function, required: true },
    getConfig: { type: Function, required: true },
    addConfig: { type: Function, required: true },
    deleteConfig: { type: Function, required: true },
    updateConfig: { type: Function, required: true },
    hideDefaultCheckbox: { type: Boolean, default: false }
  },
  data() {
    const vm = this
    return {
      tooltipID: `prev-wrapper-${Date.now()}`,
      selectedConfigId: "",
      isLoading: true,
      isLoadingConfig: true,
      brand_id: "",
      config: null,
      height: "400px",
      changed: false,
      addingNew: false,
      configName: null,
      isDefault: false,
      availableBrands: null,
      availableConfig: [],
      options: {
        onChangeText(configJson) {
          vm.changed = true
        }
      }
    }
  },
  async mounted() {
    // Get the available brands to fill the dropdown
    await this.getAvailableBrands()
    // Get the config id from the url(if any)
    const urlParams = new URLSearchParams(window.location.search)
    // If there is a config id in the url, load it
    if (urlParams.has("id")) {
      this.selectedConfigId = urlParams.get("id")
      await this.handleGetConfig(this.selectedConfigId)
    }
    // get the available configs
    await this.handleGetAllConfig()
  },
  methods: {
    async handleGetAllConfig() {
      const res = await this.getAllConfig()
      this.getAllConfigCallBack(res)
    },
    async getAllConfigCallBack(res) {
      // Check if there are any configs
      if (Array.isArray(res)) {
        this.availableConfig = res
        if (!this.selectedConfigId) {
          // If there is no selected config find if there is a default config
          /*this.availableConfig.forEach(config => {
            if (config.is_default == "1") {
              this.selectedConfigId = config.id
              this.changeSelectedConfig(this.selectedConfigId)
            }
          })*/
        }
      } else {
        this.availableConfig = []
      }
      this.isLoading = false
    },
    getBrandById(id) {
      return this.availableBrands.find(brand => brand.id === id)
    },
    configChanged() {
      this.changed = true
    },
    brandChanged() {
      if (this.type === "Subscription") {
        this.configName = this.getBrandById(this.brand_id).name + " Config"
      }
    },
    addPreview(e) {
      // check event target class name
      if (e.target.className.includes("jsoneditor-url")) {
        const previewWrapper = document.getElementById(this.tooltipID)

        previewWrapper.innerHTML = `<img style="max-width:100%" src="${e.target.innerText}"><div class="ce_tooltip-arrow" data-popper-arrow></div>`
        this.popperInstance = createPopper(e.target, previewWrapper, {
          placement: "top"
        })
      }
    },
    removePreview(e) {
      if (e.target.className.includes("jsoneditor-url")) {
        this.popperInstance.destroy()
      }
    },
    onError() {
      console.error("Editor error")
    },
    async handleGetConfig(id) {
      this.isLoadingConfig = true
      this.changed = false
      const vm = this
      await this.getConfig(id, config => {
        // Check if it was not found
        if (config && config.id !== 0) {
          vm.brand_id = config.brand_id || ""
          vm.isDefault = config.is_default === "1"
          vm.configName =
            vm.type === "Subscription"
              ? config.brand_id
                ? vm.getBrandById(config.brand_id).name + " Config"
                : "Add new " + vm.type
              : config.name || "Add new " + vm.type

          vm.config = config
        } else {
          vm.selectedConfigId = ""
        }
        vm.isLoadingConfig = false
      })
    },
    async handleAddConfig() {
      console.log("handleAddConfig", this.brand_id)
      const payload = {
        name: this.configName,
        brand_id: this.brand_id
      }

      if (this.type === "Contact Form") {
        payload.config = this.config.config
        this.changed = false
      } else if (this.type === "Chat Config") {
        payload.config_json = this.config.config_json
        payload.enabled = this.config.enabled
      } else {
        if (this.type !== "Subscription") {
          payload.details = this.config.details
          payload.is_default = this.isDefault ? 1 : 0
        } else {
          payload.config = this.config.config
        }
        this.changed = false
      }
      const vm = this

      if (this.type === "Subscription") {
        this.addConfig(payload, () => {
          vm.changed = false
          vm.addingNew = false
          vm.saveCallback("config added successfully.")
        })
      } else if (this.type === "Chat Config") {
        this.addConfig(payload, response => {
          if (response.status == "error") {
            BB.Toastr.error(response.message)
          } else {
            vm.changed = false
            vm.addingNew = false
            vm.selectedConfigId = response.id
            vm.changeSelectedConfig()
            vm.saveCallback("Config added successfully.")
          }
        })
      } else {
        this.addConfig(payload, id => {
          vm.changed = false
          vm.addingNew = false
          vm.selectedConfigId = id
          vm.changeSelectedConfig()
          vm.saveCallback("Config added successfully.")
        })
      }
    },
    saveConfig() {
      const saveCallback = this.saveCallback
      const payload = {
        id: this.selectedConfigId,
        brand_id: this.brand_id,
        name: this.configName
      }

      if (this.type === "Subscription") {
        this.changed = false
        this.updateConfig(this.config, () => {
          saveCallback("config saved successfully.")
        })
      } else if (this.type === "Contact Form") {
        payload.config = this.config.config
        this.changed = false
        this.updateConfig(payload, () => {
          saveCallback("config saved successfully.")
        })
      } else if (this.type === "Chat Config") {
        payload.config_json = this.config.config_json
        payload.enabled = this.config.enabled
        this.updateConfig(payload, response => {
          if (response) {
            if (response.status == "error") {
              BB.Toastr.error(response.message)
            } else {
              saveCallback(response.message)
            }
          }
        })
      } else {
        payload.details = this.config.details
        payload.is_default = this.isDefault ? 1 : 0
        this.changed = false
        this.updateConfig(payload, () => {
          saveCallback("config saved successfully.")
        })
      }
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.handleGetAllConfig()
    },
    clearForm(getAll = true) {
      this.changed = false
      this.brand_id = ""
      this.config = null
      this.selectedConfigId = ""
      this.configName = ""
      //window.history.pushState({}, null, "")
      if (getAll) {
        this.addingNew = false
        this.handleGetAllConfig()
      }
    },
    async getAvailableBrands() {
      let brands= await BB.brandManage.find({})
      // sort brands by name
      this.availableBrands = brands.sort((a, b) => a.name.localeCompare(b.name))
    },
    changeSelectedConfig() {
      this.handleGetConfig(this.selectedConfigId)
      window.history.pushState(
        {},
        null,
        `?id=${this.selectedConfigId}${window.location.hash}`
      )
    },
    addNew() {
      // TODO: let user cancel and show the config selector.
      if (this.addingNew || this.config) {
        this.addingNew = false
        this.config = null
      } else {
        this.addingNew = true
        this.handleGetConfig(null)
      }
      this.clearForm(false)
    },
    async handleDeleteConfig() {
      if (this.addingNew) {
        this.clearForm()
        return
      }
      if (confirm("Do you really want to delete this?")) {
        await this.deleteConfig(this.selectedConfigId)
        this.clearForm()
      }
    }
  }
}
</script>

<style lang="scss" src="./ConfigEditorManager.scss"></style>
