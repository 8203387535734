import Toastr from "toastr"
import { arrayMove } from "../../helpers"

const ENDPOINT = "/pricing-set-manage.json"

// Initial state
const state = {
	bank_id: null,
	currentProgram: null,
	chart: null,
    pricing: {},
    default_set: {}
}
const mutations = {
	SET_BANK_ID(state, bank_id) {
		state.bank_id = bank_id
	},
    SET_DEFAULT_SET(state, set) {
        state.default_set = set
    },
	LOAD_CHART(state, chart) {
		state.chart = chart
	},
	LOAD_PRICING(state, pricing) {
		state.pricing = pricing
	},
	SET_CURRENT_PROGRAM(state, program) {
		state.currentProgram = program
	},
	TOGGLE_PROGRAM_DETAILS_MODAL(state, condition) {
		state.showProgramDetailsModal = condition
	},
	REORDER_PROGRAM_PROFILES(state, { oldIndex, newIndex }) {
		arrayMove(state.currentEditedItem.pricing_profiles, oldIndex, newIndex)
	}
}
const actions = {
	resetCurrentProgram({ commit }) {
        commit("SET_CURRENT_PROGRAM", null)
        //commit("SET_DEFAULT_SET", {})
		commit("LOAD_CHART", null)
		commit("LOAD_PRICING", {})
	},
	showEditProgramDetailsModal({ commit, dispatch }, id) {
		dispatch("showEditModal", { id: id, mutation: "TOGGLE_PROGRAM_DETAILS_MODAL" })
		commit("SET_EDIT", true)
	},
	toggleProgramDetailsModal({ dispatch, state, commit }, condition) {
		dispatch("toggleModal", { mutation: "TOGGLE_PROGRAM_DETAILS_MODAL", condition: condition })
		dispatch("resetCurrentProgram")
	},
	getProgramDetails({ dispatch, state, commit }, program) {
		commit("SET_CURRENT_PROGRAM", program)
		fetch(`/api/v1/price-profile?id=${program.pricing_profile_id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			},
			credentials: "same-origin"
			})
			.then(res => {
				return res.json()
			})
			.then(response => {
				if (response.status !== "error") {
					commit("LOAD_PRICING", response)
				} else {
				}
			})
			.catch(err => {
				console.error(err)
				//dispatch("toggleItemModal", false)
			});
		BB.chart.getData({ profile_id: program.pricing_profile_id, type: "scatter_plot"}).then(function(data) {
			commit("LOAD_CHART", data);
		});
	},
	saveProfileList({ commit, dispatch, state }, payload) {
		Toastr.success("Saving profile list order…")
		const data = {
			loid: state.loid,
			bank_id: state.bank_id,
			pricing_set_id: state.currentEditedItem.id
		};
		commit("REORDER_PROGRAM_PROFILES", payload)
		data.new_weights = [];
		state.currentEditedItem.pricing_profiles.forEach(function(set, index) {
			this.push({
				wid: set.id,
				ppid: set.pricing_profile_id
			})
		}, data.new_weights);

		fetch("/pricing-reorder.json", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			credentials: "same-origin",
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(data => {
				commit("UPDATE_ITEM", { index: state.currentEditedItem.weight, item: state.currentEditedItem })
				Toastr.success("Profile list updated…")
			})
			.catch(err => {
				Toastr.error("There was an error saving the profile list, please try again later.")
				console.error(err)
			})
	},
	getItemById({}, ID) {
		return fetch(`${ENDPOINT}?id=${ID}`, {
			credentials: "same-origin"
		})
			.then(res => res.json())
			.then(data => {
				return data.set_data
			})
	},
	cloneProgramSet({ dispatch, state }, ID) {
		Toastr.success("Cloning programs…")
	},
	saveProgramSet({ commit, dispatch, state }, payload) {
		Toastr.success("Saving program…")
		const data = payload.data || payload
		let setId = data.id
		const isEdit = !!setId
		fetch(ENDPOINT, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			credentials: "same-origin",
			body: JSON.stringify(data)
		})
		.then(res => {
			return res.json()
		})
		.then(data => {
			Toastr.success("Program saved…")
			setId = setId || data.set_id

			return fetch(`${ENDPOINT}?id=${setId}`, {
				credentials: "same-origin"
			}).then(res => res.json())
		})
		.then(data => {
			dispatch("setDefaultSet");
			if (isEdit) {
				commit("UPDATE_ITEM", { index: data.set_data.weight, item: data.set_data })
			} else {
				commit("ADD_ITEM", data.set_data)
			}
			dispatch("toggleItemModal", false)
		})
		.catch(err => {
			console.error(err)
			dispatch("toggleItemModal", false)
		})
	},
	setDefaultSet({ commit, dispatch, state }){
        return fetch(`${ENDPOINT}?default=true`, {
            credentials: "same-origin"
        })
            .then(res => res.json())
            .then(data => {
                commit("SET_DEFAULT_SET",data.set_data);
            })
	}
}

export default {
	state,
	mutations,
	actions
}
