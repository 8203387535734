/**
 * Subscription API wrapper
 */

const ENDPOINT = "/api/v1/pricing-set"

const bbPricingSet = {
    get(id, callback = null) {
        return $.get(ENDPOINT, {id}, function (data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
    update(pricingSetData, callback) {
        if (!pricingSetData.id) {
            console.log("Pricing Set id missing")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }

        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(pricingSetData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
    /*
    {
        fields:[
            {
                id: <string>,
                label: <string>,
                description: <string>,
                type: <string>,
                required:<bool>
            },
            ...
        ],
        submit: {
            "label": <string>
        },
        show_left_column: <bool>,
    }
    */
    add(pricingSetData, callback) {
        if (!pricingSetData.weights) {
            console.log("program weights are required")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(contactFormConfig),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                callback.call(this, thrownError)
                }
            }
        })
    },
    delete(id, callback) {
        return $.ajax({
            url: ENDPOINT + "?id=" + id,
            type: "DELETE",
            contentType: "application/json",
            dataType: "json",
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                callback.call(this, thrownError)
            }
          }
        })
    },
    find(params, callback=null){
        return $.get(ENDPOINT, params, function (data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    }
}

export default bbPricingSet
