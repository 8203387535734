<template>
    <div class="cardsWrapper">
        <div class="form-group">
            <div v-if="integration && integration.app_settings">
              <textarea
                      class="form-control install-script"
                      readonly
                      v-model="integration.app_settings.install_script"
              ></textarea>
            </div>
        </div>
        <div>
            <button @click="confirmDelete"><span v-if="!deleting">Delete</span><span v-else>Cancel</span></button>
            <span v-if="deleting"><span>type "delete" to confirm</span><input @keyup="validateConfirmation" type="text" /><span><button v-if="ready" @click="sendDelete">Confirm</button></span></span>
        </div>
    </div>
</template>

<script>
    export default{
        name: "Install",
        props: {
            integration: {type: Object},
            onDelete: {type: Function}
        },
        data(){
            return{
                deleting: false,
                ready: false
            }
        },
        created(){
            this.deleting=false
            this.ready=false
        },
        methods:{
            confirmDelete(){
                if(this.deleting){
                    this.deleting=!this.deleting
                    this.ready=false
                }
                else{
                    this.deleting=!this.deleting
                }
            },
            async sendDelete(){
                if(this.deleting){
                    await BB.integration.delete(this.integration.id)
                    this.onDelete()
                }
            },
            validateConfirmation(e){
                if(e.currentTarget.value=="delete"){
                    this.ready=true
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .install-script {
        height: 120px;
    }
</style>