const Mustache = require("mustache")
const Moment = require("moment")

function initPairModal() {
	const $loading = $(".pair-modal_loading")
	const $noResults = $(".pair-modal_no-results")
	const $pairModal = $("#pair-modal")
	const $activityList = $("#activity-list")
	const activityTpl = `<div class="network-table__row">
        <div class="network-table__column network-table__picture">
          <img src="{{imgSrc}}" alt="Activity thumb">
        </div>
      <div class="network-table__column network-table__user-info">
        <span class="network-table__user-info-title">{{title}}</span>
        <span class="network-table__user-info-subtitle">{{time}}</span>
      </div>
    </div>`

	$(".network-table__pair-modal").click(function() {
		$loading.removeClass("hidden")
		$noResults.addClass("hidden")
		$activityList.addClass("hidden").empty()
		$pairModal.modal("show")
		const pid = $(this).data("pid")
		BB.orgActivity.getPairActivity(pid, null, null, 5, showData)
	})

	function showData(data) {
		console.log("data", data)

		if (!data.length) {
			$noResults.removeClass("hidden")
		} else {
			data.forEach(function(element) {
				console.log(element)
				//$activityList.append()
				//     const loProfileTpl = $('#lo-profile-tpl').html();
				//const rendered = Mustache.render(loProfileTpl, data.lo);
				//$('.profileContent').html(rendered);
				element.title = makeTitle(element)
				element.time = makeTime(element.timestamp)
				element.imgSrc = makeImg(element)
				const rendered = Mustache.render(activityTpl, element)
				$activityList.append(rendered)
			})
			$activityList.removeClass("hidden")
		}
		$loading.addClass("hidden")
	}
}

const makeTime = timestamp => {
	return Moment.unix(timestamp).calendar(null, {
		sameElse: "On MMM D, YYYY"
	})
}

const makeTitle = function(item) {
	let title
	switch (item.type) {
		case "pair":
			title = `${item.lo.name} is now paired with ${item.details.realtor.name}`
			break
		case "share":
			title = `${item.lo.name} shared ${item.details.listing.title} on ${item.details.share_type} with ${
				item.details.realtor.name
			}`
			break
		case "flyer":
			title = `${item.lo.name} create a flyer for ${item.details.listing.title} with ${item.details.realtor.name}`
			break
	}
	return title
}

const makeImg = function(item) {
	let imgSrc
	switch (item.type) {
		case "flyer":
			imgSrc = ( item.details.listing.picture ? item.details.listing.picture[0] : null)
			break
		case "share":
            imgSrc = ( item.details.listing.picture ? item.details.listing.picture[0] : null)
			break
	}
	return imgSrc
}

// Render Listings
const bbPairModal = {
	init: () => {
		initPairModal()
	}
}

module.exports = bbPairModal
