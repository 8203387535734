var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bb-progress" }, [
    _c(
      "div",
      {
        class: _vm.classNameWrapper,
        style:
          "height: " +
          _vm.height +
          "px; width: " +
          _vm.width +
          "%; margin: 0 auto;"
      },
      [
        _c(
          "div",
          {
            class: _vm.className,
            style: "width: " + _vm.progress + "%",
            attrs: {
              role: "progressbar",
              "aria-valuenow": _vm.progress,
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }
          },
          [
            _vm.showLabel
              ? _c("span", [_vm._v(_vm._s(_vm.progress) + "%")])
              : _vm._e()
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm.phrases.length
      ? _c("div", { staticClass: "phrase" }, [_vm._v(_vm._s(_vm.phrase))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7a328c38", { render: render, staticRenderFns: staticRenderFns })
  }
}