var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-circle pull-right",
        class: { cancel: _vm.editorActive },
        attrs: { type: "button" },
        on: { click: _vm.addNew }
      },
      [_c("div", [_vm._v("+")])]
    ),
    _vm._v(" "),
    !_vm.editorActive && _vm.available_templates
      ? _c(
          "div",
          [
            _vm.tableLoading
              ? _c("div", [_vm._v("\n      Loading ...\n    ")])
              : _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("vue-good-table", {
                        attrs: {
                          columns: _vm.headers,
                          rows: _vm.templateData,
                          "search-options": {
                            enabled: true,
                            searchFn: _vm.tableSearch,
                            trigger: "enter"
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "active"
                                  ? _c("span")
                                  : _vm._e(),
                                _vm._v(" "),
                                props.column.field == "preview"
                                  ? _c("span", [
                                      _c("img", {
                                        attrs: {
                                          width: "200px",
                                          src: props.row.preview
                                        }
                                      })
                                    ])
                                  : props.column.field == "brandPermissions"
                                    ? _c(
                                        "span",
                                        [
                                          _vm._l(props.row.orgNames, function(
                                            item
                                          ) {
                                            return _c("ul", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(item) +
                                                  "\n              "
                                              )
                                            ])
                                          }),
                                          _vm._v(" "),
                                          _vm._l(props.row.brandNames, function(
                                            item
                                          ) {
                                            return _c(
                                              "ul",
                                              {
                                                staticStyle: { display: "none" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(item) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : props.column.field == "edit"
                                      ? _c("span", [
                                          _c(
                                            "button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  _vm.configSelectedTemplate(
                                                    props.row.id
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("View/Edit")]
                                          )
                                        ])
                                      : props.column.field == "update"
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.updateBrandPermissions(
                                                      props.row.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Update Brand Permissions"
                                                )
                                              ]
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  props.formattedRow[
                                                    props.column.field
                                                  ]
                                                ) +
                                                "\n            "
                                            )
                                          ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]),
            _vm._v(" "),
            _c("FlyerTemplateModal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isModalVisible,
                  expression: "isModalVisible"
                }
              ],
              ref: "modal",
              attrs: {
                title: _vm.flyerTitle,
                orgAndBrandNames: this.orgAndBrandNames
              },
              on: { cancel: _vm.cancel }
            })
          ],
          1
        )
      : _vm.editorActive
        ? _c("div", [
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "30px"
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "loader loader-circle tt-loading"
                    })
                  ]
                )
              : _c(
                  "div",
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-xs-9" }, [
                        _c("div", { staticClass: "templateName" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.name,
                                expression: "name"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.name = $event.target.value
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-3",
                          staticStyle: { "text-align": "right" }
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.type,
                                  expression: "type"
                                }
                              ],
                              staticClass: "select-type",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.type = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v("Please select one")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { key: "flyer", attrs: { value: "flyer" } },
                                [_vm._v("flyer")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { key: "share", attrs: { value: "share" } },
                                [_vm._v("share")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  key: "quote_flyer",
                                  attrs: { value: "quote_flyer" }
                                },
                                [_vm._v("quote_flyer")]
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "Split",
                      { attrs: { gutterSize: 5 } },
                      [
                        _c(
                          "SplitArea",
                          {
                            staticClass: "leftPane",
                            attrs: { size: _vm.leftWidth, minSize: 100 }
                          },
                          [
                            _c("div", [
                              _c("iframe", {
                                class: _vm.type,
                                attrs: { srcdoc: _vm.preview }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "SplitArea",
                          {
                            staticClass: "rightPane",
                            attrs: { size: _vm.rightWidth }
                          },
                          [
                            _c("div", { staticClass: "tabWrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "drawer-tab",
                                  class: { active: _vm.drawerToggleActive },
                                  on: { click: _vm.toggleDrawer }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-chevron-right"
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "paneHeader",
                                class: { changed: _vm.changed }
                              },
                              [_vm._v("\n            Source Code\n          ")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "content" }, [
                              _c(
                                "form",
                                {
                                  ref: "templateForm",
                                  staticClass: "form",
                                  attrs: { id: "template-form" },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "fieldset",
                                    [
                                      _c("codemirror", {
                                        ref: "myCm",
                                        attrs: {
                                          value: _vm.template,
                                          options: _vm.cmOptions
                                        },
                                        on: { input: _vm.onCmCodeChange }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "activeCheckbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.active,
                            expression: "active"
                          }
                        ],
                        attrs: { type: "checkbox", id: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.active)
                            ? _vm._i(_vm.active, null) > -1
                            : _vm.active
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.active,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.active = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.active = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.active = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "checkbox" } }, [
                        _vm._v("Active")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "submitBtn btn btn-modern",
                        on: { click: _vm.save }
                      },
                      [_vm._v("Save")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "submitBtn btn btn-modern purple",
                        on: { click: _vm.convert }
                      },
                      [
                        !_vm.convert_in_progress
                          ? _c("span", [_vm._v("Convert")])
                          : _c("span", [
                              _c("div", {
                                staticClass: "loader loader-circle tt-loading"
                              })
                            ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.result
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "panel panel-default",
                              staticStyle: { "margin-top": "60px" }
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("div", { staticClass: "panel-body" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.result.file
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.name) +
                                        " " +
                                        _vm._s(_vm.dateStr)
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
          ])
        : _c("div", [_vm._v("\n    Loading ...\n  ")])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("PDF Ready")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7492a6c6", { render: render, staticRenderFns: staticRenderFns })
  }
}