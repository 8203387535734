<template>
  <ConfigEditorManager
    type="Rate Table"
    :getConfig="getConfig"
    :getAllConfig="getAllConfig"
    :addConfig="addConfig"
    :deleteConfig="deleteConfig"
    :updateConfig="updateConfig"
  />
</template>

<script>
import { bbRateTableConfig } from "../../bb-api"
import ConfigEditorManager from "../ConfigEditorManager"

export default {
  name: "RateTableManager",
  components: {
    ConfigEditorManager
  },
  methods: {
    getAllConfig() {
      return bbRateTableConfig.get({}, res => res)
    },
    getConfig(id, callBack) {
      bbRateTableConfig.get({ id }, config => {
        callBack(config)
      })
    },
    addConfig(payload, callBack) {
      // validate payload before saving
      if (!payload.name) return BB.Toastr.error("Please complete the name.")
      if (!payload.brand_id) return BB.Toastr.error("Please select brand.")

      bbRateTableConfig.add(payload, function({ id }) {
        callBack(id)
      })
    },
    updateConfig(payload, callBack) {
      bbRateTableConfig.update(payload, () => {
        callBack()
      })
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.getAllConfig()
    },
    async deleteConfig(id) {
      await bbRateTableConfig.delete(id)
    }
  }
}
</script>

<style lang="scss"></style>
