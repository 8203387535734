<doc>
   Vue-select wrapper component
   https://vue-select.org/
</doc>

<template>
  <vSelect
    :placeholder="placeholder"
    :value="value"
    :options="options"
    :reduce="option => option[reducer]"
    @input="onInputLocal"
    :components="{ OpenIndicator }"
  >
    <div slot="no-options">
      {{ noOptionTxt }}
      <span v-if="addOption" @click="addOption" class="vs__add-new">
        Add new one <strong>+</strong>
      </span>
    </div>
  </vSelect>
</template>

<script>
import vSelect from "vue-select"
export default {
  name: "CustomDropdown",
  props: {
    reducer: { type: String, required: true },
    placeholder: { type: String, default: "" },
    value: { type: [String, Object] },
    name: { type: String },
    options: { type: Array, default: [] },
    addOption: { type: [Function, Boolean], default: false },
    onInput: { type: Function, default: () => {} },
    noOptionTxt: { type: String, default: "No matching options." }
  },
  data() {
    return {
      OpenIndicator: {
        render: createElement =>
          createElement("i", { class: { fas: true, "fa-caret-down": true } })
      }
    }
  },
  components: { vSelect },
  methods: {
    onInputLocal: function(value) {
      this.onInput(value, null, this.name)
    }
  }
}
</script>

<style lang="scss" src="./CustomDropdownStyle.scss"></style>
