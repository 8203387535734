var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ce-wrapper" }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("LoaderPlaceholder", {
              staticClass: "loader",
              attrs: { type: "circle" }
            })
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm.addingNew || _vm.config
                ? _c(
                    "div",
                    { staticClass: "ce-wrapper__add-new" },
                    [
                      _c("ConfigNameInput", {
                        attrs: { disabled: _vm.type === "Subscription" },
                        on: { input: _vm.configChanged },
                        model: {
                          value: _vm.configName,
                          callback: function($$v) {
                            _vm.configName =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "configName"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex form-inline p-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "brand-selector" } }, [
                            _vm._v("Brand:")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.brand_id,
                                  expression: "brand_id"
                                }
                              ],
                              staticClass: "form-control input-sm",
                              attrs: { id: "brand-selector" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.brand_id = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.brandChanged
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v("Please select one")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.availableBrands, function(brand) {
                                return _c(
                                  "option",
                                  {
                                    key: brand.id,
                                    domProps: { value: brand.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(brand.name) +
                                        "\n              "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        !_vm.hideDefaultCheckbox
                          ? _c("div", { staticClass: "checkbox ml-3 pt-1" }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isDefault,
                                      expression: "isDefault"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.isDefault)
                                      ? _vm._i(_vm.isDefault, null) > -1
                                      : _vm.isDefault
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.isDefault,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.isDefault = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.isDefault = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.isDefault = $$c
                                        }
                                      },
                                      _vm.configChanged
                                    ]
                                  }
                                }),
                                _vm._v("\n              Default")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showSettingsButton
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary ml-3",
                                on: {
                                  click: function($event) {
                                    _vm.$emit("openSettingsModal", _vm.brand_id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.settingsButtonLabel) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _c("div", [
                    _vm.availableConfig.length > 0
                      ? _c("div", { staticClass: "d-flex form-inline" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "brand-selector" } }, [
                              _vm._v(_vm._s(_vm.type) + " Config:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedConfigId,
                                    expression: "selectedConfigId"
                                  }
                                ],
                                staticClass: "form-control input-sm",
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedConfigId = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.changeSelectedConfig
                                  ]
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", value: "" } },
                                  [_vm._v("Please select one")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.availableConfig, function(select) {
                                  return _c(
                                    "option",
                                    {
                                      key: select.id,
                                      domProps: { value: select.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.type === "Subscription"
                                              ? _vm.getBrandById(
                                                  select.brand_id
                                                ).name + " Config"
                                              : select.name
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ])
                      : _c("div", [
                          _c("p", [_vm._v("Please create a new config")])
                        ])
                  ]),
              _vm._v(" "),
              _c("AddCancelBtn", {
                key: _vm.type,
                staticClass: "ml-auto",
                attrs: {
                  onClick: _vm.addNew,
                  isCancel: _vm.addingNew || !!_vm.config
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.config
            ? _c("div", { staticClass: "editor-wrapper" }, [
                _vm.isLoadingConfig
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("LoaderPlaceholder", {
                          staticClass: "loader",
                          attrs: { type: "circle" }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        on: {
                          mouseover: _vm.addPreview,
                          mouseout: _vm.removePreview
                        }
                      },
                      [
                        _c("vjsoneditor", {
                          attrs: {
                            plus: true,
                            options: _vm.options,
                            height: _vm.height
                          },
                          on: { error: _vm.onError },
                          model: {
                            value: _vm.config,
                            callback: function($$v) {
                              _vm.config = $$v
                            },
                            expression: "config"
                          }
                        })
                      ],
                      1
                    ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex pt-3" }, [
                  _c(
                    "div",
                    { staticClass: "ml-auto" },
                    [
                      _vm.changed && !_vm.addingNew
                        ? _c(
                            "BaseButton",
                            {
                              staticClass: "mr-2",
                              on: { click: _vm.saveConfig }
                            },
                            [_vm._v("\n            Save\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.addingNew && _vm.configName && _vm.brand_id
                        ? _c(
                            "BaseButton",
                            {
                              staticClass: "mr-2",
                              on: { click: _vm.handleAddConfig }
                            },
                            [_vm._v("\n            Add\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config || _vm.addingNew
                        ? _c(
                            "BaseButton",
                            {
                              attrs: { outline: "" },
                              on: { click: _vm.handleDeleteConfig }
                            },
                            [_vm._v("\n            Delete\n          ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "ce_tooltip",
      attrs: { id: _vm.tooltipID, role: "tooltip" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-58039c20", { render: render, staticRenderFns: staticRenderFns })
  }
}