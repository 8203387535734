<doc>
    Subscription Dashboard Vue.js page
</doc>

<template>
    <div>
        <nav class="navbar navbar-default navbar-fixed-top">
            <div class="container-fluid">
                <div class="navbar-header">
                    <a class="navbar-brand" style="height:auto" href="#"><img style="height:50px;" :src="logo_url" /></a>
                    <button v-if="changed" @click="save" type="button" class="btn btn-modern navbar-btn navbar-right saveBtn">Save</button>
                </div>
            </div>
        </nav>
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <div v-if="isLoading" style="width:100%; text-align: center; margin-top:20px;">
                        <div class="loader loader-circle tt-loading"></div>
                    </div>
                    <div v-else>
                        <div class="card" style="margin-top: 0px;">
                            <div class="card-body">
                                <h5>Contact Info</h5>
                                <InputText
                                        v-model="contact_info.first_name"
                                        label="First Name"
                                        name="first_name"
                                        v-on:input="onInput"
                                        :readonly="( lead.first_name ? true : false )"
                                />
                                <InputText
                                        v-model="contact_info.last_name"
                                        label="Last Name"
                                        name="last_name"
                                        v-on:input="onInput"
                                        :readonly="( lead.last_name ? true : false )"
                                />
                                <InputText
                                        v-model="contact_info.email"
                                        label="Email"
                                        name="email"
                                        v-on:input="onInput"
                                />
                                <InputText
                                        v-model="contact_info.phone"
                                        label="Phone"
                                        name="phone"
                                        v-on:input="onInput"
                                        class="hide"
                                />
                                <div class="subscriptionActive">
                                    <input type="checkbox" id="checkbox" v-model="active">
                                    <label for="checkbox">Send Me The Latest Rates</label>
                                    <div v-if="active" class="frequency">
                                        <input type="checkbox" id="frequency" v-model="frequencyOnChange">
                                        <label for="checkbox">Only When They Change</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <h5>Quote Info</h5>
                                <InputText
                                        v-model="loan_info.list_price"
                                        label="List Price (or Appraised Value)"
                                        name="list_price"
                                        v-on:input="onInput"
                                />
                                <InputText
                                        v-model="loan_info.down_payment"
                                        label="Down Payment (or Equity)"
                                        name="down_payment"
                                        v-on:input="onInput"
                                />
                                <InputText
                                    v-model="loan_info.credit_score"
                                    label="Credit Score"
                                    name="credit_score"
                                    v-on:input="onInput"
                                />
                                <DropDown
                                        label="Loan Purpose"
                                        class="loanPurposeInput"
                                        name="loan_purpose"
                                        v-model="loan_info.loan_purpose"
                                        :options="form_info.loan_purposes"
                                        v-on:dropDownChange="onLoanPurposeSelect"
                                />
                                <DropDown
                                        label="Loan Type"
                                        class="loanTypeInput"
                                        name="loan_type"
                                        v-model="loan_info.loan_type"
                                        :options="form_info.loan_types"
                                        v-on:dropDownChange="onLoanTypeSelect"
                                />
                                <DropDown
                                        label="Property Type"
                                        class="propertyTypeInput"
                                        name="property_type"
                                        v-model="loan_info.property_type"
                                        :options="form_info.property_types"
                                        v-on:dropDownChange="onPropertyTypeSelect"
                                />
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <h5>Address Info</h5>
                                <InputText
                                        v-model="loan_info.street_address"
                                        label="Street Address"
                                        name="street_address"
                                        v-on:input="onInput"
                                />
                                <InputText
                                        v-model="loan_info.city"
                                        label="City"
                                        name="city"
                                        v-on:input="onInput"
                                />
                                <InputText
                                        v-model="loan_info.county"
                                        label="County"
                                        name="county"
                                        v-on:input="onInput"
                                />
                                <DropDown
                                        label="State"
                                        class="stateInput"
                                        name="state"
                                        v-model="loan_info.state"
                                        :options="states"
                                        v-on:dropDownChange="onStateSelect"
                                />
                                <InputText
                                        v-model="loan_info.zipcode"
                                        label="Zipcode"
                                        name="zipcode"
                                        v-on:input="onInput"
                                />
                            </div>
                        </div>
                        <div class="disclaimer"><p>{{ disclaimer }}</p></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import { InputTextArea, DropDown } from "../components/Forms"
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import { Tabs, Tab } from "../components"
import md5 from "js-md5"

const states=[ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PW', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

export default {
    name: "SubscriptionDashboard",
    data() {
        return {
            contact_info:{
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            },
            loan_info:{
                list_price: 500000,
                down_payment: 100000,
                loan_type: "Conventional",
                loan_purpose: "Purchase",
                credit_score: 760,
                term: 30,
                state: "",
                zipcode: "",
                street_address: "",
                county: "",
                city: "",
                property_type: ""
            },
            form_info:{
                loan_types:{
                    "Conventional":"Conventional",
                    "VA":"VA",
                    "USDA":"USDA",
                    "FHA":"FHA"
                },
                property_types:{
                    "single_family_home":"Single Family",
                    "townhome":"Townhome",
                    "condo":"Condo",
                    "multi_tenant_home":"Multi Tenant Home"
                },
                loan_purposes:{
                    "purchase":"Purchase",
                    "refinance":"Refinance"
                }
            },
            disclaimer:"",
            property_info:{},
            isEditing: false,
            id: null,
            isLoading: true,
            active: false,
            frequencyOnChange: false,
            originalHash: null,
            changed: false,
            logo_url: "",
            lead_id:null,
            loid:null,
            lead:{},
        }
    },
    components: {

    },
    async mounted() {
        this.logo_url=logo
        this.id=sub_id
        let subscription=await BB.subscription.set_session_id(bb_sid).get({id: sub_id});
        if(parseInt(subscription.lead_id)){
            this.lead_id=parseInt(subscription.lead_id)
        }
        if(subscription.lead){
            this.lead=subscription.lead
            this.contact_info.first_name=( this.lead.first_name ? this.lead.first_name : "" )
            this.contact_info.last_name=( this.lead.last_name ? this.lead.last_name : "" )
            this.contact_info.phone=( this.lead.phone ? this.lead.phone : "" )
        }
        if(subscription.loid){
            this.loid=parseInt(subscription.loid)
        }
        this.active=( parseInt(subscription.active) ? true: false );
        if(this.active && subscription.settings && subscription.settings.frequency=="onChange")this.frequencyOnChange=true
        this.contact_info.email=subscription.email
        if(subscription.quote_info && typeof subscription.quote_info != 'object')subscription.quote_info=JSON.parse(subscription.quote_info)
        Object.assign(this.loan_info,subscription.quote_info)
        let hash=this.getHash();
        //console.log("initial hash: "+hash);
        this.originalHash = hash
        this.disclaimer=disclaimer;
        this.isLoading=false;
    },
    updated() {
        if(this.id && !this.isLoading){
            let new_hash = this.getHash()
            //console.log("comparing original_hash: "+this.originalHash+" with new hash: "+new_hash);
            if (this.originalHash && this.originalHash != new_hash) {
                //console.log("they are different!");
                this.changed = true
            } else {
                this.changed = false
            }
        }
    },
    computed:{
        states(){
            let result={};
            [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ].forEach((item)=>{
                result[item]=item;
            });
            return result;
        },
    },
    methods: {
        toggleEdit(){
            this.isEditing = ( this.isEditing ? false : true )
        },
        async save(){
            // TODO: validate the form values (example down_payment > 0)
            if(!this.validateForm()){
                return null;
            }
            let settings={
                frequency: ( this.frequencyOnChange ? "onChange" : "default" )
            };
            await BB.subscription.set_session_id(bb_sid).update({
                id: this.id,
                quote_info: this.loan_info,
                email: this.contact_info.email,
                name: ( this.contact_info.first_name ? this.contact_info.first_name+" "+( this.contact_info.last_name ? this.contact_info.last_name : "" ) : "" ),
                phone: ( this.contact_info.phone ? this.contact_info.phone : "" ),
                lead_id: ( parseInt(this.lead_id) ? this.lead_id : 0 ),
                settings,
		active: ( this.active ? 1 : 0 )
            })
            this.toggleEdit();
            this.changed=false
            this.originalHash = this.getHash()
        },
        validateForm(){
            BB.Toastr.options={
                positionClass: "toast-top-center"
            }
            if(!this.loan_info.state){
                BB.Toastr.error("State required")
                return false
            }
            if(!this.loan_info.down_payment){
                BB.Toastr.error("Down Payment Amount (or Equity) Required")
                return false;
            }
            if(!this.loan_info.list_price){
                BB.Toastr.error("List Price (or Appriased Value) Required")
                return false;
            }
            if(this.loan_info.down_payment<=0){
                BB.Toastr.error("Down Payment must be over 0")
                return false
            }
            if(this.loan_info.down_payment>=this.loan_info.list_price){
                BB.Toastr.error("Down Payment must be less than List Price")
                return false
            }
            return true
        },
        onInput(e){
            //console.log(e)
        },
        getHash() {
            let hash=md5(
                JSON.stringify({
                    contact_info: this.contact_info,
                    loan_info: this.loan_info,
                    active: this.active ? 1 : 0,
                    frequencyOnChange: this.frequencyOnChange ? 1 : 0
                }));
            return hash
        },
        onStateSelect(state){
            this.loan_info.state=state;
        },
        onLoanTypeSelect(val){
            this.loan_info.loan_type=val;
        },
        onLoanPurposeSelect(val){
            this.loan_info.loan_purpose=val;
        },
        onPropertyTypeSelect(val){
            this.loan_info.property_type=val;
        }
    }
}
</script>

<style lang="scss">
    .container{
        margin-bottom:20px;
    }
    .card{
        box-shadow: 0 2px 4px 0 rgba(53, 64, 82, 0.24);
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        margin-top:20px;
        font-size: 16px;
        .card-body{
            flex: 1 1 auto;
            padding: 1.25rem;
        }
        h5{
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
        }
    }
    .subscriptionActive{
        font-family:Roboto, sans-serif;
        font-size:16px;
        font-weight: 400;
        .frequency{
            margin-left: 1em;
            margin-top: 5px;
            font-size: smaller;
        }
    }
    @media (max-width: 768px){
        .saveBtn{
            right: 20px !important;
        }
    }
    .saveBtn{
        position: absolute;
        right: 35px;
        top:15px;
        cursor: pointer;
    }
    .drop-down input.form-control{
        font-size: 16px !important;
    }
    .disclaimer{
        margin-top:20px;
        font-size: 12px;
    }
</style>
