<doc>
  Organization Manager Vue.js component
</doc>

<template>
  <div>
    <StaticFlyerList />
    <StaticFlyerModal />
    <StaticFlyerPrintModal />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import {
  StaticFlyerList,
  StaticFlyerModal,
  StaticFlyerPrintModal
} from "../StaticFlyers"

const { mapActions } = createNamespacedHelpers("staticFlyers")

export default {
  name: "ManagerStaticFlyers",
  components: {
    StaticFlyerList,
    StaticFlyerModal,
    StaticFlyerPrintModal
  },
  methods: Object.assign({}, mapActions(["initStaticFlyers"])),
  created() {
    this.initStaticFlyers({
      bank_id: brands,
      isBankAdmin,
      uid
    })
  }
}
</script>

<style lang="scss" scoped></style>
