import Mustache from "mustache"
import Vue from "vue"
import vjsoneditor from "v-jsoneditor"
import md5 from "js-md5"

const mountPoint = "#contact-form-manage"

$(document).ready(function() {
  var vm = null
  if ($(mountPoint).length) {
    var getRealtorStatusForm = new Vue({
      el: mountPoint,
      components: {
        vjsoneditor
      },
      data() {
        return {
          config: null,
          height: "400px",
          changed: false,
          origHash: null,
          selectedConfigId: null,
          addingNew: false,
          configName: null,
          isDefault: false,
          brandId: null,
          options: {
            onChangeText(configJson) {}
          }
        }
      },
      async mounted() {
        //this.getConfig();
        vm = this
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has("id")) {
          this.selectedConfigId = urlParams.get("id")
          await this.getConfig(this.selectedConfigId)
        }
        $(mountPoint).removeClass("notLoaded")
      },
      watch: {
        config(val) {
          $(mountPoint).on("click", function() {
            previewImg()
          })
          previewImg()
        }
      },
      updated() {
        if (vm.origHash != this.getHash()) {
          vm.changed = true
        } else {
          vm.changed = false
        }
      },
      methods: {
        onError() {
          console.log("error")
        },
        getConfig(id) {
          var url = `/api/v1/contact-form`
          if (id) {
            url += `?id=` + id
          } else {
            url += `?id=0`
          }
          fetch(url, {
            method: "GET"
          })
            .then(res => {
              console.log(res, "res")
              return res.json()
            })
            .then(form => {
              this.config = form.config
              this.isDefault = form.is_default == 1 ? true : false
              const hash = this.getHash()
              console.log(hash)
              this.origHash = hash
              //previewImg();
            })
        },
        saveConfig(e) {
          if (!this.validateConfig()) {
            return
          }
          var payload = {}
          if (this.addingNew) {
            payload = {
              name: this.configName,
              config: this.config,
              brand_id: this.brandId,
              is_default: this.isDefault ? 1 : 0
            }
          } else {
            payload = {
              id: this.selectedConfigId,
              config: this.config,
              is_default: this.isDefault ? 1 : 0
            }
          }
          fetch(`/api/v1/contact-form`, {
            method: "POST",
            body: JSON.stringify(payload)
          })
            .then(res => {
              return res.json()
            })
            .then(json => {
              console.log(json)
              if (json.status == "success") {
                BB.Toastr.success("Configuration Saved")
                this.changed = false
                vm.origHash = this.getHash()
                this.addingNew = false
              }
            })
        },
        getHash() {
          return md5(
            JSON.stringify({
              config: this.config,
              default: this.isDefault
            })
          )
        },
        changeSelectedConfig(data) {
          console.log(data)
          console.log(this.selectedConfigId)
          window.history.pushState({}, null, "?id=" + this.selectedConfigId)
          this.getConfig(this.selectedConfigId)
        },
        addNew(e) {
          if (this.addingNew) {
            this.addingNew = false
            this.config = null
          } else {
            this.addingNew = true
            this.getConfig(null)
          }
        },
        validateConfig() {
          let flag = true
          return flag
        },
        async syncDefaults() {
          let defaultConfig = await BB.leadWorkflow.get({})
          console.log(BB.leadWorkflow.get({}), "BB.leadWorkflow.get({})")
          let currentConfig = this.config
          Object.keys(defaultConfig).forEach(key => {
            switch (key) {
              case "steps":
                break
              case "disclaimers":
                break
              default:
                if (!currentConfig[key]) {
                  console.log(key + " is missing and needs to be added")
                  currentConfig[key] = defaultConfig[key]
                }
                break
            }
          })
          return
        }
      }
    })
  }
})

var timer
var previewWrapper = `<div id="previewWrapper" style="width: 200px;height: 100px;position: absolute;background: white;right: 0px;top:35px;text-align:center;"><img style="max-width:100%" src=""></div>`
function previewImg() {
  setTimeout(function() {
    $(".jsoneditor-url").off("mouseover")
    $(".jsoneditor-url").off("mouseout")
    $(".jsoneditor-url").on("mouseover", function(e) {
      timer = setTimeout(() => {
        //console.log("mouse on");
        //console.log($(this).html());
        $(".jsoneditor-outer").append(previewWrapper)
        $("#previewWrapper img").prop("src", $(this).html())
      }, 1000)
    })
    $(".jsoneditor-url").on("mouseout", function(e) {
      clearTimeout(timer)
      //console.log("mouse off");
      $("#previewWrapper").remove()
    })
  }, 500)
}

function formatDate(timestamp) {
  var t = new Date(timestamp * 1000)
  const dateTime = t.toLocaleDateString() + " " + t.toLocaleTimeString()
  return dateTime
}
