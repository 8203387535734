<doc>
  Bootstrap aligned self item Vue.js component
</doc>

<template>
  <div :class="className"><slot /></div>
</template>

<script>
export default {
	name: "AlignedItem",
	props: {
		alignment: { type: String, required: true }
	},
	computed: {
		className: function() {
			return `align-self-${this.alignment}`
		}
	}
}
</script>

<style lang="scss" src="./FlexBox.scss" scoped>
</style>
