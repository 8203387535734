<doc>
  Static Flyer details Vue.js component
</doc>

<template>
  <div :class="className">
    <div class="program-details-img">
      <img
        :src="details.pictures[0]"
        :alt="`${item.street_addr} flyer thumb`"
      />
      <div class="program-overlay">
        <BaseButton outline class="btn-view-program" v-on:click="showPrintModal"
          >Print Flyer</BaseButton
        >
        <SmartListItemOptions
          :gridView="gridView"
          :itemIndex="itemIndex"
          :itemId="item.id"
          :onDragEnd="onDragEnd"
          :isLast="isLast"
          :showEditItemModal="showEditItemModal"
          :options-list="optList"
          :removeItem="removeItem"
        />
      </div>
    </div>
    <div :class="['program-details', this.programDetailsStyle]">
      <div class="program-details-title mb-3">
        <h4>{{ item.street_addr }}</h4>
        <p>
          ${{ new Intl.NumberFormat("en-EN").format(details.listing_price) }}
        </p>
        <p>{{ details.realtorData && details.realtorData.label }}</p>
      </div>
      <ManageBadge toggleable :items="tagsItem" showIcon isTag :limit="3" />
      <span class="agentconnect-extra-tools">
        <div class="dropup">
          <a class="btn btn-small-white glyphicon glyphicon-option-vertical dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </a>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
            <li><a role="button" v-on:click="copyListingUrl(`/listing?manual_listing_id=${item.id}&lo=${item.owner}`)">Copy Listing Url</a></li>
            <li><a :href="`/listing?manual_listing_id=${item.id}&lo=${item.owner}`" target="_blank">View Listing</a></li>
          </ul>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import SmartListItemOptions from "../SmartList/SmartListItemOptions"
import ManageBadge from "../ManageBadge"

const { mapState, mapActions } = createNamespacedHelpers("manualListing")

import _ from "lodash"
export default {
  name: "ManualListingDetails",
  components: {
    SmartListItemOptions,
    ManageBadge
  },
  props: {
    item: { type: Object, required: true },
    itemIndex: { type: Number, required: true },
    isLast: { type: Boolean, default: false },
    onDragEnd: { type: Function },
    displayFlex: { type: Boolean, default: false }
  },
  computed: Object.assign({}, mapState(["gridView"]), {
    details: function() {
      return typeof this.item.data === "object"
        ? this.item.data
        : JSON.parse(this.item.data)
    },
    className: function() {
      let className = "program"
      if (!this.gridView) {
        className += " list-view d-flex"
      }
      if (this.$mq == "sm" || this.$mq == "md") {
        className += " not-desktop"
      }
      return className
    },
    programDetailsStyle: function() {
      if (this.displayFlex) {
        return "program-details--flex"
      }
    },
    imgSrc: function() {
      let src = [
        "/sites/all/themes/bankingbridge_ui/images/programs",
        "-mini",
        ".png"
      ]
      if (this.gridView) {
        src.splice(1, 1)
      }
      return src.join("")
    },
    isDefault: function() {
      if (!this.item || !this.$store.state.default_set) return false
      return this.item.id == this.$store.state.default_set.id
    },
    optList: function() {
      return ["none"]
    },
    tagsItem: function() {
      if (this.item.hasOwnProperty("tags")) {
        if (Array.isArray(this.item.tags)) {
          return this.item.tags
        } else {
          return []
        }
      } else {
        return []
      }
    }
  }),
  methods: Object.assign(
    {
      copyListingUrl: function copyListingUrl(url) {
        var copyText = window.location.origin + url;
        navigator.clipboard.writeText(copyText);
      }
    },
    mapActions([
      "showEditItemModal",
      "showPrintManualListingModal",
      "removeManualListing"
    ]),
    {
      showPrintModal() {
        this.showPrintManualListingModal(this.item.id)
      },
      removeItem() {
        this.removeManualListing({ id: this.item.id, index: this.itemIndex })
      }
    }
  )
}
</script>

<style lang="scss" src="./ManualListingDetailsStyles.scss" scoped></style>
