<doc>
  SmartList Vue.js component
</doc>

<template>
  <LoaderPlaceholder v-if="isLoading" type="circle" />
  <div v-else :class="className">
    <SmartListToolbar
      v-if="!hideToolbar"
      :searchKeys="searchKeys"
      :gridView="gridView"
      :gridViewSm="gridViewSm"
      :filteredItems="filteredItems"
      :addQuery="addQuery"
      :removeQuery="removeQuery"
      :resetQuery="resetQuery"
      :toggleListView="toggleListView"
      :addItemAction="addItemAction"
      :addItemLabel="addItemLabel"
      :hasAddItem="hasAddItem"
      :rangeFilter="rangeFilter"
      :searchQuery="searchQuery"
      :searchQueries="searchQueries"
      :vuexNamespace="vuexNamespace"
      :hasSort="hasSort"
      :searchShowListOptions="searchShowListOptions"
    />

    <LoaderPlaceholder v-if="smartListLoading" type="circle" />

    <SmartListItems
      v-else
      :items="items"
      :onDragEnd="onDragEnd"
      :gridView="gridView"
      :addItemAction="addItemAction"
      :listType="listType"
      :addItemLabel="addItemLabel"
      :hasAddItem="hasAddItem"
      :onItemClick="onItemClick"
      :toggleMemberPricing="toggleMemberPricing"
      :vuexNamespace="vuexNamespace"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import _isNull from "lodash/isNull"
import SmartListToolbar from "./SmartListToolbar"
import SmartListItems from "./SmartListItems"
const { mapGetters, mapState } = createNamespacedHelpers("activity")
export default {
  name: "SmartList",
  props: {
    listType: { type: String, default: "default" },
    searchQuery: { type: String },
    items: { type: [Array, Object] },
    gridView: { type: Boolean, default: true },
    gridViewSm: { type: Boolean, default: true },
    onDragEnd: { type: Function },
    searchQueries: { type: Array },
    searchKeys: { type: [String, Array] },
    toggleListView: { type: Function },
    addQuery: { type: Function, required: true },
    resetQuery: { type: Function, required: true },
    filteredItems: { type: Function, required: true },
    removeQuery: { type: Function, required: true },
    addItemAction: { type: Function },
    toggleMemberPricing: { type: Function },
    hasAddItem: { type: Boolean, default: true },
    rangeFilter: { type: Boolean, default: false },
    hideToolbar: { type: Boolean, default: false },
    onItemClick: { type: Function },
    vuexNamespace: { type: [String, Boolean], default: false },
    hasSort: { type: Boolean, default: false },
    searchShowListOptions: { type: Boolean, default: false }
  },
  components: {
    SmartListToolbar,
    SmartListItems
  },
  computed: Object.assign({}, mapState(["smartListLoading"]), {
    isLoading: function() {
      return _isNull(this.items)
    },
    className: function() {
      return `list-${this.listType}`
    },
    addItemLabel: function() {
      // TODO: this should be dynamic
      let label = ""
      switch (this.listType) {
        case "static_flyer":
          label = "Add a new flyer image"
          break
        case "manual_flyer":
          label = "Create a new flyer"
          break
        case "program":
          label = "Create a new program set"
          break
        case "organization":
          label = "Add new company"
          break
        case "link":
          label = "Add new link"
          break
        case "member":
          label = "Add new member"
          break
      }
      return label
    }
  })
}
</script>

<style lang="scss" scoped></style>
