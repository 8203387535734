var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vSelect",
    {
      attrs: {
        placeholder: _vm.placeholder,
        value: _vm.value,
        options: _vm.options,
        reduce: function(option) {
          return option[_vm.reducer]
        },
        components: { OpenIndicator: _vm.OpenIndicator }
      },
      on: { input: _vm.onInputLocal }
    },
    [
      _c("div", { attrs: { slot: "no-options" }, slot: "no-options" }, [
        _vm._v("\n    " + _vm._s(_vm.noOptionTxt) + "\n    "),
        _vm.addOption
          ? _c(
              "span",
              { staticClass: "vs__add-new", on: { click: _vm.addOption } },
              [_vm._v("\n      Add new one "), _c("strong", [_vm._v("+")])]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-11e624e3", { render: render, staticRenderFns: staticRenderFns })
  }
}