<template>
  <BaseModal
    ref="modal"
    v-model="showItemModal"
    :title="modalTitle"
    id="newPricingSetModal"
    :showCancelBtn="false"
    :isLoading="isLoading || initializing"
    okLabel="Close"
    :closeWhenOK="true"
  >
    <Tabs @changed="tabChanged" >
      <Tab name="Install">
        <Install
          v-if="integration"
          :integration="integration"
          :onDelete="onDelete"
        />
      </Tab>
      <Tab name="Settings">
        <Settings
          v-if="showItemModal && integration && extra_settings && selectData.availablePricingSets"
          :integration="integration"
          :extraSettings="extra_settings"
          :selectData="selectData"
        />
      </Tab>
      <Tab name="Status">
        <Status
          v-if="pollingActive && showItemModal"
          :integration="integration"
        />
      </Tab>
      <Tab name="Monitor">
        <Monitor
          v-if="integration"
          :integration="integration"
        />
      </Tab>
    </Tabs>
  </BaseModal>
</template>

<script>
import { Tabs, Tab } from "../components/Tabs"
import BaseModal from "../components/BaseModal"
import { Monitor, Status, Install, Settings } from "../components/IntegrationSettings";

export default {
  name: "IntegrationSettingsModal",
  components: {
    Monitor,
    Status,
    Install,
    Settings,
    Tabs,
    Tab,
    BaseModal
  },
  props: {
    integration_id: Number
  },
  async mounted() {
      this.initializing=true
      await this.getAvailableWorkflows()
      await this.getAvailableContactForms()
      await this.getAvailableOwners()
      await this.getAvailablePricingSets()
      this.initializing=false
  },
  async created() {},
  data() {
    return {
        modalTitle:"Edit Integration",
        isLoading: true,
        initializing: false,
        integration: null,
        extra_settings: null,
        showItemModal: false,
        pollingActive: false,
        selectData: {}
    }
  },
  methods: {
    async getIntegration(id) {
        let result = await BB.integration.get(id)
        if (!result.integrations) {
            console.log("something went wrong")
            return
        }
        this.integration = result.integrations
        this.extra_settings = {}
        if (
            result.integrations.app_settings &&
            result.integrations.app_settings.settings &&
            result.integrations.app_settings.settings != "[]"
        )
        this.extra_settings = JSON.parse(
            result.integrations.app_settings.settings
        )
    },
    submitForm() {},
    resetModal() {
        this.isLoading = true
        this.integration = null
        this.extra_settings = null
        this.modalTitle=""
    },
    hideModal() {
        //this.resetModal()
    },
    async openModal(id) {
        this.resetModal()
        this.showItemModal = true
        await this.getIntegration(id)
        this.modalTitle=this.integration.title
        this.isLoading = false
    },
    tabChanged({ tab }){
        if(tab.name=="Status"){
            this.pollingActive=true
        }
        else{
            this.pollingActive=false
        }
    },
    async getAvailableOwners() {
        let result = null
        try {
            result = await BB.brandManage.getMembers(
                $("#integration-list-vue-app").data("brand_id")
            )
        } catch (e) {
            console.log("something went wrong getting available owners")
            return
        }
        this.selectData.availableOwners = result.members
    },
    async getAvailableWorkflows() {
        let result = null
        try {
            result = await BB.leadWorkflow.find({
                brand_id: $("#integration-list-vue-app").data("brand_id")
            })
        } catch (e) {
            console.log(e)
        }
        this.selectData.availableWorkflows = result.workflows
    },
    async getAvailableContactForms() {
        let result = null
        try {
            result = await BB.contactForm.find({
                brand_id: $("#integration-list-vue-app").data("brand_id")
            })
        } catch (e) {
            console.log(e)
        }
        this.selectData.availableContactForms = result.contact_forms
    },
    async getAvailablePricingSets(){
        let result=null
        try{
            result=await BB.pricingSet.find({brand_id: $("#integration-list-vue-app").data("brand_id")})
        }
        catch(e){
            console.log(e)
            return
        }
        //await new Promise(resolve => setTimeout(resolve, 10000));
        this.selectData.availablePricingSets=result.sets
    },
    onDelete(){
        this.showItemModal = false
        $("div[data-integration-id='"+this.integration.id+"']").addClass("hide")
        this.resetModal()
        BB.Toastr.success("Integration Deleted")
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-body {
  padding-top: 0px;
}
.modal-dialog,
#vue-integration-manager-app {
  overflow: auto;
}

</style>
