<doc>
	Company card Vue.js component
</doc>

<template>
	<CardToggle :title="titleDesc">
		<p
			v-if="!editMode">
			{{formData.about}}
		</p>
		<InputTextArea
			v-if="editMode"
			v-model="formData.about"
			label="About"
			name="about"
			noResize
		/>

		<h4 v-if="!editMode">Marketing Service Agreement:</h4>
		<p v-if="!editMode">
			{{org.msa_template}}
		</p>
		<InputTextArea
			v-if="editMode"
			v-model="formData.msa_template"
			label="Marketing Service Agreement"
			name="msa_template"
			noResize
		/>

		<h4 v-if="!editMode">Disclaimer:</h4>
		<p v-if="!editMode">
			{{org.disclaimer}}
		</p>
		<InputTextArea
			v-if="editMode"
			v-model="formData.disclaimer"
			label="Disclaimer"
			name="disclaimer"
			noResize
		/>

		<div class="text-right">
			<BaseButton
				v-if="editMode"
				type="button"
				v-on:click="setEditMode(false)"
				theme="link"
				size="sm"
				>
				Cancel
			</BaseButton>
			<BaseButton
				v-if="editMode"
				type="button"
				v-on:click="saveFormData()"
				size="sm"
				>
				Save
			</BaseButton>
		</div>

		<BaseButton
			v-if="!editMode"
			class="edit"
			type="button"
			theme="link"
			v-on:click="setEditMode(true)"
			>
			<span class="sr-only">Edit program set</span>
			<icon name="pencil-alt"></icon>
		</BaseButton>
	</CardToggle>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { cleanURL } from "../../helpers"
import _isEmpty from "lodash/isEmpty"
import CardToggle from "../Card/CardToggle"

const { mapState, mapActions } = createNamespacedHelpers("organization")

export default {
	name: "CompanyAboutCard",
	components: {
		CardToggle: CardToggle
	},
	data() {
		return {
			editMode: false
		}
	},
	computed: Object.assign({}, mapState(["org"]), {
		titleDesc: function() {
			return `About ${this.org.name}`
		},
		isLoading: function() {
			return _isEmpty(this.org)
		},
		website: function() {
			return cleanURL(this.org.website)
		},
		logoAlt: function() {
			return `${this.org.name} logo`
		},
		formData: function() {
			let data = { msa_template: "", about: "", disclaimer: "" }

			if (!_isEmpty(this.org)) {
				data = {
					msa_template: this.org.msa_template,
					disclaimer: this.org.disclaimer,
					about: this.org.about
				}
			}
			return data
		}
	}),
	watch: {
		org: {
			handler: function() {
				this.setEditMode(false)
			},
			deep: true
		}
	},
	methods: Object.assign({}, mapActions(["saveOrg"]), {
		setEditMode(mode) {
			this.editMode = mode
		},
		saveFormData() {
			this.saveOrg(this.formData)
		}
	})
}
</script>

<style lang="scss" scoped>
.card {
	position: relative;
}
.edit {
	position: absolute;
	right: 0;
	top: 5px;
	opacity: 1;
	transition: opacity 0.4s $transition-timing-function;

	.closed & {
		pointer-events: none;
		cursor: default;
		opacity: 0;
	}
}
h4 {
	font-weight: 400;
	margin: 10px 0 5px;
}
</style>
