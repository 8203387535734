var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      attrs: { name: "modal" },
      on: {
        "before-enter": _vm.beforeEnter,
        "after-enter": _vm.afterEnter,
        "after-leave": _vm.afterLeave
      }
    },
    [
      _c(
        "div",
        { key: "modal", staticClass: "flyer-modal", attrs: { tabindex: "-1" } },
        [
          _c("div", { staticClass: "modal-dialog flyer-modal-dialog" }, [
            _c("div", { staticClass: "modal-content flyer-modal-content" }, [
              _c("div", { staticClass: "modal-header flyer-modal-header" }, [
                _c("h4", { staticClass: "modal-title flyer-modal-title" }, [
                  _vm._v("\n            " + _vm._s(_vm.title) + "\n          ")
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { theme: "", type: "button", "aria-label": "Close" },
                    on: { click: _vm.cancel }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "closeBtn",
                        attrs: { "aria-hidden": "true" }
                      },
                      [_vm._v("×")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body flyer-modal-body" },
                [
                  _c("label", { staticClass: "flyer-modal-label" }, [
                    _vm._v("Organizations")
                  ]),
                  _vm._v(" "),
                  _c("Multiselect", {
                    attrs: {
                      "max-height": 500,
                      options: _vm.orgOptions,
                      multiple: true,
                      "close-on-select": false,
                      "clear-on-select": false,
                      "preserve-search": true,
                      placeholder: "Pick some",
                      selectLabel: "Click to select",
                      deselectLabel: "Click to remove",
                      label: "name",
                      "track-by": "name",
                      "preselect-first": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function(ref) {
                          var values = ref.values
                          var search = ref.search
                          var isOpen = ref.isOpen
                          return [
                            values.length && !isOpen
                              ? _c(
                                  "span",
                                  { staticClass: "multiselect__single" },
                                  [
                                    _vm._v(
                                      _vm._s(values.length) +
                                        " options selected"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.orgValue,
                      callback: function($$v) {
                        _vm.orgValue = $$v
                      },
                      expression: "orgValue"
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.orgValue, function(v) {
                    return _c("ul", { key: v.id }, [
                      _vm._v("\n            " + _vm._s(v.name) + "\n          ")
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "flyer-modal-label margin-top-16" },
                    [_vm._v("Brands")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.orgValue, function(v) {
                    return _c("Multiselect", {
                      key: v.id,
                      attrs: {
                        options: _vm.getBrandsByOrgId(v.id),
                        multiple: true,
                        "close-on-select": false,
                        "clear-on-select": false,
                        "preserve-search": true,
                        placeholder: v.name,
                        selectLabel: "Click to select",
                        deselectLabel: "Click to remove",
                        label: "name",
                        "track-by": "name",
                        "preselect-first": false
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function(ref) {
                            var values = ref.values
                            var search = ref.search
                            var isOpen = ref.isOpen
                            return [
                              values.length && !isOpen
                                ? _c(
                                    "span",
                                    { staticClass: "multiselect__single" },
                                    [_vm._v(_vm._s(v.name))]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.brandValue,
                        callback: function($$v) {
                          _vm.brandValue = $$v
                        },
                        expression: "brandValue"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.brandValue, function(v) {
                    return _c("ul", { key: v.name }, [
                      _vm._v("\n            " + _vm._s(v.name) + "\n          ")
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer flyer-modal-footer" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-modern", on: { click: _vm.save } },
                  [_vm._v("\n            Save\n          ")]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.isVisible
        ? _c("div", { key: "backdrop", staticClass: "modal-backdrop in" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41b243f4", { render: render, staticRenderFns: staticRenderFns })
  }
}