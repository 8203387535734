<doc>
  SmartList items Vue.js component
</doc>

<template>
  <div>
    <draggable
      :class="className"
      v-model="localItems"
      :options="{
        chosenClass: 'sortable-chosen',
        filter: '.program-set-placeholder',
        handle: '.drag-handler'
      }"
      :move="onMove"
      @end="onEnd"
      @start="onStart"
    >
      <transition-group class="d-flex draggable-wrapper" tag="div">
        <GridColumn
          v-if="hasAddItem"
          class="program-set-placeholder d-none d-lg-block"
          :size="colSizes"
          key="placeholder"
        >
          <SmartListAddItem
            :label="addItemLabel"
            :addItemAction="addItemAction"
            :listType="listType"
          />
        </GridColumn>
        <GridColumn
          v-if="noItems"
          class="program-set-placeholder d-none d-lg-block"
          :size="colSizes"
          key="msg"
        >
          <h3 key="msg" class="text-center">{{ noResultMsg }}</h3>
        </GridColumn>
        <GridColumn
          :class="colClassName(index, items, listType)"
          :size="colSizes"
          v-for="(item, index) in localItems"
          :item="item"
          :key="`${listType}-${index}`"
          :data-set-id="
            item.id || item.nid || item.event_id || item.rateflow_log_id
          "
        >
          <ProgramsDetails
            v-if="listType == 'program'"
            :item="item"
            :itemIndex="index"
            :onDragEnd="onDragEnd"
            :isLast="isLast(index)"
          />
          <CompanyActivityDetails
            v-if="listType == 'activity'"
            :onItemClick="onItemClick"
            :item="item"
            :itemIndex="index"
          />
          <CompanyDetails
            v-if="listType == 'organization'"
            :item="item"
            :itemIndex="index"
            :onDragEnd="onDragEnd"
            :isLast="isLast(index)"
          />
          <RatesDetails
            v-if="listType == 'rates'"
            :item="item"
            :itemIndex="index"
            :onDragEnd="onDragEnd"
            :isLast="isLast(index)"
          />
          <StaticFlyerDetails
            v-if="listType == 'static_flyer'"
            :item="item"
            :itemIndex="index"
            :onDragEnd="onDragEnd"
            :isLast="isLast(index)"
          />
          <ManualListingDetails
            v-if="listType == 'manual_flyer'"
            :item="item"
            :itemIndex="index"
            :onDragEnd="onDragEnd"
            :isLast="isLast(index)"
          />
          <PeopleDetails
            v-if="listType == 'member'"
            :item="item"
            :itemIndex="index"
            :onDragEnd="onDragEnd"
            :toggleMemberPricing="toggleMemberPricing"
            :isLast="isLast(index)"
          />
        </GridColumn>
      </transition-group>
    </draggable>
    <BasePagination
      v-if="isPagination"
      :total="qtyItemsDB"
      :maxItemsPerPage="max_items"
      :toOffset="max_items"
      @onPagination="reloadItems"
      :vuexNamespace="vuexNamespace"
    />
  </div>
</template>

<script>
import _isNull from "lodash/isNull"
import _isEmpty from "lodash/isEmpty"
import draggable from "vuedraggable"
import ProgramsDetails from "../Programs/ProgramsDetails"
import StaticFlyerDetails from "../StaticFlyers/StaticFlyerDetails"
import ManualListingDetails from "../ManualListing/ManualListingDetails"
import CompanyDetails from "../Manager/CompanyDetails"
import PeopleDetails from "../Manager/PeopleDetails"
import CompanyActivityDetails from "../Manager/CompanyActivityDetails"
import RatesDetails from "../Rates/RatesDetails"
import SmartListAddItem from "./SmartListAddItem"
import BasePagination from "../BasePagination"

/**
 * TODO: The deatils component should be dynamic in some way
 *       we don't need to add each type manually here
 */

export default {
  name: "SmartListItems",
  data() {
    return {
      noResultMsg: "There are no items to show."
    }
  },
  components: {
    CompanyDetails,
    CompanyActivityDetails,
    ProgramsDetails,
    PeopleDetails,
    SmartListAddItem,
    RatesDetails,
    StaticFlyerDetails,
    ManualListingDetails,
    draggable,
    BasePagination
  },
  props: {
    hasAddItem: { type: Boolean, required: true },
    addItemLabel: { type: String, default: "Add item" },
    listType: { type: String },
    items: { type: [Array, Object], required: true },
    gridView: { type: Boolean, default: true },
    onDragEnd: { type: Function },
    addItemAction: { type: Function },
    toggleMemberPricing: { type: Function },
    onItemClick: { type: Function },
    vuexNamespace: { type: [String, Boolean], default: false }
  },
  computed: {
    nameSpace() {
      return this.vuexNamespace ? `${this.vuexNamespace}` : ""
    },
    bank_id() {
      return this.$store.state[this.nameSpace].bank_id
    },
    qtyItemsDB() {
      return this.$store.state[this.nameSpace].qtyItemsDB
    },
    enable_pagination() {
      return this.$store.state[this.nameSpace].enable_pagination
    },
    max_items() {
      return this.$store.state[this.nameSpace].max_items
    },
    isPagination() {
      const nameSpace = this.vuexNamespace ? `${this.vuexNamespace}/` : ""
      return this.$store.getters[`${nameSpace}isPagination`]
    },
    noItems: function() {
      return !_isNull(this.items) && _isEmpty(this.items)
    },
    className: function() {
      return !this.gridView ? "list-view" : "grid-view"
    },
    colSizes: function() {
      return this.gridView ? ["sm-6", "lg-4"] : "sm-12"
    },
    localItems: {
      get() {
        return this.items
      },
      set(value) {
        /**
         * The "correct" way is commit the update here,
         * but vuedraggable seems to have issues with it.
         * We are doing it on the "onEnd" event
         **/
        // this.$store.commit("updateSets", value)
      }
    },
    showPagination: function() {
      if (this.enable_pagination && this.qtyItemsDB >= this.max_items)
        return true
    }
  },
  methods: {
    colClassName: function(index, items, listType) {
      let colClassName = `is-${listType}`
      if (listType !== "manual_flyer" && listType !== "static_flyer") {
        colClassName += " draggable"
      }
      if (index == items.length - 1) {
        colClassName += " last-item"
      }
      return colClassName
    },
    onStart: function(evt) {
      document.body.classList.add("dragging")
    },
    onEnd: function(evt) {
      document.body.classList.remove("dragging")
      if (evt.newIndex != evt.oldIndex && this.onDragEnd) {
        this.onDragEnd(evt.oldIndex - 1, evt.newIndex - 1)
      }
    },
    onMove: function(evt) {
      // TODO: add conditional if there is no placeholder at the begining
      return evt.related.className.indexOf("program-set-placeholder") === -1
    },
    isLast: function(index) {
      return index == this.items.length - 1
    },
    reloadItems(e) {
      const { offset } = e

      const nameSpace = this.vuexNamespace ? `${this.vuexNamespace}/` : ""
      this.$store.dispatch(`${nameSpace}setCurrentOffset`, offset)
      this.$store.dispatch(`${nameSpace}getFlyerImages`, {
        bank_id: this.bank_id
      })
      this.$store.dispatch(`${nameSpace}clearAllItems`)
    }
  }
}
</script>

<style lang="scss" scoped>
.sortable-ghost {
  .program {
    position: relative;
    .list-view & {
      border-left-color: #fff;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      background: #fff;
      width: 100%;
      height: 100%;
      z-index: 10;
      border-radius: 4px;

      .list-view & {
        top: 0;
        left: 0;
        border-radius: 4px;
      }
    }
  }
}
.draggable-wrapper {
  flex-wrap: wrap;
  width: 100%;
}
</style>
