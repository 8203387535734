<doc>
  Image uploader Vue.js component
</doc>

<template>
  <div :class="className">
    <LoaderPlaceholder class="loader" v-if="isLoading" type="circle" />
    <DefaultImgUploader
      :id="id"
      :editMode="editMode"
      :label="label"
      :previewAlt="previewAltComp"
      :isLoading="isLoading"
      :previewClass="previewClassComp"
      :uploadPhoto="uploadPhoto"
      :imgSrc="imgSrc"
      :labelTop="labelTop"
      :hasRemove="hasRemove"
      :removePhoto="removePhotoLocal"
      :isHeadshot="isHeadshot"
      v-if="this.type === 'default'"
    />
    <ThumbPrevImgUploader
      :id="id"
      :editMode="editMode"
      :fullRounded="fullRounded"
      :size="size"
      :label="label"
      :uploadPhoto="uploadPhoto"
      :imgSrc="imgSrc"
      :preview="preview"
      :hasError="showAlert || hasError.length"
      v-else-if="this.type === 'thumbPrev'"
    />
    <p v-if="hasError" class="text-danger">{{ hasError }}</p>
    <AlertModal
      :showAlert="showAlert"
      :text="confirmTxt"
      v-on:modalConfirm="modalConfirm"
    />
  </div>
</template>

<script>
import DefaultImgUploader from "./DefaultImgUploader"
import ThumbPrevImgUploader from "./ThumbPrevImgUploader"
export default {
  name: "ImgUploader",
  props: {
    uploadCallback: { type: Function, required: true },
    removePhoto: { type: Function },
    editMode: { type: Boolean, default: false },
    labelTop: { type: Boolean, default: false },
    hasRemove: { type: Boolean, default: false },
    preview: { type: Boolean, default: true },
    fullRounded: { type: Boolean, default: false },
    label: { type: String, default: "Upload image" },
    id: { type: [String, Boolean], default: false },
    previewClass: { type: String, default: "" },
    previewAlt: { type: String, default: "" },
    type: { type: String, default: "default" },
    previewSrc: { type: String },
    useS3: { type: Boolean, default: false },
    onErrorShowAlert: { type: Boolean, default: false },
    size: { type: [String, Number], default: 0 },
    isHeadshot: { type: Boolean, default: false }
  },
  components: {
    DefaultImgUploader,
    ThumbPrevImgUploader
  },
  data() {
    return {
      isLoading: false,
      hasError: "",
      confirmTxt: "",
      imgSrc: false,
      showAlert: false
    }
  },
  computed: {
    className() {
      return `img-uploader img-uploader--${this.type}`
    },
    previewAltComp() {
      return this.previewAlt || "uploaded image preview"
    },
    previewClassComp() {
      let className = this.previewClass
      if (this.type === "thumbPrev") {
        className += "img-uploader__prev"
      }
      return className
    }
  },
  methods: {
    removePhotoLocal() {
      this.imgSrc = ""
      this.removePhoto()
    },
    modalConfirm() {
      this.showAlert = false
      this.confirmTxt = ""
    },
    uploadPhoto(e) {
      this.isLoading = true
      this.hasError = ""
      const file =
        e.target.files && e.target.files[0] ? e.target.files[0] : null
      const minimumHeight = 150;
      const minimumWidth = 150;

      if (file) {
        const data = new FormData()
        const reader = new FileReader()
        var vm = this

        data.append("files[img]", e.target.files[0])
        data.append("bb_sid", window.SESSION_ID)

        reader.onload = e => {
          //this.$refs.preview.src = e.target.result
          var image = new Image()
          image.src = e.target.result

          image.onload = _e => {
            var height = image.height
            var width = image.width

            if(vm.isHeadshot && (height < minimumHeight || width < minimumWidth)) {
              vm.isLoading = false
              vm.hasError = "Image must be at least " + minimumWidth + "x" + minimumHeight + "."
            }
            else {
              vm.imgSrc = image.src
              let url = vm.useS3 ? "/s3-file-upload.json" : "/bb-file-upload.json"
              fetch(url, {
                method: "POST",
                body: data,
                credentials: "same-origin"
              })
                .then(res => {
                  return res.json()
                })
                .then(data => {
                  vm.isLoading = false
                  if (data.status === "error") {
                    vm.imgSrc = ""
                    if (vm.onErrorShowAlert) {
                      vm.confirmTxt = data.message
                      vm.showAlert = true
                    } else {
                      vm.hasError = data.message
                    }
                    return
                  }
                  if (vm.useS3) {
                    vm.uploadCallback(data, vm.id)
                  } else {
                    vm.uploadCallback(
                      data.message.fid,
                      data.message.filename,
                      data.message.url,
                      vm.id
                    )
                  }
                })
                .catch(err => {
                  console.error(err)
                  vm.isLoading = false
                  vm.hasError = "There was an error, please try again."
                })
            }
          }
        }

        reader.readAsDataURL(file)
      }
    }
  },
  created() {
    this.imgSrc = this.previewSrc || false
  }
}
</script>

<style lang="scss" scoped src="./ImgUploader.scss"></style>
