var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "newLinkModal",
        okLabel: "Save",
        isLoading: _vm.isLoading
      },
      on: {
        beforeEnter: _vm.beforeEnter,
        afterEnter: _vm.afterEnter,
        ok: _vm.submitForm,
        cancel: _vm.hideModal
      },
      model: {
        value: _vm.showItemModal,
        callback: function($$v) {
          _vm.showItemModal = $$v
        },
        expression: "showItemModal"
      }
    },
    [_c("h5", [_vm._v("\n\t\tSet your parameters\n\t")])]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ac33bac", { render: render, staticRenderFns: staticRenderFns })
  }
}