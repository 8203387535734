'use strict';

var init = function() {

    var fields = ['city','neighborhood','zipcode','mls','address'],
        bhounds = [],
        filter = 'all';
    for(var i=0; i<fields.length; i++){
        bhounds[fields[i]] = new Bloodhound({
          datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          remote: {
            url: '/get_listing_values.json?field=' + fields[i] + '&str=%QUERY',
            wildcard: '%QUERY',
            cache: true,
            filter: function(response){
                return response;
            }
          }
        });
    }

    $('#searchAll, .search-box').typeahead(
      {
        hint: true,
        highlight: true,
        limit: Infinity
      },
      {
        name: 'cities',
        source: bhounds['city'],
        templates: {
            header: function (){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'cities'){
                return '<div class="tt-suggestion tt-header">Cities</div>';
              }
              return '';
            },
            suggestion: function(data){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'cities'){
                return '<div class="bb_filter_btn" data-bb_filter_key="city" data-bb_filter_value="'+data+'">'+data+'</div>';
              }
              return '<i class="hide">';
            }
        },
        limit: Infinity
      },
      {
        name: 'addresses',
        source: bhounds['address'],
        templates: {
            header: function (){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'address'){
                return '<div class="tt-suggestion tt-header">Street Addresses</div>';
              }
              return '<i class="hide">';
            },
            suggestion: function(data){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'address'){
                return '<div class="bb_filter_btn" data-bb_filter_key="fullstreetaddress" data-bb_filter_value="'+data+'">'+data+'</div>';
              }
              return '<i class="hide">';
            }
        },
        limit: Infinity
      },
      {
        name: 'neighborhoods',
        source: bhounds['neighborhood'],
        templates: {
            header: function (){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'neighborhoods'){
                return '<div class="tt-suggestion tt-header">Neighborhoods</div>';
              }
              return '';
            },
            suggestion: function(data){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'neighborhoods'){
                return '<div class="bb_filter_btn" data-bb_filter_key="neighborhood" data-bb_filter_value="'+data+'">'+data+'</div>';
              }
              return '<i class="hide">';
            }
        },
        limit: Infinity
      },
      {
        name: 'zipcodes',
        source: bhounds['zipcode'],
        templates: {
            header: function (){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'zipcode'){
                return '<div class="tt-suggestion tt-header">Zipcodes</div>';
              }
              return '';
            },
            suggestion: function(data){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'zipcode'){
                return '<div class="bb_filter_btn" data-bb_filter_key="zipcode" data-bb_filter_value="'+data+'">'+data+'</div>';
              }
              return '<i class="hide">';
            }
        },
        limit: Infinity
      },
      {
        name: 'mls',
        source: bhounds['mls'],
        templates: {
            header: function (){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'mls'){
                return '<div class="tt-suggestion tt-header">MLS Number</div>';
              }
              return '';
            },
            suggestion: function(data){
              if($('body').hasClass('is-mobile') || filter == 'all' || filter == 'mls'){
                return '<div class="bb_filter_btn" data-bb_filter_key="mls_number" data-bb_filter_value="'+data+'">'+data+'</div>';
              }
              return '<i class="hide">';
            }
        },
        limit: Infinity
      }
    );


    $('#searchAll, .search-box')
      .bind('typeahead:asyncrequest', function() {
        $('.tt-loading').removeClass('hide');
      }).bind('typeahead:asyncreceive', function() {
        $('.tt-loading').addClass('hide');
      }).bind('typeahead:change', function() {
        $(this).val('');
      }).bind('typeahead:select', function() {
        $('.tt-cursor').trigger('bb_filter_btn_click');
        $(this).trigger('blur');
      });

    // Dropdown
    $('.bb-typeahead-filter').on('click', function(){
      const $this = $(this);

      $this.closest('.dropdown-menu').find('.active').removeClass('active');
      $this.parent().addClass('active');
      filter = $this.data('bb_auto_value');
      $('#searchAll, .search-box').typeahead('val', '');
    });

}

module.exports = {
  init: init
};