<doc>
  Input text Vue.js component
</doc>

<template>
  <div :class="wrapperClass">
    <label :for="name" :class="labelClass">
      {{ label }}
    </label>
    <textarea
      ref="input"
      :class="inputClassName"
      :type="type"
      :id="name"
      :aria-describedby="describedby"
      :placeholder="placeholder"
      v-bind:value="value"
      v-on:input="onInput"
      v-on:focus="onFocus"
      v-on:blur="onBlur"
      @keyup.enter="$emit('keyupEnter', $event)"
      @keyup.esc="$emit('keyupEsc', $event)"
      :maxlength="this.limit > 0 ? this.limit : false"
    ></textarea>
    <small v-if="limit > 0 && showLimit" :class="textLimitClass">
      <icon v-show="charactersLeft <= 0" name="exclamation-circle"></icon>
      {{ charactersLeft }} characters left
    </small>
    <small v-if="helpText" :id="describedby" class="form-text text-muted">
      {{ helpText }}
    </small>
    <span v-if="helperText" class="overview-tooltip">Flyer will display at most 500 characters then appends ellipsis. The landing page will display the full text.</span>
  </div>
</template>

<script>
import { props, methods, mounted, updated } from "./inputCommons"

export default {
  name: "InputTextArea",
  props: Object.assign({}, props, {
    noResize: { type: Boolean, default: false },
    limit: { type: Number, default: 0 },
    showLimit: { type: Boolean, default: true},
    helperText: { type: Boolean, default: false }
  }),
  computed: {
    charactersLeft: function() {
      const current = this.value ? this.value.length : 0

      return this.limit - current
    },
    textLimitClass: function() {
      let className = "form-text form-text--limit"
      className += this.charactersLeft <= 0 ? " text-danger" : " text-muted"
      return className
    },
    wrapperClass: function() {
      let className = "form-group"
      if (this.isMaterial) {
        className += " material-input"
      }

      return className
    },
    labelClass: function() {
      let className = this.isMaterial ? "input-label" : ""
      if (this.hideLabel) {
        className += " sr-only"
      }
      return className
    },
    inputClassName: function() {
      let className = `form-control ${this.inputClass}`
      if (this.noResize) {
        className += " no-resize"
      }
      if (this.size) {
        className += ` form-control-${this.size}`
      }
      return className
    }
  },
  methods: methods,
  mounted: mounted,
  updated: updated
}
</script>

<style lang="scss" src="./Forms.scss" scoped></style>
