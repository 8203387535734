import _find from "lodash/find"

// Initial state
const state = {
  rateflow_id: null,
  currentRate: null,
  chart: null,
  showModal: false,
  showChartModal: false,
  updatedTime:null
}
const mutations = {
  SET_RATEFLOW_ID(state, id) {
    state.rateflow_id = id
  },
  SET_CURRENT_RATE(state, rate) {
    state.currentRate = rate
  },
  SET_UPDATED_TIME(state, timestamp){
    state.updatedTime = timestamp
  },
  SET_CHART_PROGRAM(state, program) {
    state.chartInfo = program
  },
  SET_CHART(state, chart) {
    state.chart = chart
  },
  TOGGLE_RATE_MODAL(state, condition) {
    state.showModal = condition
  },
  TOGGLE_CHART_MODAL(state, condition) {
    state.showChartModal = condition
  }
}

const actions = {
  resetCurrentRate({ commit }) {
    commit("SET_CURRENT_RATE", null)
  },
  resetChart({ commit }) {
    commit("SET_CHART", null)
  },
  setCurrentRate({ commit }, rate) {
    commit("SET_CURRENT_RATE", rate)
  },
  setChart({ commit }, profile_id) {
    BB.chart
      .getData({
        profile_id: profile_id,
        type: "line_chart",
        include_treasury: true
      })
      .then(function(data) {
        commit("SET_CHART", data)
      })
  },
  toggleRateModal({ dispatch, state, commit }, condition) {
    commit("TOGGLE_RATE_MODAL", condition)
    if (!condition) {
      dispatch("resetCurrentRate")
    }
  },
  toggleChartModal({ dispatch, state, commit }, condition) {
    commit("TOGGLE_CHART_MODAL", condition)
    if (!condition) {
      dispatch("resetChartProgram")
      dispatch("resetChart")
    }
  },
  initRates({ commit, dispatch, state }, { rateflow_id }) {
    commit("SET_RATEFLOW_ID", rateflow_id)
    dispatch("getRates")
  },
  getRates({ commit, dispatch, state }, params) {
    let rateflow_data = { id: state.rateflow_id, max_results: 100 }
    if (params) {
      rateflow_data = Object.assign({}, rateflow_data, params)
    }
    return BB.rateflow
      .price(rateflow_data)
      .then(data => {
        if (data.status !== "error") {
          dispatch("getLog", data.results)
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  getLog({ commit, dispatch, state }, rates) {
    let log_id = []
    rates.forEach(function(rate, index) {
      this.push(rate.rateflow_log_id)
    }, log_id)
    BB.rateflow
      .log(log_id)
      .then(data => {
        if (data.status !== "error") {
          if(data[0] && data[0].timestamp)commit("SET_UPDATED_TIME",data[0].timestamp*1000)
          rates.forEach(function(rate, index) {
            rates[index].log = _find(data, [
              "id",
              rate.rateflow_log_id.toString()
            ])
          })
        }
        commit("UPDATE_ORIGINAL_ITEMS", rates)
      })
      .catch(err => {
        console.error(err)
      })
  },
  async refreshRates({ commit, dispatch, state }, params) {
    //const params = { id: state.rateflow_id, max_results: 10, list_price: list_price, min_credit: min_credit }
    await dispatch("getRates", params)
  }
}

export default {
  state,
  mutations,
  actions
}
