import Vue from "vue"
import RateTableManagerStandalone from "./components/RateTableManagerStandalone/index.vue"

export default function() {
  if (!document.getElementById("rate-table-manager-app")) {
    return
  }

  // Vuejs setup
  new Vue({
    el: "#rate-table-manager-app",
    components: {
      RateTableManagerStandalone
    }
  })
}
