<doc>
  Links Vue.js component
</doc>

<template>
  <div>
        <SmartList
          :searchQuery="searchQuery"
          :searchQueries="searchQueries"
          :filteredItems="filteredItems"
          :addQuery="addQuery"
          :removeQuery="removeQuery"
          :resetQuery="resetQuery"
          :toggleListView="toggleListView"
          :addItemAction="toggleItemModal"
          :items="items"
          :onDragEnd="onEnd"
          :gridView="gridView"
          listType="link" />
    <LinkModal />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import { LinkModal, SmartList } from "../components"

export default {
  name: "MyLinks",
  components: {
    SmartList: SmartList,
    LinkModal: LinkModal
  },
  computed: Object.assign(
    {},
    mapState(["originalItems", "gridView", "searchQueries", "searchQuery"]),
    mapGetters(["currentItems"]),
    {
      initialHash: function() {
        return this.originalItems.length ? "my-links" : ""
      },
      items: {
        get() {
          try {
            return this.currentItems
          } catch (e) {
            console.log(e)
          }
        },
        set(value) {
          /**
           * The "correct" way is commit the update here,
           * but vuedraggable seems to have issues with it.
           * We are doing it on the "onEnd" event
           **/
          // this.$store.commit("updateSets", value)
        }
      }
    }
  ),
  methods: Object.assign(
    {},
    mapMutations({
      toggleListView: "TOGGLE_LIST_VIEW"
    }),
    mapActions([
      "filteredItems",
      "addQuery",
      "removeQuery",
      "resetQuery",
      "toggleLinkDetailsModal",
      "toggleItemModal",
      "updateOriginalItems"
    ]),
    {
      onEnd: function(oldIndex, newIndex) {
        this.updateOriginalItems({ oldIndex: oldIndex, newIndex: newIndex })
      }
    }
  )
}
</script>

<style lang="scss" scoped>
</style>
