<doc>
	Program profiles Modal Vue.js component
</doc>

<template>
		<draggable
			v-model="pricing_profiles"
			:options="{
				chosenClass: 'sortable-chosen',
				filter: '.program-set-placeholder',
				handle: '.profile-title'
			}"
			@end="onEnd"
			v-if="currentEditedItem">
	    <transition-group class="d-flex draggable-wrapper" tag="div" style="flex-wrap: wrap;">
	      <GridColumn
	      	class="draggable"
	      	:size="['sm-6', 'lg-4']"
					v-for="(program, index) in pricing_profiles"
					:item="program"
					:key="program.id"
					:data-program-id="program.id">
	          <ProgramProfile :program="program" :index="index" />
	      </GridColumn>
	    </transition-group>
	</draggable>
</template>

<script>
import { mapState, mapActions } from "vuex"
import draggable from "vuedraggable"
import ProgramProfile from "./ProgramProfile"
export default {
	name: "ProgramsProfile",
	components: {
		ProgramProfile: ProgramProfile,
		draggable
	},
	computed: Object.assign({}, mapState(["currentEditedItem"]), {
		pricing_profiles: {
			get() {
				return this.currentEditedItem.pricing_profiles
			},
			set(value) {
				/**
				 * The "correct" way is commit the update here,
				 * but vuedraggable seems to have issues with it.
				 * We are doing it on the "onEnd" event
				 **/
				// this.$store.commit("updateSets", value)
			}
		}
	}),
	methods: Object.assign({}, mapActions(["saveProfileList"]), {
		onEnd: function(evt) {
			if (evt.newIndex != evt.oldIndex) {
				this.saveProfileList({ oldIndex: evt.oldIndex, newIndex: evt.newIndex })
			}
		}
	})
}
</script>

<style lang="scss" scoped>
#newPricingSetModal {
	h5 {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.intro {
		margin-bottom: 25px;
	}
}
.sortable-ghost {
	.profile-program {
		border-color: #fff;
		position: relative;
		&::before {
			content: "";
			display: block;
			position: absolute;
			background: #fff;
			width: 100%;
			height: 100%;
			z-index: 10;
			left: -1px;
			top: -1px;
		}
	}
}
</style>
