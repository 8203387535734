// Initial state
const state = {
	bank_id: null
}
const mutations = {
	TOGGLE_LINK_DETAILS_MODAL(state, condition) {
		state.showLinkDetailsModal = condition
	}
}

const actions = {
	toggleLinkDetailsModal({ dispatch, state, commit }, condition) {
		dispatch("toggleModal", { mutation: "TOGGLE_LINK_DETAILS_MODAL", condition: condition })
	}
}

export default {
	state,
	mutations,
	actions
}
