import Vue from "vue"
import _orderBy from "lodash/orderBy"
import initStore from "./store/bb-flyer-templates"
import { FlyerTemplateManager } from "./pages"

export default function() {
    if (!document.getElementById("vue-manage-flyer-templates-app")) {
        return
    }
    //const store = initStore()
    // initial items
    //store.dispatch("initFlyerTemplateManager", {bank_id,isBankAdmin,uid})
    // Vuejs setup
    new Vue({
        el: "#vue-manage-flyer-templates-app",
        components: {
            FlyerTemplateManager,
        }
    })

    // Do we need this? 25/4
    //window.dispatchEvent(new Event("resize"))
}

