var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.dd_class },
    [
      _c(
        "BaseButton",
        {
          staticClass: "dropdown-toggle custom-margin-top",
          attrs: {
            size: _vm.size,
            "aria-haspopup": _vm.haspopup,
            "aria-expanded": _vm.expanded,
            theme: "link"
          },
          on: { click: _vm.toggle }
        },
        [_c("i", { staticClass: "fas fa-filter" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.ddm_class,
          attrs: { "aria-labelledby": "dropdownMenuButton" }
        },
        _vm._l(_vm.options, function(option, index) {
          return _c(
            "a",
            {
              key: index,
              staticClass: "dropdown-item",
              class: { "dopdown-item--active": index === _vm.currentIndex },
              style: index === _vm.currentIndex ? _vm.styles : null,
              on: {
                click: function($event) {
                  _vm.triggerOption(option, index)
                }
              }
            },
            [
              _c("i", { class: option.icon }),
              _vm._v(" " + _vm._s(option.label) + "\n    ")
            ]
          )
        })
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDropdown,
            expression: "showDropdown"
          }
        ],
        staticClass: "dropdown-overlay",
        on: { click: _vm.toggle }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-499fe122", { render: render, staticRenderFns: staticRenderFns })
  }
}