var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.defaultScenarios
    ? _c(
        "grid-row",
        [_c("grid-column", [_c("h3", [_vm._v("Pricing is not configured")])])],
        1
      )
    : _c(
        "grid-row",
        [
          _vm.currentPage === "scenarios"
            ? _c(
                "grid-column",
                [
                  _c(
                    "grid-row",
                    [
                      _c("grid-column", [
                        _c("h3", [_vm._v("Set Loan Program Order")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n              Set the order of the programs you want on the flyer and landing page. System will match to the closest best execution based on the Mortgage Points and Program. If match does not exist, offers will be requested in the order defined below until the number of programs required for the flyer / landing page are returned.\n            "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "grid-row",
                    { staticClass: "target-price-tool" },
                    [
                      _c(
                        "grid-column",
                        [
                          _c("InputText", {
                            attrs: {
                              label: "Discount Points",
                              labelClassName: "required",
                              name: "target_price"
                            },
                            on: { onBlur: _vm.onChange },
                            model: {
                              value: _vm.points,
                              callback: function($$v) {
                                _vm.points =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "points"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "increment-tp-btn",
                                on: {
                                  click: function($event) {
                                    _vm.incrementTP("plus")
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fas fa-plus" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "increment-tp-btn",
                                on: {
                                  click: function($event) {
                                    _vm.incrementTP("minus")
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fas fa-minus" })]
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "preview-btn-wrapper" }, [
                            _c(
                              "button",
                              { on: { click: _vm.previewPricing } },
                              [_vm._v("Preview")]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.scenarios
                    ? _c(
                        "div",
                        [
                          _c("scenarios-list", {
                            attrs: {
                              "initial-scenarios": _vm.scenarios,
                              "save-list": _vm.saveList,
                              "select-scenario": _vm.selectScenario
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-small-turquesa btn-sm btn-reset",
                        attrs: { type: "button" },
                        on: { click: _vm.reset }
                      },
                      [_vm._v("Reset")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { clear: "both" } })
                  ])
                ],
                1
              )
            : _vm.currentPage === "details"
              ? _c("grid-column", [
                  _c("button", { on: { click: _vm.clearScenario } }, [
                    _vm._v("Back")
                  ]),
                  _vm._v(" "),
                  _vm.previewLoading || _vm.previewLoading === null
                    ? _c("div", [_vm._v("Loading...")])
                    : _vm.previewLoading === false &&
                      _vm.scenarioPricingData &&
                      _vm.scenarioPricingData.cards
                      ? _c(
                          "div",
                          [
                            _c("h3", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.scenarioPricingData.label) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n              Below is the Best Fit and two other closest pricing options for this program and the discount points you entered.\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.scenarioPricingData.cards, function(
                              card,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "col-sm-4 list-cards"
                                },
                                [
                                  _c("CalcCard", {
                                    attrs: {
                                      rate: card.rate,
                                      price: card.price,
                                      term: parseInt(card.amortizationTerm),
                                      listPrice:
                                        _vm.scenarioPricingData.listPrice,
                                      fee: card.discount
                                        ? {
                                            title: "Discount",
                                            amount: card.discount
                                          }
                                        : {
                                            title: "Rebate",
                                            amount: card.rebate
                                          },
                                      type: card.productName,
                                      principalAndInterest:
                                        card.principalAndInterest,
                                      investor: card.investor,
                                      active: index === 0,
                                      label: index === 0 ? "Best Fit" : ""
                                    }
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      : _c("div", [
                          _c("p", [
                            _vm._v("No offers currently, try again later.")
                          ])
                        ])
                ])
              : _vm.currentPage === "preview"
                ? _c("grid-column", [
                    _c(
                      "button",
                      {
                        staticClass: "pricing-set-tool-back-btn",
                        on: { click: _vm.clearPreview }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _vm.previewLoading || _vm.previewLoading === null
                      ? _c("div", [_vm._v("Loading...")])
                      : _vm.previewLoading === false && _vm.previewPricingData
                        ? _c(
                            "div",
                            [
                              _c("h3", [_vm._v("Preview of Loan Programs")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Based on the order you selected and Discount Points entered, these are the Loan Programs that would appear on the home flyer and landing page at this time per the pricing engine results."
                                )
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.previewPricingData, function(
                                card,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "col-sm-3 list-cards"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "preview-card-header" },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(card.label))
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(card.rate) +
                                          "% | " +
                                          _vm._s(card.apr) +
                                          "% (" +
                                          _vm._s(card.price) +
                                          ")\n          "
                                      )
                                    ])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _c("div", [
                            _c("p", [
                              _vm._v("No offers currently, try again later.")
                            ])
                          ])
                  ])
                : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b67812c", { render: render, staticRenderFns: staticRenderFns })
  }
}