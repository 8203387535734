/**
 * Program Preference Manage API wrapper
 */
const ENDPOINT = "/program_preferences/manage.json"

const bbPreferencesManage = {
	get(params = {}, callback = null) {
		return $.get(ENDPOINT, params, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
	update(preferenceData, callback) {
		if (!preferenceData.id) {
			console.log("preference id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(preferenceData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
    add(preferenceData, callback) {
        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(preferenceData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
}

export default bbPreferencesManage
