/**
 * Realtor API wrapper
 */

const ENDPOINT = "/manage-realtor.json"
const MANUAL_ENDPOINT = "/api/v1/manual-realtors"

const bbRealtorManage = {
  get(uid, callback) {
    if (!uid) {
      console.log("uid missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.get(ENDPOINT, { uid: uid }, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(realtorData, callback) {
    if (!realtorData.uid) {
      console.log("uid missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(realtorData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  getFlyerPreference(uid, callback) {
    if (!uid) {
      console.log("uid missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.get(ENDPOINT, { uid: uid, flyer_preference: true }, function(
      data
    ) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  manual: {
    get(params, callback) {
      return $.get(MANUAL_ENDPOINT, params, function(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      })
    },
    // required fields:
    //		first_name (string)
    //		last_name (string)
    //		email (string)
    //		phone (string)
    //		agency_name (string)
    //		agency_logo (string)
    //		picture (string)
    //		state (string)
    //		owner (int)
    create(realtorData, callback) {
      if (
        !realtorData.last_name ||
        !realtorData.first_name ||
        !realtorData.email
      ) {
        console.log("required realtor fields missing")
        return new Promise((resolve, reject) => {
          reject("error")
        })
      }

      return $.ajax({
        url: MANUAL_ENDPOINT,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(realtorData),
        success(data) {
          if (callback && typeof callback === "function") {
            callback.call(this, data)
          }
        },
        error(xhr, ajaxOptions, thrownError) {
          if (callback && typeof callback === "function") {
            callback.call(this, thrownError)
          }
        }
      })
    },
    update(realtorData, callback) {
      if (!realtorData.id) {
        console.log("id missing")
        return new Promise((resolve, reject) => {
          reject("error")
        })
      }

      return $.ajax({
        url: MANUAL_ENDPOINT,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(realtorData),
        success(data) {
          if (callback && typeof callback === "function") {
            callback.call(this, data)
          }
        },
        error(xhr, ajaxOptions, thrownError) {
          if (callback && typeof callback === "function") {
            callback.call(this, thrownError)
          }
        }
      })
    }
  }
}

export default bbRealtorManage
