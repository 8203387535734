var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ConfigEditorManager", {
        attrs: {
          type: "Rate Cards",
          getConfig: _vm.getConfig,
          getAllConfig: _vm.getAllConfig,
          addConfig: _vm.addConfig,
          deleteConfig: _vm.deleteConfig,
          updateConfig: _vm.updateConfig,
          "show-settings-button": true
        },
        on: { openSettingsModal: _vm.openSettingsModal }
      }),
      _vm._v(" "),
      _c(
        "BaseModal",
        {
          attrs: {
            title: "Scenario Preferences",
            id: "rcModal",
            isLoading: _vm.isLoading
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("p", { staticClass: "intro" }),
          _vm._v(" "),
          _vm.prefMap
            ? _c("div", [
                _c("h3", [_vm._v("Profile Preferences")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.prefMap, function(value, key) {
                    return _c(
                      "li",
                      { key: key, staticClass: "pref-map-list-item" },
                      [
                        _vm._v("\n          " + _vm._s(key) + ":\n          "),
                        _vm.getProfileById(value) &&
                        _vm.getProfileById(value).title
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getProfileById(value).title) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "mini-delete-btn-wrapper",
                                  on: {
                                    click: function($event) {
                                      _vm.deletePref(key)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-times-circle mini-delete-btn",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            ])
                          : _c("span", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.prefMap[key],
                                      expression: "prefMap[key]"
                                    }
                                  ],
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.prefMap,
                                          key,
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        _vm.savePref(key, _vm.prefMap[key])
                                      }
                                    ]
                                  }
                                },
                                _vm._l(_vm.defaultProfiles, function(profile) {
                                  return !Object.values(_vm.prefMap).includes(
                                    profile.id
                                  )
                                    ? _c(
                                        "option",
                                        {
                                          key: profile.id,
                                          domProps: { value: profile.id }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(profile.title) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                })
                              )
                            ])
                      ]
                    )
                  })
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-549c1ace", { render: render, staticRenderFns: staticRenderFns })
  }
}