// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../node_modules/css-loader/index.js!../../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-900bdcae\",\"scoped\":true,\"sourceMap\":true}!../../../node_modules/sass-loader/lib/loader.js!../../../node_modules/postcss-loader/src/index.js!../../../node_modules/sass-resources-loader/lib/loader.js?{\"resources\":\"/opt/atlassian/pipelines/agent/build/sass/_vue-components-settings.scss\"}!../../../node_modules/vue-loader/lib/selector.js?type=styles&index=0!./ManagerPeople.vue");
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add the styles to the DOM
var add = require("!../../../node_modules/vue-style-loader/lib/addStylesClient.js").default
var update = add("55213603", content, false, {});
// Hot Module Replacement
if(module.hot) {
 // When the styles change, update the <style> tags
 if(!content.locals) {
   module.hot.accept("!!../../../node_modules/css-loader/index.js!../../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-900bdcae\",\"scoped\":true,\"sourceMap\":true}!../../../node_modules/sass-loader/lib/loader.js!../../../node_modules/postcss-loader/src/index.js!../../../node_modules/sass-resources-loader/lib/loader.js?{\"resources\":\"/opt/atlassian/pipelines/agent/build/sass/_vue-components-settings.scss\"}!../../../node_modules/vue-loader/lib/selector.js?type=styles&index=0!./ManagerPeople.vue", function() {
     var newContent = require("!!../../../node_modules/css-loader/index.js!../../../node_modules/vue-loader/lib/style-compiler/index.js?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-900bdcae\",\"scoped\":true,\"sourceMap\":true}!../../../node_modules/sass-loader/lib/loader.js!../../../node_modules/postcss-loader/src/index.js!../../../node_modules/sass-resources-loader/lib/loader.js?{\"resources\":\"/opt/atlassian/pipelines/agent/build/sass/_vue-components-settings.scss\"}!../../../node_modules/vue-loader/lib/selector.js?type=styles&index=0!./ManagerPeople.vue");
     if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
     update(newContent);
   });
 }
 // When the module is disposed, remove the <style> tags
 module.hot.dispose(function() { update(); });
}