var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      attrs: { name: "modal" },
      on: {
        "before-enter": _vm.beforeEnter,
        "after-enter": _vm.afterEnter,
        "after-leave": _vm.afterLeave
      }
    },
    [
      _vm.is_visible
        ? _c(
            "div",
            {
              key: "modal",
              class: _vm.className,
              attrs: { tabindex: "-1", role: "dialog" },
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.clickMask($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  ref: "dialog",
                  staticClass: "modal-dialog",
                  class: _vm.modalClass,
                  attrs: { role: "document" }
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm.title
                      ? _c(
                          "div",
                          { staticClass: "modal-header" },
                          [
                            _c("h4", { staticClass: "modal-title" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.title) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "base-button",
                              {
                                staticClass: "close",
                                attrs: {
                                  theme: "",
                                  type: "button",
                                  "aria-label": "Close"
                                },
                                on: { click: _vm.cancel }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "closeBtn",
                                    attrs: { "aria-hidden": "true" }
                                  },
                                  [_vm._v("×")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        { staticClass: "modal-body-inner" },
                        [_vm._t("default")],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-footer" },
                      [
                        _vm._t("footer", [
                          _vm.showCancelBtn
                            ? _c(
                                "button",
                                {
                                  class: _vm.cancelClass,
                                  attrs: { type: "button" },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.cancelLabel) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              class: _vm.okClass,
                              attrs: { type: "button" },
                              on: { click: _vm.ok }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.okLabel) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isLoading
                    ? _c("div", {
                        staticClass:
                          "loader vertical-align-middle loader-circle"
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.is_visible
        ? _c("div", { key: "backdrop", staticClass: "modal-backdrop in" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-03261a2e", { render: render, staticRenderFns: staticRenderFns })
  }
}