<doc>
	Program profiles Modal Vue.js component
</doc>

<template>
	<BaseModal
		ref="modal"
		v-model="showChartModal"
		title="Rate History"
		id="rateChartModal"
		:isLoading="false"
		@ok="hideModal"
		:showCancelBtn="false"
		okLabel="Close">
		<div v-if="chart">
			<GChart
					type="LineChart"
					:data="chart.data"
					:options="chart.options"
					class="bbChart"
			/>
			<ul class="list-inline chart-key">
				<li class="list-inline-item"><span class="key-item" v-bind:style="bgc[0]"></span>{{chart.profile_title}}</li>
				<li class="list-inline-item"><span class="key-item" v-bind:style="bgc[1]"></span>10 Year Treasury Yield</li>
			</ul>
		</div>
		<div v-else>Loading...</div>
	</BaseModal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BaseModal from "../BaseModal"
import { GChart } from 'vue-google-charts'

export default {
	name: "RateChartModal",
	components: {
		BaseModal,
        GChart
	},
	data(){
	    return {
	        "bgc":[
				{
				    'background-color':"#4EB3E5"
				},
				{
                    'background-color':"#78E09C"
				}

			]
		}
	},
	updated: function(){},
	computed: Object.assign({}, mapState([ "chartInfo", "showChartModal", "chart"]), {
		showChartModal: {
			get() {
				return this.$store.state.showChartModal
			},
			set(value) {
				this.toggleChartModal(value)
			}
		}
	}),
	methods: Object.assign({}, mapActions(["toggleChartModal"]), {
		hideModal() {
			this.toggleChartModal(false)
		}
	})
}
</script>

<style lang="scss">
	.bbChart{
		height:350px;
		width:100%;
	}
	.chart-key{
		margin-top:10px;
		span.key-item{
			height: 5px;
			width: 20px;
			border-radius: 5px;
			display: inline-block;
			margin-right: 10px;
			margin-bottom: 2px;
		}
	}
</style>
