<doc>
	Program profiles Modal Vue.js component
</doc>

<template>
	<BaseModal
		ref="modal"
		v-model="showModal"
		title="Rate Details"
		id="newProfilesModal"
		:isLoading="false"
		@ok="hideModal"
		:showCancelBtn="false"
		okLabel="Close">
    <div class="pdfLink">
      <a v-if="pdfResult && pdfResult.file" target="_blank" :href="pdfResult.file">Download</a>
      <span v-else-if="pdfLoading">loading...</span>
      <a v-else @click="printSummary">Print PDF</a>
    </div>
    <div style="clear:both">

    </div>
		<Table class="rate-modal-details" v-if="currentRate">
			<tr>
				<td class="details-label">Loan Product</td>
				<td>{{currentRate.rateInfo.productName}}</td>
			</tr>
			<tr>
				<td class="details-label">Loan Term</td>
				<td>{{currentRate.rateInfo.amortizationTerm}} years</td>
			</tr>
			<tr>
				<td class="details-label">Lock Period</td>
				<td>{{currentRate.rateInfo.lockPeriod}} days</td>
			</tr>
			<tr>
				<td class="details-label">Interest Rate</td>
				<td>{{currentRate.rateInfo.rate}}%</td>
			</tr>
			<tr>
				<td class="details-label">APR</td>
				<td>{{currentRate.rateInfo.apr}}%</td>
			</tr>
			<tr>
				<td class="details-label">Closing cost</td>
				<td>${{currentRate.rateInfo.closingCost}}</td>
			</tr>
			<tr>
				<td class="details-label">Discount</td>
				<td>${{currentRate.rateInfo.discount}}</td>
			</tr>
			<tr>
				<td class="details-label">Investor</td>
				<td>{{currentRate.rateInfo.investor}}</td>
			</tr>
			<tr>
				<td class="details-label">Actual Price</td>
				<td>{{currentRate.rateInfo.price}}</td>
			</tr>
			<tr>
				<td class="details-label">Target Price</td>
				<td>{{currentRate.fullcard.log.target_price}}</td>
			</tr>
			<tr>
				<td class="details-label">Selected Price</td>
				<td>{{currentRate.fullcard.log.net_price}}</td>
			</tr>
			<tr>
				<td class="details-label">Sample List Price</td>
				<td>${{currentRate.fullcard.list_price}}</td>
			</tr>
			<tr v-if="currentRate.fullcard.log.full_request">
				<td class="details-label">Loan Amount</td>
				<td>${{currentRate.fullcard.log.full_request.loanInformation.baseLoanAmount}}</td>
			</tr>
			<tr v-if="currentRate.fullcard.log.full_request">
				<td class="details-label">Loan Purpose</td>
				<td>{{currentRate.fullcard.log.full_request.loanInformation.loanPurpose}}</td>
			</tr>
			<tr>
				<td class="details-label">OB Search ID</td>
				<td>{{currentRate.fullcard.log.full_response.searchId}}</td>
			</tr>
			<tr>
				<td class="details-label">Updated</td>
				<td>{{getTime(currentRate.fullcard.log.timestamp)}}</td>
			</tr>
			<tr v-if="currentRate.fullcard.log.full_request">
				<td class="details-label">Property State</td>
				<td>{{currentRate.fullcard.log.full_request.propertyInformation.state}}</td>
			</tr>
			<tr v-if="currentRate.fullcard.log.full_request">
				<td class="details-label">Property County</td>
				<td>{{currentRate.fullcard.log.full_request.propertyInformation.county}}</td>
			</tr>
			<tr v-if="currentRate.fullcard.log.full_request">
				<td class="details-label">Property Type</td>
				<td>{{currentRate.fullcard.log.full_request.propertyInformation.propertyType}}</td>
			</tr>
			<tr v-if="currentRate.fullcard.log.full_request">
				<td class="details-label">Credit Score</td>
				<td>{{currentRate.fullcard.log.full_request.representativeFICO}}</td>
			</tr>
		</Table>
		<div class="response-json-wrapper" v-html="response_json"></div>
	</BaseModal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BaseModal from "../BaseModal"

export default {
	name: "RatesModal",
	components: {
		BaseModal
	},
	data(){
	    return {
        response_json: null,
        pdfResult: null,
        pdfLoading: false
      }
	},
	updated: function(){},
	computed: Object.assign({}, mapState([ "currentRate", "showModal"]), {
		showModal: {
			get() {
				return this.$store.state.showModal
			},
			set(value) {
				this.toggleRateModal(value)
			}
		}
	}),
	methods: Object.assign({}, mapActions(["toggleRateModal"]), {
		hideModal() {
			this.toggleRateModal(false)
      this.pdfResult = null
      this.pdfLoading = false
		},
		getTime(timestamp){
      let date=new Date(timestamp*1000);
      return date.toLocaleString();
		},
    async printSummary(e){
		  e.preventDefault()
      this.pdfLoading = true
      this.pdfResult=await $.get("/api/v1/loan-summary-pdf?quote_id="+this.currentRate.fullcard.rateflow_log_id+"&card_hash="+this.currentRate.rateInfo.hash)
      this.pdfLoading = false
    }
	})
}
</script>

<style lang="scss">
#newProfilesModal {
	.rate-modal-details {
    margin-top: 25px;
	}
	.response-json-wrapper{
		padding:10px;
	}
	.details-label{
		font-weight:700;
	}
  .pdfLink{
    float:right;
    a{
      cursor: pointer;
    }
  }
}
</style>
