'use strict';

let isFistTime = 1;

// Init deep linking
const init = function(bbNetwork){
	let base_path = $('.deep_link_base_path').data('base_path');
    $.address.autoUpdate(false)
    	.state(base_path)
    	.init(function(event) {
            let pathName = '',
                    pathParts = window.location.pathname.split('/');
            pathName = pathParts[2];
            $.address.value('/');
            if(window.location.search.length > 0){
                $.address.queryString(window.location.search.replace('?',''));
            }
            $.address.path(pathName);

            getResults(bbNetwork);

            bind(bbNetwork);
                $('#network-search').removeData('current-params');
                const currentParams=getParamArray($.address);
                if(currentParams.length){
                    $('#network-search').data('current-params',currentParams);                
                }
                paramToTag(bbNetwork);
            }).change(function(event) {
                if(isFistTime){
                        isFistTime = 0;
                        return;
                }
                getResults(bbNetwork);
                $('#network-search').removeData('current-params');
                const currentParams=getParamArray($.address);
                if(currentParams.length){
                    $('#network-search').data('current-params',currentParams);                
                }
    	}).externalChange(function(event){}).internalChange(function(event){});
};

const getResults = function (bbNetwork) {
	console.log('getResults')
	$('#results-tabs .results-tabs__tab.results-tabs__tab--active').removeClass('results-tabs__tab--active');
	bbNetwork.get();
}

const bind = function(){
	// Tabs
	$('#results-tabs .results-tabs__tab').click(function () {
		const $this = $(this),
			  type = $this.data('bb_type');

		$.address.parameter('type', type);
		$.address.update();
		$this.parent().find('.results-tabs__tab--active').removeClass('results-tabs__tab--active');
	})

	$('body')
		// Common filters
		.on('click', '.bb_filter_btn', function(e) {
			e.preventDefault();
			filterBtnAction($(this));
    });
}

// Generates tags from the URL params
var paramToTag = function(bbNetwork) {
  let queryString = location.search.slice(1),
    re = /([^&=]+)=([^&]*)/g,
    m;
  while (m = re.exec(queryString)) {
    let key = decodeURIComponent(m[1]);

    if (key != 'type' && key != 'name') {
      let val = decodeURIComponent(m[2]),
        qry = '';

      if (key === 'aid') {
        qry += '?type=agency';
      } else if (key === 'mls_sc') {
        qry += '?type=mls';
      } else if (key === 'agencyid') {
        qry += '?type=agency';
      }

      qry += '&' + key + '=' + val;

      bbNetwork.getEntry(qry, function(data) {
        if (!$('.bb-tag-filter-' + key).length) {
          let label;
          if (key == 'aid') {
            label = data.agency_results.agencies[0].name;
          }
          if (key == 'agencyid') {
            label = data.agency_results.agencies[0].name;
          }
          if (key == 'mls_sc') {
            label = data.mls_results.mls_entries[0].mls_name;
          }
          addFilterTag(key, val, label);
        }
      });
    }
  }
};


const filterBtnAction = function($ele) {
  const key = $ele.data('bb_filter_key'),
    clearName = $ele.data('bb_clear_name'),
    val = $ele.data('bb_filter_value'),
    label = $ele.data('bb_label'),
    action = ($ele.data('bb_filter_action')) ? $ele.data('bb_filter_action') : 'add';

  if (clearName) {
    $.address.parameter('name', null);
  }

  if (key == 'aid') {
    if (action == 'add') {
      $.address.parameter('type', 'realtor');
      $.address.parameter('aid', $ele.data('aid'));
    } else {
      $.address.parameter('aid', null);
    }
  }
  toggleFilters(key, val, action, label);
}

const toggleFilters = function(key, val, action, label) {
  let needsUpdate = 0,
    isAdded = 0;

  if (action === 'add' && $.address.parameter(key) !== val + '') {
    $.address.parameter(key, val);
    if (!$('.bb-tag-filter-' + key).length) {
      addFilterTag(key, val, label);
      isAdded = 1;
    }
    needsUpdate = 1;
  } else if (action === 'remove') {
    $.address.parameter(key, null);
    needsUpdate = 1;
  }
  if (needsUpdate) {
    $.address.update();
    if (!isAdded) {
      removeFilterTags(val);
    }
  }
};

// Remove filter tag
var removeFilterTags = function(label){
	$('[data-bb_selector="' + label + '"]').remove();
}

// Add filter tag
var addFilterTag = function(key, tag, label) {
  const $ul = $('ul.filter-tags');

  // for tags without label
  label = (label) ? label : tag;

  let li = ['<li data-bb_selector="' + tag + '"><p>' + label + '</p><a', ' data-bb_filter_key="' + key + '" data-bb_filter_value="' + tag + '" data-bb_filter_action="remove" class="bb-tag-filter-' + key + ' bb_filter_btn close-btn"></a></li>'];

  $ul.append(li.join(' '));
};

var getParamArray = function(address){
    var paramNames = address.parameterNames(),
    	currentParams = [];
    for(var i=0; i<paramNames.length; i++){
        currentParams[i]={
            name:paramNames[i],
            value:address.parameter(paramNames[i])
        }
    }
    return currentParams;
};

module.exports = {
  init: init
};
