var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        drop: function($event) {
          $event.preventDefault()
          return _vm.addFile($event)
        },
        dragover: function($event) {
          $event.preventDefault()
          return _vm.dragFile($event)
        },
        dragleave: function($event) {
          $event.preventDefault()
          return _vm.dragFileLeave($event)
        }
      }
    },
    [
      _c("SmartList", {
        attrs: {
          toggleMemberPricing: _vm.toggleMemberPricing,
          searchQuery: _vm.searchQuery,
          searchQueries: _vm.searchQueries,
          filteredItems: _vm.filteredItems,
          addQuery: _vm.addQuery,
          removeQuery: _vm.removeQuery,
          resetQuery: _vm.resetQuery,
          toggleListView: _vm.toggleListView,
          addItemAction: _vm.toggleItemModal,
          items: _vm.items,
          gridView: _vm.gridView,
          listType: "member",
          vuexNamespace: "members"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "overlay", class: { hide: !_vm.fileHovering } },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("PeopleImportModal", {
        attrs: {
          hideModal: _vm.hideModal,
          "show-modal": _vm.showImportModal,
          "import-data": _vm.importData
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c("i", {
        staticClass: "fa fa-upload",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Drop File to Import")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-900bdcae", { render: render, staticRenderFns: staticRenderFns })
  }
}