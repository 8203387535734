var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Tabs",
        { attrs: { initialHash: _vm.initialHash } },
        [
          _c(
            "Tab",
            { attrs: { name: "Getting Started" } },
            [
              _c(
                "mq-layout",
                { attrs: { mq: ["sm", "md"] } },
                [
                  _c("ProgramCarousel", {
                    attrs: { toggleItemModal: _vm.toggleItemModal }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "mq-layout",
                { attrs: { mq: "lg" } },
                [
                  _c("ProgramJumbotrom", {
                    attrs: { toggleItemModal: _vm.toggleItemModal }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "My Programs" } },
            [
              _c("SmartList", {
                attrs: {
                  searchQuery: _vm.searchQuery,
                  searchQueries: _vm.searchQueries,
                  filteredItems: _vm.filteredItems,
                  addQuery: _vm.addQuery,
                  removeQuery: _vm.removeQuery,
                  resetQuery: _vm.resetQuery,
                  toggleListView: _vm.toggleListView,
                  addItemAction: _vm.toggleItemModal,
                  items: _vm.items,
                  onDragEnd: _vm.onEnd,
                  gridView: _vm.gridView,
                  listType: "program"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("ProgramModal"),
      _vm._v(" "),
      _c("ProgramDetailsModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-068def6e", { render: render, staticRenderFns: staticRenderFns })
  }
}