var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardsWrapper" }, [
    _c("div", { staticClass: "form-group" }, [
      _vm.integration && _vm.integration.app_settings
        ? _c("div", [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.integration.app_settings.install_script,
                  expression: "integration.app_settings.install_script"
                }
              ],
              staticClass: "form-control install-script",
              attrs: { readonly: "" },
              domProps: { value: _vm.integration.app_settings.install_script },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.integration.app_settings,
                    "install_script",
                    $event.target.value
                  )
                }
              }
            })
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", [
      _c("button", { on: { click: _vm.confirmDelete } }, [
        !_vm.deleting
          ? _c("span", [_vm._v("Delete")])
          : _c("span", [_vm._v("Cancel")])
      ]),
      _vm._v(" "),
      _vm.deleting
        ? _c("span", [
            _c("span", [_vm._v('type "delete" to confirm')]),
            _c("input", {
              attrs: { type: "text" },
              on: { keyup: _vm.validateConfirmation }
            }),
            _c("span", [
              _vm.ready
                ? _c("button", { on: { click: _vm.sendDelete } }, [
                    _vm._v("Confirm")
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36faabd2", { render: render, staticRenderFns: staticRenderFns })
  }
}