var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.threads
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                class: ["col-sm-4", { "d-none": !_vm.showList && _vm.isMobile }]
              },
              [
                _c("h5", { staticClass: "mt-3" }, [_vm._v("Threads")]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group mb-3" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedBrand,
                          expression: "selectedBrand"
                        }
                      ],
                      staticClass: "form-select",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedBrand = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.filterThreads
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("All Brands")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.brandList, function(brand) {
                        return _c(
                          "option",
                          { key: brand.id, domProps: { value: brand.id } },
                          [_vm._v(_vm._s(brand.name))]
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "settings-btn",
                      on: { click: _vm.toggleSettingsModal }
                    },
                    [_c("v-icon", { attrs: { name: "cog" } })],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group threads-list" },
                  _vm._l(_vm.filteredThreads, function(thread) {
                    return _c(
                      "li",
                      {
                        key: thread._id,
                        staticClass: "list-group-item list-group-item-action",
                        class: {
                          active:
                            _vm.selectedThread &&
                            _vm.selectedThread._id === thread._id
                        },
                        on: {
                          click: function($event) {
                            _vm.selectThread(thread)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.threadName(thread)) +
                            " - " +
                            _vm._s(_vm.getLastMessageTimestamp(thread)) +
                            "\n          "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "status-indicator",
                            class: _vm.getThreadStatus(thread)
                          },
                          [
                            _c("v-icon", {
                              attrs: {
                                name:
                                  _vm.getThreadStatus(thread) === "takeover"
                                    ? "user-circle"
                                    : _vm.getThreadStatus(thread) ===
                                      "converted"
                                      ? "check-circle"
                                      : "circle"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  })
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: ["col-sm-8", { "d-none": _vm.showList && _vm.isMobile }]
              },
              [
                _vm.selectedThread
                  ? _c(
                      "div",
                      [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "chat-back-btn",
                              on: { click: _vm.clickBack }
                            },
                            [_c("v-icon", { attrs: { name: "arrow-left" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "options-dropdown btn-group" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "dropdown-toggle thread-options",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false"
                                  }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { name: "ellipsis-v" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right"
                                },
                                [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: { click: _vm.deleteThread }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.selectedThread.status === "takeover" ||
                                  _vm.selectedThread.status === "active"
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: _vm.takeOverThreadToggle
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedThread.status ==
                                                "takeover"
                                                  ? "Release"
                                                  : "Takeover"
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("li", {
                                    staticClass: "divider",
                                    attrs: { role: "separator" }
                                  }),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      { staticClass: "disabled" },
                                      [
                                        _vm._v("Calendar "),
                                        _c("v-icon", {
                                          class: [
                                            "pull-right",
                                            "calendar-status-icon",
                                            _vm.getCalendarIcons.integration
                                              .color
                                          ],
                                          attrs: {
                                            name:
                                              _vm.getCalendarIcons.integration
                                                .icon
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      { staticClass: "disabled" },
                                      [
                                        _vm._v("Webhook "),
                                        _c("v-icon", {
                                          class: [
                                            "pull-right",
                                            "calendar-status-icon",
                                            _vm.getCalendarIcons.webhook.color
                                          ],
                                          attrs: {
                                            name:
                                              _vm.getCalendarIcons.webhook.icon
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { clear: "both" } })
                        ]),
                        _vm._v(" "),
                        _c("Chat", {
                          attrs: {
                            messages: _vm.selectedThread.messages,
                            events: _vm.selectedThread.events,
                            "send-message": _vm.sendMessage
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [
                      _c("p", [_vm._v("Select a thread to view messages.")])
                    ])
              ]
            )
          ])
        : _c("div", [
            _vm.loading
              ? _c("div", [_c("p", [_vm._v("Loading...")])])
              : _c("div", [_c("p", [_vm._v("No threads found.")])])
          ]),
      _vm._v(" "),
      _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            title: "Chat Settings",
            id: "settings-modal",
            showCancelBtn: false,
            isLoading: _vm.modalIsLoading,
            okLabel: "Close",
            closeWhenOK: true
          },
          model: {
            value: _vm.showSettingsModal,
            callback: function($$v) {
              _vm.showSettingsModal = $$v
            },
            expression: "showSettingsModal"
          }
        },
        [
          _c(
            "Tabs",
            [
              _c("Tab", { attrs: { name: "Calendly" } }, [
                _c("div", [
                  _vm.calendlyIntegrations
                    ? _c(
                        "div",
                        { attrs: { id: "accordion" } },
                        _vm._l(_vm.calendlyIntegrations, function(integration) {
                          return _c(
                            "div",
                            { key: integration._id, staticClass: "panel" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "panel-heading",
                                  attrs: { id: "heading" + integration._id }
                                },
                                [
                                  _c("h5", { staticClass: "mb-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "panel-btn",
                                        attrs: {
                                          "data-toggle": "collapse",
                                          "data-target":
                                            "#collapse" + integration._id,
                                          "aria-expanded": "true",
                                          "aria-controls":
                                            "collapse" + integration._id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(integration.lo.name) +
                                            " - " +
                                            _vm._s(integration.lo.bank) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "collapse",
                                  attrs: {
                                    id: "collapse" + integration._id,
                                    "aria-labelledby":
                                      "heading" + integration._id,
                                    "data-parent": "#accordion"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "panel-body" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-10" }, [
                                        _c("ul", [
                                          _c("li", [
                                            _c("strong", [_vm._v("LO:")]),
                                            _vm._v(
                                              " " + _vm._s(integration.lo.name)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("li", [
                                            _c("strong", [_vm._v("Email:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(integration.user.email)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("li", [
                                            _c("strong", [_vm._v("Timezone:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  integration.user.timezone
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("li", [
                                            _c("strong", [
                                              _vm._v("Scheduling URL:")
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    integration.user
                                                      .scheduling_url,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    integration.user
                                                      .scheduling_url
                                                  )
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            [
                                              _c("strong", [
                                                _vm._v("Webhook:")
                                              ]),
                                              _vm._v(" "),
                                              _c("v-icon", {
                                                attrs: {
                                                  name: integration.webhook_uri
                                                    ? "check-circle"
                                                    : "times-circle"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-sm-2" })
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        })
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("Tab", { attrs: { name: "Senders" } }, [
                _c("div", [
                  _vm.threadSenders
                    ? _c(
                        "div",
                        { attrs: { id: "accordion" } },
                        _vm._l(_vm.threadSenders, function(sender) {
                          return _c(
                            "div",
                            { key: sender._id, staticClass: "panel" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "panel-heading",
                                  attrs: { id: "heading-" + sender._id }
                                },
                                [
                                  _c("h5", { staticClass: "mb-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "panel-btn",
                                        attrs: {
                                          "data-toggle": "collapse",
                                          "data-target":
                                            "#collapse-" + sender._id,
                                          "aria-expanded": "true",
                                          "aria-controls":
                                            "collapse-" + sender._id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              sender.brand_name
                                                ? sender.brand_name
                                                : "System Default"
                                            ) +
                                            " - " +
                                            _vm._s(sender.provider) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "collapse",
                                  attrs: {
                                    id: "collapse-" + sender._id,
                                    "aria-labelledby": "heading-" + sender._id,
                                    "data-parent": "#accordion"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "panel-body" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-10" }, [
                                        _c(
                                          "ul",
                                          [
                                            _c("li", [
                                              _c("strong", [
                                                _vm._v("Api Key:")
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    sender.api_key
                                                      ? _vm.truncateString(
                                                          sender.api_key,
                                                          10
                                                        )
                                                      : ""
                                                  )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("li", [
                                              _c("strong", [
                                                _vm._v("External ID:")
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    sender.external_id
                                                      ? sender.external_id
                                                      : ""
                                                  )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("li", [
                                              _c("strong", [
                                                _vm._v("Phone Numbers:")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _vm._l(
                                              sender.outgoing_phone_numbers,
                                              function(phone, index) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "phone-number-list-item"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class:
                                                          index == 0
                                                            ? "default"
                                                            : ""
                                                      },
                                                      [_vm._v(_vm._s(phone))]
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-sm-2" })
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        })
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52380718", { render: render, staticRenderFns: staticRenderFns })
  }
}