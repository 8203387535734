var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c(
      "ul",
      { staticClass: "tabs-component-tabs", attrs: { role: "tablist" } },
      _vm._l(_vm.tabs, function(tab, i) {
        return _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: tab.isVisible,
                expression: "tab.isVisible"
              }
            ],
            key: i,
            staticClass: "tabs-component-tab",
            class: { "is-active": tab.isActive, "is-disabled": tab.isDisabled },
            attrs: { role: "presentation" }
          },
          [
            _c("a", {
              staticClass: "tabs-component-tab-a",
              attrs: {
                "aria-controls": tab.hash,
                "aria-selected": tab.isActive,
                href: tab.hash,
                role: "tab"
              },
              domProps: { innerHTML: _vm._s(tab.header) },
              on: {
                click: function($event) {
                  _vm.selectTab(tab.hash, $event)
                }
              }
            })
          ]
        )
      })
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tabs-component-panels" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-54fb439e", { render: render, staticRenderFns: staticRenderFns })
  }
}