var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("div", { staticClass: "people-details-img" }, [
      _c(
        "div",
        { staticClass: "people-thumb-wrappar" },
        [
          _c("Thumb", {
            staticClass: "item-image mx-auto",
            attrs: { fullRounded: "", src: _vm.item.picture, alt: _vm.alt }
          }),
          _vm._v(" "),
          _c("icon", { class: _vm.statusClassName, attrs: { name: "circle" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "people-overlay" },
        [
          _c("SmartListItemOptions", {
            attrs: {
              hasTogglePricing: "",
              pricing_status: _vm.item.pricing_status,
              toggleMemberPricing: _vm.toggleMemberPricing,
              itemIndex: _vm.itemIndex,
              itemId: _vm.item.id || _vm.item.nid,
              onDragEnd: _vm.onDragEnd,
              isLast: _vm.isLast,
              gridView: _vm.gridView,
              showEditItemModal: _vm.showEditItemModal,
              removeItem: _vm.removeItem,
              hideOptions: ["obProfiles", "exportMembers"]
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "people-details" }, [
      _c("div", { staticClass: "people-details-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.item.first_name) +
            " " +
            _vm._s(_vm.item.last_name) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "people-details-meta" },
        [
          _c("span", { staticClass: "meta-title" }, [
            _vm._v(_vm._s(_vm.item.position))
          ]),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { target: "_blank", href: "mailto:" + _vm.item.email } },
            [_vm._v(_vm._s(_vm.item.email))]
          ),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                color: "#abc0d3",
                "font-size": "12px",
                "margin-top": "5px"
              }
            },
            [_vm._v("ID: " + _vm._s(_vm.item.id || _vm.item.nid))]
          ),
          _vm._v(" "),
          _vm.item.brand_admin
            ? _c("div", { staticStyle: { "margin-top": "5px" } }, [
                _c("span", { staticClass: "badge" }, [_vm._v("admin")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("Thumb", {
            staticClass: "item-logo",
            attrs: { noBorder: "", src: _vm.item.logo, alt: _vm.altLogo }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-47c25e40", { render: render, staticRenderFns: staticRenderFns })
  }
}