import Vue from "vue"
import { ChatAdmin } from "./pages"

export default function() {
  if (!document.getElementById("chat-admin-app")) {
    return
  }

  // Vuejs setup
  new Vue({
    el: "#chat-admin-app",
    components: {
      ChatAdmin
    }
  })
}
