const objectFitImages = require('object-fit-images');

$(document).ready(() => {
  const step1 = $('#step-1');
  const step2 = $('#step-2');
  const step3 = $('#step-3');
  const submitButton = $('#password-form-submit-button');

  //console.log('SESSION_ID', window.SESSION_ID);

  step1.on('click', '[data-action="confirm-data"]', (e) => {
    e.preventDefault();

    step2.removeClass('hide');
    step1.addClass('hide');
  });

  step1.on('click', '[data-action="incorrect-data"]', (e) => {
    e.preventDefault()
    step1.find('.signup-flow__data').addClass('hide');
    step1.find('.signup-flow__info').removeClass('hide');
  });

  const passwordForm = step2.find('form');
  const pictureInput = passwordForm.find('.form__photo-upload input');
  const pictureEl = passwordForm.find('.form__photo-upload img');
  const loader = passwordForm.find('.form__photo-upload .loader');
  const text = passwordForm.find('.form__photo-upload .form__photo-upload-text');

  passwordForm.on('click', '.form__photo-upload a', (e) => {
    // e.preventDefault();
    pictureInput[0].click();
  });

  pictureInput.on('change', (e) => {
    const file = (e.target.files && e.target.files[0]) ? e.target.files[0] : null;

    if (file) {
      const data = new FormData();
      const reader = new FileReader();

      data.append('files[img]', e.target.files[0]);
      data.append('bb_sid', window.SESSION_ID);

      reader.onload = (e) => {
        pictureEl.attr('src', e.target.result);
        objectFitImages(pictureEl);
      };

      reader.readAsDataURL(file);

      submitButton.attr('disabled', true);
      loader.removeClass('hide');
      text.html('Uploading');

      fetch('/bb-file-upload.json?bb_sid='+window.SESSION_ID, {
        method: 'POST',
        body: data,
        credentials: 'same-origin'
      }).then(res => {
        return res.json();
      }).then(data => {
        if(data.status=="error"){
            console.log(data);
            pictureEl.attr('src', null);
            BB.Toastr.error(data.message.charAt(0).toUpperCase() + data.message.slice(1))
            loader.addClass('hide');
            text.html('+ Add photo');
        }
        else{
            passwordForm[0].picture_fid.value = data.message.fid;
            submitButton.attr('disabled', false);
            loader.addClass('hide');
            text.html('Change photo');
        }
      }).catch(err => {
        console.error(err);
        loader.addClass('hide');
        text.html('+ Add photo');
      });
    }
  });

  passwordForm.on('submit', (e) => {
    e.preventDefault();
    $('#password-form-submit-button').prop("disabled",true);
    const loader = step3.find('.signup-flow__loading');
    const successMessage = step3.find('.signup-flow__success');
    const defaultError = step3.find('[data-error="default"]');
    const accountExistError = step3.find('[data-error="account-exist"]');
    const data = new FormData();

    data.append('invite_hash', passwordForm[0].hash.value);
    data.append('bb_sid', window.SESSION_ID);
    data.append('picture_fid', passwordForm[0].picture_fid.value);
    data.append('email', passwordForm[0].email.value);
    data.append('phone', passwordForm[0].phone.value);
    data.append('password', passwordForm[0].password.value);

    step2.addClass('hide');
    step3.removeClass('hide');

    loader.removeClass('hide');
    successMessage.addClass('hide');
    defaultError.addClass('hide');
    accountExistError.addClass('hide');

    fetch('/create-realtor.json', {
      method: 'POST',
      body: data,
      credentials: 'same-origin'
    }).then(res => {
      return res.text();
    }).then(dataText => {
      return dataText.length ? JSON.parse(dataText) : {}
    }).then(data => {
      if (data.status && data.status === 'error') {
        return Promise.reject((data.message) ? data.message : 'Error');
      }

      loader.addClass('hide');
      successMessage.removeClass('hide');
      $('#password-form-submit-button').prop("disabled",false);
      $('.uname').html(data.user.name);
      if(data.user.name){
        $('.uname').html(data.user.name);
        $('.signup-flow__success a').attr('href','/user/login?user='+data.user.name);
      }
    }).catch(err => {
      console.error(err);

      loader.addClass('hide');
      $('#password-form-submit-button').prop("disabled",false);

      if (err === 'user already exists') {
        accountExistError.removeClass('hide');
        return;
      }

      defaultError.removeClass('hide');
    });
  });
  $('.msaCheckbox').click(function(e){
      if($(this).is(':checked')){
          $('[data-action="confirm-data"]').prop("disabled", false);
      }
      else{
          $('[data-action="confirm-data"]').prop("disabled", true);          
      }
  });
});
