<doc>
  Custom dropdown Vue.js component
  TODO: there is 2 dropdown component, we should unify them
</doc>

<template>
  <div ref="dropDown" class="drop-down">
    <label :for="name" :class="labelClass">
      {{ label }}
    </label>
    <input
      ref="input"
      :id="name"
      v-on:focus="onFocusLocal"
      v-on:blur="onBlur"
      :aria-describedby="describedby"
      :placeholder="placeholder"
      :class="inputClassName"
      v-bind:value="options[value]"
      type="text"
      readonly
    />
    <ul v-show="visible">
      <li
        v-for="(option, key) in options"
        v-on:click="
          $event => {
            change($event, key)
          }
        "
        :key="key"
        :class="value === key ? 'selected' : ''"
      >
        {{ option }}
      </li>
    </ul>
    <icon class="caret" name="caret-down" v-on:click="toggle"></icon>

    <div
      v-show="visible"
      class="dropdown-overlay"
      v-on:click="toggle($event)"
    />
  </div>
</template>

<script>
import { props, methods, mounted, updated } from "./inputCommons"
export default {
  name: "DropDown",
  data() {
    return {
      visible: false
    }
  },
  computed: {
    labelClass: function() {
      let className = `input-label ${this.labelClassName}`
      if (this.hideLabel) {
        className += " sr-only"
      }
      return className
    },
    inputClassName: function() {
      let className = `form-control ${this.inputClass} ${
        this.readonly ? "is-readonly" : ""
      }`
      if (this.size) {
        className += ` form-control-${this.size}`
      }
      return className
    }
  },
  props: Object.assign({}, props, {
    options: { type: Object, required: true },
    value: { type: String }
  }),
  methods: Object.assign({}, methods, {
    onFocusLocal: function() {
      this.onFocus()
      this.toggle()
    },
    toggle: function() {
      if (!this.readonly) {
        this.visible = !this.visible
      }
    },
    change: function($event, key) {
      const val = $event.target.innerText
      this.$emit("dropDownChange", key, $event, this.name)
      this.valuCheck(val)
      this.toggle()
    }
  }),
  mounted: mounted,
  updated: updated
}
</script>

<style lang="scss" src="./DropDownStyle.scss" scoped></style>
