var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("LoaderPlaceholder", {
            staticClass: "loader",
            attrs: { type: "circle" }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "rt-admin-wrapper", attrs: { id: "rate_table_admin" } },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-circle pull-right",
              class: { cancel: _vm.addingNew },
              attrs: { type: "button" },
              on: { click: _vm.addNew }
            },
            [_c("div", { staticClass: "btn__inner" }, [_vm._v("+")])]
          ),
          _vm._v(" "),
          _vm.addingNew
            ? _c("div", { attrs: { id: "addNewForm" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.configName,
                      expression: "configName"
                    }
                  ],
                  attrs: { type: "text", placeholder: "name" },
                  domProps: { value: _vm.configName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.configName = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "brand-selector" } }, [
                  _vm._v("Brand:")
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.brandId,
                        expression: "brandId"
                      }
                    ],
                    staticClass: "selectBrand",
                    attrs: { id: "brand-selector" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.brandId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v("Please select one")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.availableBrands, function(brand) {
                      return _c(
                        "option",
                        { key: brand.id, domProps: { value: brand.id } },
                        [_vm._v("\n        " + _vm._s(brand.name) + "\n      ")]
                      )
                    })
                  ],
                  2
                )
              ])
            : _c("div", [
                _vm.availableConfig.length > 0
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedConfigId,
                            expression: "selectedConfigId"
                          }
                        ],
                        staticClass: "selectConfig",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedConfigId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.changeSelectedConfig
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Please select one")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.availableConfig, function(select) {
                          return _c(
                            "option",
                            { key: select.id, domProps: { value: select.id } },
                            [
                              _vm._v(
                                "\n        " + _vm._s(select.name) + "\n      "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _c("div", [_c("p", [_vm._v("Please create a new config")])])
              ]),
          _vm._v(" "),
          _vm.config
            ? _c(
                "div",
                { staticClass: "editor-wrapper" },
                [
                  _vm.isLoadingConfig
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("LoaderPlaceholder", {
                            staticClass: "loader",
                            attrs: { type: "circle" }
                          })
                        ],
                        1
                      )
                    : _c("vjsoneditor", {
                        attrs: {
                          plus: true,
                          options: _vm.options,
                          height: _vm.height
                        },
                        on: { error: _vm.onError },
                        model: {
                          value: _vm.config,
                          callback: function($$v) {
                            _vm.config = $$v
                          },
                          expression: "config"
                        }
                      }),
                  _vm._v(" "),
                  _c("div", { staticClass: "rt-admin-wrapper__footer" }, [
                    _c("div", { staticClass: "setDefault" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isDefault,
                            expression: "isDefault"
                          }
                        ],
                        attrs: { type: "checkbox", id: "isDefault" },
                        domProps: {
                          checked: Array.isArray(_vm.isDefault)
                            ? _vm._i(_vm.isDefault, null) > -1
                            : _vm.isDefault
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.isDefault,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.isDefault = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isDefault = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isDefault = $$c
                              }
                            },
                            _vm.defaultChanged
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v("Default")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pull-right" }, [
                    _vm.changed && !_vm.addingNew
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-modern mr-2",
                            on: { click: _vm.saveConfig }
                          },
                          [_vm._v("\n        Save\n      ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.addingNew && _vm.configName && _vm.brandId
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-modern mr-2",
                            on: { click: _vm.addConfig }
                          },
                          [_vm._v("\n        Add\n      ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.config || _vm.addingNew
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-modern btn-flat",
                            on: { click: _vm.deleteConfig }
                          },
                          [_vm._v("\n        Delete\n      ")]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { attrs: { id: "prev-wrapper", role: "tooltip" } })
        ]
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a64a4c30", { render: render, staticRenderFns: staticRenderFns })
  }
}