<template>
  <div v-if="isLoading" class="text-center">
    <LoaderPlaceholder class="loader" type="circle" />
  </div>
  <div v-else>
    <div v-if="disabled" class="d-flex">
      <span class="label label-danger">Disabled</span>
    </div>
    <div class="d-flex">
      <div class="d-flex form-inline" v-if="!addingNew && !config">
        <div class="form-group">
          <label for="bank_id">Brand:</label>
          <select
            id="bank_id"
            class="form-control input-sm"
            v-model="bank_id"
            @change="changeSelectedBrand"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="brand in availableBrands"
              :value="brand.id"
              :key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
        </div>

        <div
          class="form-group ml-3"
          v-if="bank_id && availableRateflows && !selectedConfigId"
        >
          <label>Rateflow:</label>
          <select
            class="form-control input-sm"
            v-model="selectedConfigId"
            @change="changeSelectedConfig"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="rateflow in availableRateflows"
              :value="rateflow.id"
              :key="rateflow.id"
              >{{ rateflow.name }}</option
            >
          </select>
        </div>
      </div>
      <ConfigNameInput
        v-else
        v-model.trim="configName"
        :hasInUse="false"
        @input="configChanged"
      />

      <AddCancelBtn
        class="ml-auto"
        :onClick="addNew"
        :isCancel="addingNew || !!config"
      />
    </div>

    <div v-if="isLoadingConfig" class="text-center">
      <LoaderPlaceholder class="loader" type="circle" />
    </div>
    <div v-else-if="config">
      <div class="form-inline d-flex p-3">
        <div class="form-group">
          <label for="bank_id">Brand: </label>
          <select class="form-control input-sm" v-model="bank_id" id="bank_id">
            <option disabled value="">Please select one</option>
            <option
              v-for="brand in availableBrands"
              :value="brand.id"
              :key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
        </div>
        <div class="form-group settingsTplSelect">
          <label for="pricing_engine_tpl">Settings Template: </label>
          <select @change="addSettingsTpl" v-model="selectedEngineTpl" class="form-control input-sm" id="pricing_engine_tpl">
            <option disabled value="">Select settings tpl</option>
            <option
                v-for="engineTpl in engineTpls"
                :value="engineTpl.value"
                :key="engineTpl.value"
            >{{ engineTpl.label }}</option
            >
          </select>
        </div>
        <div class="form-group sync-required-btn" v-if="selectedEngineTpl==='optimal_blue' || selectedEngineTpl==='loan_sifter'">
          <button @click="openPEModal">Options</button>
        </div>
        <div class="checkbox ml-3 pt-1">
          <label
            ><input
              type="checkbox"
              v-model="isDefault"
              @change="configChanged"
            />
            Default</label
          >
        </div>
      </div>

      <vjsoneditor
        v-model="config"
        :options="options"
        :plus="true"
        :height="height"
        @error="onError"
      />

      <div class="d-flex pt-3">
        <div class="ml-auto">
          <BaseButton
            v-show="changed || (addingNew && bank_id)"
            class="mr-2"
            @click="saveConfig"
          >
            {{ addingNew ? "Add" : "Save" }}
          </BaseButton>

          <BaseButton
            v-show="config || addingNew"
            @click="deleteWorkflow"
            outline
          >
            Delete
          </BaseButton>
        </div>
      </div>
    </div>
    <BaseModal
        ref="modal"
        v-model="showPEModal"
        :title="peModalTitle"
        id="peModal"
        :isLoading="isLoading"
        @ok="hidePEModal"
        :showCancelBtn="false"
        okLabel="Close">
      <h5>
        Add New LO
      </h5>
      <p class="intro"></p>
      <div>
        <button @click="clearOBCache">Clear OB Cache</button>
      </div>
      <div class="lo-search-wrapper">
        <autocomplete
            placeholder="Search LO & Connect Pricing Engine"
            class="form-group material-input"
            input-class="form-control ob-lo-search"
            :resultsFormatter="acResultsFormatter"
            :resultsDisplay="acResultsDisplay"
            :source="obLOAutocomplete"
            @selected="selectOBLO"
        />
        <ul class="selected-ob-los">
          <li class="selected-ob-lo" v-for="(lo, index) in obLOs" :key="index">
            <div class="selected-ob-lo-label col-sm-5">
              {{lo.firstName}} {{lo.lastName}}
            </div>
            <div class="col-sm-6">
              <select
                  ref="obMemberSelect"
                  :id="'obMember' + index"
                  class="form-control input-sm"
                  v-model="selectedOBMembers[index]"
                  @change="changeSelectedOBMember($event, index)"
              >
                <option disabled value="">Please select one</option>
                <option
                    v-for="member in members"
                    :value="member.loid"
                    :key="member.loid"
                >
                  {{ member.name }}
                </option>
              </select>
            </div>
            <div class="col-sm-1">
              <span @click="removeOBLO(index)"><i class="selected-ob-lo-delete fas fa-trash"></i></span>
            </div>
            <div class="clearfix"></div>
          </li>
        </ul>
        <div v-if="obLOs && obLOs.length>0"><button @click="addOBLOs">Add</button></div>
        <div><button @click="syncAll">Sync All</button></div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { bbRateflow } from "../../bb-api"
import vjsoneditor from "v-jsoneditor"
import ConfigNameInput from "../ConfigNameInput"
import AddCancelBtn from "../AddCancelBtn"
import BaseModal from "../BaseModal/index.vue";
import Autocomplete from "vuejs-auto-complete";

let vm

const DEFAULT_CONFIG = {
  pricing_set_id: 0,
  settings: {
    api_config: {},
    api_mode: "",
    cache_ttl: 6,
    lo_map: {},
    disabled: false,
    target_price: 100
  },
  default: 0
}

export default {
  name: "RateflowManager",
  components: {Autocomplete, BaseModal, ConfigNameInput, AddCancelBtn, vjsoneditor },
  data() {
    return {
      height: "400px",
      isLoading: true,
      isDefault: false,
      isLoadingConfig: false,
      addingNew: false,
      config: null,
      bank_id: "",
      configName: "",
      availableRateflows: null,
      selectedConfigId: "",
      changed: false,
      options: {
        onChangeText(configJson) {
          vm.changed = true
        }
      },
      engineTpls: [
        {
          "value": "polly",
          "label": "Polly"
        },
        {
          "value": "mortech",
          "label": "Mortech"
        },
        {
          "value": "optimal_blue",
          "label": "Optimal Blue"
        },
        {
          "value": "loan_sifter",
          "label": "Loan Sifter"
        },
        {
          "value": "lender_price",
          "label": "Lender Price"
        },
      ],
      selectedEngineTpl: null,
      showPEModal: false,
      obLOs: [],
      members: null,
      selectedOBMembers: [],
    }
  },
  updated(){
  },
  computed:{
    peModalTitle(){
      return this.selectedEngineTpl ? `${this.engineTpls.find(item => item.value===this.selectedEngineTpl).label} Options` : "Options"
    },
    disabled() {
      return this.config && this.config.settings && (this.config.settings.disabled || this.config.settings.disable)
    }
  },
  methods: {
    hidePEModal(){
      this.showPEModal=false
      this.resetPEModal();
    },
    openPEModal(){
      this.showPEModal=true
      if(!this.members){
        this.getMembers(this.bank_id)
      }
      console.log(this.config.settings.lo_map)
      this.resetPEModal();
    },
    resetPEModal(){
      this.obLOs=[]
      this.selectedOBMembers=[]
    },
    configChanged() {
      this.changed = true
    },
    async getAvailableBrands() {
      let brands=await BB.brandManage.find({})
      // sort brands by name
      brands.sort((a, b) => a.name.localeCompare(b.name))
      this.availableBrands = brands
      this.isLoading = false
    },
    async changeSelectedBrand() {
      await this.getAvailableRateflows()
    },
    async getAvailableRateflows() {
      if (!this.bank_id) return
      let result = await bbRateflow.find({ bank_id: this.bank_id })
      this.availableRateflows = result
    },
    changeSelectedConfig() {
      this.getConfig(this.selectedConfigId)
      window.history.pushState(
        {},
        null,
        `?id=${this.selectedConfigId}${window.location.hash}`
      )
      this.getMembers(this.bank_id)
    },
    getAllConfig() {
      return bbRateflow.get({}, res => res)
    },
    async getConfig(id) {
      this.isLoadingConfig = true
      const rateflow = await bbRateflow.get({ id })
      this.config = rateflow
      this.isDefault = rateflow.default == 1

      this.configName = rateflow.name ? rateflow.name : "New Rateflow"
      this.bank_id = rateflow.bank_id
      this.isLoadingConfig = false
      this.changed = false
      if(rateflow.settings && rateflow.settings.api_mode){
        this.selectedEngineTpl=rateflow.settings.api_mode
      }
    },
    addConfig(payload, callBack) {
      // validate payload before saving
      if (!payload.name) return BB.Toastr.error("Please complete the name.")
      if (!payload.bank_id) return BB.Toastr.error("Please select brand.")

      bbRateflow.add(payload, function({ id }) {
        callBack(id)
      })
    },
    updateConfig(payload, callBack) {
      const action = this.addingNew ? "add" : "update"

      bbRateflow[action](payload, res => {
        callBack(res)
      })
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.getAllConfig()
    },
    async deleteConfig(id) {
      await bbRateflow.delete(id)
    },
    async syncRequiredFields(){
      await bbRateflow.syncRequiredFields(this.selectedConfigId)
      BB.Toastr.success("Synced required fields successfully")
    },
    saveConfig() {
      const saveCallback = this.saveCallback
      let payload = {
        bank_id: this.bank_id,
        name: this.configName,
        default: this.isDefault ? 1 : 0
      }

      if (this.selectedConfigId) {
        payload.id = this.selectedConfigId
      }

      if (!this.config.owner) {
        payload.owner = $("body").data("user_id")
        payload.loid = payload.owner
      }

      // merge config with payload
      payload = Object.assign({}, this.config, payload)

      this.changed = false
      this.updateConfig(payload, res => {
        if (this.addingNew && res.result.id) {
          this.config.id = res.result.id
        }
        this.changed = false
        this.addingNew = false
        saveCallback("Config saved successfully.")
      })
    },
    async addNew(e) {
      if (this.addingNew || this.config) {
        this.clearRateflow()
      } else {
        this.addingNew = true
        //DEFAULT_CONFIG
        this.config = JSON.parse(JSON.stringify(DEFAULT_CONFIG))
        this.changed = false
        this.configName = "New Rateflow"
      }
    },
    clearRateflow() {
      this.changed = false
      this.addingNew = false
      this.config = null
      this.bank_id = ""
      this.selectedConfigId = ""
      window.history.pushState({}, null, `/config-admin${window.location.hash}`)
    },
    onError() {
      console.log("error")
    },
    async deleteWorkflow() {
      if (confirm("Do you really want to delete this?")) {
        await bbRateflow.delete(this.selectedConfigId)
        this.clearRateflow()
      }
    },
    async addSettingsTpl(){
      if(!this.selectedEngineTpl){
        return null
      }
      let newConfig = JSON.parse(JSON.stringify(this.config))
      newConfig.settings=Object.assign(newConfig.settings,{
        "api_config":{},
        "api_mode": "",
        "cache_ttl": 6
      })
      switch (this.selectedEngineTpl) {
        case "polly":
          newConfig.settings=Object.assign(newConfig.settings,{
            "api_mode":"polly",
            "api_config":{
              "audience_id":"",
              "api_username":"BankingBridgeAPI",
              "api_password":"7niDDeym6QGbs09kR6XR",
              "client_id":"",
              "client_secret":"",
              "custom_values":[
                {
                  "name": "LO Name",
                  "value": ""
                }
              ]
            }
          });
          break;
        case "lender_price":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"lender_price",
            "api_config":{
              "user_id":"",
              "company_id":"",
              "api_key":"!AEx8FgClara.com"
            }
          })
          break;
        case "loan_sifter":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"loan_sifter",
            "api_config":{
              'user_id':'',
              'company_id':'',
              'client_id':'7a50b13a-57d6-484b-8fbf-f3a83c1a5eec',
              'client_secret':'nWy8Q~tZ_t6Zr0dQhglW5Yu4YXVseENs7_YyLcJs'
            }
          })
          break;
        case "ob":
        case "optimal_blue":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"optimal_blue",
            "api_config":{
              "originator_id":"",
              "biz_channel":""
            }
          })
          break;
        case "mortech":
          newConfig.settings=Object.assign(newConfig.settings,newConfig.settings={
            "api_mode":"mortech",
            "api_config":{
              "customer_id":"",
              "email":""
            }
          })
          break;
      }
      //this.config.updated = Math.round((+ new Date())/1000)
      this.config=newConfig
      this.configChanged()
      return null;
    },
    obLOAutocomplete(input) {
      //debugger;
      let brand_id = this.bank_id
      return "/api/v1/find-ob-lo?name=" + input + "&brand_id=" + brand_id
    },
    acResultsFormatter(data) {
      return data
    },
    acResultsDisplay(data) {
      return (
          `
          <div class='resultWrapper'>
            <div>
              <span class="name">` +
          data.firstName +
          ` ` +
          data.lastName +
          `</span>
            </div>
            <div class='details'>
              <span style='font-weight:400;color:#aaa;'>` +
          data.email +
          `</span>
            </div>
            <div class='details'>
              <span style='font-weight:400;color:#888;'>` +
          data.biz_channel.name +
          `</span>
            </div>
          </div>
      `
      )
    },
    selectOBLO(data) {
      //debugger;
      //this.currentEditedItem.first_name=data.selectedObject.firstName;
      this.obLOs.push(data.selectedObject)
      // see if there is a match between data.email and email in members
      let match = this.members.find(member => member.email === data.selectedObject.email)
      if(match)this.selectedOBMembers.push(match.loid)
      else this.selectedOBMembers.push(null)
    },
    removeOBLO(index) {
      this.obLOs.splice(index, 1)
      this.selectedOBMembers.splice(index, 1)
    },
    async syncAll(){
      let obLos=await $.get("/api/v1/find-ob-lo?brand_id="+this.bank_id);
      // console.log(this.members)
      obLos.forEach(obLo=>{
        // if already in obLos, skip
        if(this.obLOs.find(lo=>lo.email===obLo.email))return
        let match = this.members.find(member => member.email === obLo.email)
        if(match){
          // console.log("match for "+obLo.email+" in members")
          this.selectedOBMembers.push(match.loid)
          this.obLOs.push(obLo)
        }
      })
      BB.Toastr.success("Sync Finished")
    },
    async getMembers(brand_id) {
      if(!brand_id)return
      let result = null
      try {
        result = await BB.brandManage.getMembers(
            brand_id
        )
      } catch (e) {
        console.log("something went wrong getting available owners")
        return
      }

      console.log("before filter: "+result.members.length+" members")
      // filter out members that have an entry in this.config.settings.lo_map['loid']
      if(this.config.settings.lo_map){
        console.log("filtering out members that have an entry in this.config.settings.lo_map['loid']")
        let loids = Object.keys(this.config.settings.lo_map)
        //console.log("searching for loid: "+loids[0])
        //console.log("loid from members: "+result.members[183].loid+", loid from lo_map: "+loids[0]+", match: "+(!loids.includes(result.members[183].loid)));
        //console.log(result.members.find(member=>member.loid===loids[0]))
        result.members = result.members.filter(member => !loids.includes(member.loid.toString()))
      }
      console.log("after filter: "+result.members.length+" members")

      // sort members by last name
      result.members.sort((a, b) => {
        if (a.last_name < b.last_name) return -1
        if (a.last_name > b.last_name) return 1
        return 0
      })

      this.members = result.members
    },
    changeSelectedOBMember(event, index) {
      console.log('Selected OB Member:', this.selectedOBMembers[index]);
      console.log('Index:', index);
    },
    async addOBLOs(){
      if(!this.obLOs){
        return
      }
      if(this.obLOs.length===0){
        return
      }
      if(this.obLOs.length !== this.selectedOBMembers.length){
        return
      }
      let newConfig = JSON.parse(JSON.stringify(this.config))
      if(!newConfig.settings.lo_map){
        newConfig.settings.lo_map={}
      }
      this.selectedOBMembers.forEach((loid, index)=>{
        newConfig.settings.lo_map[loid]={
          biz_channel: (this.obLOs[index].biz_channel && this.obLOs[index].biz_channel.id ? this.obLOs[index].biz_channel.id : this.obLOs[index].biz_channel),
          originator_id: this.obLOs[index].index ? this.obLOs[index].index : this.obLOs[index].originator_id
        }
      })
      this.config=newConfig
      this.configChanged()
      this.hidePEModal()
      return null;
    },
    async clearOBCache(){
      await $.get("/api/v1/find-ob-lo?reset_cache=1")
    }
  },
  async mounted() {
    vm = this
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has("bank_id")) {
      this.bank_id = urlParams.get("bank_id")
      await this.getAvailableRateflows()
    }

    await this.getAvailableBrands()

    if (urlParams.has("id")) {
      this.selectedConfigId = urlParams.get("id")
      await this.getConfig(this.selectedConfigId)
      this.addingNew = false
    }
  }
}
</script>

<style lang="scss" src="./RateflowManager.scss" scoped>
</style>
