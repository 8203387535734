<doc>
  Add/edit Program Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    v-model="showPrintModal"
    :title="modalTitle"
    id="newPricingSetModal"
    @beforeEnter="beforeEnter"
    @afterEnter="afterEnter"
    @cancel="hidePrintModal"
    @clickMask="hidePrintModal"
    v-bind:force="false"
    okClass="hide"
    :isLoading="isLoading"
  >
    <div v-if="currentFlyerImage">
      <h5>Print your custom flyer: {{ currentFlyerImage.name }}</h5>
      <img
        class="mt-2 mb-3 print-image-prev"
        :src="currentFlyerImage.url"
        :alt="currentFlyerImage.name + ' image'"
      />
    </div>
    <h5 v-else>Print your custom flyer.</h5>

    <p class="intro">
      Select the Loan Officer to appear in the signature block of the flyer.
    </p>
    <form
      id="new-print-flyer-form"
      class="form"
      ref="newPrintFlyerForm"
      v-on:submit.prevent
    >
      <div v-if="!selectedLO">
        <autocomplete
          :resultsFormatter="acResultsFormatter"
          :resultsDisplay="acResultsDisplay"
          :source="loAutocomplete"
          @selected="selectLO"
          @input="acInput"
        />
      </div>
      <div v-else>
        <div class="resultWrapper">
          <span class="avatarWrapper">
            <img :src="selectedLO.picture" />
          </span>
          <span class="name">{{ selectedLO.name }}</span>
          <span class="btns">
            <button
              type="button"
              class="btn btn-modern printLO"
              v-on:click="printLO"
            >
              <span v-if="!isPrinting">Print</span>
              <span
                v-else
                class="loader loader-circle"
                data-type="default"
              ></span>
            </button>
            <i
              v-if="isBankAdmin"
              class="fas fa-times-circle clearLOBtn"
              v-on:click="clearLO"
            ></i>
          </span>
        </div>
        <div class="result" v-if="printResult">
          <i class="fas fa-file-pdf"></i>
          <span class="downloadLink">
            <a
              :href="printResult.url"
              :download="printResult.label"
              target="_blank"
              >{{ printResult.label }}</a
            >
          </span>
        </div>
      </div>
    </form>
  </BaseModal>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import BaseModal from "../BaseModal"
import Autocomplete from "vuejs-auto-complete"

const { mapState, mapActions } = createNamespacedHelpers("staticFlyers")

export default {
  name: "StaticFlyerPrintModal",
  components: { BaseModal, Autocomplete },
  data() {
    return {
      showPrintModal: false,
      selectedLO: null,
      isPrinting: false,
      printResult: null
    }
  },
  computed: Object.assign(
    {},
    mapState([
      "loid",
      "bank_id",
      "originalItems",
      "currentFlyerImage",
      "isEdit",
      "showStaticFlyerPrintModal",
      "loDetails",
      "isBankAdmin"
    ]),
    {
      modalTitle() {
        return `Print Flyer`
      },
      staticFlyer() {
        let staticFlyer = { name: "", imgFid: "" }
        if (this.currentEditedItem) {
          staticFlyer = Object.assign({}, this.currentEditedItem)
        } else if (!this.showItemModal) {
          // Reset values when the modal is closed
          staticFlyer = { name: "", imgFid: "", imgFilename: "" }
        }
        return staticFlyer
      },
      isLoading() {
        return !this.currentEditedItem && this.isEdit
      },
      flyerImgSrc: function() {
        return this.currentEditedItem ? this.currentEditedItem.flyerImg : ""
      }
    }
  ),
  watch: {
    currentEditedItem: function(val, oldVal) {
      if (val && this.showStaticFlyerPrintModal) {
      } else if (!this.showStaticFlyerPrintModal) {
        // Reset values when the modal is closed
      }
    },
    showStaticFlyerPrintModal: function(val, oldVal) {
      this.showPrintModal = val
    }
  },
  methods: Object.assign(
    {},
    mapActions(["togglePrintModal", "saveCustomFlyer", "getLODetails"]),
    {
      beforeEnter() {
        if (this.loDetails && !this.isBankAdmin) {
          this.selectedLO = this.loDetails
        }
      },
      afterEnter() {
        //this.initAutocomplete()
      },
      hidePrintModal() {
        this.togglePrintModal(false)
        this.selectedLO = null
        this.printResult = null
      },
      loAutocomplete(input) {
        return "/lo-manage.json?fullname=" + input + "&gid=" + this.bank_id
      },
      acResultsFormatter(data) {
        return data["los"]
      },
      acResultsDisplay(data) {
        return (
          `
          <div class='resultWrapper'>
            <span class='avatarWrapper'><img src='` +
          data.picture +
          `' /></span>
            <span class="name">` +
          data.name +
          `</span>
          </div>
      `
        )
      },
      selectLO(data) {
        this.selectedLO = data.selectedObject
      },
      acInput(data) {
        //debugger;
      },
      clearLO() {
        this.selectedLO = null
        this.printResult = null
      },
      printLO() {
        this.isPrinting = true
        BB.staticFlyer
          .print({
            loid: this.selectedLO.uid,
            id: this.currentFlyerImage.id
          })
          .then(data => {
            this.isPrinting = false
            if (data.url) {
              this.printResult = data
            } else {
              BB.Toastr.error("Something went wrong")
            }
          })
      }
    }
  )
}
</script>

<style lang="scss">
#newPricingSetModal {
  .print-image-prev {
    max-height: 150px;
  }
  input,
  textarea,
  select {
    font-size: 16px;
  }
  select {
    height: 38px;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .intro {
    margin-bottom: 25px;
  }
  .modal-body .autocomplete {
    box-shadow: none;
    padding: 0px;
    @media (max-width: 767px) {
      width: auto !important;
      right: 0 !important;
      top: 0px !important;
      left: 0 !important;
      margin: 0px;
      &--extended {
        margin: 0px;
      }
    }
    .autocomplete__inputs {
      input {
        font-size: 24px;
      }
    }
  }
  .resultWrapper {
    position: relative;
    .avatarWrapper {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      img {
        max-width: 50px;
      }
    }
    .name {
      position: absolute;
      margin-left: 25px;
      margin-top: 20px;
    }

    .btns {
      float: right;
      margin-top: 20px;
      .clearLOBtn {
        font-size: 24px;
        cursor: pointer;
      }
      .printLO {
        margin-top: -10px;
        margin-right: 10px;
        width: 70px;
      }
    }
    .loader {
      font-size: 16px;
      border-top: 0.125em solid rgba(255, 255, 255, 0.5);
      border-right: 0.125em solid rgba(255, 255, 255, 0.5);
      border-bottom: 0.125em solid rgba(255, 255, 255, 0.5);
      border-left: 0.125em solid #fff;
      top: 2px;
    }
  }
  .result {
    margin-top: 20px;
    padding-left: 10px;
    .downloadLink {
      margin-left: 10px;
    }
  }
}
</style>
