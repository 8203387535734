<doc>
  Organization Manager Vue.js component
</doc>

<template>
  <div>
    <Tabs>
      <Tab name="Overview">
        <ManagerOverview />
      </Tab>
      <Tab name="Brands">
        <ManagerBrands />
      </Tab>
      <Tab name="People">
        <ManagerPeople />
      </Tab>
      <Tab name="Activity">
        <CompanyActivity />
      </Tab>
      <Tab v-if="showStaticFlyers" name="Static Flyers">
        <ManagerStaticFlyers />
      </Tab>
      <Tab v-if="isAdmin" name="Status">
        <OrgStatus />
      </Tab>
    </Tabs>
    <CompanyModal />
    <CompanyPeopleModal />
  </div>
</template>

<script>
import {
  Tab,
  Tabs,
  ManagerOverview,
  ManagerBrands,
  CompanyActivity,
  ManagerPeople,
  CompanyModal,
  CompanyPeopleModal,
  ManagerStaticFlyers,
  OrgStatus
} from "../components"

export default {
  name: "OrganizationManager",
  components: {
    Tabs,
    Tab,
    ManagerBrands,
    CompanyActivity,
    ManagerOverview,
    ManagerPeople,
    CompanyModal,
    CompanyPeopleModal,
    ManagerStaticFlyers,
    OrgStatus
  },
  computed: {
    showStaticFlyers() {
      return hasListingAccess
    },
    isAdmin(){
      return $('body').hasClass('bb-admin')
    }
  }
}
</script>

<style lang="scss" scoped></style>
