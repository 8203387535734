<doc>
  Dropdown Vue.js component

   - Options example:
     const options = [
          {
            label: "option 1",
            action: () => {
              console.log("Option 1 selected")
            }
          },
          {
            label: "option 2",
            action: () => {
              console.log("Option 2 selected")
            }
          }
        ]
  TODO: there is 2 dropdown component, we should unify them
</doc>

<template>
  <div :class="dd_class">
    <BaseButton
      :outline="outline"
      :size="size"
      class="dropdown-toggle"
      :aria-haspopup="haspopup"
      :aria-expanded="expanded"
      v-on:click="toggle"
    >
      {{ label }}
    </BaseButton>
    <div :class="ddm_class" aria-labelledby="dropdownMenuButton">
      <a
        v-for="option in options"
        :key="option.label"
        class="dropdown-item"
        v-on:click="triggerOption(option)"
      >
        {{ option.label }}
      </a>
    </div>
    <div v-show="showDropdown" class="dropdown-overlay" v-on:click="toggle" />
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    label: { type: String, required: true },
    size: { type: String, default: "" },
    options: { type: Array, required: true },
    outline: { type: Boolean, default: true }
  },
  data() {
    return {
      haspopup: false,
      expanded: false,
      showDropdown: false
    }
  },
  computed: {
    dd_class: function() {
      let className = "dropdown"
      if (this.showDropdown) {
        className += " show"
      }
      return className
    },
    ddm_class: function() {
      let className = "dropdown-menu"
      if (this.showDropdown) {
        className += " show"
      }
      return className
    }
  },
  methods: {
    toggle() {
      this.showDropdown = !this.showDropdown
    },
    triggerOption(option) {
      this.toggle()
      option.action(option)
    }
  }
}
</script>

<style lang="scss" src="./Dropdown.scss" scoped></style>
