<doc>
  Bootstrap Style Modal Component for Vue
</doc>

<template>
  <transition-group
    name="modal"
    v-on:before-enter="beforeEnter"
    v-on:after-enter="afterEnter"
    v-on:after-leave="afterLeave"
  >
    <div
      key="modal"
      :class="className"
      tabindex="-1"
      role="dialog"
      v-if="is_visible"
      @click.self="clickMask"
    >
      <div
        class="modal-dialog"
        role="document"
        :class="modalClass"
        ref="dialog"
      >
        <div class="modal-content">
          <div v-if="title" class="modal-header">
            <h4 class="modal-title">
              {{ title }}
            </h4>
            <base-button
              class="close"
              theme=""
              type="button"
              aria-label="Close"
              v-on:click="cancel"
            >
              <span class="closeBtn" aria-hidden="true">&times;</span>
            </base-button>
          </div>
          <div class="modal-body">
            <div class="modal-body-inner">
              <slot></slot>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button
                v-if="showCancelBtn"
                type="button"
                :class="cancelClass"
                @click="cancel"
              >
                {{ cancelLabel }}
              </button>
              <button type="button" :class="okClass" @click="ok">
                {{ okLabel }}
              </button>
            </slot>
          </div>
        </div>
        <div
          v-if="isLoading"
          class="loader vertical-align-middle loader-circle"
        ></div>
      </div>
    </div>
    <div key="backdrop" class="modal-backdrop in" v-if="is_visible"></div>
  </transition-group>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    showCancelBtn: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    // Bootstrap small style modal
    small: {
      type: Boolean,
      default: false
    },
    // Bootstrap large style modal
    large: {
      type: Boolean,
      default: false
    },
    // Bootstrap full style modal
    full: {
      type: Boolean,
      default: false
    },
    // if it set false, click background will close modal
    force: {
      type: Boolean,
      default: true
    }, 
    // vue transition name
    transition: {
      type: String,
      default: "modal"
    },
    // [OK button] text
    okLabel: {
      type: String,
      default: "OK"
    },
    // [Cancel button] text
    cancelLabel: {
      type: String,
      default: "Cancel"
    },
    // [OK button] className
    okClass: {
      type: String,
      default: "btn btn-modern"
    },
    // [Cancel button] className
    cancelClass: {
      type: String,
      default: "btn btn-modern btn-flat msaDeny"
    },
    // automatically close when click [OK button]
    closeWhenOK: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "showModal",
    event: "change"
  },
  data() {
    return {
      is_visible: false
    }
  },
  computed: {
    className() {
      let className = "modal"
      if (this.isLoading) {
        className += " is-loading"
      }
      return className
    },
    modalClass() {
      return {
        "modal-lg": this.large,
        "modal-sm": this.small,
        "modal-full": this.full
      }
    }
  },
  watch: {
    showModal(newVal, oldVal) {
      if (newVal === oldVal) {
        return
      }
      this[newVal ? "show" : "hide"]()
    }
  },
  methods: {
    show() {
      if (this.is_visible) {
        return
      }
      this.is_visible = true
      this.$emit("change", true)
    },
    hide() {
      if (!this.is_visible) {
        return
      }
      this.is_visible = false
      this.$emit("change", false)
    },
    beforeEnter() {
      this.$emit("beforeEnter")
      document.body.classList.add("modal-open")
    },
    afterEnter() {
      this.$emit("afterEnter")
    },
    afterLeave() {
      document.body.classList.remove("modal-open")
    },

    ok() {
      this.$emit("ok")
      if (this.closeWhenOK) {
        this.hide()
      }
    },
    cancel() {
      this.$emit("cancel")
      this.hide()
    },
    clickMask() {
      if (!this.force) {
        this.$emit("clickMask")
        this.hide()
      }
    }
  }
}
</script>

<style lang="scss" src="./BaseModal.scss" scoped></style>
