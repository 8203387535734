<doc>
  Final Step manual flyer form Vue.js component
</doc>

<template>
  <div></div>
</template>

<script>
export default {
  name: "FinalStep",
  props: {}
}
</script>

<style lang="scss" scoped></style>
