<template>
  <Tabs>
    <Tab name="Rate Table">
      <RateTableManager />
    </Tab>
    <Tab name="Lead Workflow">
      <LeadWorkflowManager />
    </Tab>
    <Tab name="Rateflow">
      <RateflowManager />
    </Tab>
    <Tab name="Contact Form">
      <ContactFormManager />
    </Tab>
    <Tab name="County Admin">
      <CountyAdmin />
    </Tab>
    <Tab name="Chat Config">
      <DashboardChatManager />
    </Tab>
    <Tab name="Subscription">
      <SubscriptionManager />
    </Tab>
    <Tab name="Billing">
      <BillingManager />
    </Tab>
    <Tab name="Rate Cards">
      <RateCardsManager />
    </Tab>
  </Tabs>
</template>

<script>
import {
  Tab,
  Tabs,
  RateTableManager,
  ConfigEditorWrapper,
  LeadWorkflowManager,
  RateflowManager,
  ContactFormManager,
  CountyAdmin,
  DashboardChatManager,
  SubscriptionManager,
  BillingManager,
  RateCardsManager
} from "../components"

export default {
  name: "ConfigEditorManager",
  components: {
    RateCardsManager,
    Tabs,
    Tab,
    RateTableManager,
    ConfigEditorWrapper,
    LeadWorkflowManager,
    RateflowManager,
    ContactFormManager,
    CountyAdmin,
    DashboardChatManager,
    SubscriptionManager,
    BillingManager
  }
}
</script>
