<doc>
	Date picker Vue.js component
</doc>

<template>
	<div>
		<icon class="datepicker-icon" name="calendar-alt"></icon>
		<v2-datepicker
			lang="en"
			v-model="theModel"
			:format="dateFormat"
			:placeholder="placeholder" />
		<icon class="datepicker-chevron" name="chevron-down"></icon>
  </div>
</template>

<script>
import Vue from "vue"
import { DatePicker } from "v2-datepicker"
Vue.use(DatePicker)

export default {
	name: "DatePicker",
	props: {
		dateFormat: { type: String, default: "MM dd, yy" },
		placeholder: { type: String, default: "Choose a date…" }
	},
	data() {
		return {
			theModel: this.value
		}
	},
	methods: {}
}
</script>

<style lang="scss" src="./DatePickerStyle.scss" scoped>
</style>
