function isSameDay(d1, d2, isTimestamp = true) {
  if (isTimestamp) {
    d1 = new Date(d1 * 1000)
    d2 = new Date(d2 * 1000)
  }
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

export default isSameDay
