// Vendors
require("es6-promise").polyfill()

// App
var bbApp = require("./bb-app.js")

module.exports = bbApp

// -----------------------------------------
//console.log("Webpack <-");
