import Toastr from "toastr"
import _forEach from "lodash/forEach"
import _orderBy from "lodash/orderBy"
import _isEmpty from "lodash/isEmpty"

// Initial state
const state = {
  org: {},
  orgID: null,
  members: {},
  membersCount: 0
}

const getters = {
  isLoading: state => _isEmpty(state.org)
}

const mutations = {
  UPDATE_ORG(state, org) {
    state.org = org
  },
  UPDATE_ORG_ID(state, id) {
    state.orgID = id
  },
  UPDATE_MEMBERS(state, members) {
    state.members = members
  },
  UPDATE_MEMBERS_COUNT(state, count) {
    state.membersCount = count
  }
}

const actions = {
  initManager({ commit, dispatch, state }) {
    if (org_id) {
      dispatch("getOrgById", org_id).then(async data => {
        dispatch("getBrandPricingStatus", state.org)
        dispatch("getMembers", state.orgID)
        dispatch(
          "activity/getInitalActivity",
          { brands: state.originalItems },
          { root: true }
        )
      })
    } else {
      dispatch("getOrg").then(data => {
        dispatch("getMembers", state.orgID)
        dispatch(
          "activity/getInitalActivity",
          { brands: state.originalItems },
          { root: true }
        )
      })
    }
  },
  getOrg({ commit, dispatch }) {
    // list all orgs belonging to current user
    return BB.orgManage
      .get()
      .then(data => {
        if (data.status !== "error") {
          const id = data.id
          commit("UPDATE_ORG_ID", id)
          return dispatch("getOrgById", id)
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  getOrgById({ commit }, id) {
    // Get org based on the id
    return BB.orgManage
      .getById(id)
      .then(org => {
        if (org) {
          commit("UPDATE_ORG_ID", id)
          commit("UPDATE_ORG", org)
          commit("UPDATE_ORIGINAL_ITEMS", _orderBy(org.brands, "weight"))
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  saveOrg({ commit, state }, payload) {
    // Save a new org or edit one based on the id
    Toastr.success("Updating organization…")
    const data = Object.assign({}, state.org, payload)

    BB.orgManage
      .update(data)
      .then(response => {
        if (response.status !== "error") {
          commit("UPDATE_ORG", data)
          Toastr.success("Organization saved…")
        } else {
          Toastr.error("There was an error, please try again.")
        }
      })
      .catch(err => {
        console.error(err)
        Toastr.error("There was an error, please try again.")
      })
  },
  updateBrands({ commit, state }, currentBrand) {
    const brands = { ...state.org.brands }
    let tmpKey
    _forEach(brands, function(brand, key) {
      if (brand.id == currentBrand.id) {
        tmpKey = key
      }
    })

    brands[tmpKey] = currentBrand
    commit("UPDATE_ORIGINAL_ITEMS", _orderBy(brands, "weight"))
  },
  saveBrand({ dispatch }, payload) {
    Toastr.success("Saving brand…")
    if (!payload.id) {
      return BB.brandManage
        .add(payload)
        .then(response => {
          if (response.status !== "error") {
            payload.id = response.brand_id
            dispatch("updateBrands", payload)
            Toastr.success("Brand saved…")
          } else {
            Toastr.error("There was an error, please try again.")
          }
        })
        .catch(err => {
          console.error(err)
          Toastr.error("There was an error, please try again.")
        })
    }
    return BB.brandManage
      .update(payload)
      .then(response => {
        if (response.status !== "error") {
          dispatch("updateBrands", payload)
          Toastr.success("Brand saved…")
        } else {
          Toastr.error("There was an error, please try again.")
        }
      })
      .catch(err => {
        console.error(err)
        Toastr.error("There was an error, please try again.")
      })
  },
  async getBrandPricingStatus({commit, dispatch}, org){
    if(org && org.brands && org.brands[0]){
      const brands = JSON.parse(JSON.stringify(org.brands))
      _forEach(brands,async (item) => {
        let res = await (await fetch(`/api/v1/pricing-status-brand?brand_id=${item.id}`)).json()

        // update org state
        let tmpKey
        _forEach(brands, function(brand, key) {
          if (brand.id === Object.assign(item, res).id) {
            tmpKey = key
          }
        })

        brands[tmpKey] = Object.assign(item, res)
        const _org = { ...org }
        _org.brands=brands
        _org.force_update = + new Date()
        commit("UPDATE_ORG", _org)
        commit("UPDATE_ORIGINAL_ITEMS", _orderBy(brands, "weight"))
      })
    }
  },
  getMembers({ commit }, id) {
    BB.orgManage
      .getMembers(id)
      .then(data => {
        if (data) {
          //commit("UPDATE_ORIGINAL_ITEMS", data)
          let members = []
          const keys = Object.keys(data.members)
          for (let i = 0; i < keys.length; i++) {
            members = members.concat(data.members[keys[i]].members)
          }
          commit("members/UPDATE_ORIGINAL_ITEMS", members, { root: true })
          commit("UPDATE_MEMBERS", data.members)
          commit("UPDATE_MEMBERS_COUNT", data.count)
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  getItemById({ commit, state }, ID) {
    return BB.brandManage.get(ID).then(data => {
      return data
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
