<doc>
  SmartList toolbar Vue.js component
</doc>

<template>
  <div>
    <flex-box class="smartlist-toolbar">
      <grid-column
        class="ml-auto p-sm-0 d-flex justify-content-end align-items-center"
      >
        <DatePickerRange
          v-if="rangeFilter"
          v-model="computedDateQuery"
          :class="dateClassName"
        />
        <transition>
          <!-- <InputText
            ref="search"
            hideLabel
            v-model="computedSearchQuery"
            label="search"
            name="searchText"
            placeholder="Type for searching…"
            :class="inputClassName"
            v-on:keyupEnter="addFilter"
            v-on:keyupEsc="resetQuery"
            size="sm"
            :isMaterial="false"
          /> -->
          <BaseAutoComplete
            :listItem="filteredTags"
            :showListOptions="searchShowListOptions"
            :listOptions="listOptions"
            name="set-tag"
            :hideLabel="true"
            :placeHolder="this.placeholder"
            :showInput="this.showInput"
            :hidden="true"
            :float="true"
            marTop="0"
            marBottom="0"
            :paddLeft="true"
            :isMaterial="false"
            :fluidList="false"
            @text="filterTags"
            @emptyText="reset"
            @submit="addFilter"
            @keyEsc="closeInput"
            @selectedItem="addTagToFilter"
            @changePlaceholderByType="changePlaceholderByType"
          />
        </transition>
        <base-button
          type="button"
          theme="link"
          btnIcon="search"
          v-on:click="toggleSearch"
          class="btn-search"
        >
          <span class="sr-only">Search</span>
        </base-button>

        <sort v-if="hasSort" :options="sortOptions" />

        <base-button
          v-if="hasAddItem"
          type="button"
          theme="circle"
          v-on:click="addItemAction"
        >
          <span class="sr-only">{{ addItemLabel }}</span>
          +
        </base-button>
      </grid-column>
    </flex-box>
    <flex-box class="smartlist-toolbar-filter">
      <badge
        class="badge-filter"
        pill
        hasClose
        v-on:removeBadge="removeFilter(query)"
        v-for="(query, index) in searchQueries"
        :key="index"
        :label="query.text"
        :showIcon="true"
        :isTag="query.isTag"
        :mr="5"
      />
    </flex-box>
  </div>
</template>

<script>
import Badge from "../Badge"
import DatePickerRange from "../Forms/DatePickerRange"
import BaseAutoComplete from "../BaseAutocomplete"
import Sort from "../Sort"
import Moment from "moment"

export default {
  name: "SmartListToolbar",
  components: {
    Badge,
    DatePickerRange,
    BaseAutoComplete,
    Sort
  },
  props: {
    hasAddItem: { type: Boolean, required: true },
    rangeFilter: { type: Boolean, required: true },
    addItemLabel: { type: String, default: "Add item" },
    searchKeys: { type: [String, Array] },
    searchQuery: { type: String },
    searchQueries: { type: Array },
    gridView: { type: Boolean, default: false },
    gridViewSm: { type: Boolean, default: true },
    toggleListView: { type: Function },
    addQuery: { type: Function, required: true },
    filteredItems: { type: Function, required: true },
    removeQuery: { type: Function, required: true },
    resetQuery: { type: Function, required: true },
    addItemAction: { type: Function },
    vuexNamespace: { type: [String, Boolean], default: false },
    hasSort: { type: Boolean, default: false },
    searchShowListOptions: { type: Boolean, default: false }
  },
  methods: {
    clearTags() {
      const nameSpace = this.vuexNamespace ? `${this.vuexNamespace}/` : ""
      this.$store.dispatch(`${nameSpace}clearTags`)
    },

    matchTags(textTag) {
      const nameSpace = this.vuexNamespace ? `${this.vuexNamespace}/` : ""
      this.$store.dispatch(`${nameSpace}matchTags`, textTag)
    },
    toggleSearch() {
      this.showInput = !this.showInput
      // if (this.showInput) {
      //   this.$refs.search.setFocus()
      // }
    },
    addFilter(filter) {
      this.addQuery(filter)
    },
    removeFilter(query) {
      this.removeQuery(query)
    },
    filterTags(data) {
      if (!data.isTag) return

      let textTag = data.text.trim()
      this.matchTags(textTag)
    },
    reset() {
      this.clearTags()
    },
    closeInput() {
      this.showInput = false
    },
    addTagToFilter(tag) {
      let { text } = tag
      let filter = {
        isTag: true,
        text
      }
      this.addQuery(filter)
    },
    changePlaceholderByType(listOptionType) {
      this.placeholder = listOptionType 
        ? "Search by " + listOptionType + "…"
        : "Search by title…"
    }
  },
  data: function() {
    const nameSpace = this.vuexNamespace ? `${this.vuexNamespace}/` : ""

    let placeholder = "Type for searching…"
    switch (this.vuexNamespace) {
      case "staticFlyers":
        placeholder = "Search by title…"
        break
    }
    
    return {
      showInput: false,
      fromDate: "",
      placeholder: placeholder,
      sortOptions: [
        {
          icon: "fas fa-sort-alpha-up",
          label: "Title ascendant",
          action: () => {
            this.$store.dispatch(`${nameSpace}sortBy`, "title_asc")
          }
        },
        {
          icon: "fas fa-sort-alpha-down",
          label: "Title descendant",
          action: () => {
            this.$store.dispatch(`${nameSpace}sortBy`, "title_desc")
          }
        },
        {
          icon: "fas fa-sort-numeric-up",
          label: "Created ascendant",
          action: () => {
            this.$store.dispatch(`${nameSpace}sortBy`, "created_as")
          }
        },
        {
          icon: "fas fa-sort-numeric-down",
          label: "Created descendant",
          action: () => {
            this.$store.dispatch(`${nameSpace}sortBy`, "created_desc")
          }
        },
        {
          icon: "fas fa-toggle-off",
          label: "Default",
          action: () => {
            this.$store.dispatch(`${nameSpace}sortBy`, "default")
          }
        }
      ]
    }
  },
  computed: {
    nameSpace() {
      return this.vuexNamespace ? `${this.vuexNamespace}` : ""
    },
    filteredTags() {
      return this.vuexNamespace
        ? this.$store.state[this.nameSpace].filteredTags
        : []
    },
    listOptions() {
      let listOptions = undefined
      switch (this.vuexNamespace) {
        case "staticFlyers":
          listOptions = [
            { icon: "file-alt", text: "Flyer Title", type: "title"},
            { icon: "tags", text: "Tags", type: "tags" }
          ]
          break
      }
      return listOptions
    },
    gridViewClassName() {
      return this.gridView ? "btn-active" : ""
    },
    gridViewSmClassName() {
      return this.gridViewSm ? "" : " grid-view-sm"
    },
    gridViewDisabled() {
      return this.gridView
    },
    listViewClassName() {
      return this.gridView ? "" : "btn-active"
    },
    listViewDisabled() {
      return !this.gridView
    },
    // computedSearchQuery: {
    //   get() {
    //     return this.searchQuery
    //   },
    //   set(value) {
    //     this.filteredItems({ query: value, searchKeys: this.searchKeys })
    //   }
    // },
    computedDateQuery: {
      get() {
        return this.searchDateQuery
      },
      set(value) {
        let range
        if (value.length) {
          range = { from: Moment(value[0]).unix(), to: Moment(value[1]).unix() }
        } else {
          range = { from: 0, to: 0 }
        }

        this.filteredItems({ query: range, isDateRange: true })
      }
    },
    inputClassName: function() {
      let inputClassName = "search-input"
      inputClassName += this.showInput ? " visible" : ""
      return inputClassName
    },
    dateClassName: function() {
      let inputClassName = "date-range"
      inputClassName += this.showInput ? " search-visible" : ""
      return inputClassName
    }
  },
  updated() {
    console.log("searchQueries", this.searchQueries, this.searchKeys)
  }
}
</script>

<style lang="scss" scoped>
.smartlist-toolbar {
  margin-bottom: 10px;
  margin-right: 22px;
  .form-group {
    margin-bottom: 0;
  }

  &-filter {
    margin-bottom: 10px;
    padding: 0 15px;
  }
}
.btn {
  transition: color 0.25s;
}
.btn-active {
  color: #6c757d !important;
  &:disabled {
    color: #b8b9c2 !important;
  }

  cursor: unset;
}
.btn-search {
  font-size: 17px;
  display: inline-flex;
  padding-right: 0px;
  position: relative;
  z-index: 8;
}
.badge-filter {
  margin-right: 10px;
}
.date-range {
  display: inline-flex;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(185px, 0);
  position: relative;
  z-index: 5;
  @include media-breakpoint-up(lg) {
    transform: translate(150px, 0);
  }
  &.search-visible {
    transform: translate(-10px, 0);
  }
  + .search-input {
    width: 150px;
  }
}

@include media-breakpoint-down(xs) {
  .grid-view-sm {
    display: none !important;
  }
}
</style>
