<doc>
	LO rates list component
</doc>

<template>
	<div>
		<RatesHeader />

		<SmartList
			:searchQuery="searchQuery"
			:searchQueries="searchQueries"
			:filteredItems="filteredItems"
			:addQuery="addQuery"
			:removeQuery="removeQuery"
			:resetQuery="onresetQuery"
			:items="items"
			:hasAddItem="false"
			:toggleListView="toggleListView"
			:gridView="gridView"
			:gridViewSm="false"
			listType="rates" />
		<RatesModal />
		<RateChartModal />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import { SmartList } from "../SmartList"
import RatesModal from "./RatesModal"
import RateChartModal from "./RateChartModal"
import RatesHeader from "./RatesHeader"
export default {
	name: "RatesList",
	components: {
		SmartList,
		RatesHeader,
		RatesModal,
		RateChartModal
	},
	computed: Object.assign(
		{},
		mapState(["searchQueries", "searchQuery", "gridView"]),
		mapGetters(["currentItems"]),
		{
			items: {
				get() {
					try {
						return this.currentItems
					} catch (e) {
						console.log(e)
					}
				},
				set(value) {
					/**
					 * The "correct" way is commit the update here,
					 * but vuedraggable seems to have issues with it.
					 * We are doing it on the "onEnd" event
					 **/
					// this.$store.commit("updateSets", value)
				}
			}
		}
	),
	methods: Object.assign({},
		mapMutations({
			toggleListView: "TOGGLE_LIST_VIEW"
		}),
		mapActions(["filteredItems", "addQuery", "removeQuery", "resetQuery"]), {
		onresetQuery() {
			this.resetQuery()
		}
	})
}
</script>

<style lang="scss">
	.smartlist-toolbar{
		display:none !important;
	}
</style>
