var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ce-wrapper" }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("LoaderPlaceholder", {
              staticClass: "loader",
              attrs: { type: "circle" }
            })
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("span", { staticClass: "config-title" }, [
                _vm._v(_vm._s(_vm.orgName) + " Billing Config")
              ]),
              _vm._v(" "),
              _vm.org_id
                ? _c("AddCancelBtn", {
                    key: _vm.org_id,
                    staticClass: "ml-auto",
                    attrs: { onClick: _vm.clearForm, isCancel: true }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "d-flex form-inline p-3" }, [
              !_vm.org_id
                ? _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "org-selector" } }, [
                      _vm._v("Org:")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.org_id,
                            expression: "org_id"
                          }
                        ],
                        staticClass: "form-control input-sm",
                        attrs: { id: "org-selector" },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.org_id = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.orgChanged
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Please select one")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.availableOrgs, function(org) {
                          return _c(
                            "option",
                            { key: org.id, domProps: { value: org.id } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(org.name) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.details
            ? _c("div", { staticClass: "editor-wrapper" }, [
                _vm.isLoadingConfig
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("LoaderPlaceholder", {
                          staticClass: "loader",
                          attrs: { type: "circle" }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        on: {
                          mouseover: _vm.addPreview,
                          mouseout: _vm.removePreview
                        }
                      },
                      [
                        _c("div", { staticClass: "form-group addon-select" }, [
                          _c("label", { attrs: { for: "org-selector" } }, [
                            _vm._v("Addons")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newAddon,
                                  expression: "newAddon"
                                }
                              ],
                              staticClass: "form-control input-sm",
                              attrs: { id: "addon-selector" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.newAddon = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.addonSelected
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v("Please select one")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.availableAddons, function(addon) {
                                return _c(
                                  "option",
                                  {
                                    key: addon.type,
                                    domProps: { value: addon.type }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(addon.label) +
                                        "\n            "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("vjsoneditor", {
                          attrs: {
                            plus: true,
                            options: _vm.options,
                            height: _vm.height
                          },
                          on: { error: _vm.onError },
                          model: {
                            value: _vm.details,
                            callback: function($$v) {
                              _vm.details = $$v
                            },
                            expression: "details"
                          }
                        })
                      ],
                      1
                    ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex pt-3" }, [
                  _c(
                    "div",
                    { staticClass: "ml-auto" },
                    [
                      _vm.changed && !_vm.addingNew
                        ? _c(
                            "BaseButton",
                            {
                              staticClass: "mr-2",
                              on: { click: _vm.saveConfig }
                            },
                            [_vm._v("\n            Save\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.addingNew && _vm.org_id
                        ? _c(
                            "BaseButton",
                            {
                              staticClass: "mr-2",
                              on: { click: _vm.handleAddConfig }
                            },
                            [_vm._v("\n            Add\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config || !_vm.addingNew
                        ? _c(
                            "BaseButton",
                            {
                              attrs: { outline: "" },
                              on: { click: _vm.handleDeleteConfig }
                            },
                            [_vm._v("\n            Delete\n          ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "ce_tooltip",
      attrs: { id: _vm.tooltipID, role: "tooltip" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6b5e67f6", { render: render, staticRenderFns: staticRenderFns })
  }
}