var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "newProfilesModal",
        isLoading: _vm.isLoading,
        showCancelBtn: false,
        okLabel: "Close"
      },
      on: { ok: _vm.hideModal },
      model: {
        value: _vm.showProgramDetailsModal,
        callback: function($$v) {
          _vm.showProgramDetailsModal = $$v
        },
        expression: "showProgramDetailsModal"
      }
    },
    [
      _vm.currentProgram
        ? _c("ProgramProfileDetails")
        : _c(
            "div",
            [
              _c("h5", [
                _vm._v("\n\t\t\tSet the order of your available loans\n\t\t")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "intro" }, [
                _vm._v(
                  "Drag and drop the available loans (below) into the order you would like them to appear in the media created using this custom program set."
                )
              ]),
              _vm._v(" "),
              _c("ProgramsProfile")
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-56d1d330", { render: render, staticRenderFns: staticRenderFns })
  }
}