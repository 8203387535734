/**
 * Rate Table API wrapper
 */

const ENDPOINT = "/api/v1/rate-table-config"
const FIND_ENDPOINT = "/api/v1/rate-table-configs"

const bbRateTableConfig = {
  get(params, callback = null) {
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(config, callback) {
    if (!config.id) {
      console.log("Rate Table Config id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(config),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  /*
        {
            "name":"Test Rate Table",
            "brand_id":366184,
            "details":{
                "defaults":{
                    "lock_period":30
                },
                "mortgage_terms":[
                    {
                        "type":"fixed",
                        "term":"30",
                        "label":"30yr Fixed"
                    }
                    ...
                ],
                "usageLimit":4,
                "rateFlowCTA":{
                    "header":"Want more personalized rates?",
                    "subHeader":"Get customized rates tailored to your individual mortgage needs.",
                    "button":"Personalize My Rate"
                }
            },
            "is_default":0
        }
    */
  add(config, callback) {
    if (!config.brand_id || !config.name) {
      console.log("brand_id is required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(config),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  delete(id, callback) {
    return $.ajax({
      url: ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  find(params, callback = null) {
    return $.get(FIND_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbRateTableConfig
