<doc>
  Add/edit Program Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    v-model="showItemModalLocal"
    :title="modalTitle"
    id="newPricingSetModal"
    @beforeEnter="beforeEnter"
    @afterEnter="afterEnter"
    @ok="submitForm"
    @cancel="hideModal"
    @clickMask="hideModal"
    okLabel="Save"
    :isLoading="isLoading"
  >
    <h5>Create Your New Flyer</h5>
    <p
      class="intro"
    >Set up your flyer by uploading a static flyer image, adding helpful tags and assigning to the appropriate brands.</p>
    <form id="new-pricing-set-form" class="form" ref="newPricingSetForm" v-on:submit.prevent>
      <InputText
        v-model="staticFlyer.name"
        v-on:input="onInput"
        label="Name of flyer template"
        name="set-name"
      />
      <ImgUploader
        previewAlt="Custom Flyer Image"
        :previewSrc="flyerImgSrc"
        previewClass="custom-flyer-image"
        label="Upload Custom Flyer Image"
        :uploadCallback="uploadCallback"
        :editMode="true"
        useS3
      />

      <BaseAutocomplete
        :listItem="filteredTags"
        :showListOptions="true"
        :listOptions="[
          {
            icon: 'search',
            text: 'Press Enter or click to add a new tag',
            type: 'submit'
          }
        ]"
        name="search"
        label="Add tag"
        :fluidList="false"
        marBottom="0"
        @text="filterTags"
        @selectedItem="saveItemSelected"
        @submit="createTag"
      />
      <ManageBadge
        class="mt-2"
        :items="listTags"
        showIcon
        isTag
        hasClose
        @deleteBadgeById="deleteBadge"
      />
      <div id="select-brands">
        <h5>Manage Brands</h5>
        <ul>
          <li v-for="(brand, key) in allBrands" :key="key">
            <input
              :id="'brand-' + key"
              type="checkbox"
              @change="manageBrands(brand)"
              :checked="brandIsActive(brand)"
            />
            <label :for="'brand-' + key">{{ brand.name }}</label>
          </li>
        </ul>
      </div>
    </form>
  </BaseModal>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import BaseModal from "../BaseModal"
import ImgUploader from "../ImgUploader"
import BaseAutocomplete from "../BaseAutocomplete"
import ManageBadge from "../ManageBadge"

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "staticFlyers"
)

export default {
  name: "StaticFlyerModal",
  components: { BaseModal, ImgUploader, BaseAutocomplete, ManageBadge },
  created() {
    this.getAllTags()
    if (org_id) {
      this.getAllBrands(org_id)
    }
  },
  computed: Object.assign(
    {},
    mapState([
      "loid",
      "bank_id",
      "originalItems",
      "currentEditedItem",
      "isEdit",
      "filteredTags",
      "savedTags",
      "allBrands",
      "showItemModal"
    ]),
    {
      showItemModalLocal: {
        get() {
          return this.showItemModal
        },
        set(value) {
          this.toggleItemModal(value)
        }
      },
      modalTitle() {
        const prefix = this.isEdit ? "Edit" : "New"
        return `${prefix} Custom Flyer`
      },
      staticFlyer() {
        let staticFlyer = {
          name: "",
          url: "",
          etag: "",
          filename: "",
          tags: "",
          brands: ""
        }
        if (this.currentEditedItem) {
          staticFlyer = Object.assign({}, this.currentEditedItem)
        } else if (!this.showItemModal) {
          // Reset values when the modal is closed
          staticFlyer = {
            name: "",
            imgFid: "",
            imgFilename: "",
            tags: "",
            brands: ""
          }
        }
        return staticFlyer
      },
      isLoading() {
        return !this.currentEditedItem && this.isEdit
      },
      flyerImgSrc: function() {
        return this.currentEditedItem ? this.currentEditedItem.url : ""
      },
      listTags() {
        if (this.currentEditedItem && this.currentEditedItem.tags) {
          let tags = [...this.currentEditedItem.tags, ...this.savedTags]
          // remove duplicates -- note: there is probably a better way to do this
          tags = uniqByKeepLast(tags, it => it.id)
          const item = this.getCurrentItemLocal()
          item.tags = tags
          this.setCurrentEditedItem(item)
          return tags
        }
        return this.savedTags
      }
    }
  ),
  methods: Object.assign(
    {},
    mapMutations({
      setCurrentEditedItem: "SET_CURRENT_EDITED_ITEM"
    }),
    mapActions([
      "getAllTags",
      "getAllBrands",
      "toggleItemModal",
      "saveCustomFlyer",
      "matchTags",
      "saveTagSelected",
      "deleteTagSelected",
      "createNewTag",
      "clearTags"
    ]),
    {
      getCurrentItemLocal() {
        const currentItem = !this.currentEditedItem
          ? this.staticFlyer
          : this.currentEditedItem
        return Object.assign({}, currentItem)
      },
      beforeEnter() {},
      afterEnter() {
        //this.initAutocomplete()
      },
      hideModal() {
        this.$refs.newPricingSetForm.reset()
        /*
      this line is wrong, there are not ref formPreview
      //this.$refs.formPreview.classList.add("disable")
      */
        //this.toggleItemModal()
        this.clearTags()
      },
      submitForm: function() {
        if (this.staticFlyer.brand_ids.length == 0) {
          BB.Toastr.warning("You need at least 1 brand assigned")
          return null
        }
        const data = {
          name: this.staticFlyer.name,
          filename: this.staticFlyer.filename,
          etag: this.staticFlyer.etag,
          url: this.staticFlyer.url,
          list_name: "custom_flyers",
          brand_ids: this.staticFlyer.brand_ids
        }

        if (this.staticFlyer.id) {
          data.id = this.staticFlyer.id
        }

        this.saveCustomFlyer(data)
      },
      uploadCallback(data) {
        if (!data.etag) {
          BB.Toastr.error("Image size too large")
          return
        }
        const item = this.getCurrentItemLocal()

        item.etag = data.etag
        item.filename = data.filename
        item.url = data.url
        this.setCurrentEditedItem(item)
      },
      onInput(val, $event) {
        const item = this.getCurrentItemLocal()
        item.name = val
        this.setCurrentEditedItem(item)
      },
      filterTags(data) {
        let textTag = data.text.trim()
        this.matchTags(textTag)
      },
      saveItemSelected(item) {
        this.saveTagSelected(item)
      },
      deleteBadge(id) {
        //this.clearTags()
        if (this.currentEditedItem) {
          this.deleteTagSelected({
            id,
            currentEditedItem: this.currentEditedItem
          })
        } else {
          this.deleteTagSelected({ id, currentEditedItem: null })
        }
      },
      createTag(data) {
        let textTag = data.text.trim()
        let existingTag = this.verifyExistingTag(textTag)

        if (existingTag) {
          this.saveTagSelected(existingTag)
        } else {
          this.createNewTag({ text: textTag, list_name: "custom_flyers" })
        }
      },
      verifyExistingTag(text) {
        return this.filteredTags.find(tag => tag.text === text)
      },
      manageBrands(brand) {
        const item = this.getCurrentItemLocal()
        if (!item.brand_ids) item.brand_ids = []
        let brands = [...item.brand_ids]

        if (this.brandIsActive(brand)) {
          let i = brands.indexOf(brand.id)
          brands.splice(i, 1)
        } else {
          brands.push(brand.id)
        }
        item.brand_ids = brands
        this.setCurrentEditedItem(item)
      },
      brandIsActive(brand) {
        let currentItem = !this.currentEditedItem
          ? this.staticFlyer
          : this.currentEditedItem
        if (!currentItem) {
          return false
        }
        if (!currentItem.brand_ids) {
          return false
        }
        let result = currentItem.brand_ids.indexOf(brand.id) >= 0
        return result
      }
    }
  )
}

function uniqByKeepLast(data, key) {
  return [...new Map(data.map(x => [key(x), x])).values()]
}
</script>

<style lang="scss">
#newPricingSetModal {
  input,
  textarea,
  select {
    font-size: 16px;
  }
  select {
    height: 38px;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .intro {
    margin-bottom: 25px;
  }
}
#select-brands {
  margin-top: 20px;
  label {
    margin-left: 5px;
  }
  li {
    font-size: 16px;
  }
}

.custom-flyer-image {
  max-width: 200px;
}
</style>
