var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "newPricingSetModal",
        okLabel: "Save",
        isLoading: _vm.isLoading
      },
      on: {
        beforeEnter: _vm.beforeEnter,
        afterEnter: _vm.afterEnter,
        ok: _vm.submitForm,
        cancel: _vm.hideModal
      },
      model: {
        value: _vm.showItemModal,
        callback: function($$v) {
          _vm.showItemModal = $$v
        },
        expression: "showItemModal"
      }
    },
    [
      _c("h5", [_vm._v("\n    Set your parameters\n  ")]),
      _vm._v(" "),
      _c("p", { staticClass: "intro" }, [
        _vm._v("Set up the parameters of your new custom loan set.")
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "newPricingSetForm",
          staticClass: "form",
          attrs: { id: "new-pricing-set-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("InputText", {
            attrs: { label: "Name of program", name: "set-name" },
            model: {
              value: _vm.programSet.name,
              callback: function($$v) {
                _vm.$set(_vm.programSet, "name", $$v)
              },
              expression: "programSet.name"
            }
          }),
          _vm._v(" "),
          _c("InputTextArea", {
            attrs: { label: "Description", name: "set-desc" },
            model: {
              value: _vm.programSet.description,
              callback: function($$v) {
                _vm.$set(_vm.programSet, "description", $$v)
              },
              expression: "programSet.description"
            }
          }),
          _vm._v(" "),
          _c("DropDown", {
            staticClass: "filterInputName",
            attrs: {
              label: "Type of filter",
              name: "set-filter-name",
              options: _vm.options
            },
            on: { dropDownChange: _vm.onInput },
            model: {
              value: _vm.filter.name,
              callback: function($$v) {
                _vm.$set(_vm.filter, "name", $$v)
              },
              expression: "filter.name"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { ref: "formPreview", staticClass: "form-preview disable" },
            [
              _c("InputText", {
                attrs: { label: "Value", name: "set-filter-value" },
                model: {
                  value: _vm.filter.value,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "value", $$v)
                  },
                  expression: "filter.value"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("input", { attrs: { type: "hidden", name: "set-id" } }),
          _vm._v(" "),
          _c("input", { attrs: { type: "hidden", name: "set-weight" } }),
          _vm._v(" "),
          _c("div", { staticClass: "form-preview-overlay" })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c6119250", { render: render, staticRenderFns: staticRenderFns })
  }
}