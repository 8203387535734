<doc>
	LO rates list component
</doc>

<template>
	<div id="rates-header" class="row">
		<time v-if="now" class="col-sm-12">As of {{now}}</time>
		<div class="col-sm-12">
			<BaseButton
				class="pull-right"
				v-on:click="updateRates"
				>
				<span v-if="!refreshing">Refresh Rates</span>
				<span v-else>Loading...</span>
			</BaseButton>
		</div>
		<div v-if="showSlider" class="col-sm-12">
			<div class="col-sm-8">
				<div class="slider" ref="list_price"></div>
				<div class="slider" ref="min_credit"></div>
			</div>
			<div class="col-sm-4">
				<BaseButton
					class="refresh-rates-btn"
					v-on:click="updateRates"
					>
					Refresh Rates
				</BaseButton>
				<BaseButton
					theme="link"
					class="refresh-rates-btn"
					v-on:click="toggleRateSlider"
					>
					Hide selectors
				</BaseButton>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Moment from "moment"
export default {
	name: "RatesHeader",
	data(){
		return {
			showSlider: false,
			refreshing: false
		}
	},
	computed: Object.assign(
		{},
        mapState([ "updatedTime" ]),
		{
			now(){
			    if(this.updatedTime)return Moment(this.updatedTime).format("MMM D YYYY, h:mm:ss a")
				return false
			},
			filter(){
				const filter = {list_price: 1000,	min_credit: 300}
				return filter
			}
		}
	),
	methods: Object.assign({},
		mapActions(["refreshRates"]), {
		async updateRates() {
			console.log('refreshRates', this.filter.list_price, this.filter.min_credit)
			this.refreshing=true;
			await this.refreshRates({ignore_cache: true})
			this.refreshing=false;
		},
		toggleRateSlider(){
			console.log('toggleRateSlider',this.showSlider)
			this.showSlider = !this.showSlider
		}
	}),
	updated: function () {
		if(!this.showSlider){
			return
		}
		const vm = this
		const list_price = vm.$refs.list_price
		const min_credit = vm.$refs.min_credit

		const list_price_slider = BB.uiSlider.create(list_price, {
			start: [vm.filter.list_price],
			step: 10000,
			tooltips: true,
			format: {
				to: function ( value ) {
					return '$' + Math.round(value).toLocaleString()
				},
				from: function ( value ) {
					return Math.round(value)
				}
			},
			range: {
				min: 50000,
				max: 1500000
			}
		});

		const min_credit_slider = BB.uiSlider.create(min_credit, {
			start: [vm.filter.min_credit],
			step: 1,
			tooltips: true,
			format: {
				to: function ( value ) {
					return Math.round(value)
				},
				from: function ( value ) {
					return Math.round(value)
				}
			},
			range: {
				min: 450,
				max: 850
			}
		})

		list_price_slider.on('update', function( values, handle ) {
			vm.filter.list_price = values[handle].replace(/(?:\$)|,/g, "")
		})

		min_credit_slider.on('update', function( values, handle ) {
			vm.filter.min_credit = values[handle]
		})
	}
}
</script>

<style lang="scss" scoped>
#rates-header{
	margin: 30px 0 25px;

	time {
		color: #A5AEB9;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 15px
	}
	.slider {
		margin: 50px 15px;
	}
	.refresh-rates-btn{
		margin-top: 98px;
		@include media-breakpoint-down(sm) {
			float: right;
			margin-top: 0;
		}
	}
}
</style>
