<doc>
	People smartlist  Vue.js component
</doc>

<template>
  <div @drop.prevent="addFile" @dragover.prevent="dragFile" @dragleave.prevent="dragFileLeave">
    <SmartList
      :toggleMemberPricing="toggleMemberPricing"
      :searchQuery="searchQuery"
      :searchQueries="searchQueries"
      :filteredItems="filteredItems"
      :addQuery="addQuery"
      :removeQuery="removeQuery"
      :resetQuery="resetQuery"
      :toggleListView="toggleListView"
      :addItemAction="toggleItemModal"
      :items="items"
      :gridView="gridView"
      listType="member"
      vuexNamespace="members"
    />

    <div class="overlay" :class="{ hide : !fileHovering }">
      <div class="message">
        <i class="fa fa-upload" aria-hidden="true"></i>
        <span>Drop File to Import</span>
      </div>
    </div>
    <PeopleImportModal
      :hideModal="hideModal"
      :show-modal="showImportModal"
      :import-data="importData"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { SmartList } from "../SmartList"
import PeopleImportModal from "./PeopleImportModal"

const {
  mapState,
  mapActions,
  mapGetters,
  mapMutations
} = createNamespacedHelpers("members")

export default {
  name: "ManagerPeople",
  components: {
    SmartList,
    PeopleImportModal
  },
  data() {
    return {
      fileHovering: false,
      showImportModal: false,
      importData: []
    }
  },
  computed: Object.assign(
    {},
    mapState(["gridView", "searchQueries", "searchQuery"]),
    mapGetters(["currentItems"]),
    {
      items: {
        get() {
          try {
            /*const keys = Object.keys(this.members)
						if (keys.length) {
							return this.members[keys[0]].members
						}*/
            return this.currentItems
          } catch (e) {
            console.log(e)
          }
        },
        set(value) {
          /**
           * The "correct" way is commit the update here,
           * but vuedraggable seems to have issues with it.
           * We are doing it on the "onEnd" event
           **/
          // this.$store.commit("updateSets", value)
        }
      }
    }
  ),
  methods: Object.assign(
    {},
    mapMutations({
      toggleListView: "TOGGLE_LIST_VIEW"
    }),
    mapActions([
      "filteredItems",
      "addQuery",
      "removeQuery",
      "resetQuery",
      "toggleItemModal",
      "toggleMemberPricing"
    ]),
    {
      addFile(event) {
        this.fileHovering = false
        this.sendFile(event.dataTransfer.files[0])
      },
      dragFile(event) {
        this.fileHovering = true
      },
      dragFileLeave(event) {
        if(event.offsetX<=0 || event.offsetY<=0){
          this.fileHovering = false
        }
      },
      hideModal() {
        this.importData=[];
        this.showImportModal = false
      },
      async sendFile(file) {
        this.importData=[]
        let formData = new FormData()
        formData.append("lo_list", file)
        let response = await(await fetch("/lo-import", {
          method: "POST",
          body: formData
        })).json()
        if(response && response.status=="error"){
          BB.Toastr.error("Cannot read file: .csv required.")
          return null
        }
        this.importData = response
        this.showImportModal = true
      }
    }
  )
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  margin-top: 25px;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(19, 152, 177, 0.5);
  top: 0px;
  z-index: 10000;
  .message {
    font-size: 40px;
    color: white;
    margin-top: 40px;
    text-align: center;
    span {
      margin-left: 20px;
    }
  }
}
</style>
