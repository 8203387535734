import Vue from "vue"
import { BillingAdmin } from "./pages"

export default function() {
    if (!document.getElementById("vue-billing-admin-app")) {
        return
    }

    // Vuejs setup
    let test=new Vue({
        el: "#vue-billing-admin-app",
        components: {
            BillingAdmin,
        }
    })
    // Do we need this? 25/4
    //window.dispatchEvent(new Event("resize"))
}

