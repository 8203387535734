var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.wrapperClass }, [
    _c("label", { class: _vm.labelClass, attrs: { for: _vm.name } }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
    ]),
    _vm._v(" "),
    _vm.type === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchAddress,
              expression: "searchAddress"
            }
          ],
          ref: "input",
          class: _vm.inputClassName,
          attrs: {
            id: _vm.name,
            "aria-describedby": _vm.describedby,
            placeholder: _vm.placeholder || "",
            type: "checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.searchAddress)
              ? _vm._i(_vm.searchAddress, null) > -1
              : _vm.searchAddress
          },
          on: {
            focus: _vm.onInputFocused,
            blur: _vm.onBlur,
            keyup: [
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                _vm.$emit("keyupEnter", $event)
              },
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
                ) {
                  return null
                }
                _vm.$emit("keyupEsc", $event)
              }
            ],
            change: function($event) {
              var $$a = _vm.searchAddress,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.searchAddress = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.searchAddress = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.searchAddress = $$c
              }
            }
          }
        })
      : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchAddress,
                expression: "searchAddress"
              }
            ],
            ref: "input",
            class: _vm.inputClassName,
            attrs: {
              id: _vm.name,
              "aria-describedby": _vm.describedby,
              placeholder: _vm.placeholder || "",
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.searchAddress, null) },
            on: {
              focus: _vm.onInputFocused,
              blur: _vm.onBlur,
              keyup: [
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  _vm.$emit("keyupEnter", $event)
                },
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
                  ) {
                    return null
                  }
                  _vm.$emit("keyupEsc", $event)
                }
              ],
              change: function($event) {
                _vm.searchAddress = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchAddress,
                expression: "searchAddress"
              }
            ],
            ref: "input",
            class: _vm.inputClassName,
            attrs: {
              id: _vm.name,
              "aria-describedby": _vm.describedby,
              placeholder: _vm.placeholder || "",
              type: _vm.type
            },
            domProps: { value: _vm.searchAddress },
            on: {
              focus: _vm.onInputFocused,
              blur: _vm.onBlur,
              keyup: [
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  _vm.$emit("keyupEnter", $event)
                },
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
                  ) {
                    return null
                  }
                  _vm.$emit("keyupEsc", $event)
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchAddress = $event.target.value
              }
            }
          }),
    _vm._v(" "),
    _vm.helpText
      ? _c(
          "small",
          {
            staticClass: "form-text text-muted",
            attrs: { id: _vm.describedby }
          },
          [_vm._v("\n    " + _vm._s(_vm.helpText) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3c2cfc45", { render: render, staticRenderFns: staticRenderFns })
  }
}