<doc>
  Step 1 manual flyer form Vue.js component
</doc>

<template>
  <div>
    <grid-row>
      <grid-column>
        <InputPlaces
          label="Search address…"
          name="searchAddress"
          v-on:onInputPlaces="onInputPlacesLocal"
        /> </grid-column
    ></grid-row>
    <grid-row>
      <grid-column>
        <InputText
          v-model.trim="value.street_addr"
          label="Address"
          name="street_addr"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>
    <grid-row>
      <grid-column size="6">
        <InputText
          v-model.trim="value.neighborhood"
          label="Neighborhood"
          name="neighborhood"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="6">
        <InputText
          v-model.trim="value.city"
          label="City"
          name="city"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>

    <grid-row>
      <grid-column size="6">
        <InputText
          v-model.trim="value.county"
          label="County"
          name="county"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="3">
        <StatesDropDown
          label="State"
          name="state"
          v-model="value.state"
          :onInput="onInputStates"
        />
      </grid-column>
      <grid-column size="3">
        <InputText
          v-model.trim="value.zipcode"
          label="Zip"
          name="zipcode"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
import { InputPlaces, StatesDropDown } from "../../Forms"

export default {
  name: "FormStepAddress",
  props: {
    value: { type: Object },
    onInput: { type: Function },
    onInputStates: { type: Function }
  },
  components: {
    InputPlaces,
    StatesDropDown
  },
  methods: {
    onInputPlacesLocal(val) {
      this.$emit("onInputPlaces", val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
