var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editorContainer" }, [
    _vm.initialLoad
      ? _c("div", [_vm._v("\n        Loading...\n    ")])
      : _vm._e(),
    _vm._v(" "),
    _vm.requests && !_vm.initialLoad
      ? _c(
          "div",
          {
            staticClass: "integration-status-table table-responsive",
            staticStyle: { "max-height": "600px", "overflow-y": "scroll" }
          },
          [
            _c("table", { staticClass: "table" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.requests, function(request, i) {
                  return _c("tr", { class: { validated: request.validated } }, [
                    _c("td", { staticClass: "date-time" }, [
                      _vm._v(_vm._s(request.dateTime))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(request.referrer))])
                  ])
                })
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date/Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Referrer")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9ceffeea", { render: render, staticRenderFns: staticRenderFns })
  }
}