<doc>
	Program profiles Modal Vue.js component
</doc>

<template>
	<BaseModal
		ref="modal"
		v-model="showProgramDetailsModal"
		:title="modalTitle"
		id="newProfilesModal"
		:isLoading="isLoading"
		@ok="hideModal"
		:showCancelBtn="false"
		okLabel="Close">
		<ProgramProfileDetails v-if="currentProgram" />
		<div v-else>
			<h5>
				Set the order of your available loans
			</h5>
			<p class="intro">Drag and drop the available loans (below) into the order you would like them to appear in the media created using this custom program set.</p>
			<ProgramsProfile />
		</div>
	</BaseModal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BaseModal from "../BaseModal"
import ProgramsProfile from "./ProgramsProfile"
import ProgramProfileDetails from "./ProgramProfileDetails"

export default {
	name: "ProgramDetailsModal",
	components: { BaseModal: BaseModal, ProgramsProfile: ProgramsProfile, ProgramProfileDetails: ProgramProfileDetails },
	computed: Object.assign({}, mapState(["currentEditedItem", "isEdit", "currentProgram"]), {
		showProgramDetailsModal: {
			get() {
				return this.$store.state.showProgramDetailsModal
			},
			set(value) {
				this.toggleProgramDetailsModal(value)
			}
		},
		modalTitle() {
			const prefix = this.isEdit ? "Edit" : "New"
			return `${prefix} Loan Program Set`
		},
		isLoading() {
			return !this.currentEditedItem && this.isEdit
		}
	}),
	methods: Object.assign({}, mapActions(["toggleProgramDetailsModal", "saveProfileList"]), {
		hideModal() {
			this.toggleProgramDetailsModal()
		},
		submitForm: function() {
			this.saveProfileList()
		}
	})
}
</script>

<style lang="scss">
#newProfilesModal {
	h5 {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.intro {
		margin-bottom: 25px;
	}
}
</style>
