var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.dd_class },
    [
      _c(
        "BaseButton",
        {
          staticClass: "dropdown-toggle",
          attrs: {
            outline: _vm.outline,
            size: _vm.size,
            "aria-haspopup": _vm.haspopup,
            "aria-expanded": _vm.expanded
          },
          on: { click: _vm.toggle }
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.ddm_class,
          attrs: { "aria-labelledby": "dropdownMenuButton" }
        },
        _vm._l(_vm.options, function(option) {
          return _c(
            "a",
            {
              key: option.label,
              staticClass: "dropdown-item",
              on: {
                click: function($event) {
                  _vm.triggerOption(option)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(option.label) + "\n    ")]
          )
        })
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDropdown,
            expression: "showDropdown"
          }
        ],
        staticClass: "dropdown-overlay",
        on: { click: _vm.toggle }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f2e24156", { render: render, staticRenderFns: staticRenderFns })
  }
}