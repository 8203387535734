<doc>
  Table Vue.js component
</doc>

<template>
	<table :class="className"><slot /></table>
</template>

<script>
export default {
	name: "Table",
	props: {
		dark: { type: Boolean, default: false },
		striped: { type: Boolean, default: false },
		bordered: { type: Boolean, default: false },
		borderless: { type: Boolean, default: false },
		hoverable: { type: Boolean, default: false },
		small: { type: Boolean, default: false }
	},
	computed: {
		className: function() {
			const dark = this.dark ? "table-dark" : ""
			const striped = this.striped ? "table-striped" : ""
			const bordered = this.bordered ? "table-bordered" : ""
			const borderless = this.borderless ? "table-bordered" : ""
			const hoverable = this.hoverable ? "table-hover" : ""
			const small = this.small ? "table-sm" : ""

			return `table ${this.theme} ${this.striped} ${this.bordered} ${this.borderless} ${this.small} ${this.hoverable}`
		}
	}
}
</script>

<style lang="scss" src="./Table.scss" scoped>
</style>
