<template>
  <div class="editorWrapper">
    <div style="height:calc(100% - 50px);">
      <Tabs>
        <Tab name="Cards">
          <div class="cardsWrapper">
            <LoaderPlaceholder v-if="isLoading" type="circle" />
            <div v-else class="col-sm-4 list-cards" v-for="(card, index) in activeRowLocal.cards" :key="index">
              <CalcCard
                  :rate="card.rate"
                  :price="card.price"
                  :term="parseInt(card.amortizationTerm)"
                  :loanAmount="getLoanAmount"
                  :fee="card.fee"
                  :type="card.productName"
                  :principalAndInterest="card.principalAndInterest"
                  :investor="card.investor"
              />
            </div>
          </div>
        </Tab>
        <Tab name="Details">
          <div class="editorContainer">
            <LoaderPlaceholder v-if="isLoading" type="circle" />
            <vjsoneditor
                v-else
                v-model="activeRowLocal"
                :options="options"
                :plus="true"
            />
          </div>
        </Tab>
      </Tabs>
      <div class="copy-wrapper" v-if="activeRowLocal.full_request">
        <input v-model="requestJSONString" type="text">
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-modern closeBtn" @click="closePanel">Close</button>
      <button class="btn btn-modern copyBtn pull-right" @click="gotoPricingTool">Test Request</button>
    </div>
  </div>

</template>

<script>
import vjsoneditor from "v-jsoneditor"
import { Tabs, Tab } from "../Tabs"
import CalcCard from "../CalcCard"

export default {
  name: "PricingPanel",
  components: {
        vjsoneditor,
        Tabs,
        Tab,
        CalcCard
    },
  data: function() {
    return {
      activeRowLocal: {},
      isLoading: true
    }
  },
  props: {
    options: { type: Object },
    onCreated: { type: Function }
  },
  computed: {
    requestJSONString(){
        return JSON.stringify(this.activeRowLocal.full_request)
    },
    getLoanAmount(){
      if(this.activeRowLocal.type=="mortech"){
        return this.activeRowLocal.full_request.loan_amount
      }
      else if(this.activeRowLocal.type=="ob"){
        return this.activeRowLocal.full_request.loanInformation.baseLoanAmount
      }
      else if(this.activeRowLocal.type=="lender_price"){
        return this.activeRowLocal.full_request.criteria.loanAmount;
      }
      return 0;
    }
  },
  created() {
    this.onCreated().then(activeRow => {
      this.activeRowLocal = activeRow
      this.isLoading = false
    })
  },
  methods: {
      closePanel() {
          this.$emit('closePanel', {});
      },
      gotoPricingTool(){
          window.open("/pricing-tool?log_id="+this.activeRowLocal.id, '_blank');
          return;
      }
  }

}
</script>

<style lang="scss">
  .editorWrapper{
    height: 100%;
    position: relative;
    margin: 0px;
    overflow: hidden;
  }
  .editorContainer{
    height: calc(100% - 50px);
    overflow: scroll;
  }
  .jsoneditor-container{
    div.jsoneditor{
      border: none;
    }
    .jsoneditor-menu{
      top: 0px;
      z-index: 900
    }
  }
  .footer{
    height: 50px;
    position: relative;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    width:100%;
    border-top:1px solid #eee;
    bottom: 0px;
    .btn{
      margin-top:5px;
      &.copyBtn{
        background: linear-gradient(0deg, #7e57c2 0%, #9883be 100%);
        border: 1px solid #9883be;
      }
    }
  }
  .tabs-component{
    height:100%;
  }
  .tabs-component-panels{
    padding: 0px !important;
    height: 100%;
    .tabs-component-panel{
      height:100%;
    }
  }
  .tabs-component-tabs{
    margin-left:0px !important;
  }
  .label{
    color: #7c7c8d;
  }
  .copy-wrapper{
    //display:none;
    position: absolute;
    right:-9999px;
  }
</style>

