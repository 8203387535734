// Initial state
const state = {
  allTags: [],
  tagsIndexed: {},
  filteredTags: [],
  savedTags: [],
  tagsToDelete: []
}
const getters = {
  getTagsCurrentItem: state => {
    let alreadyTagsItem = []
    let savedTags = []
    let allTagsItem = []

    if (
      state.currentEditedItem &&
      state.currentEditedItem.hasOwnProperty("tags")
    )
      alreadyTagsItem.push(...state.currentEditedItem.tags)

    savedTags.push(...state.savedTags)
    allTagsItem.push(...alreadyTagsItem, ...savedTags)

    return allTagsItem
  }
}
const mutations = {
  GET_ALL_TAGS(state, payload) {
    state.allTags = payload.originalTags
    state.tagsIndexed = payload.tagsIndexed
  },
  MATCH_TAGS(state, payload) {
    state.filteredTags = payload
  },
  SAVE_TAG_SELECTED(state, payload) {
    state.savedTags = payload
  },
  DELETE_TAG_SELECTED(state, payload) {
    let { tag_id, item_id, newSavedTags, destination } = payload

    state.savedTags = newSavedTags
    /*    if (destination === "savedTags") {
      state.savedTags = newSavedTags
    }*/
    if (destination === "tagsToDelete") {
      const currentEditedItem = { ...state.currentEditedItem }

      currentEditedItem.tags = newSavedTags
      state.currentEditedItem = currentEditedItem
      state.tagsToDelete = state.tagsToDelete.concat({ tag_id, item_id })
    }
  },
  CREATE_NEW_TAG(state, payload) {
    let allTags = state.allTags
    allTags.push(payload)

    state.allTags = allTags
  },
  SAVE_NEW_TAG(state, payload) {
    let savedTags = state.savedTags
    savedTags.push(payload)

    state.savedTags = savedTags
  },
  CLEAR_TAGS(state) {
    state.savedTags = []
    state.filteredTags = []
  },
  CLEAR_TAGS_TO_DELETE(state) {
    state.tagsToDelete = []
  }
}

const actions = {
  getAllTags({ commit }) {
    BB.tag.get({ list_name: "custom_flyers" }).then(data => {
      let tagsIndexedByText = data.reduce(
        (acc, current) => ({
          ...acc,
          [current.value]: current
        }),
        {}
      )

      commit("GET_ALL_TAGS", {
        originalTags: data,
        tagsIndexed: tagsIndexedByText
      })
    })
  },
  matchTags({ commit, state }, payload) {
    let wordsToSearch = payload.toLowerCase()
    let originalTags = state.allTags
    let filteredTags = []
    let convertedTags = []

    filteredTags = originalTags.filter(tag => {
      let textTag = tag.value.toLowerCase()
      let hasWords = textTag.includes(wordsToSearch)

      return hasWords
    })

    convertedTags = filteredTags.map(tag => {
      return {
        icon: "tag",
        text: tag.value,
        type: "item",
        id: tag.id,
        list_name: tag.list_name
      }
    })

    commit("MATCH_TAGS", convertedTags)
  },
  saveTagSelected({ commit, state }, payload) {
    let savedTags = []

    if (Array.isArray(payload)) {
      savedTags.push(...state.savedTags, ...payload)
    } else {
      savedTags.push(...state.savedTags, payload)
    }

    commit("SAVE_TAG_SELECTED", savedTags)
  },
  deleteTagSelected({ commit, state }, payload) {
    let savedTags = []
    let newSavedTags = []

    if (payload.currentEditedItem) {
      //console.log(`tag_id: ${payload.id}, item_id: ${payload.currentEditedItem.id}`)
      savedTags = payload.currentEditedItem.tags
      newSavedTags = savedTags.filter(tag => tag.id != payload.id)
      commit("DELETE_TAG_SELECTED", {
        tag_id: payload.id,
        item_id: payload.currentEditedItem.id,
        newSavedTags,
        destination: "tagsToDelete"
      })
    } else {
      savedTags = state.savedTags
      newSavedTags = savedTags.filter(tag => tag.id != payload.id)
      commit("DELETE_TAG_SELECTED", {
        tag_id: null,
        item_id: null,
        newSavedTags,
        destination: "savedTags"
      })
    }
  },
  associateTag({ commit, state }, payload) {
    const itemId = payload
    const savedTags = state.savedTags

    savedTags.forEach(tag => {
      BB.tag.association
        .add({
          tag_id: tag.id,
          item_id: itemId
        })
        .then(data => {
          console.log("action associateTag: associated tag")
          console.log(data)
        })
    })
  },
  createNewTag({ commit }, payload) {
    BB.tag
      .add({ list_name: payload.list_name, value: payload.text })
      .then(data => {
        console.log(data)
        if (data.status === "success") {
          let newTag = {
            id: data.tag_id,
            list_name: payload.list_name,
            value: payload.text
          }

          let saveNewTag = {
            id: data.tag_id,
            list_name: payload.list_name,
            text: payload.text,
            icon: "tag",
            type: "item"
          }

          commit("CREATE_NEW_TAG", newTag)
          commit("SAVE_NEW_TAG", saveNewTag)
        }
      })
  },
  getTagById() {},
  clearTags({ commit }) {
    commit("CLEAR_TAGS")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
