import Toastr from "toastr"
import _ from "lodash"

const ENDPOINT = "/static-flyer-manage.json"

// Initial state
const state = {
  bank_id: null,
  currentFlyerImage: null,
  flyerImages: [],
  showStaticFlyerPrintModal: false,
  isBankAdmin: false,
  uid: null,
  loDetails: null,
  allBrands: [],
  qtyItemsDB: 0
}

const mutations = {
  SET_BANK_ID(state, bank_id) {
    state.bank_id = bank_id
  },
  TOGGLE_STATIC_FLYER_PRINT_MODAL(state, condition) {
    state.showStaticFlyerPrintModal = condition
  },
  SET_CURRENT_FLYER_IMAGE(state, item) {
    state.currentFlyerImage = item
  },
  SET_CURRENT_EDITED_ITEM(state, item) {
    state.currentEditedItem = item
  },
  SET_IS_BANK_ADMIN(state, condition) {
    state.isBankAdmin = condition
  },
  SET_UID(state, uid) {
    state.uid = uid
  },
  SET_LO_DETAILS(state, details) {
    state.loDetails = details
  },
  SET_ALL_BRANDS(state, brands) {
    state.allBrands = brands
  },
  CLEAR_ALL_BRANDS(state) {
    state.allBrands = []
  },
  SET_QTY_ITEMS_DB(state, payload) {
    state.qtyItemsDB = payload
  }
}

const actions = {
  initStaticFlyers({ commit, dispatch, state }, { bank_id, isBankAdmin, uid }) {
    commit("SET_BANK_ID", bank_id)
    commit("SET_IS_BANK_ADMIN", isBankAdmin)
    commit("SET_UID", uid)
    dispatch("getLODetails", uid)
    dispatch("getFlyerImages", { bank_id })
  },
  showPrintStaticFlyerModal({ commit, dispatch }, id) {
    commit("TOGGLE_STATIC_FLYER_PRINT_MODAL", true)
    dispatch("getItemById", id).then(data => {
      commit("SET_CURRENT_FLYER_IMAGE", data)
    })
  },
  setBankId({ commit }, bank_id){
    commit("SET_BANK_ID", bank_id)
  },
  refreshFlyerImages({ commit, dispatch }, bank_id){
    dispatch("getFlyerImages", { bank_id })
  },
  getFlyerImages({ commit, dispatch, state, getters }, payload) {
    const { bank_id, offset } = payload
    const { max_items, sort_by, current_offset } = state

    let config = {
      bank_id
    }

    if (state.sort_by !== "default")
      config = {
        ...config,
        sort_by
      }

    const tagIndexById = data => {
      let initialData = data
      let indexedById

      indexedById = initialData.reduce(
        (acc, current) => ({
          ...acc,
          [current.item_id]: {
            item_id: current.item_id,
            value: []
          }
        }),
        {}
      )

      return indexedById
    }

    const getQtyItems = () => {
      return new Promise((resolve, reject) => {
        if (state.qtyItemsDB > 0) resolve(state.qtyItemsDB)

        BB.staticFlyer
          .get({ bank_id: bank_id, limit: 1, offset: 0 })
          .then(data => {
            resolve(data.total)
          })
          .catch(err => reject(err))
      })
    }

    const init = async () => {
      const qtyItems = await getQtyItems()
      commit("SET_QTY_ITEMS_DB", qtyItems)

      if (max_items < qtyItems) {
        config = {
          ...config,
          limit: max_items,
          offset: current_offset ? current_offset : 0
        }
      }

      BB.staticFlyer
        .get(config)
        .then(data => {
          if (data.status !== "error") {
            let items = data.data
            let totalItems = data.totalData

            BB.tag.association
              .get("custom_flyers")
              .then(data => {
                let tagIndexedById = tagIndexById(data)
                
                data.forEach(item => {
                  tagIndexedById[item.item_id].value.push({
                    icon: "default",
                    id: state.tagsIndexed[item.value].id,
                    list_name: "custom_flyers",
                    text: item.value,
                    type: "item"
                  })
                })
                
                let itemsWithTags = items.map(item => {
                  return {
                    ...item,
                    tags: tagIndexedById[item.id]
                      ? tagIndexedById[item.id].value
                      : []
                  }
                })
                if(totalItems){
                  let totalItemsWithTags = totalItems.map(item => {
                    return {
                      ...item,
                      tags: tagIndexedById[item.id]
                        ? tagIndexedById[item.id].value
                        : []
                    }
                  })
                  commit("UPDATE_TOTAL_ITEMS", totalItemsWithTags)
                }
                commit("UPDATE_ORIGINAL_ITEMS", itemsWithTags)
              })
          }
        })
        .catch(err => {
          console.error(err)
        })
    }

    init()
  },
  upsertItem({ commit, dispatch, getters, state }, payload) {
    if (!payload.item.id) {
      return null
    }

    let currentItem = payload.item
    let items = [...state.originalItems]
    var index = _.findIndex(items, { id: currentItem.id })

    if (payload.existsItem) {
      currentItem.tags = getters.getTagsCurrentItem
    } else {
      currentItem.tags = state.savedTags
    }

    if (index == -1) {
      items[items.length] = currentItem
    } else {
      items.splice(index, 1, currentItem)
    }

    commit("UPDATE_ORIGINAL_ITEMS", items)
  },
  saveCustomFlyer({ commit, dispatch, state }, item) {
    let itemId = item.id
    if (itemId) {
      let deleteTags = tagsToDelete => {
        tagsToDelete.forEach(tag => {
          const { tag_id, item_id } = tag
          BB.tag.association
            .delete({ tag_id, item_id })
            .then(data => console.log(data))
        })

        commit("CLEAR_TAGS_TO_DELETE")
      }

      if (state.tagsToDelete.length) {
        let tagsToDelete = [...state.tagsToDelete]
        deleteTags(tagsToDelete)
      }

      BB.staticFlyer
        .update(item)
        .then(data => {
          console.log(data)
          if (data.status === "success") {
            Toastr.success("Flyer saved")
          }
          if (state.savedTags.length) {
            dispatch("associateTag", itemId)
            dispatch("clearTags")
          }

          dispatch("upsertItem", { item, existsItem: true })
          dispatch("toggleItemModal", false)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      BB.staticFlyer
        .add(item)
        .then(data => {
          // add item
          if (data.status === "error") {
            Toastr.error("There was an error, please try again later.")
            console.error("saveCustomFlyer error:", data.message)
          } else {
            console.log(data)
            Toastr.success("Flyer saved")
          }

          let itemId = data.id
          dispatch("toggleItemModal", false)
          item.id = itemId

          dispatch("associateTag", itemId)
          dispatch("upsertItem", { item, existsItem: false })
          dispatch("clearTags")
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  getItemById({ commit, state }, ID) {
    // it isn't neccesary because we can get item by id from the store
    // var item=BB.staticFlyer
    //     .get({id:ID})
    //     .then(data=>{return data[0]});
    let item = state.totalItems.find(item => item.id === ID)

    return item
  },
  removeStaticFlyer({ commit, dispatch, state }, info) {
    BB.staticFlyer.delete(info.id).then(data => {
      const itemToDelete = Object.assign({}, state.originalItems[info.index])
      const listTagsItemToDelete =
        itemToDelete.tags.length > 0 ? itemToDelete.tags : null

      if (listTagsItemToDelete)
        listTagsItemToDelete.forEach(tag => {
          BB.tag.association.delete({ tag_id: tag.id, item_id: info.id })
        })

      commit("REMOVE_ITEM", { id: info.id, index: info.index })
      Toastr.success("Item Deleted")
    })
  },
  togglePrintModal({ commit, dispatch, state }, condition) {
    commit("TOGGLE_STATIC_FLYER_PRINT_MODAL", condition)
  },
  getLODetails({ commit, dispatch, state }, loid) {
    return BB.loManage.get(loid).then(data => {
      commit("SET_LO_DETAILS", data.lo)
    })
  },
  getAllBrands({ commit, state }, org_id) {
    BB.orgManage.getById(org_id).then(data => {
      commit("SET_ALL_BRANDS", data.brands)
    })
  }
}

export default {
  state,
  mutations,
  actions
}
