<template>
  <ckeditor
    :editor="editor"
    v-model="val"
    v-on:input="onInput"
    :config="editorConfig"
  ></ckeditor>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue"
import CKEditorUploadAdapter from "./CKEditorCustomUploadAdapter"

// Plugin for image upload
function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    return new CKEditorUploadAdapter(loader)
  }
}

export default {
  name: "Editor",
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
    name: { type: String, default: "" },
    onInput: { type: Function, default: () => {} }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "bold",
            "italic",
            "|",
            "imageUpload",
            "mediaEmbed",
            "|",
            "undo",
            "redo"
          ]
        },
        language: "en",
        image: {
          styles: ["full", "alignLeft", "alignCenter", "alignRight"],
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "imageTextAlternative"
          ]
        },
        extraPlugins: [CustomUploadAdapterPlugin],
        mediaEmbed: {
          previewsInData: true,
          toolbar: [
            "mediaStyle:full",
            "|",
            "mediaStyle:alignLeft",
            "mediaStyle:alignCenter",
            "mediaStyle:alignRight"
          ],
          styles: ["full", "alignLeft", "alignCenter", "alignRight"]
        }
      }
    }
  },
  computed: {
    val: {
      get: function() {
        return this.value + ""
      },
      set: function(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>

<style lang="scss">
.rich-text-editor {
  .ck.ck-editor__editable .image,
  .ck-content .image.image_resized {
    display: inline-block;
  }

  .ck.ck-editor {
    padding-top: 10px;
  }

  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border-color: $primary;
  }

  i {
    font-style: italic;
  }
}
.ck.ck-balloon-panel {
  z-index: 1051;
}
</style>
