<template>
  <BaseButton :class="{ cancel: isCancel }" @click="onClick" theme="circle">
    <div class="btn__inner">+</div>
  </BaseButton>
</template>

<script>
export default {
  name: "AddCancelBtn",
  props: {
    isCancel: { type: Boolean, default: false },
    onClick: { type: Function, required: true }
  }
}
</script>

<style lang="scss" scoped src="./AddCancelBtn.scss"></style>
