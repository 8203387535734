<template>
  <div class="row">
    <div class="col">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Org</th>
          <th scope="col">Lead Total</th>
          <th scope="col">Email Total</th>
          <th scope="col">Invoice Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(org, key) in orgs" :key="key">
          <td :style="{ fontWeight: 500, color: !org.stripe_connected ? '#EF5350' : 'inherit' }">{{ org.name }}</td>
          <td v-if="!systemUsage[org.id]" style="padding-left:10px;">loading...</td><td v-else style="padding-left:10px;">{{ systemUsage[org.id] && systemUsage[org.id].breakdown && systemUsage[org.id].breakdown.leads ? systemUsage[org.id].breakdown.leads.total : 0 }}</td>
          <td v-if="!systemUsage[org.id]" style="padding-left:10px;"></td><td v-else style="padding-left:10px;">{{ systemUsage[org.id] && systemUsage[org.id].breakdown && systemUsage[org.id].breakdown.emails ? systemUsage[org.id].breakdown.emails.total : 0 }}</td>
          <td v-if="!systemUsage[org.id]" style="padding-left:10px;"></td><td v-else style="padding-left:10px;"><a :href="`/billing/${org.id}`">${{ systemUsage[org.id] ? formatNumber(systemUsage[org.id].total) : 0 }}</a><span style="margin-left: 5px" v-if="!systemUsage[org.id].current_invoice">*</span></td>
        </tr>
        </tbody>
      </table>
      <div>
        <span style="font-weight: 500;">Invoice Count:</span> {{ invoiceCount }}
      </div>
      <div>
        <span style="font-weight: 500;">Total Revenue:</span> ${{ formatNumber(totalRevenue) }}
      </div>
      <div>
        <span style="font-weight: 500;">Avg Revenue:</span> ${{ invoiceCount ? formatNumber(totalRevenue/invoiceCount) : 0 }}
      </div>
      <div>
        <span style="font-weight: 500;">Total Leads:</span> {{ parseInt(leadTotal) }}
      </div>
      <div>
        <span>* Estimated revenue, no invoice for this month</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingAdminPage',
  data() {
    return {
      orgs: [],
      systemUsage: {},
      month: "",
      year: ""
    };
  },
  async mounted() {
    console.log("BillingAdminPage mounted")
    // get all orgs
    const result=await $.get("/api/v1/orgs?limit=9999")

    // filter out disabled orgs
    result.data = result.data.filter(org => !org.disabled);

    this.$set(this, 'orgs', result.data);

    // sort orgs by name
    this.orgs.sort((a, b) => a.name.localeCompare(b.name));
    console.log("orgs", this.orgs)
    // wait for the DOM to update with orgs data
    this.$nextTick(() => {
      // then fetch systemUsage data
      this.fetchSystemUsage();
    });

    // get url param month if exists
    const urlParams = new URLSearchParams(window.location.search);
    this.month = urlParams.get('month') ? urlParams.get('month') : "";
    this.year = urlParams.get('year') ? urlParams.get('year') : "";

  },
  computed: {
    leadTotal() {
      return this.systemUsage
          ? Object.values(this.systemUsage).reduce((total, usage) => {
            const leadSubtotal = usage && usage.breakdown && usage.breakdown.leads ? usage.breakdown.leads.total : 0;
            return total + leadSubtotal;
          }, 0)
          : 0;
    },
    totalRevenue() {
      return this.systemUsage
          ? Object.values(this.systemUsage).reduce((total, usage) => {
            return total + usage.total;
          }, 0)
          : 0;
    },
    invoiceCount() {
      return Object.keys(this.systemUsage).length;
    },
  },
  methods: {
    async fetchSystemUsage() {
      // for each org, get usage in batches of 10
      const batchSize = 10;
      for (let i = 0; i < this.orgs.length; i += batchSize) {
        const batch = this.orgs.slice(i, i + batchSize);
        await Promise.all(batch.map(async (org) => {
          let monthParam = (this.month ? `&month=${this.month}` : "");
          let yearParam = (this.year ? `&year=${this.year}` : "");
          let usage = await $.get(`/api/v1/org-billing?org_id=${org.id}${monthParam}${yearParam}`);
          this.$set(this.systemUsage, org.id, usage);
        }));
      }
    },
    formatNumber(value) {
      return Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style>
/* Add any specific styling here */
</style>
