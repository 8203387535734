<doc>
  Add a new item placeholder Vue.js component
</doc>

<template>
  <div :class="className" v-on:click="addItemAction">
    <div class="item-add-label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartListAddItem",
  props: {
    addItemAction: { type: Function, required: true },
    label: { type: String, default: "Add item" },
    listType: { type: String }
  },
  computed: {
    className() {
      return `${this.listType} item-add`
    }
  }
}
</script>

<style lang="scss" src="./SmartListAddItemStyles.scss" scoped></style>
