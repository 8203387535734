<template>
  <div id="app">
    <div class="container chat-app">
      <div class="chat-box">
        <div class="chat-history">
          <div v-for="item in sortedItems" :key="item['_id']">
            <div v-if="item.type" class="event-wrapper">
              <div class="event-content">
                <small class="text-muted">{{ formatTimestamp(item.timestamp) }}</small>
                <div>{{ formatEvent(item).text }}</div>
              </div>
            </div>
            <div v-else class="message-wrapper" :class="{'text-right is-user': formatMessage(item).isUser}">
              <div class="d-flex align-items-center" :class="{'flex-row-reverse': formatMessage(item).isUser}">
                <div>
                  <div class="message-content" :class="{'bg-primary text-white': formatMessage(item).isUser}" v-html="formatMessage(item).text" />
                  <small class="text-muted">{{ formatMessage(item).timestamp }}</small>
                </div>
              </div>
            </div>
            <div style="clear: both;"></div>
          </div>
        </div>
        <div class="mt-3">
          <div class="chat-input">
            <div class="input-wrapper">
              <textarea
                  placeholder=""
                  v-model="newMessage"
                  @keydown.enter.exact.prevent="sendMessageInternal"
                  @keydown.enter="newLine"></textarea>
            </div>
            <span class="button-wrapper">
              <button class="chat-send" @click="sendMessageInternal"><v-icon name="paper-plane"/></button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "vue-awesome/components/Icon"

function convertEventType(eventType) {
  return eventType
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}
export default {
  name: "Chat",
  components: {
    'v-icon': Icon
  },
  props: {
    messages: { type: Array, default: () => [] },
    events: { type: Array, default: () => [] },
    sendMessage: { type: Function },
  },
  data() {
    return {
      newMessage: '',
      takeoverPrefix: '** LO Takeover: ',
    };
  },
  computed: {
    sortedItems() {
      return [...this.messages, ...this.events].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    }
  },
  methods: {
    formatMessage(message) {
      // incoming message format
      // {
      //     "message": "** LO Takeover: hi, how are you?",
      //     "timestamp": "2024-08-10T16:11:15.846Z",
      //     "sender": "system",
      //     "status": "sent",
      //     "_id": "66b791241e2ada2a2f1c927a"
      // },

      let message_content = message.message;
      let takeOverFlag = false;
      if (message_content.includes('[image:')) {
        const imagePattern = /\[image:(.*?)\]/g;
        message_content = message_content.replace(imagePattern, '<img src="$1" style="max-width: 100%; max-height: 100%;"/>');
      } else {
        message_content = message_content.replace(/(?:\r\n|\r|\n)/g, '<br>');
        message_content = this.wrapLinks(message_content);
        if (message_content.startsWith(this.takeoverPrefix)) {
          message_content = message_content.substring(this.takeoverPrefix.length);
          takeOverFlag = true;
        }
      }
      return {
        text: message_content,
        timestamp: new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        isUser: message.sender == "system" ? true : false,
        takeOverFlag
      };
    },
    formatEvent(event) {
      // incoming event format
      // {
      //     "type": "chat_message",
      //     "timestamp": "2024-08-10T16:11:15.846Z"
      // },
      return {
        text: `${convertEventType(event.type)}`,
        timestamp: new Date(event.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    sendMessageInternal() {
      if (this.newMessage) {
        this.newMessage.trim();
        this.messages.push({
          message: this.newMessage,
          timestamp: new Date().toISOString(),
          sender: 'system',
          status: 'sent',
          _id: Math.random().toString(36).substr(2, 9),
        });
        this.sendMessage(this.newMessage.trim());
        this.newMessage = '';
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatHistory = this.$el.querySelector('.chat-history');
        chatHistory.scrollTop = chatHistory.scrollHeight;
      });
    },
    newLine(event) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
    wrapLinks(message) {
      const urlPattern = /(https?:\/\/[^\s<]+)(?=<br>|\.\s|$)/g;
      return message.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    },
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
.chat-box {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
}
.message {
  margin-bottom: 20px;
}
.message-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  line-height: normal;
  a {
    color: white;
    text-decoration: underline;
  }
  img {
    width: 200px;
    max-width: 100%;
  }
}
.text-muted {
  font-size: 12px;
}
.text-right {
  float: right;
}
.bg-primary {
  background-color: #7367f0;
}
.chat-app {
  background-color: #f8f9fa;
  max-width: fit-content;
}
.chat-history {
  max-height: 500px;
  overflow-y: scroll;
}
.chat-input {
  width: 100%;
  background: #fff;
  padding: 10px;
  input {
    width: 85%;
    padding: 0.426rem 0.9375rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1;
    color: #444050;
    appearance: none;
    background-color: transparent;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    border: none;
  }
  input:focus-visible {
    outline: none;
    border: none;
  }
  .button-wrapper {
    width: 40px;
    display: inline-block;
    float: right;
    .chat-send {
      font-size: 17px;
      color: #7367f0;
      background: none;
      border: none;
      margin-top: 7px;
    }
    button:focus-visible {
      outline: none;
      border: none;
    }
  }
  .input-wrapper {
    display: inline-block;
    width: calc(100% - 40px);
    textarea {
      display: inline-block;
      width: 85%;
      padding: 0.426rem 0.9375rem;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1;
      color: #444050;
      background-color: transparent;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      outline: none;
      border: none;
      resize: none;
      height: 35px; /* Adjust as needed */
      overflow-y: auto;
    }
    textarea:focus-visible {
      outline: none;
      border: none;
    }
  }
}
.message-wrapper {
  max-width: 80%;
}
.event-wrapper {
  text-align: center;
  margin: 10px 0;
}
.event-content {
  background-color: #e9ecef;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}
</style>