var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type == "placeholder"
      ? _c("div", {
          staticClass:
            "loading-placeholder loading-placeholder--animated-background",
          style: _vm.styles
        })
      : _c("div", { staticClass: "d-flex align-items-center loader-wrapper" }, [
          _c("div", {
            staticClass: "loader vertical-align-middle loader-circle",
            attrs: { "data-type": "default" }
          })
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f65a332", { render: render, staticRenderFns: staticRenderFns })
  }
}