import Vue from "vue"
import LeadWorkflowManagerStandalone from "./components/LeadWorkflowManagerStandalone"

export default function() {
  if (!document.getElementById("lead-workflow-manage-app")) {
    return
  }

  // Vuejs setup
  new Vue({
    el: "#lead-workflow-manage-app",
    components: {
      LeadWorkflowManagerStandalone
    }
  })
}
