<doc>
	Add/edit Program Modal Vue.js component
</doc>

<template>
	<BaseModal
		ref="modal"
		v-model="showItemModal"
		:title="modalTitle"
		id="newLinkModal"
		@beforeEnter="beforeEnter"
		@afterEnter="afterEnter"
		@ok="submitForm"
		@cancel="hideModal"
		okLabel="Save"
		:isLoading="isLoading">
		<h5>
			Set your parameters
		</h5>

	</BaseModal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BaseModal from "../BaseModal"
export default {
	name: "LinkModal",
	components: { BaseModal: BaseModal },
	computed: Object.assign({}, mapState(["originalItems", "currentEditedItem", "isEdit"]), {
		showItemModal: {
			get() {
				return this.$store.state.showItemModal
			},
			set(value) {
				this.toggleItemModal(value)
			}
		},
		modalTitle() {
			const prefix = this.isEdit ? "Edit" : "New"
			return `${prefix} Link Package`
		},
		isLoading() {
			return !this.currentEditedItem && this.isEdit
		}
	}),
	methods: Object.assign({}, mapActions(["toggleItemModal", "saveLink"]), {
		beforeEnter() {},
		afterEnter() {},
		hideModal() {},
		submitForm: function() {},
		// Autocomplete
		initAutocomplete: function() {}
	})
}
</script>

<style lang="scss">
#autocomplete-loader {
	position: absolute;
	right: 10px;
	left: auto;
	font-size: 20px;
	top: 28px;
}
#newLinkModal {
	input,
	textarea,
	select {
		font-size: 16px;
	}
	select {
		height: 38px;
	}
	h5 {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.intro {
		margin-bottom: 25px;
	}

	.ui-autocomplete {
		position: absolute !important;
		background: #fff;
		box-shadow: 0 8px 16px 0 rgba(12, 0, 51, 0.1);
		margin-top: 1px;
		border-radius: 3px;
		.ui-menu-item {
			.ui-menu-item-wrapper {
				padding: 8px 16px;
			}
			.ui-state-active {
				background-color: #d5edf1;
			}

			&:first-child {
				.ui-state-active {
					border-radius: 3px 3px 0 0;
				}
			}
			&:last-child {
				.ui-state-active {
					border-radius: 0 0 3px 3px;
				}
			}
		}
	}
}
</style>
