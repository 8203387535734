/**
  API activity wrapper

	GET /api/v1/activity
	query params:
	group_id
	range_start (unix timestamp)
	range_end (unix timestamp)
	limit
**/

const bbOrgActivity = {
	get(ids, range_start, range_end, limit, callback) {
		const url = `/api/v1/activity-legacy?group_id=${ids}&range_start=${range_start}&range_end=${range_end}&limit=${limit}`

		return $.get(url, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		}).fail(function(event, jqxhr, settings, thrownError) {
			if (callback && typeof callback === "function") {
				callback.call(this, thrownError)
			}
		})
	},
	getPairActivity(pid, range_start, range_end, limit, callback) {
		const url = `/api/v1/activity-legacy?type=pair&pair_id=${pid}&limit=${limit}`

		return $.get(url, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
				//callback.call(this, response)
			}
		}).fail(function(event, jqxhr, settings, thrownError) {
			if (callback && typeof callback === "function") {
				callback.call(this, thrownError)
			}
		})
	}
}

export default bbOrgActivity
