import Vue from "vue"
import _orderBy from "lodash/orderBy"
import initStore from "./store/bb-links"
import { MyLinks } from "./pages"

export default function() {
  if (!document.getElementById("vue-links-app")) {
    return
  }
  const store = initStore()
  // initial items
//  store.commit("UPDATE_ORIGINAL_ITEMS", _orderBy(pricing_sets, "weight"))
//  store.commit("SET_LOID", loid)
//  store.commit("SET_BANK_ID", bank_id)
  // Vuejs setup
  new Vue({
    el: "#vue-links-app",
    store,
    components: {
      MyLinks: MyLinks
    }
  })

  // Do we need this? 25/4
  window.dispatchEvent(new Event("resize"))
}

