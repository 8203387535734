var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editorWrapper" }, [
    _c(
      "div",
      { staticStyle: { height: "calc(100% - 50px)" } },
      [
        _c(
          "Tabs",
          [
            _c("Tab", { attrs: { name: "Cards" } }, [
              _c(
                "div",
                { staticClass: "cardsWrapper" },
                [
                  _vm.isLoading
                    ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
                    : _c("div", [
                        _vm.quote && _vm.quote.cards
                          ? _c(
                              "div",
                              _vm._l(_vm.quote.cards, function(card, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "col-sm-3 list-cards"
                                  },
                                  [
                                    _c("CalcCard", {
                                      attrs: {
                                        rate: card.rate,
                                        price: card.price,
                                        term: parseInt(card.amortizationTerm),
                                        loanAmount: _vm.quote.full_request
                                          .loanInformation
                                          ? _vm.quote.full_request
                                              .loanInformation.baseLoanAmount
                                          : _vm.quote.full_request.loan_amount,
                                        type: card.productName,
                                        fee: card.fee,
                                        principalAndInterest:
                                          card.principalAndInterest,
                                        investor: card.investor
                                      }
                                    })
                                  ],
                                  1
                                )
                              })
                            )
                          : _c("div", [
                              _vm._v(
                                "\n              Quote is empty\n            "
                              )
                            ])
                      ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("Tab", { attrs: { name: "Details" } }, [
              _c(
                "div",
                { staticClass: "editorContainer" },
                [
                  _vm.isLoading
                    ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
                    : _c("vjsoneditor", {
                        attrs: { options: _vm.options, plus: true },
                        model: {
                          value: _vm.activeRowLocal,
                          callback: function($$v) {
                            _vm.activeRowLocal = $$v
                          },
                          expression: "activeRowLocal"
                        }
                      })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("Tab", { attrs: { name: "History" } }, [
              _c(
                "ul",
                { staticClass: "list-group list-group-flush" },
                _vm._l(_vm.historicEvents, function(event) {
                  return _c(
                    "li",
                    { key: event.id, staticClass: "list-group-item" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.humanize(event.name)))]),
                      _vm._v(" @ "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatDate(event.timestamp)))
                      ])
                    ]
                  )
                })
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm.activeRowLocal.full_request
          ? _c("div", { staticClass: "copy-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.requestJSONString,
                    expression: "requestJSONString"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.requestJSONString },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.requestJSONString = $event.target.value
                  }
                }
              })
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-modern closeBtn",
          on: { click: _vm.closePanel }
        },
        [_vm._v("Close")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-585b616c", { render: render, staticRenderFns: staticRenderFns })
  }
}