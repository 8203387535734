/**
 * Listing API wrapper
 */

const ENDPOINT = "/api/v1/listings"
const MANUAL_ENDPOINT = "/api/v1/manual-listing"
const REFRESH_ENDPOINT = "/api/v1/refresh-listing"

const bbListingManage = {
  get(params, callback) {
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(listingData, callback) {
    if (!listingData.id) {
      console.log("id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(listingData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  refresh(params, callback){
      return $.get(REFRESH_ENDPOINT, params, function(data) {
          if (callback && typeof callback === "function") {
              callback.call(this, data)
          }
      })
  },
  manual: {
    get(params, callback) {
      return $.get(MANUAL_ENDPOINT, params, function(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      })
    },
    print(params, callback) {
      return $.get(MANUAL_ENDPOINT + "/print", params, function(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      })
    },
    // required fields:
    //    street_addr (string)
    //    city (string)
    //    zipcode (string)
    //    state (string)
    //    owner (int)
    //    data:
    //      listing_price
    //      pictures
    //      num_beds
    //      num_baths
    //      sqft
    //      property_type
    create(listingData, callback) {
      if (!listingData.street_addr || !listingData.data) {
        console.log("id missing")
        return new Promise((resolve, reject) => {
          reject("error")
        })
      }

      if (
        !listingData.data.listing_price ||
        !listingData.data.pictures ||
        !listingData.data.num_beds ||
        !listingData.data.num_baths ||
        !listingData.data.sqft ||
        !listingData.data.property_type
      ) {
        console.log("required listing fields missing")
        return new Promise((resolve, reject) => {
          reject("error")
        })
      }

      return $.ajax({
        url: MANUAL_ENDPOINT,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(listingData),
        success(data) {
          if (callback && typeof callback === "function") {
            callback.call(this, data)
          }
        },
        error(xhr, ajaxOptions, thrownError) {
          if (callback && typeof callback === "function") {
            callback.call(this, thrownError)
          }
        }
      })
    },
    update(listingData, callback) {
      if (!listingData.id) {
        console.log("id missing")
        return new Promise((resolve, reject) => {
          reject("error")
        })
      }
      return $.ajax({
        url: MANUAL_ENDPOINT,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(listingData),
        success(data) {
          if (callback && typeof callback === "function") {
            callback.call(this, data)
          }
        },
        error(xhr, ajaxOptions, thrownError) {
          if (callback && typeof callback === "function") {
            callback.call(this, thrownError)
          }
        }
      })
    },
    delete(id, callback) {
      return $.ajax({
        url: MANUAL_ENDPOINT + "?id=" + id,
        type: "DELETE",
        contentType: "application/json",
        dataType: "json",
        success(data) {
          if (callback && typeof callback === "function") {
            callback.call(this, data)
          }
        },
        error(xhr, ajaxOptions, thrownError) {
          if (callback && typeof callback === "function") {
            callback.call(this, thrownError)
          }
        }
      })
    }
  }
}
export default bbListingManage
