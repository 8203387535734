<doc>
	Company card Vue.js component
</doc>

<template>
	<Card>
		<div>
			<ImgUploader
				:editMode="editMode"
				:previewAlt="logoAlt"
				:previewSrc="org.logo"
        :use-s3="true"
				previewClass="company-logo"
				label="Upload company logo"
				:uploadCallback="uploadCallback" />
			<h3
				class="company-name"
				v-if="!editMode"
				>
				{{org.name}}
			</h3>
			<InputText
				v-if="editMode"
				v-model="formData.name"
				label="Organization Name"
				name="name"
				/>
			<a
				:href="org.website"
				class="company-link"
				target="_blank"
				v-if="!editMode"
			>
			{{website}}
			</a>
			<InputText
				v-if="editMode"
				v-model="formData.website"
				label="Organization website"
				name="website"
				type="url"
				/>

			<div class="text-right">
				<BaseButton
					v-if="editMode"
					type="button"
					v-on:click="setEditMode(false)"
					theme="link"
					size="sm"
					>
					Cancel
				</BaseButton>
				<BaseButton
					v-if="editMode"
					type="button"
					v-on:click="saveFormData()"
					size="sm"
					>
					Save
				</BaseButton>
			</div>

			<BaseButton
				v-if="!editMode"
				class="edit"
				type="button"
				theme="link"
				v-on:click="setEditMode(true)"
				>
				<span class="sr-only">Edit program set</span>
				<icon name="pencil-alt"></icon>
			</BaseButton>
		</div>
	</Card>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { cleanURL } from "../../helpers"
import _isEmpty from "lodash/isEmpty"
import ImgUploader from "../ImgUploader"
import Card from "../Card"
import Dropdown from "../Dropdown"

const { mapState, mapActions } = createNamespacedHelpers("organization")

export default {
	name: "CompanyCard",
	components: {
		Card,
		Dropdown,
		ImgUploader
	},
	data() {
		return {
			isLoading: false,
			editMode: false,
			hasError: '',
			logo_fid: null,
      logo_url: null,
			dd_options: [
				{
					label: "option 1",
					action: () => {
						console.log("Option 1 selected")
					}
				},
				{
					label: "option 2",
					action: () => {
						console.log("Option 2 selected")
					}
				}
			]
		}
	},
	computed: Object.assign({}, mapState(["org"]), {
		website: function() {
			return cleanURL(this.org.website)
		},
		logoAlt: function() {
			return `${this.org.name} logo`
		},
		formData: function() {
			let data = { name: "", website: "" }
			if (!_isEmpty(this.org)) {
				data = { name: this.org.name, website: this.org.website }
			}
			return data
		}
	}),
	watch: {
		org: {
			handler: function() {
				this.setEditMode(false)
			},
			deep: true
		}
	},
	methods: Object.assign({}, mapActions(["saveOrg"]), {
		setEditMode(mode) {
			this.editMode = mode
		},
		saveFormData() {
			if(this.logo_url){
				this.formData.logo = this.logo_url
			}
			this.saveOrg(this.formData)
		},
		uploadCallback(logo_upload_data){
			this.logo_url = logo_upload_data.url
		}
	})
}
</script>

<style lang="scss" src="./CompanyInfoCard.scss">
</style>
