<doc>
	Programs details Vue.js component
</doc>

<template>
	<div :class="className">
		<flex-box
			class="profile-title"
			justify="center"
			alignment="center">
			{{program.title}}
		</flex-box>
		<div class="profile-details" v-on:click="getProgramDetails(program)">
			<span class="profile-toggle">View details</span>
		</div>
		<div class="profile-number">
			<strong>{{profileNumber}}</strong>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions } from "vuex"

export default {
	name: "ProgramProfile",
	props: {
		program: { type: Object, required: true },
		index: { type: Number, required: true },
		isThumb: { type: Boolean, default: false }
	},
	computed: {
		profileNumber() {
			let num = this.index + 1 + ""
			return num.padStart(2, "0")
		},
		className() {
			let className = "profile-program"
			if (this.isThumb) {
				className += " is-thumb"
			}
			return className
		}
	},
	methods: Object.assign(
		{},
                mapActions(["getProgramDetails"]),
		mapMutations({
			setCurrentProgram: "SET_CURRENT_PROGRAM"
		})
	)
}
</script>

<style lang="scss" scoped>
.profile {
	&-program {
		font-size: 12px;
		line-height: 1.5;
		border: 1px solid #ddd;
		border-radius: 4px;
		background-color: #fff;
		position: relative;
		margin: 0 0 15px;

		&.is-thumb {
			font-size: 8px;
			margin-bottom: 5px;
			.list-view & {
				font-size: 4px;
				margin-bottom: 2px;
			}
			.profile {
				&-title {
					height: 42px;
					margin: 3px;
					padding: 3px 4px;
					.list-view & {
						height: 29px;
						padding: 1px;
						margin: 0;
					}
				}
				&-number {
					height: 14px;
					width: 14px;
					strong {
						font-size: 7px;
						letter-spacing: 1px;
						margin-top: -6px;
						margin-left: 8px;
						.list-view & {
							font-size: 5px;
							letter-spacing: 0.5px;
							margin-top: -13px;
							margin-left: 2px;
						}
					}
					&:before {
						top: 4px;
						left: -4px;
						border-left-width: 14px;
						border-right-width: 14px;
						border-bottom-width: 14px;

						.list-view & {
							top: -1px;
							border-left-width: 8px;
							border-right-width: 8px;
							border-bottom-width: 8px;
						}
					}
				}
			}
		}
	}
	&-title {
		background-color: #f0f8fb;
		color: #44b666;
		cursor: move;
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
		height: 60px;
		margin: 5px;
		padding: 5px 6px;
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
	}
	&-details {
		cursor: pointer;
		margin: 5px;
		.list-view & {
			display: none;
		}
	}
	&-toggle {
		display: block;
		text-align: right;
		color: #00b2cb;
		text-transform: capitalize;
	}
	&-number {
		font-size: 11px;
		color: #fff;
		font-weight: 400;
		left: -1px;
		top: -1px;
		width: 35px;
		height: 35px;
		position: absolute;
		line-height: 35px;
		strong {
			position: relative;
			z-index: 5;
			display: block;
			font-size: 10px;
			margin-top: -7px;
			margin-left: 7px;
		}
		&:before {
			position: absolute;
			top: -3px;
			right: 2px;
			content: "";
			width: 0;
			height: 0;
			border-left: 24px solid transparent;
			border-right: 24px solid transparent;
			border-bottom: 24px solid #35a5ba;
			transform: rotate(-45deg);
			z-index: 1;
		}
	}
}
</style>
