var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentEditedItem
    ? _c(
        "draggable",
        {
          attrs: {
            options: {
              chosenClass: "sortable-chosen",
              filter: ".program-set-placeholder",
              handle: ".profile-title"
            }
          },
          on: { end: _vm.onEnd },
          model: {
            value: _vm.pricing_profiles,
            callback: function($$v) {
              _vm.pricing_profiles = $$v
            },
            expression: "pricing_profiles"
          }
        },
        [
          _c(
            "transition-group",
            {
              staticClass: "d-flex draggable-wrapper",
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { tag: "div" }
            },
            _vm._l(_vm.pricing_profiles, function(program, index) {
              return _c(
                "GridColumn",
                {
                  key: program.id,
                  staticClass: "draggable",
                  attrs: {
                    size: ["sm-6", "lg-4"],
                    item: program,
                    "data-program-id": program.id
                  }
                },
                [
                  _c("ProgramProfile", {
                    attrs: { program: program, index: index }
                  })
                ],
                1
              )
            })
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ee744f8a", { render: render, staticRenderFns: staticRenderFns })
  }
}