var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "grid-row",
    [
      _c(
        "grid-column",
        [
          _c("InputTextArea", {
            staticClass: "prop-overview__input",
            attrs: {
              label: "Property Overview",
              name: "overview",
              helperText: "",
              limit: 500
            },
            model: {
              value: _vm.value.overview,
              callback: function($$v) {
                _vm.$set(_vm.value, "overview", $$v)
              },
              expression: "value.overview"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2e62e370", { render: render, staticRenderFns: staticRenderFns })
  }
}