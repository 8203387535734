<doc>
  Step Pictures manual flyer form Vue.js component
</doc>

<template>
  <grid-row>
    <draggable
        tag="v-layout"
        v-model="pictures"
        :move="onMove"
        @end="onEnd"
        @start="onStart"
        class="draggble-container"
    >
    <transition-group class="d-flex draggable-wrapper" tag="div" style="flex-wrap: wrap;">
    <grid-column
      class="img-preview__wrapper"
      :size="colSize"
      v-for="(item, index) in pictures"
      :key="index"
    >
        <Thumb :src="item" type="preview" isResponsive />
        <div v-if="index === 0" class="img-preview__label">
          Cover
        </div>
        <div v-else-if="index > 0 && index<10" class="img-preview__index">
          <span>0{{ index }}</span>
        </div>
      <div v-else class="img-preview__index">
        <span>{{ index }}</span>
      </div>
        <div class="img-preview__buttons">
          <base-button
            size="sm"
            type="button"
            v-on:click="removePhoto(index)"
            btnIcon="regular/trash-alt"
            outline
          >
            <span class="sr-only">Remove image</span>
          </base-button>
        </div>
    </grid-column>
    </transition-group>
    </draggable>
    <grid-column
      class="img-uploader__wrapper"
      :size="colSize"
      v-show="pictures.length < imgLimit"
    >
      <ImgUploader
        type="thumbPrev"
        label="Add picture"
        :uploadCallback="uploadCallback"
        :preview="false"
        onErrorShowAlert
        isResponsive
        useS3
      />
    </grid-column>
  </grid-row>
</template>

<script>
import ImgUploader from "../../ImgUploader"
import Thumb from "../../Thumb"
import draggable from "vuedraggable"

export default {
  name: "FormStepPictures",
  components: {
    ImgUploader,
    Thumb,
    draggable
  },
  data: function() {
    return {
      imgLimit: 5,
      colSize: 12 / 6
    }
  },
  props: {
    pictures: { type: Array, required: true },
    addPhoto: { type: Function, required: true },
    removePhoto: { type: Function, required: true },
    movePhoto: { type: Function, required: true },
    setCover: { type: Function, required: true }
  },
  methods: {
    uploadCallback(data) {
      if (data.url) {
        this.addPhoto(data.url)
      }
    },
    onMove(e){
      // console.log(e)
    },
    onStart(e){
      // console.log(e)
    },
    onEnd(e){
      this.movePhoto(e.oldIndex,e.newIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.draggble-container{
  width:100%
}
.img-preview__wrapper {
  position: relative;
  padding-bottom: 24px;
  cursor: grab;
  height: 142px;
}
.sortable-chosen{
  cursor: grabbing;
}
.img-preview__label {
  text-align: center;
  color: #fff;
  top: 0;
  left: $grid-gutter-width / 2;
  right: $grid-gutter-width / 2;
  position: absolute;
  line-height: 28px;
  height: 28px;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(180deg, #3ad3d5 0%, #009193 100%);
}
.img-preview__index {
  color: #fff;
  background: linear-gradient(
    to bottom right,
    #009193 0%,
    #009193 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: $grid-gutter-width / 2;
  top: 0;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 4px 0 0 0;
  font-size: 12px;

  span {
    position: absolute;
    left: 4px;
    top: -5px;
    font-size: 9px;
  }
}
.img-preview__buttons {
  position: absolute;
  top: 80px;
  left: 25px;
  .btn-outline-primary {
    background: #fff;
  }
}
.img-preview__btn-cover {
  width: 100%;
  margin-top: 8px;
}
.img-uploader__wrapper {
  max-height: 120px;
}
</style>
