var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("div", { staticClass: "program-details-img" }, [
      _c("img", { attrs: { src: _vm.item.url, alt: _vm.item.name + " logo" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "program-overlay" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "btn-view-program",
              attrs: { outline: "" },
              on: { click: _vm.showPrintModal }
            },
            [_vm._v("Print Flyer")]
          ),
          _vm._v(" "),
          _c("SmartListItemOptions", {
            attrs: {
              gridView: _vm.gridView,
              itemIndex: _vm.itemIndex,
              itemId: _vm.item.id,
              onDragEnd: _vm.onDragEnd,
              isLast: _vm.isLast,
              showEditItemModal: _vm.showEditItemModal,
              "options-list": _vm.optList,
              removeItem: _vm.removeItem
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: ["program-details", this.programDetailsStyle] },
      [
        _c("div", { staticClass: "program-details-title" }, [
          _vm._v(_vm._s(_vm.item.name))
        ]),
        _vm._v(" "),
        _c("ManageBadge", {
          attrs: {
            toggleable: "",
            items: _vm.tagsItem,
            showIcon: "",
            isTag: "",
            limit: 3
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4e435d6d", { render: render, staticRenderFns: staticRenderFns })
  }
}