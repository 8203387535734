//bb-dashboard.js
var Mustache = require('mustache');
var Toastr = require('toastr');

var bbDashboard={
    
};

$(document).ready(function(){
    //load add lender modal
    if($('.page-dashboard #find-lender-table').length){
        $.get('/listing_flyer/footable_lenders?gid='+$('#find-lender-table').data('gid'),function(data){
            $('#find-lender-table').footable({
                    "useParentWidth": true,
                    "columns": data.columns,
                    "rows": data.users,
            }).on("postdraw.ft.table",function(e,ft){
                setTimeout(function(){
                    //$('.footable-filtering-search .input-group-btn .btn-primary').removeClass('btn-primary').addClass('btn-default');
                    $('.footable-filtering-search .dropdown-menu li').last().find('input').prop("checked",false).change();
                    $('.footable-filtering-search .dropdown-menu li').last().hide();
                    //do things
                    $(".masqLink").click(function(e){
                        location.href=$(this).data('url');
                    });
                    $(".manageLO").click(function(e){
                        $('#dashboardLOModal').data('loid',$(this).data('uid'));
                        $('#dashboardLOModal .loName').html($(this).data('name'));
                        $('#dashboardLOModal').modal('show');
                        $.get('/manage-pairs.json?loid='+$('#dashboardLOModal').data('loid'),function(data){
                            $('#dashboardLOModal #pairs .loader').addClass('hide');
                            $('#dashboardLOModal .pairsList').html('');
                            $.each(data.pairs, function(index,value){
                                if(value.realtor){
                                    $('#dashboardLOModal .pairsList').append('<li data-pid="'+value.pid+'">'+value.realtor.name+'</li>');                                
                                }
                            });
                        });
                    });
                }, 300);
            });
        });
        $('#dashboardLOModal').on('hidden.bs.modal', function (e) {
            $('#dashboardLOModal .pairsList').html('');
            $('#dashboardLOModal .loader').each(function(index){
                $(this).removeClass('hide');
            });
            $('#dashboardLOModal .statsContent').addClass('hide');
            $('#dashboardLOModal .pairsTab a').trigger('click');
            $('#dashboardLOModal #profile .profileContent').html("");
        });
        $('.statsTab').click(function(e){
            e.preventDefault();
            $.get('/lo-stats.json?loid='+$('#dashboardLOModal').data('loid'),function(data){
                $('#dashboardLOModal #stats .loader').addClass('hide');
                var date=new Date(data.last_login*1000);
                $('.lastLogin .panel-body').html(date.toLocaleString());
                var date=new Date(data.last_seen*1000);
                $('.lastSeen .panel-body').html(date.toLocaleString());
                $('.totalShares .panel-body').html(data.fb_shares.length);
                $('.totalImpressions .panel-body').html(data.total_fb_impressions);
                $('.totalFlyers .panel-body').html(data.total_flyers);
                $('.totalFlyerImpressions .panel-body').html(data.total_flyer_impressions);
                $('#dashboardLOModal .statsContent').removeClass('hide');
                //$('.periscopeEmbed').attr('src',data.charts_url).css('height','1300px').removeClass('hide');
            });
        });
        $('.profileTab').click(function(e){
            e.preventDefault();
            $.get('/lo-manage.json?bb_sid='+window.SESSION_ID+'&loid='+$('#dashboardLOModal').data('loid'),function(data){
                $('#dashboardLOModal #profile .loader').addClass('hide');
                const loProfileTpl = $('#lo-profile-tpl').html();
                const rendered = Mustache.render(loProfileTpl, data.lo);
                $('.profileContent').html(rendered);
            });
        });
                        
        
    }
});

module.exports = bbDashboard;
