const objectFitImages = require('object-fit-images');
const Toastr = require('toastr');
var debounce = require('lodash/debounce');

$(document).ready(function() {
  if(!$(".page-launchpad").length){
    return null
  }
  let editMode = false;
  const form = $('#lauchpad-form');
  const formLoadingOverlay = form.find('.launchpad__loading-overlay');
  const tasksContainer  = $('.launchpad__tasks');
  const editButton = $('.launchpad [data-action="edit-mode"]');
  const cancelButton = $('.launchpad [data-action="cancel-edit-mode"]');
  const saveButton = $('.launchpad [data-action="save"]');
  const showTaskButton = $('.launchpad [data-action="show-task"]');

  const avatarImageEl = form.find('.launchpad__form-avatar img');
  const avatarImageInput = form.find('input[name="picture"]');
  const avatarUploadButton = $('.launchpad__form-avatar-control--upload');
  const avatarRemoveButton = $('.launchpad__form-avatar-control--remove');

  const templateInput = form.find('input[name="template"]');
  const templates = $('.flyer-template-picker__template');
  const swatches = $('.flyer-template-picker__swatch');
  const swatchesList = $('.flyer-template-picker__swatch-list');

  const taskModal = $('#launchpad-task-modal');
  const taskModalTitle = taskModal.find('.modal-title');
  const taskModalBody = taskModal.find('.modal-inner');
  const taskModalLoader = taskModal.find('.loader');

  toggleEditMode(editMode);

  editButton.on('click', (e) => {
    e.preventDefault();

    toggleEditMode(!editMode);
  });

  cancelButton.on('click', (e) => {
    e.preventDefault();

    toggleEditMode(false);
  });

  saveButton.on('click', (e) => {
    e.preventDefault();

    toggleEditMode(!editMode);

    const data = {
      first_name: form[0].first_name.value,
      last_name: form[0].last_name.value,
      email: form[0].email.value,
      phone: form[0].phone.value,
      template: form[0].template.value,
      agentid: form[0].agentid.value,
      all_notifications: form[0].all_notifications.checked
    };

    // TODO: picture_fid = -1 when avatar removed

    if (form[0].picture_fid.value) {
      data.picture_fid = form[0].picture_fid.value;
    }

    formLoadingOverlay.show();

    fetch('/manage-realtor.json', {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'same-origin'
    }).then(res => {
      return res.json();
    }).then(data => {
      Toastr.success('Profile saved!');
      formLoadingOverlay.hide();
    })
    .catch(err => {
      Toastr.error('Error saving profile!');
      console.error(err);
      formLoadingOverlay.hide();
    });
  });

  showTaskButton.on('click', (e) => {
    const $this = $(e.target);
    const parent = $this.parent('.launchpad__task');
    const title = parent.find('.launchpad__task-title').html();
    const description = parent.find('.launchpad__task-description').html();
    const full_description = parent.find('.launchpad__task-full-description').html();

    showModal(title, full_description);
  });

  avatarUploadButton.on('click', (e) => {
    avatarImageInput[0].click();
  });

  avatarImageInput.on('change', (e) => {
    const file = (e.target.files && e.target.files[0]) ? e.target.files[0] : null;

    if (file) {
      const data = new FormData();
      const reader = new FileReader();

      data.append('files[img]', e.target.files[0]);
      data.append('bb_sid', window.SESSION_ID);

      reader.onload = (e) => {
        avatarImageEl.attr('src', e.target.result);
        objectFitImages(avatarImageEl);
      };

      reader.readAsDataURL(file);

      // submitButton.attr('disabled', true);
      // loader.removeClass('hide');
      // text.html('Uploading');

      fetch('/bb-file-upload.json', {
        method: 'POST',
        body: data,
        credentials: 'same-origin'
      }).then(res => {
        return res.json();
      }).then(data => {
        form[0].picture_fid.value = data.message.fid;
        // submitButton.attr('disabled', false);
        // loader.addClass('hide');
      }).catch(err => {
        console.error(err);
        // loader.addClass('hide');
      });
    }
  });

  templates.on('click', function(e) {
    e.preventDefault();

    if (templateInput.prop('disabled')) {
      return;
    }

    const $this = $(this);
    const templateVal = templateInput.val().split('-');

    templates.removeClass('flyer-template-picker__template--active');
    $this.addClass('flyer-template-picker__template--active');

    templateVal[0] = $this.data('tplnum');
    templateInput.val(templateVal.join('-'));

    swatchesList.hide();
    $(`.flyer-template-picker__swatch-list[data-tplnum="${$this.data('tplnum')}"]`).show();
  });

  swatches.on('click', function(e) {
    e.preventDefault();

    if (templateInput.prop('disabled')) {
      return;
    }

    const $this = $(this);
    const templateVal = templateInput.val().split('-');

    swatches.removeClass('flyer-template-picker__swatch--active');
    $this.addClass('flyer-template-picker__swatch--active');

    templateVal[1] = $this.data('swatch');
    templateInput.val(templateVal.join('-'));
  });

  $('.launchpad__form-switch').on('click', function(e) {
    e.preventDefault();
    const $this = $(this);
    const checkbox = $this.find('input');

    checkbox.prop('checked', !checkbox.prop('checked'));

    $this.toggleClass('launchpad__form-switch--active');

    fetch('/manage-realtor.json', {
      method: 'POST',
      body: JSON.stringify({ all_notifications: form[0].all_notifications.checked }),
      credentials: 'same-origin'
    }).then(res => {
      return res.json();
    }).then(data => {
      Toastr.success('Profile saved!');
    })
    .catch(err => {
      Toastr.error('Error saving profile!');
      console.error(err);
    });
  });

  tasksContainer.slick({
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 3,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  });

  function toggleEditMode(mode) {
    const inputs = form.find('input');
    editMode = mode;

    inputs.each((index, input) => {
      input.disabled = !editMode;
    });

    if (editMode) {
      editButton.hide();
      cancelButton.show();
      saveButton.show();
      window.onbeforeunload = function(e) {
        e.returnValue = 'Are you sure you want to navigate away from this page?';
        return 'Are you sure you want to navigate away from this page?';
      };
    } else {
      editButton.show();
      cancelButton.hide();
      saveButton.hide();
      window.onbeforeunload = null;
    }
  }

  function showModal(title, content) {
    taskModalTitle.html(title);
    taskModalBody.html(content);

    taskModalLoader.hide();
    taskModal.modal('show');
  }

  $.get('/api/v1/match-listings?rid='+$('body').data('user_id')+"&auto=true").then(function(data){
      console.log(data);
      if(data.status="success" && data.listings_updated){
      }
      return null;
  });


});
