<doc> Company activity component </doc>

<template>
  <div>
    <Tabs :useUrlFragment="false" outline>
      <Tab name="Activity">
        <SmartList
                rangeFilter
                :hideToolbar="hideToolbar"
                :searchKeys="[
          'lo.name',
          'details.listing.title',
          'details.realtor.name'
        ]"
                :searchQuery="searchQuery"
                :searchQueries="searchQueries"
                :filteredItems="filteredItems"
                :addQuery="addQuery"
                :removeQuery="removeQuery"
                :resetQuery="onresetQuery"
                :items="items"
                :hasAddItem="false"
                :gridView="false"
                :onItemClick="onItemClick"
                listType="activity"
                vuexNamespace="activity"
        />
        <LoaderPlaceholder v-if="isLoading" type="circle" />

        <div v-if="noMoreItems" class="text-center">There is no more items.</div>

        <BaseButton
                outline
                class="view-more-btn pull-right"
                v-if="hideToolbar"
                size="sm"
                isLink
                href="#activity"
        >View more</BaseButton
        >
        <BaseButton
                outline
                class="view-more-btn pull-right d-flex"
                v-if="showLoadMoreBtn"
                type="button"
                size="sm"
                v-on:click="loadMoreItems()"
        >Load more</BaseButton
        >
        <CompanyActivityDetailsModal
                :showModal="showDetailsModal"
                :item="modalItem"
        />
      </Tab>
      <Tab name="Stats">
        <div class="chartContainer">
          <div class="range-btn-wrapper">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-default" :class="{active: range==90}" @click="rangeSet(90)">90 Days</button>
              <button type="button" class="btn btn-default" :class="{active: range==30}" @click="rangeSet(30)">30 Days</button>
              <button type="button" class="btn btn-default" :class="{active: range==7}" @click="rangeSet(7)">7 Days</button>
            </div>
            <div style="clear: both;">
            </div>
          </div>
          <div v-if="embedData" class="dataContent">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">Overall Embed Performance</h3>
                <span class="dataSince">Since {{sinceDate}}</span>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="label">Total Pageviews</div>
                    <div class="value">{{totalEmbedPageviews.toLocaleString()}}</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="label">Total Leads</div>
                    <div class="value">{{totalEmbedLeads.toLocaleString()}}</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="label">Overall Conversion Rate</div>
                    <div class="value">{{totalEmbedConversion}}%</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">
                  <select style="max-width:240px;" v-model="selectedEmbedId">
                    <option v-for="(embed, index) in embedData" :value="embed.id">{{embed.name}}</option>
                  </select>
                </h3>
                <span class="dataSince" style="padding-top:4px;">Since {{sinceDate}}</span>
              </div>
              <div v-if="selectedEmbed" class="panel-body">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="label">Pageviews</div>
                    <div class="value">{{parseInt(selectedEmbed.requests).toLocaleString()}}</div>
                    <div class="notation">{{((parseInt(selectedEmbed.requests)/totalEmbedPageviews)*100).toFixed(1)}}% of total</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="label">Leads</div>
                    <div class="value">{{parseInt(selectedEmbed.leads).toLocaleString()}}</div>
                    <div class="notation">{{((parseInt(selectedEmbed.leads)/totalEmbedLeads)*100).toFixed(1)}}% of total</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="label">Conversion Rate</div>
                    <div class="value">{{selectedEmbed.conversion_rate.toFixed(1)}}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom:20px;" v-else>
            <div v-if="embedDataLoading">
              <LoaderPlaceholder type="circle" />
            </div>
            <div v-else>
              No Data
            </div>
          </div>
          <div v-if="subEmailData && subEmailData.emails">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">Subscription Email Performance</h3>
                <span class="dataSince">Since {{sinceDate}}</span>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="label">Total Emails</div>
                    <div class="value">{{parseInt(subEmailData.emails).toLocaleString()}}</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="label">Total Opens</div>
                    <div class="value">{{parseInt(subEmailData.opens).toLocaleString()}}</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="label">Open Rate</div>
                    <div class="value">{{((subEmailData.opens/subEmailData.emails)*100).toFixed(1)}}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom:20px;" v-else>
            <div v-if="subEmailDataLoading">
              <LoaderPlaceholder type="circle" />
            </div>
            <div v-else>
              No Data
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import CompanyActivityDetailsModal from "./CompanyActivityDetailsModal"
import { Tabs, Tab } from "../Tabs"
import { SmartList } from "../SmartList"
import Moment from "moment"
import { GChart } from "vue-google-charts"
const {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} = createNamespacedHelpers("activity")
export default {
  name: "CompanyActivity",
  components: {
    Tabs,
    Tab,
    SmartList,
    CompanyActivityDetailsModal,
    GChart
  },
  props: {
    title: { type: String },
    hideToolbar: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false,
      modalItem: {},
      subscriptionData: null,
      embedData: null,
      selectedEmbedId: 0,
      range: 30,
      embedDataLoading:false,
      subEmailData: null,
      subEmailDataLoading: false
    }
  },
  mounted() {
    this.getChartData(this.range)
    this.getSubscriptionEmailStats(this.range)
  },
  computed: Object.assign(
    {
      showLoadMoreBtn: function() {
        return (
          !this.hideToolbar ||
          (this.items && this.items.length <= this.prev_limit)
        )
      },
      noMoreItems: function() {
        const condition = this.items && this.items.length == this.prev_length
        if (condition) {
          this.isLoading = false
        }
        return condition
      },
      noItems: function(){
          if(!this.items)this.isLoading = false
      },
      totalEmbedPageviews(){
        return this.embedData.reduce((total, item)=>{
            return total+parseInt(item.requests)
        },0)
      },
      totalEmbedLeads(){
          return this.embedData.reduce((total, item)=>{
              return total+parseInt(item.leads)
          },0)
      },
      totalEmbedConversion(){
          return ( this.totalEmbedPageviews>0 ? ((this.totalEmbedLeads/this.totalEmbedPageviews)*100).toFixed(1) : 0)
      },
      sinceDate(){
          let date=new Date(Date.now() - this.range * 24 * 60 * 60 * 1000)
          return date.toDateString()
      },
      selectedEmbed(){
          return this.embedData.find((elem)=>{return elem.id==this.selectedEmbedId})
      }
    },
    mapState([
      "originalItems",
      "searchQueries",
      "searchQuery",
      "prev_limit",
      "prev_length",
      "showDetailsModal",
      "org"
    ]),
    mapGetters(["currentItems"]),
    {
      items: {
        get() {
          this.isLoading = false
          try {
            return this.currentItems
          } catch (e) {
            console.log(e)
          }
        },
        set(value) {
          /**
           * The "correct" way is commit the update here,
           * but vuedraggable seems to have issues with it.
           * We are doing it on the "onEnd" event
           **/
          // this.$store.commit("updateSets", value)
        }
      }
    }
  ),
  methods: Object.assign(
    {},
    mapActions([
      "filteredItems",
      "addQuery",
      "removeQuery",
      "resetQuery",
      "loadMore",
      "toggleDetailsModal"
    ]),
    {
      onresetQuery() {
        this.resetQuery()
      },
      loadMoreItems() {
        this.isLoading = true
        this.loadMore()
      },
      onItemClick(item) {
        this.modalItem = item
        this.toggleDetailsModal(true)
      },
      async getChartData(range) {
        if (
          this.$store.state.organization &&
          this.$store.state.organization.orgID
        ) {
            this.embedData=null
            this.embedDataLoading=true
          let result = await $.get(
            "/api/v1/embed-stats?range="+range+"&org_id=" +
              this.$store.state.organization.orgID
          )
            if(result && result.data && result.data.length>0){
                this.embedData=result.data.sort((a,b)=>{
                    return ( parseInt(a.requests)<parseInt(b.requests) ? 1 : -1 )
                })
                if(this.embedData[0] && this.embedData[0].id && !this.selectedEmbed)this.selectedEmbedId=this.embedData[0].id
                return
            }
            this.embedDataLoading=false
            this.embedData=null
/*
          let data = await $.get(
            "/api/v1/subscription-chart-data?range=60&org_id=" +
              this.$store.state.organization.orgID
          )
          if (BB.chart.checkForDate(data.data[0]) !== false) {
            this.chartData = BB.chart.convertDate(data.data, 0)
          }
*/
        }
      },
      async getSubscriptionEmailStats(range){
        if (
            this.$store.state.organization &&
            this.$store.state.organization.orgID
        ) {
          this.subEmailData=null
          this.subEmailDataLoading=true
          let result = await $.get(
              "/api/v1/subscription-chart-data/emails?range="+range+"&org_id=" +
              this.$store.state.organization.orgID
          )
          if(result && result.data && result.data.length>0){
            let totals = {
              opens: 0,
              emails: 0
            };
            let total_opens = 0;
            result.data.forEach((item,index) => {
              if(index>0){
                totals.emails+=item[1]
                totals.opens+=item[2]
              }
            })
            this.subEmailData=totals;
          }
          this.subEmailDataLoading=false
          return
        }
      },
      rangeSet(range){
        this.range=range;
        this.getChartData(range)
        this.getSubscriptionEmailStats(range)
      }
    }
  )
}
</script>

<style lang="scss">
.jumbotron {
  background: #fff;
  padding: 40px 25px;
  position: relative;
  color: #0c0033;

  &::before {
    content: "";
    display: block;
    height: 7px;
    width: 100%;
    transform: scaleY(-1);
    border-radius: 0 0 4px 4px;
    background: linear-gradient(63.84deg, #58bec8 0%, #29c9c5 100%);
    position: absolute;
    left: 0;
    top: 0;
  }
  &-title {
    font-size: 30px;
    line-height: 1.8em;
    margin: 0 auto 45px;
    max-width: 777px;
    padding: 0 25px;
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  img {
    max-width: 100%;
  }

  [class^="col"] {
    &:first-child {
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      height: 65%;
      width: 1px;
      background: rgba(196, 196, 196, 0.5);
      display: block;
      position: absolute;
      left: 0;
      top: 16%;
    }
  }
}
.view-more-btn {
  margin: 15px 15px 0 0;
}
.loader-wrapper {
  height: 80px;
}
.bbChart {
  width: 100%;
  height: 400px;
}
  .chartContainer{
    .range-btn-wrapper{
      margin-bottom:20px;
      .btn-group{
        float:right;
      }
    }
    .value{
      font-family: "Segoe UI",Roboto;
      font-weight: bold;
      font-size: 2.5rem;
      display: block;
      line-height: 1;
      margin-top: 1rem;
    }
    .panel-title{
      display: inline-block;
    }
    .dataSince{
      float:right;
      color: #888;
    }
  }
</style>
