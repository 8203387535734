<template>
    <div class="editorContainer">
        <div v-if="monitorLoading">
            Loading...
        </div>
        <div v-else>
            <div v-if="monitorEntries && monitorEntries.length>0">
                <div v-for="(entry, i) in monitorEntries" class="col-sm-6 col-md-4">
                    <div class="thumbnail">
                        <a target="_blank" :href="entry.screen_shot_url"><img :src="entry.screen_shot_url" /></a>
                    </div>
                    <div class="caption">
                        <h3 style="font-size: 15px;">{{entry.datetime}}</h3>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="monitor && monitor.id">
                    No monitor entries yet
                </div>
                <div v-else>
                    <form>
                        <fieldset>
                            <InputText
                                    v-model="newMonitor.config.url"
                                    label="URL"
                                    name="url"
                            />
                            <InputText
                                    v-model="newMonitor.config.delay"
                                    label="Delay"
                                    name="delay"
                            />
                            <InputText
                                    v-model="newMonitor.config.width"
                                    label="Width"
                                    name="width"
                            />
                            <InputText
                                    v-model="newMonitor.config.height"
                                    label="Height"
                                    name="height"
                            />
                            <InputText
                                    v-model="newMonitor.config.offset"
                                    label="Offset"
                                    name="offset"
                            />
                            <BaseButton
                                    type="button"
                                    v-on:click="createMonitor"
                            >
                                Create
                            </BaseButton>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Monitor",
    props:{
        integration: {type: Object}
    },
    components:{},
    created(){
        this.getMonitorInfo()
    },
    data(){
        return{
            monitorEntries: null,
            monitor:null,
            monitorLoading: true,
            newMonitor:{
                app_key: "",
                config: {}
            }
        }
    },
    methods:{
        async getMonitorInfo(){
            this.monitorLoading=true;
            if(!this.integration){
                return
            }
            let intResult=await BB.integration.get(this.integration.id)
            let result=await BB.embedMonitor.get({app_key:intResult.integrations.app_key})
            this.monitor=result.monitor
            this.monitorEntries=result.entries ? result.entries.map(entry=>{
                entry.datetime=new Date(entry.timestamp * 1000).toLocaleString()
                return entry
            }) : null
            this.monitorLoading=false;
        },
        async createMonitor(e){
            this.newMonitor.app_key=this.integration.app_key
            let result=await BB.embedMonitor.add(this.newMonitor)
            if(result.id){
                await this.getMonitorInfo()
            }
        }
    }

}
</script>

<style lang="scss" scoped>

</style>