var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CardToggle",
    { attrs: { title: _vm.titleDesc } },
    [
      !_vm.editMode
        ? _c("p", [_vm._v("\n\t\t" + _vm._s(_vm.formData.about) + "\n\t")])
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode
        ? _c("InputTextArea", {
            attrs: { label: "About", name: "about", noResize: "" },
            model: {
              value: _vm.formData.about,
              callback: function($$v) {
                _vm.$set(_vm.formData, "about", $$v)
              },
              expression: "formData.about"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.editMode
        ? _c("h4", [_vm._v("Marketing Service Agreement:")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.editMode
        ? _c("p", [_vm._v("\n\t\t" + _vm._s(_vm.org.msa_template) + "\n\t")])
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode
        ? _c("InputTextArea", {
            attrs: {
              label: "Marketing Service Agreement",
              name: "msa_template",
              noResize: ""
            },
            model: {
              value: _vm.formData.msa_template,
              callback: function($$v) {
                _vm.$set(_vm.formData, "msa_template", $$v)
              },
              expression: "formData.msa_template"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.editMode ? _c("h4", [_vm._v("Disclaimer:")]) : _vm._e(),
      _vm._v(" "),
      !_vm.editMode
        ? _c("p", [_vm._v("\n\t\t" + _vm._s(_vm.org.disclaimer) + "\n\t")])
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode
        ? _c("InputTextArea", {
            attrs: { label: "Disclaimer", name: "disclaimer", noResize: "" },
            model: {
              value: _vm.formData.disclaimer,
              callback: function($$v) {
                _vm.$set(_vm.formData, "disclaimer", $$v)
              },
              expression: "formData.disclaimer"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _vm.editMode
            ? _c(
                "BaseButton",
                {
                  attrs: { type: "button", theme: "link", size: "sm" },
                  on: {
                    click: function($event) {
                      _vm.setEditMode(false)
                    }
                  }
                },
                [_vm._v("\n\t\t\tCancel\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editMode
            ? _c(
                "BaseButton",
                {
                  attrs: { type: "button", size: "sm" },
                  on: {
                    click: function($event) {
                      _vm.saveFormData()
                    }
                  }
                },
                [_vm._v("\n\t\t\tSave\n\t\t")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.editMode
        ? _c(
            "BaseButton",
            {
              staticClass: "edit",
              attrs: { type: "button", theme: "link" },
              on: {
                click: function($event) {
                  _vm.setEditMode(true)
                }
              }
            },
            [
              _c("span", { staticClass: "sr-only" }, [
                _vm._v("Edit program set")
              ]),
              _vm._v(" "),
              _c("icon", { attrs: { name: "pencil-alt" } })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-51135b7a", { render: render, staticRenderFns: staticRenderFns })
  }
}