<doc>
  Static Flyer details Vue.js component
</doc>

<template>
  <div :class="className">
    <div class="program-details-img">
      <img :src="item.url" :alt="`${item.name} logo`" />
      <div class="program-overlay">
        <BaseButton outline class="btn-view-program" v-on:click="showPrintModal"
          >Print Flyer</BaseButton
        >
        <SmartListItemOptions
          :gridView="gridView"
          :itemIndex="itemIndex"
          :itemId="item.id"
          :onDragEnd="onDragEnd"
          :isLast="isLast"
          :showEditItemModal="showEditItemModal"
          :options-list="optList"
          :removeItem="removeItem"
        />
      </div>
    </div>
    <div :class="['program-details', this.programDetailsStyle]">
      <div class="program-details-title">{{ item.name }}</div>
      <ManageBadge toggleable :items="tagsItem" showIcon isTag :limit="3" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import SmartListItemOptions from "../SmartList/SmartListItemOptions"
import ManageBadge from "../ManageBadge"

const { mapState, mapActions } = createNamespacedHelpers("staticFlyers")

import _ from "lodash"
export default {
  name: "StaticFlyerDetails",
  components: {
    SmartListItemOptions,
    ManageBadge
  },
  props: {
    item: { type: Object, required: true },
    itemIndex: { type: Number, required: true },
    isLast: { type: Boolean, default: false },
    onDragEnd: { type: Function },
    displayFlex: { type: Boolean, default: false }
  },
  computed: Object.assign({}, mapState(["gridView"]), {
    className: function() {
      let className = "program"
      if (!this.gridView) {
        className += " list-view d-flex"
      }
      if (this.$mq == "sm" || this.$mq == "md") {
        className += " not-desktop"
      }
      return className
    },
    programDetailsStyle: function() {
      if (this.displayFlex) {
        return "program-details--flex"
      }
    },
    imgSrc: function() {
      let src = [
        "/sites/all/themes/bankingbridge_ui/images/programs",
        "-mini",
        ".png"
      ]
      if (this.gridView) {
        src.splice(1, 1)
      }
      return src.join("")
    },
    isDefault: function() {
      if (!this.item || !this.$store.state.default_set) return false
      return this.item.id == this.$store.state.default_set.id
    },
    optList: function() {
      return ["none"]
    },
    tagsItem: function() {
      if (this.item.hasOwnProperty("tags")) {
        if (Array.isArray(this.item.tags)) {
          return this.item.tags
        } else {
          return []
        }
      } else {
        return []
      }
    }
  }),
  methods: Object.assign(
    {},
    mapActions([
      "showEditItemModal",
      "showPrintStaticFlyerModal",
      "removeStaticFlyer"
    ]),
    {
      showPrintModal() {
        this.showPrintStaticFlyerModal(this.item.id)
      },
      removeItem() {
        this.removeStaticFlyer({ id: this.item.id, index: this.itemIndex })
      }
    }
  )
}
</script>

<style lang="scss" src="./StaticFlyerDetailsStyles.scss" scoped></style>
