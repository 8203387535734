<template>
  <div class="rich-text-editor form-group material-input typed">
    <label :for="name" class="input-label">
      {{ label }}
    </label>
    <Editor v-model="val" v-on:input="onInput" />
  </div>
</template>

<script>
import Editor from "./Editor"

export default {
  name: "RichTextEditor",
  components: {
    Editor
  },
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
    name: { type: String, default: "" },
    onInput: { type: Function, default: () => {} }
  },
  computed: {
    val: {
      get: function() {
        return this.value + ""
      },
      set: function(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>

<style lang="scss" src="../Forms.scss" scoped></style>
