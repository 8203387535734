<doc>
  States drop down Vue.js component
</doc>

<template>
  <DropDown
    :label="label"
    :name="name"
    v-model="value"
    :options="USStates"
    v-on:dropDownChange="onInput"
    :readonly="readonly"
    :labelClassName="labelClassName"
  />
</template>

<script>
import { USStates } from "../../helpers"
export default {
  name: "StatesDropDown",
  props: {
    value: { type: String },
    label: { type: String, required: true },
    name: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    onInput: { type: Function, required: true },
    labelClassName: { type: String },
  },
  data: function() {
    return {
      USStates: USStates
    }
  }
}
</script>

<style lang="scss" scoped></style>
