var Toastr = require("toastr");
var Mustache = require('mustache');

$(document).ready(function() {
    $('#editModal').on("hidden.bs.modal", function(){
        $(this).find('ul.pictureList').html("");
    });
    //debugger;
    if($(".page-listing").length){
        $('.page-content').removeClass("col-md-9").addClass("col-md-12");

        //var loaderHTML='<div class="loader vertical-align-middle loader-circle" data-type="default"></div>';

      $('.facebook-square').click(async function(event){
          event.preventDefault();
          let _this=this;
          $(_this).find(".loader").removeClass('hide');
          $(_this).addClass('loading');
          await $(this).initFbShare();
          $(_this).find(".loader").addClass('hide');
          $(_this).removeClass('loading');
      });
        let pricing_url_str='/listing_flyer/listing_financial_callback?nid='+jQuery('.loanContainer').data('nid');
        if(jQuery('.loanContainer').data('listing_type')=="manual"){
            pricing_url_str='/listing_flyer/listing_financial_callback?manual_listing_id='+jQuery('.loanContainer').data('nid');
        }
        $.get(pricing_url_str, function(html){
            insertLoanCards(html)
        });
        let closeForm=(formDiv)=>{
            formDiv.slideUp()
        }
        $('.loForm .submit-btn').click(async function(e){
            e.preventDefault();
            let formVals=$('.loForm').serializeArray();
            let emptyFields=false;
            //console.log(formVals);
            let postBody={}
            postBody.details={}
            $.each(formVals, function(index,field){
                if(!field.value){
                    emptyFields=true;
                }
                if(field.name=="email" || field.name=="phone"){
                    postBody[field.name]=field.value
                }
                else{
                    postBody.details[field.name]=field.value
                }
            });
            postBody.source_id=$("#bbListingFlyer").data("listing-id");
            postBody.source=$("#bbListingFlyer").data("listing-type")+"_listing";
            postBody.owner=$("#bbListingFlyer").data("loid");
            if(!emptyFields){
                let res=null;
                try{
                    res=await BB.leadManage.add(postBody)
                }
                catch (e) {
                    Toastr.error("Hmm.. Something went wrong. Try sending it again.");
                    closeForm($(".contact-agent-form.loForm"))
                    return
                }
                if(res && res.status=="success"){
                    Toastr.success("Loan Officer has been contacted.");
                    closeForm($(".contact-agent-form.loForm"))
                }
                else{
                    Toastr.error("Hmm.. Something went wrong. Try sending it again.");
                }
            }
            else{
                Toastr.error("Make sure you fill out all of the fields.");
            }
        });
        $('.realtorForm .submit-btn').click(async function(e){
            e.preventDefault();
            let formVals=$('.realtorForm').serializeArray();
            let emptyFields=false;
//            console.log(formVals);
//            console.log($('.realtorForm').serialize());
            let postBody={}
            postBody.details={}
            $.each(formVals, function(index,field){
                if(!field.value){
                    emptyFields=true;
                }
                if(field.name=="email" || field.name=="phone"){
                    postBody[field.name]=field.value
                }
                else{
                    postBody.details[field.name]=field.value
                }
            });
            postBody.source_id=$("#bbListingFlyer").data("listing-id");
            postBody.source=$("#bbListingFlyer").data("listing-type")+"_listing";
            postBody.owner=$("#bbListingFlyer").data("loid");
            postBody.details.rid=$("#bbListingFlyer").data("rid");
            if(!emptyFields){
                let res=null;
                try{
                    res=await BB.leadManage.add(postBody)
                }
                catch (e) {
                    Toastr.error("Hmm.. Something went wrong. Try sending it again.");
                    closeForm($(".contact-agent-form.realtorForm"))
                    return
                }
                if(res && res.status=="success"){
                    Toastr.success("Realtor has been contacted.");
                    closeForm($(".contact-agent-form.realtorForm"))
                }
                else{
                    Toastr.error("Hmm.. Something went wrong. Try sending it again.");
                }
            }
            else{
                Toastr.error("Make sure you fill out all of the fields.");
            }
        });
        //TODO: collect info on .bbAppLink click (data-listing_nid and data-lo_uid)
    }
});

var insertLoanCards = function(html){
    $('.loanContainer').html(html)
    //var content = document.querySelector('#tooltipContent')
    /*
    const tip=BB.Tippy('.trendArrow', {
        placement: 'top',
        html: function(el){
            return "#tooltipContent"
        },
        onShow: function () {
            const tipTpl=$('#tooltipContent').html();
            var trendStr="";
            var trendIntro="";
            if($(this._reference).data().trend_slope<0){
                //trendStr="The payment is $"+Math.abs($(this._reference).data().payment_spread).toFixed(0)+"/mo less now compared to last month.";
                trendStr='Rates have been going down.';
            }
            else if($(this._reference).data().trend_slope>0){
                //trendStr="The payment is $"+Math.abs($(this._reference).data().payment_spread).toFixed(0)+"/mo more now compared to last month.";                        
                trendStr='Rates have been going up.';
            }
            else{
                trendStr="The rate for this loan have been steady for the last several months.";                        
            }
            const tplData={
                trend_slope: ($(this._reference).data().trend_slope).toFixed(4),
                trend_str: trendStr,
            }
            const rendered = Mustache.render(tipTpl, tplData);
            $(this).find('.tippy-content').html(rendered);
        }
    });
    */

    $('#loanInfoModal').on("hidden.bs.modal", function(){
        $(this).find('.loan-details .table-container').html("");
        $(this).find('.loan-details').addClass('hide');
        $(this).find('.step1').removeClass('hide');
        $(this).find('.step2').addClass('hide');
        $(this).find('.step2 form')[0].reset();
        $('#loanInfoModal .send-msg').attr('disabled',false);                
    });
    if($('.loan-offers').data('monthly_amount_preview').length>0){
        $('.financingPreview').html("Financing: $<span class='monthlyAmount'>"+$('.loan-offers').data('monthly_amount_preview')+"</span>/<span>mo</span>");            
    }
    $('#loanInfoModal .msg-lo').click(function(e){
        e.preventDefault();
        $('#loanInfoModal .step1').addClass('hide');
        $('#loanInfoModal .step2').removeClass('hide');
        if(BB.Cookie.get('leadEmail'))$("#loanInfoModal form #emailInput").val(BB.Cookie.get('leadEmail'));
    });
    $('#loanInfoModal .back-btn').click(function(e){
        e.preventDefault();
        $('#loanInfoModal .step1').removeClass('hide');
        $('#loanInfoModal .step2').addClass('hide');
    });
    $('#loanInfoModal .send-msg').click(function(e){
        e.preventDefault();
        if($('#loanInfoModal .send-msg').attr('disabled')){
            return null;
        }
        $('#loanInfoModal .send-msg').attr('disabled',true);
        var leadInfo={};
        leadInfo.details=$(this).data('loanDetails');
        leadInfo.loid=$('#loanInfoModal').data('loid');
        leadInfo.source_id=$('#loanInfoModal').data('listing_id');
        leadInfo.source='listing-pricing';
        //var loid=$(this).data('loid');
        $('#loanInfoModal .step2 form').serializeArray().forEach(function(element){
            if(element.name==="name"){
                leadInfo.name=element.value;
            }
            if(element.name==="email"){
                leadInfo.email=element.value;
            }
            if(element.name==="phone"){
                leadInfo.phone=element.value;
            }
        });
        if(leadInfo.name && leadInfo.loid){
            $.ajax({
                url: '/lead-manage.json',
                type: 'POST',
                contentType:'application/json',
                dataType:'json',
                data: JSON.stringify(leadInfo),
                success: function(data){
                    Toastr.success("Thank you for contacting us. We will be in touch shortly.");
                    $('#loanInfoModal .step2 form')[0].reset();
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    //On error do this
                    if (xhr.status == 200) {
                        Toastr.success("Thank you for contacting us. We will be in touch shortly.");
                        //alert(ajaxOptions);
                    }
                    else {
                    }
                }
            });                    
        }
        //console.log($('#loanInfoModal .step2 form').serializeArray());
    });
    //setup loanInfo modal
    $('.loanInfoBtn').addClass("hide")
    $('.loanInfoBtn').click(function(e){
        e.preventDefault();
        //refresh modal
        $('.quotePdf').addClass('hide');
        $('.archDisclaimer').text("");
        var arch_log_id=$(this).data("arch_log_id");
        var log_id=$(this).data("log_id");
        $('.arch-info').addClass('hide');
        $('.loader').removeClass('hide');
        var loanTitle=$(this).data("loan_title");
        $('#loanInfoModal .loanTitle').text(loanTitle);
        //$.get('/pricing_log_json?ob='++'&arch='++'&bb_sid=<bb sid>',function(data){
        $.get('/api/v1/rateflow-log?id='+log_id+'&bb_sid='+window.SESSION_ID,function(data){
            $('.loader').addClass('hide');
            $('.loan-details').removeClass('hide');
            if(arch_log_id>0){
                $('.arch-info').removeClass('hide');
                $('.archDisclaimer').text(data.arch_log_entry.response.disclaimer);
                $('.quotePdfLink').attr("href",data.arch_log_entry.response.pdfURL);
                $('.quotePdf').removeClass('hide');
            }
            //TODO: add meaningful loan info
            var pricing=JSON.parse(data.ob_log_entry.formatted_pricing);
            pricing[0].closingCost=pricing[0].closingCost.toLocaleString();
            pricing[0].discount=pricing[0].discount.toLocaleString();
            var loanDetails={
                term: data.pricing_profile.ob_loan_term,
                credit: data.pricing_profile.ob_min_credit,
                dpp: data.pricing_profile.ob_dp_percent,
                dp: (data.ob_log_entry.listing_price*data.pricing_profile.ob_dp_percent/100).toLocaleString(),
                pricing: pricing[0],
                bb_apr: data.ob_log_entry.bb_apr,
                listprice: parseInt(data.ob_log_entry.listing_price).toLocaleString(),
                loan_amt: (data.ob_log_entry.listing_price-data.ob_log_entry.listing_price*data.pricing_profile.ob_dp_percent/100).toLocaleString(),
                title: data.pricing_profile.title,
                id: data.ob_log_entry.id
            };
            $('#loanInfoModal .send-msg').data('loanDetails',loanDetails);
            var realtorTpl = $('#loan-details-tpl').html();
            var rendered = Mustache.render(realtorTpl, loanDetails);
            $('.loan-details .table-container').html(rendered);
        });
    });    
}

var Flickity = require("flickity")
var FliNav = require("flickity-as-nav-for")

var bbHelper = require("./bbHelper");

var debounce = require("lodash/debounce")

const bbListingFlyer = {
	init: () => {
            const carouselMain = new Flickity(".carousel-main", {
                pageDots: false
            });
            const carouselNav = new Flickity(".carousel-nav", {
                asNavFor: ".carousel-main",
                contain: true,
                pageDots: false
            });
            var slider = $("#creditRange")[0];
            BB.uiSlider.create(slider, {
                start: [650],
                connect: [true, false],
                range: {
                        min: 300,
                        max: 850
                }
            });
            slider.noUiSlider.on("update", function(values, handle, unencoded, tap, positions) {
                $("#leadCaptureModal form #inputCreditScore").val(Math.round(values[0]));
            });
            $("#inputCreditScore").keyup(
                debounce(function() {
                        slider.noUiSlider.set(parseInt($(this).val()));
                }, 1000)
            );
            $("#leadCaptureModal").on("hidden.bs.modal", function() {
                $("body").removeClass("leadCapActive");
                //$(".loan-offers").removeClass("hide");
                //$('.loanContainer').html('');
                var param_str="";
                if($("#leadCaptureModal form #inputCreditScore").val()){
                    param_str="&min_credit="+$("#leadCaptureModal form #inputCreditScore").val();
                }
                $.get('/listing_flyer/listing_financial_callback?nid='+jQuery('.loanContainer').data('nid')+param_str, function(html){
                    insertLoanCards(html);
                    $(".loanContainer").removeClass("hide");
                });
                $(this).prop("disabled", false);
            });
            $("#leadCaptureModal").on("shown.bs.modal", function() {
                BB.leadManage.isConnected(connected=>{
                    if(connected){
                        BB.leadManage.getFBLead(lead=>{
                            $('.fb-validated img').attr('src',"https://graph.facebook.com/"+lead.id+"/picture?type=large&width=720&height=720")
                            $('.fb-validated #leadName').html(lead.name);
                            $('.fb-validated #leadEmail').html(lead.email);
                            $('.fb-validated').data('fbLead',lead);
                            $('#leadCaptureModal #inputEmail').val(lead.email);
                            $('.validate-options').addClass('hide');
                            $('.fb-validated').removeClass('hide');
                        })
                    }
                    else{
                        $('.validate-options').removeClass('hide');
                    }
                });
                $("#leadCaptureModal form #inputEmail").val(BB.Cookie.get("leadEmail"));
            });
            $(".get-rates").click(function() {
                    $(this).prop("disabled", true);
                    if (!$("#leadCaptureModal form #inputCreditScore").val()) {
                            BB.Toastr.error("Credit score is required");
                            $(this).prop("disabled", false);
                    }
                    if ($("#leadCaptureModal form #inputEmail").val()) {
                            $.get(
                                "/api/v1/validate-email?bb_sid=" +
                                        window.SESSION_ID +
                                        "&email=" +
                                        $("#leadCaptureModal form #inputEmail").val(),
                                function(data) {
                                    // skip validation if FB lead is present
                                    if (data.status == "valid" || $('.fb-validated').data('fbLead').email) {
                                            BB.Cookie.set("leadEmail", $("#leadCaptureModal form #inputEmail").val());
                                            var currentLoans=[];
                                            $('.loanInfoBtn').each(function(){
                                                currentLoans=[...currentLoans,$(this).data('ob_api_log_id')]
                                            });
                                            let details={
                                                credit_score: $("#leadCaptureModal form #inputCreditScore").val(),
                                                current_loans: currentLoans
                                            }
                                            let fb_lead=$('.fb-validated').data('fbLead')
                                            if(fb_lead){
                                                details={...details,fb_lead}
                                            }
                                            var leadInfo={
                                                email: $("#leadCaptureModal form #inputEmail").val(),
                                                details,
                                                owner: $("#leadCaptureModal").data("loid"),
                                                source: "listing",
                                                source_id: $("#leadCaptureModal").data("listing_id")
                                            };
                                            if(window.IMPRESSION){
                                                leadInfo=Object.assign(leadInfo,{source_id:window.IMPRESSION.source_id,source:window.IMPRESSION.source});
                                            }
                                            BB.leadManage.add(
                                                    leadInfo,
                                                    function(data) {
                                                            console.log("lead added")
                                                    }
                                            );
                                            $("#leadCaptureModal").modal("hide");
                                    } else {
                                            BB.Toastr.error("Email is not valid");
                                            $(this).prop("disabled", false);
                                    }
                                }
                            );
                    } else {
                            BB.Toastr.error("Email is required")
                            $(this).prop("disabled", false);
                    }
            })
            if($("#bbListingFlyer").hasClass("enableLeadCapture")){
                setInterval(function() {
                    $("body").addClass("leadCapActive")
                    if (
                        $(".loan-offers").length &&
                        $(".loan-offers").isInViewport() &&
                        !$("#leadCaptureModal").is(":visible") &&
                        !$("body").hasClass("logged-in") &&
                        !BB.Cookie.get("leadEmail")
                    ) {
                        $('.loanContainer').addClass("hide");
                        $("#leadCaptureModal").modal("show");
                    }
                }, 5000)
            }

	}
}

module.exports = {
	init: bbListingFlyer.init
}
