<doc>
    LeadRateComparison Vue.js component
</doc>

<template>
    <div v-if="quote_id>0" class="vue-container">
        <div class="col-md-12">
            <button v-if="!inProgress" @click="onClick">{{label}}</button>
        </div>
        <div v-if="comparison" class="col-sm-3 comparison-info">
            <CalcCard
                    :rate="newQuote.rate"
                    :price="newQuote.price"
                    :loanAmount="loanAmount"
                    :fee="newQuote.fee"
                    :principalAndInterest="newQuote.principalAndInterest"
                    :investor="newQuote.investor"
                    :comparison="comparison"
            />
        </div>
    </div>
</template>

<script>
    import CalcCard from "../components/CalcCard"
    //console.log("in script...");
    export default {
        name: "lead-rate-comparison",
        props: {
            quote_id: Number
        },
        components: {
            CalcCard
        },
        computed: {
            newQuoteHeader(){
                return ( this.comparison && this.comparison.relative_value>0 ? "panel-success" : "panel-default")
            }
        },
        data() {
            return {
                label: "Check Rates",
                comparison: null,
                newQuote: null,
                inProgress: false,
                loanAmount: 0
            }
        },
        mounted(){
            console.log("in mounted...");
        },
        methods: {
            async onClick(){
                BB.Toastr.info("Getting New Rates ...");
                this.inProgress=true;
                let result=await this.compareRates(this.quote_id)
                this.inProgress=false;
                if(!result.selected_product)return
                BB.Toastr.success("Finished!");
                this.comparison=result
                this.newQuote=result.selected_product;
                this.newQuote.fee.amount=this.newQuote.fee.amount
                this.newQuote.principalAndInterest=this.newQuote.principalAndInterest
                this.loanAmount=( this.comparison.request.loanInformation.baseLoanAmount ? this.comparison.request.loanInformation.baseLoanAmount : this.comparison.request.loanInformation.loanAmount)
            },
            compareRates(quote_id){
                return BB.rateflow.compare(quote_id)
            }
        }
    }
</script>

<style lang="scss">
    .comparison-info{
        margin-top: 20px;
    }
    .value-arrow{
        float: right;
    }
</style>
