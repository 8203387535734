var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("pricing-set-tool", {
        attrs: {
          "loan-info": _vm.loanInfo,
          "pricing-config": _vm.pricingConfig,
          "set-pricing": _vm.setPricing,
          "toggle-parent-btns": _vm.toggleParentBtns
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f78f9c7", { render: render, staticRenderFns: staticRenderFns })
  }
}