<doc>
  Input text Vue.js component
</doc>

<template>
  <div :class="wrapperClass">
    <label :for="name" :class="labelClass">
      {{ label }}
    </label>
    <input
      ref="input"
      :class="inputClassName"
      :type="type"
      :id="name"
      :aria-describedby="describedby"
      :placeholder="placeholder || ''"
      v-model="searchAddress"
      v-on:focus="onInputFocused"
      v-on:blur="onBlur"
      @keyup.enter="$emit('keyupEnter', $event)"
      @keyup.esc="$emit('keyupEsc', $event)"
    />
    <small v-if="helpText" :id="describedby" class="form-text text-muted">
      {{ helpText }}
    </small>
  </div>
</template>

<script>
import { props, updated } from "./inputCommons"

export default {
  name: "InputPlaces",
  props: props,
  data() {
    return {
      searchAddress: ""
    }
  },
  computed: {
    wrapperClass: function() {
      let className = "form-group"
      if (this.isMaterial) {
        className += " material-input"
      }

      return className
    },
    labelClass: function() {
      let className = this.isMaterial ? "input-label" : ""
      if (this.hideLabel) {
        className += " sr-only"
      }
      return className
    },
    inputClassName: function() {
      let className = `form-control ${this.inputClass}`
      if (this.size) {
        className += ` form-control-${this.size}`
      }
      return className
    }
  },
  methods: {
    setFocus() {
      this.$refs.input.focus()
    },
    onFocus() {
      this.$refs.input.parentElement.classList.add("focus")
    },
    onBlur() {
      this.$refs.input.parentElement.classList.remove("focus")
    },
    onInput(val) {
      // send the value to the parent
      this.$emit("onInputPlaces", val)
      this.valuCheck(val)
      this.searchAddress = ""
    },
    valuCheck(val) {
      const $input = this.$refs.input
      let parent = $input.parentElement
      if (val == "") {
        parent.classList.remove("typed")
      } else {
        parent.classList.add("typed")
      }
    },
    fillInAddress() {
      const componentForm = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        administrative_area_level_2: "short_name",
        country: "long_name",
        postal_code: "short_name"
      }

      const address = {
        value: this.$refs.input.value
      }
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace()

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0]
        if (componentForm[addressType]) {
          const val = place.address_components[i][componentForm[addressType]]
          address[addressType] = val
        }
      }
      this.onInput(address)
    },
    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    geolocate() {
      const autocomplete = this.autocomplete
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          const circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          })
          autocomplete.setBounds(circle.getBounds())
        })
      }
    },
    onInputFocused() {
      this.onFocus()
      this.geolocate()
    }
  },
  mounted() {
    this.valuCheck(this.$refs.input.value)

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    this.autocomplete = new google.maps.places.Autocomplete(this.$refs.input, {
      types: ["geocode"],
      componentRestrictions: { country: "us" }
    })

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(["address_component"])

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.addListener("place_changed", this.fillInAddress)
  },
  updated: updated
}
</script>

<style lang="scss" src="./Forms.scss" scoped></style>
