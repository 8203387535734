import Vue from "vue"
import Vuex from "vuex"
import smartlist from "../modules/smartlist"
import bbStaticFlyers from "../modules/bb-static-flyers"
import bbManualListing from "../modules/bb-manual-listing"
import tags from "../modules/tags"

import { createLogger } from "../../helpers"
import _merge from "lodash/merge"

const initStore = () => {
  Vue.use(Vuex)
  const debug = process.env.NODE_ENV !== "production"
  const staticFlyersStates = _merge(
    {},
    smartlist.state(),
    bbStaticFlyers.state,
    tags.state
  )
  const manualListingStates = _merge(
    {},
    smartlist.state(),
    bbManualListing.state
  )
  const modulesStaticFlyers = _merge({}, smartlist, bbStaticFlyers, tags)
  modulesStaticFlyers.state = staticFlyersStates
  const modulesManualListing = _merge({}, smartlist, bbManualListing)
  modulesManualListing.state = manualListingStates

  return new Vuex.Store({
    modules: {
      staticFlyers: {
        namespaced: true,
        ...modulesStaticFlyers
      },
      manualListing: {
        namespaced: true,
        ...modulesManualListing
      },
      activity: {
        namespaced: true
      }
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
  })
}
export default initStore
