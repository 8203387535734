<doc>
	Company card Vue.js component
</doc>

<template>
  <CardToggle :title="titlePeople">
    <div v-for="(group, key) in members" :key="key">
      <AvatarList :items="group.members" :limit="5" />
    </div>
    <FlexBox class="btn-wrapper" justify="between">
      <BaseButton outline type="button" size="sm" :clickHandler="() => {}">
        + Add People
      </BaseButton>
      <BaseButton isLink type="button" size="sm" theme="link" href="#people">
        View all {{ membersCount }} people
      </BaseButton>
    </FlexBox>
  </CardToggle>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import CardToggle from "../Card/CardToggle"
import AvatarList from "../AvatarList"

const { mapState, mapActions } = createNamespacedHelpers("organization")

export default {
  name: "CompanyPeolple",
  components: {
    CardToggle: CardToggle,
    AvatarList: AvatarList
  },
  computed: Object.assign({}, mapState(["members", "membersCount", "org"]), {
    titlePeople: function() {
      return `People at ${this.org.name}`
    }
  })
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  margin-top: 25px;
}
</style>
