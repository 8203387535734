import Vue from "vue"
import Vuex from "vuex"
import smartlist from "../modules/smartlist"
import bbRates from "../modules/bb-rates"
import { createLogger } from "../../helpers"
import _merge from "lodash/merge"

const initStore = () => {
	Vue.use(Vuex)

	const debug = process.env.NODE_ENV !== "production"

	//	const modulesRates = _merge({}, smartlist, bbRates)

	const states = _merge({}, smartlist.state(), bbRates.state)
	const modulesRates = _merge({}, smartlist, bbRates)
	modulesRates.state = states

	return new Vuex.Store({
		...modulesRates,
		strict: debug,
		plugins: debug ? [createLogger()] : []
	})
}
export default initStore
