<doc>
  Confirm Modal Vue.js component
  Props:
    - showConfirm: boolean to show or hide modal
    - title: String value for the title (optional)
    - text: String value for the content (required)
    - v-on:confirm callback for the confirm action
</doc>

<template>
  <BaseModal
    class="alert-modal"
    small
    ref="modal"
    v-model="showAlert"
    @ok="confirm"
    okLabel="OK"
    :showCancelBtn="false"
  >
    <div :class="iconClass"><icon :name="iconName"></icon></div>
    <p>{{ text }}</p>
  </BaseModal>
</template>

<script>
import BaseModal from "../BaseModal"
export default {
  name: "AlertModal",
  props: {
    showAlert: { type: Boolean },
    text: { type: String, required: true },
    status: { type: String, default: "error" }
  },
  components: { BaseModal: BaseModal },
  computed: {
    iconName: function() {
      return this.status === "error" ? "exclamation-triangle" : "check-circle"
    },
    iconClass: function() {
      return `alert-modal__icon ${
        this.status === "error" ? "text-danger" : "text-success"
      }`
    }
  },
  methods: {
    confirm: function() {
      this.$emit("modalConfirm")
    }
  },
  mounted: function() {
    document.body.appendChild(this.$el)
  }
}
</script>

<style lang="scss" scoped>
.alert-modal__icon {
  font-size: 30px;
  text-align: center;
  margin-bottom: 8px;
}
</style>
