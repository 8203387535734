/**
 * Lead manage API wrapper
 */

const ENDPOINT = "/lead-manage.json"
const LOG_ENDPOINT = "/api/v1/lead-log"

const bbLeadManage = {
    get(params, callback = null) {
        return $.get(ENDPOINT, params, function (data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
    getLog(params, callback = null){
        return $.get(LOG_ENDPOINT, params, function (data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
	add(leadData, callback) {
		const url = "/lead-manage.json?bb_sid=" + window.SESSION_ID
		if (!leadData.owner) {
			console.log("owner missing")
			if (callback && typeof callback === "function") {
				callback.call(this, "error")
			}
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		if (!leadData.email && !leadData.phone) {
			console.log("not enough info to add a lead")
			if (callback && typeof callback === "function") {
				callback.call(this, "error")
			}
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		return $.ajax({
			url: url,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(leadData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
	validate(validationId, callback){
        const url = "/lead-validate.json?bb_sid=" + window.SESSION_ID
        return $.ajax({
            url: url,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({id:validationId}),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
	},
    isConnected : function(callback){
        if (!FB) {
            console.log("FB SDK not initialized")
            if (callback && typeof callback === "function") {
                callback.call(this, "error")
            }
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return FB.getLoginStatus(function(response) {
            if(response.status==="connected"){
                if (callback && typeof(callback) === "function") {callback.call(this,true);}
            }
            else{
                if (callback && typeof(callback) === "function") {callback.call(this,false);}
            }
        });
    },
    getFBLead : function(callback){
        if (!FB) {
            console.log("FB SDK not initialized")
            if (callback && typeof callback === "function") {
                callback.call(this, "error")
            }
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return FB.api('/me?fields=id,name,email', function(response) {
            if(response.email){
                if (callback && typeof(callback) === "function") {callback.call(this,response);}
            }
            else{
                if (callback && typeof(callback) === "function") {callback.call(this,null);}
            }
        });
    },
    // log(params,callback){
    //     return $.get(LOG_ENDPOINT, params, function (data) {
    //         if (callback && typeof callback === "function") {
    //             callback.call(this, data)
    //         }
    //     })
    // }
}


export default bbLeadManage
