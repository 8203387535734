<doc>
  Realtor search manual flyer form Vue.js component
</doc>

<template>
  <div class="buildModeSelect">
    <div class="row btnRow">
      <button @click="modeSelect('listingSearch')" type="button" class="btn btn-med-turquesa btn-lg">Listing Search</button>
      <button @click="modeSelect('manual')" type="button" class="btn btn-med-turquesa manualMode btn-lg ">Manual</button>
    </div>
    <div v-if="mode==='listingSearch'">
      <v-select class="style-chooser"
        @input="setSelected"
        :filterable="false"
        @search="fetchOptions"
        :options="realtorList"
        :placeholder="'Realtor Name'"
      >
        <template slot="no-options">
          type to search Realtors
        </template>
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.first_name+" "+option.last_name+" - "+option.agency_name }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.first_name+" "+option.last_name+" - "+option.agency_name }}
          </div>
        </template>
      </v-select>
    </div>
    <div v-if="!selectedListing && listingList.length>0">
      <v-select class="style-chooser" :filterable="false" @input="setSelectedListing" :options="listingList" :placeholder="'123 Main St'">
        <template slot="no-options">
          type to search Listings
        </template>
        <template slot="option" slot-scope="option">
          <div class="selected d-center">
            {{ option.fullstreetaddress }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.fullstreetaddress }}
          </div>
        </template>
      </v-select>
    </div>
    <div v-if="selectedListing">
      <div>
        <div class="row d-flex justify-content-center">
          <div class="col-sm-6 col-md-4">
            <div class="thumbnail">
              <img :src="selectedListing.pictures[0]" alt="...">
              <div class="caption">
                <h3>{{ selectedListing.street_addr }}</h3>
                <p>{{ selectedListing.overview.length > 150 ? `${selectedListing.overview.substring(0, 150)}...` : selectedListing.overview }}</p>
                <p class="btnWrapper">
                  <a href="#" @click="clearListing" class="btn btn-default" role="button">Clear</a>
                  <a href="#" @click="nextStep" class="btn btn-primary" role="button">Select</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StatesDropDown } from "../../Forms"
import ImgUploader from "../../ImgUploader"
import _find from "lodash/find";
import _debounce from "lodash/debounce";
import { CustomDropdown } from "../../Forms"
import vSelect from "vue-select"

export default {
  name: "FormBuildMode",
  props: {
    value: { type: Object },
    onInputStates: { type: Function },
    enableSaveNext: { type: Function },
    editMode: { type: Boolean, default: false }
  },
  data() {
    return {
      mode: "",
      realtorList: [],
      listingList: [],
      selectedRealtor: null,
      selectedListing: null
    }
  },
  components: { StatesDropDown, ImgUploader, CustomDropdown, vSelect },
  methods: {
    modeSelect(mode){
      this.mode=mode
      if(this.mode==="manual"){
        this.$emit("onChooseManualMode" )
      }
    },
    async fetchOptions(search,loading){
      loading(true)
      let response=await $.get("/api/v1/homescout-realtors?name_str="+search)
      if(response.status){
        this.realtorList=[];
      }
      else{
        this.realtorList=response
      }
      loading(false)
    },
    async setSelected(val){
      this.selectedRealtor=val
      let response=[];
      try{
        response=await $.get("/api/v1/homescout-realtor-listings?realtor_id="+val.id)
      }
      catch (e) {
        console.log("GET failed")
      }
      this.listingList=response
    },
    async setSelectedListing(val){
      if(!this.selectedRealtor){
        console.log("selected realtor is empty!")
        return
      }
      if(!val){
        return
      }
      let newRealtor={
        first_name: this.selectedRealtor.first_name,
        last_name: this.selectedRealtor.last_name,
        phone: (this.selectedRealtor.phone ? this.selectedRealtor.phone : ""),
        agency_name: (this.selectedRealtor.agency_name ? this.selectedRealtor.agency_name : ""),
        email: (this.selectedRealtor.email ? this.selectedRealtor.email : ""),
        picture: "",
        agency_logo: "",
        title: "",
        type: "homescout",
        homescout_id: this.selectedRealtor.id
      }
      this.value.realtorData=newRealtor

      let newListing={};
      //newListing.data={}
      newListing.street_addr=val.fullstreetaddress
      newListing.city=val.city
      newListing.state=val.state
      newListing.zipcode=val.zipcode
      newListing.county=val.county
      newListing.neighborhood=val.neighborhood
      newListing.num_beds=val.num_beds
      newListing.num_baths=val.num_baths
      newListing.num_half_baths=val.num_half_baths
      newListing.property_type=
        val.property_type.toLowerCase().includes("condo") ? "condominium" :
        val.property_type.toLowerCase().includes("town") ? "townhome" :
        val.property_type.toLowerCase().includes("multi") ? "multi_tenant_home" :
        val.property_type.toLowerCase().includes("manu") ? "manufactured_home" : "single_family_home"
      newListing.year_built=val.year_built
      newListing.sqft=val.sqft
      newListing.listing_price=val.listing_price
      newListing.hoa=val.hoa
      newListing.high_school=val.high_school
      newListing.middle_school=val.middle_school
      newListing.elem_school=val.elem_school
      newListing.mls=val.mls_id
      newListing.overview=val.remarks
      newListing.pictures=[];
      for(let i=0; i<val.pictures.length; i++){
        newListing.pictures.push(val.pictures[i].url)
      }
      this.selectedListing=newListing;
    },
    clearListing(){
      this.selectedListing=null;
    },
    nextStep(){
      this.$emit("onListingSelect", {newListing:this.selectedListing,newRealtor:this.value.realtorData})
    }
  },
  created() {
    this.fetchOptions = _.debounce(this.fetchOptions, 2000)
  },
  updated() {

  }
}
</script>

<style lang="scss">
  .style-chooser {
      .vs__dropdown-menu {
        max-height: 200px;
      }
  }
  .buildModeSelect{
    min-height: 300px;
  }
  .manualMode{
    background-color: #7e57c2;
    &:hover{
      background-color: #65469b;
    }

  }
  .btnRow{
    text-align: center;
  }
  .caption {
    h3 {
      margin: 0px;
      font-weight: bolder;
    }
    .btnWrapper{
      margin-top:10px;
    }
  }
</style>
