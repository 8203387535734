import Vue from "vue"
import Vuex from "vuex"
import smartlist from "../modules/smartlist"
import bbFlyerTemplates from "../modules/bb-flyer-templates"
import { createLogger } from "../../helpers"
import _merge from "lodash/merge"

const initStore = () => {
	Vue.use(Vuex)

	const debug = process.env.NODE_ENV !== "production"
	const states = _merge({}, smartlist.state(), bbFlyerTemplates.state)
	const modules = _merge({}, smartlist, bbFlyerTemplates)
	modules.state = states
	return new Vuex.Store({
		...modules,
		strict: debug,
		plugins: debug ? [createLogger()] : []
	})
}
export default initStore
