<doc>
  Program Jumbotrom Vue.js component
</doc>

<template>
  <jumbotron
    v-once
    class="text-center"
    title="Loan Program Sets help you select relevant loan programs for specific marketing scenarios">
    <flex-box>
      <grid-column>
        <img src="/sites/all/themes/bankingbridge_ui/images/static-flyer-step1.png" alt="Program demo" style="margin: 15px 0 18px;">
        <h2>1. Create a new flyer template</h2>
        <p class="text-center">Start by clicking the round, bright orange “plus” button at the top-right corner of the page.</p>
      </grid-column>
      <grid-column>
        <img src="/sites/all/themes/bankingbridge_ui/images/static-flyer-step2.png" alt="Program demo" style="margin: 25px 0 45px;">
        <h2>2. Upload your image</h2>
        <p class="text-center">Prioritize your loan programs for each set and give it context with a "whitelist" filter.  For example: you may want your FHA loan to appear first when marketing to a specific neighborhood.</p>
      </grid-column>
      <grid-column>
        <img src="/sites/all/themes/bankingbridge_ui/images/static-flyer-step3.png" alt="Program demo" style="margin-bottom: 15px;">
        <h2>3. Choose Loan Officer</h2>
        <p class="text-center">Once you have a Program Set created, if a quote is requested for a property that matches your filter, that program set will determine the priority in which your loan programs are displayed.  </p>
      </grid-column>
    </flex-box>
    <flex-box justify="center">
      <base-button type="button" outline class="mt-4" v-on:click="toggleItemModal">Create my first flyer</base-button>
    </flex-box>
  </jumbotron>
</template>

<script>
import Jumbotron from "../Jumbotron"

export default {
  name: "StaticFlyerJumbotron",
  components: {
    jumbotron: Jumbotron
  },
  props: {
    toggleItemModal: { type: Function, required: true }
  }
}
</script>

<style lang="scss" scoped>
</style>
