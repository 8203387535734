var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
    : _c(
        "div",
        { class: _vm.className },
        [
          !_vm.hideToolbar
            ? _c("SmartListToolbar", {
                attrs: {
                  searchKeys: _vm.searchKeys,
                  gridView: _vm.gridView,
                  gridViewSm: _vm.gridViewSm,
                  filteredItems: _vm.filteredItems,
                  addQuery: _vm.addQuery,
                  removeQuery: _vm.removeQuery,
                  resetQuery: _vm.resetQuery,
                  toggleListView: _vm.toggleListView,
                  addItemAction: _vm.addItemAction,
                  addItemLabel: _vm.addItemLabel,
                  hasAddItem: _vm.hasAddItem,
                  rangeFilter: _vm.rangeFilter,
                  searchQuery: _vm.searchQuery,
                  searchQueries: _vm.searchQueries,
                  vuexNamespace: _vm.vuexNamespace,
                  hasSort: _vm.hasSort,
                  searchShowListOptions: _vm.searchShowListOptions
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.smartListLoading
            ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
            : _c("SmartListItems", {
                attrs: {
                  items: _vm.items,
                  onDragEnd: _vm.onDragEnd,
                  gridView: _vm.gridView,
                  addItemAction: _vm.addItemAction,
                  listType: _vm.listType,
                  addItemLabel: _vm.addItemLabel,
                  hasAddItem: _vm.hasAddItem,
                  onItemClick: _vm.onItemClick,
                  toggleMemberPricing: _vm.toggleMemberPricing,
                  vuexNamespace: _vm.vuexNamespace
                }
              })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e87b19a4", { render: render, staticRenderFns: staticRenderFns })
  }
}