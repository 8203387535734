import Vue from "vue"
import { SubscriptionLog } from "./pages"

export default function() {
    if (!document.getElementById("vue-subscription-log-app")) {
        return
    }

    // Vuejs setup
    let test=new Vue({
        el: "#vue-subscription-log-app",
        components: {
            SubscriptionLog,
        }
    })
    // Do we need this? 25/4
    //window.dispatchEvent(new Event("resize"))
}

