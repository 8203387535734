<template>
  <div>
    <ConfigEditorManager
        type="Rate Cards"
        :getConfig="getConfig"
        :getAllConfig="getAllConfig"
        :addConfig="addConfig"
        :deleteConfig="deleteConfig"
        :updateConfig="updateConfig"
        :show-settings-button="true"
        @openSettingsModal="openSettingsModal"
    />
    <BaseModal
        v-model="showModal"
        title="Scenario Preferences"
        id="rcModal"
        :isLoading="isLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <p class="intro"></p>
      <div v-if="prefMap">
        <h3>Profile Preferences</h3>
        <ul>
          <li class="pref-map-list-item" v-for="(value, key) in prefMap" :key="key">
            {{ key }}:
            <span v-if="getProfileById(value) && getProfileById(value).title">
              {{ getProfileById(value).title }} <span @click="deletePref(key)" class="mini-delete-btn-wrapper"><i class="fa fa-times-circle mini-delete-btn" aria-hidden="true"></i></span>
            </span>
            <span v-else>
              <select v-model="prefMap[key]" @change="savePref(key, prefMap[key])">
                <option v-for="profile in defaultProfiles" :key="profile.id" :value="profile.id" v-if="!Object.values(prefMap).includes(profile.id)">
                  {{ profile.title }}
                </option>
              </select>
            </span>
          </li>
        </ul>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { bbRateCardsConfig } from "../../bb-api"
import ConfigEditorManager from "../ConfigEditorManager"
import BaseModal from "../BaseModal/index.vue";
import bbPreferencesManage from "../../bb-api/programPreferencesManage";

export default {
  name: "RateCardsManager",
  components: {
    ConfigEditorManager,
    BaseModal
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      prefMap: {},
      defaultProfiles: [],
      brand_id: null
    }
  },
  methods: {
    getAllConfig() {
      return bbRateCardsConfig.get({}, res => res)
    },
    getConfig(id, callBack) {
      bbRateCardsConfig.get({ id }, config => {
        callBack(config)
      })
    },
    handleOk() {
      // Handle OK button click
      this.showModal = false;
    },
    handleCancel() {
      // Handle Cancel button click
      this.showModal = false;
      this.brand_id = null
      this.prefMap = {}
      this.defaultProfiles = []
    },
    getProfileById(id) {
      return this.defaultProfiles.find(profile => profile.id === id)
    },
    async fetchPrefMap(brand_id){
      this.isLoading = true
      await $.get('/api/v1/profile-preference-map?brand_id=' + brand_id, (data) => {
        this.prefMap = data.profile_preferences
        this.defaultProfiles = data.default_profiles
        this.isLoading = false
      })
    },
    async openSettingsModal(brand_id) {
      this.brand_id = brand_id
      await this.fetchPrefMap(brand_id)
      this.showModal = true;
    },
    async savePref(key, value) {
      this.isLoading = true
      // use bbPreferencesManage.js to save the preference
      let pref_array = {}
      pref_array[key] = value
      await bbPreferencesManage.mapUpdate({
        profile_preferences: pref_array,
        brand_id: this.brand_id
      })
      this.isLoading = false
    },
    addConfig(payload, callBack) {
      // validate payload before saving
      if (!payload.name) return BB.Toastr.error("Please complete the name.")
      if (!payload.brand_id) return BB.Toastr.error("Please select brand.")

      bbRateCardsConfig.add(payload, function({ id }) {
        callBack(id)
      })
    },
    updateConfig(payload, callBack) {
      bbRateCardsConfig.update(payload, () => {
        callBack()
      })
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.getAllConfig()
    },
    async deleteConfig(id) {
      await bbRateCardsConfig.delete(id)
    },
    deletePref(key) {
      this.isLoading = true
      // use bbPreferencesManage.js to delete the preference
      let pref_array = {}
      pref_array[key] = null
      this.prefMap[key] = null
      // bbPreferencesManage.mapUpdate({
      //   profile_preferences: pref_array,
      //   brand_id: this.brand_id
      // })
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.pref-map-list-item {
  margin-bottom: 5px;
  .mini-delete-btn{
    color: #666;
    cursor: pointer;
  }
}
</style>
