<doc>
	Card toggle Vue.js component
</doc>

<template>
  <Card :class="className">
    <h5 v-on:click="toggle" class="card-title">
      <FlexBox>
        <span class="title-icon">
          <icon v-show="!is_open" name="angle-right"></icon>
          <icon v-show="is_open" name="angle-down"></icon>
        </span>
        <span class="title-txt">{{title}}</span>
      </FlexBox>
    </h5>
    <div class="card-toggle-content">
      <slot />
    </div>
  </Card>
</template>

<script>
import "vue-awesome/icons/angle-down"
import "vue-awesome/icons/angle-right"
import Card from "../Card"
export default {
  name: "CardToggle",
  props: {
    title: { type: String, required: true }
  },
  data() {
    return {
      is_open: false
    }
  },
  computed: {
    className: function() {
      return this.is_open ? "open" : "closed"
    }
  },
  components: { Card: Card },
  methods: {
    toggle() {
      this.is_open = !this.is_open
    }
  }
}
</script>

<style lang="scss" scoped>
.card-title {
  cursor: pointer;
  color: #29c9c5;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  .title-txt {
    color: #354052;
  }
  .title-icon {
    display: block;
    width: 20px;
    margin-right: 5px;
    font-size: 20px;
  }
}
.card-toggle-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s $transition-timing-function;
  padding-top: 0;
  .open & {
    max-height: 1000px;
    padding-top: 25px;
  }
}
</style>
