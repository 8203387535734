var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.availableBrands
    ? _c(
        "div",
        { staticClass: "notLoaded", attrs: { id: "lead-workflow-manage" } },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-circle pull-right",
              class: { cancel: _vm.addingNew || _vm.config },
              attrs: { type: "button" },
              on: { click: _vm.addNew }
            },
            [_c("div", { staticClass: "btn__inner" }, [_vm._v("+")])]
          ),
          _vm._v(" "),
          _c("div", [
            !_vm.addingNew && !_vm.brandId
              ? _c("div", [
                  _c("label", [_vm._v("Brand:")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brandId,
                          expression: "brandId"
                        }
                      ],
                      staticClass: "selectBrand",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.brandId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.changeSelectedBrand
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v("Please select one")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.availableBrands, function(brand) {
                        return _c(
                          "option",
                          { key: brand.id, domProps: { value: brand.id } },
                          [_vm._v(_vm._s(brand.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.brandId && _vm.availableWorkflows && !_vm.selectedConfigId
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedConfigId,
                        expression: "selectedConfigId"
                      }
                    ],
                    staticClass: "selectConfig",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedConfigId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeSelectedConfig
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v("Please select one")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.availableWorkflows, function(workflow) {
                      return _c(
                        "option",
                        { key: workflow.id, domProps: { value: workflow.id } },
                        [_vm._v(_vm._s(workflow.name))]
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.config
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("div", [
                    _c("span", {
                      class: { active: _vm.inUse },
                      attrs: { id: "inUse" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "workflowName" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.configName,
                            expression: "configName"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.configName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.configName = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.config
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "5px",
                              background: "gainsboro",
                              display: "inline-block",
                              "border-radius": "5px"
                            }
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedStep,
                                    expression: "selectedStep"
                                  }
                                ],
                                staticClass: "selectStep",
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedStep = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.changeSelectedStep
                                  ]
                                }
                              },
                              [
                                _c("option", {
                                  attrs: { disabled: "", value: "" }
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.availableSteps, function(step) {
                                  return _c("option", { key: step }, [
                                    _vm._v(_vm._s(step))
                                  ])
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("button", { on: { click: _vm.addNewStep } }, [
                              _vm._v("Add New Step")
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                      _c("span", [_vm._v("Brand: ")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.brandId,
                              expression: "brandId"
                            }
                          ],
                          staticClass: "selectBrand",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.brandId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { disabled: "", value: "" } }, [
                            _vm._v("Please select one")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.availableBrands, function(brand) {
                            return _c(
                              "option",
                              { key: brand.id, domProps: { value: brand.id } },
                              [_vm._v(_vm._s(brand.name))]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.addingNew
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c("span", [_vm._v("Copy from: ")]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.copyFrom,
                                      expression: "copyFrom"
                                    }
                                  ],
                                  staticClass: "selectWorkflowCopy",
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.copyFrom = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.copyFromWorkflow
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { disabled: "", value: "" } },
                                    [_vm._v("Please select one")]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.allWorkflows, function(workflow) {
                                    return _c(
                                      "option",
                                      {
                                        key: workflow.id,
                                        domProps: { value: workflow.id }
                                      },
                                      [_vm._v(_vm._s(workflow.name))]
                                    )
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "Tabs",
                    [
                      _c("Tab", { attrs: { name: "Config" } }, [
                        _c(
                          "div",
                          [
                            _c("vjsoneditor", {
                              key: _vm.componentKey,
                              attrs: {
                                options: _vm.options,
                                plus: true,
                                height: _vm.height
                              },
                              on: { error: _vm.onError },
                              model: {
                                value: _vm.config,
                                callback: function($$v) {
                                  _vm.config = $$v
                                },
                                expression: "config"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "footer" }, [
                          _c("div", { staticClass: "setDefault" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isDefault,
                                  expression: "isDefault"
                                }
                              ],
                              attrs: { type: "checkbox", id: "isDefault" },
                              domProps: {
                                checked: Array.isArray(_vm.isDefault)
                                  ? _vm._i(_vm.isDefault, null) > -1
                                  : _vm.isDefault
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.isDefault,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isDefault = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isDefault = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isDefault = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", [_vm._v("Default")])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("Tab", { attrs: { name: "Steps" } }, [
                        _c(
                          "ul",
                          { staticClass: "steps" },
                          [
                            _c(
                              "draggable",
                              {
                                on: { change: _vm.changeSteps },
                                model: {
                                  value: _vm.config.steps,
                                  callback: function($$v) {
                                    _vm.$set(_vm.config, "steps", $$v)
                                  },
                                  expression: "config.steps"
                                }
                              },
                              _vm._l(_vm.config.steps, function(step, index) {
                                return _c("li", { key: index }, [
                                  _c("i", {
                                    staticClass: "fa fa-bars dragBtn",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _c("span", { staticClass: "listId" }, [
                                    _vm._v(_vm._s(step.id))
                                  ]),
                                  _c("i", {
                                    staticClass: "fas fa-pencil-alt editBtn"
                                  }),
                                  step.condition
                                    ? _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.tooltipContent(step),
                                            expression: "tooltipContent(step)"
                                          }
                                        ],
                                        staticClass: "fas fa-random"
                                      })
                                    : _vm._e()
                                ])
                              })
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("Tab", { attrs: { name: "Upload" } }, [
                        _c(
                          "div",
                          [
                            _vm.uploadUrl
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.uploadUrl) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.config
                              ? _c("ImgUploader", {
                                  attrs: {
                                    previewAlt: "Uploaded Image",
                                    editMode: true,
                                    previewSrc: _vm.uploadUrl,
                                    previewClass: "bg-preview",
                                    label: "",
                                    uploadCallback: _vm.uploadCallback,
                                    labelTop: true,
                                    hasRemove: true,
                                    removePhoto: _vm.removePhoto,
                                    "use-s3": true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("Tab", { attrs: { name: "Delete" } }, [
                        _c("button", { on: { click: _vm.deleteWorkflow } }, [
                          _vm._v("Delete")
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.changed ||
                  (_vm.addingNew && _vm.configName && _vm.brandId)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-default pull-right",
                          attrs: { id: "saveConfig" },
                          on: { click: _vm.saveConfig }
                        },
                        [_vm._v("\n      Save\n    ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { attrs: { id: "prev-wrapper", role: "tooltip" } })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5e6ff269", { render: render, staticRenderFns: staticRenderFns })
  }
}