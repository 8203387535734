var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel", class: _vm.newQuoteHeader }, [
    _c("div", { staticClass: "panel-heading" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.label ? _vm.label : "Loan Option") +
          "\n        "
      ),
      _c("span", { staticClass: "value-arrow" }, [
        _vm.comparison && _vm.comparison.relative_value > 0
          ? _c("i", { staticClass: "fas fa-arrow-down" })
          : _vm.comparison && _vm.comparison.relative_value < 0
            ? _c("i", { staticClass: "fas fa-arrow-up" })
            : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "panel-body" }, [
      _c("h2", [_vm._v("\n            " + _vm._s(_vm.rate) + "%\n        ")])
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "list-group" }, [
      _c("li", { staticClass: "list-group-item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("Price")]),
        _vm._v(_vm._s(_vm.price))
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-group-item" }, [
        _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.fee.title))]),
        _vm._v("$" + _vm._s(_vm.fee.amount))
      ]),
      _vm._v(" "),
      _vm.loanAmount
        ? _c("li", { staticClass: "list-group-item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Loan Amount")]),
            _vm._v("$" + _vm._s(_vm.loanAmountFormatted))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.listPrice
        ? _c("li", { staticClass: "list-group-item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Property Value")]),
            _vm._v("$" + _vm._s(_vm.listPriceFormatted))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("li", { staticClass: "list-group-item" }, [
        _c("div", { staticClass: "label" }, [_vm._v("P/I")]),
        _vm._v("$" + _vm._s(_vm.principalAndInterest))
      ]),
      _vm._v(" "),
      false
        ? _c("li", { staticClass: "list-group-item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Term")]),
            _vm._v(_vm._s(_vm.term) + " year")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type
        ? _c(
            "li",
            { staticClass: "list-group-item", attrs: { title: _vm.type } },
            [
              _c("div", { staticClass: "label" }, [_vm._v("Type")]),
              _vm._v(_vm._s(_vm.shortenStr(_vm.type)))
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "list-group-item", attrs: { title: _vm.investor } },
        [
          _c("div", { staticClass: "label" }, [_vm._v("Investor")]),
          _vm._v(_vm._s(_vm.shortenStr(_vm.investor)))
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-11b2b149", { render: render, staticRenderFns: staticRenderFns })
  }
}