var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLink
    ? _c(
        "a",
        { class: _vm.className, attrs: { href: _vm.href } },
        [
          _vm.btnIcon ? _c("icon", { attrs: { name: _vm.btnIcon } }) : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    : _c(
        "button",
        {
          class: _vm.className,
          attrs: { type: _vm.type },
          on: {
            click: function($event) {
              _vm.$emit("click", $event)
            }
          }
        },
        [
          _vm.btnIcon ? _c("icon", { attrs: { name: _vm.btnIcon } }) : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-503c4032", { render: render, staticRenderFns: staticRenderFns })
  }
}