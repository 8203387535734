const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?/i
const emptyPattern = /^(?![\s\S])/gi

const errosMsgs = ["The field is required", "Please enter a valid url"]

const validate = $form => {
	const $requiredFields = $("[required]", $form)
	let errors = []

	clearValidation()

	$requiredFields.each(function(index) {
		const $this = $(this)
		const type = $this.attr("type")
		const val = $this.val()
		let hasErrors = false
		let errorMsg = errosMsgs[0]
		switch (type) {
			case "url":
				if (intputValidation(val, emptyPattern)) {
					hasErrors = true
				} else if (!intputValidation(val, urlPattern)) {
					errorMsg = errosMsgs[1]
					hasErrors = true
				}
				break
			default:
				hasErrors = intputValidation(val, emptyPattern)
		}

		if (hasErrors) {
			errors.push({ name: $this.attr("name"), input: $this, msg: errorMsg })
		}
	})

	if (errors.length) {
		$.each(errors, function(index, error) {
			const $item = error.input
			const $label = $item.parent().find("label")
			$label.append(`<span class="bb-error--msg">${error.msg}</span>`)
		})
	}
	return !!errors.length
}

const intputValidation = (str, pattern) => {
	return pattern.test(str)
}

const clearValidation = () => {
	$(".bb-error--msg").remove()
}

module.exports = {
	validate: validate,
	clearValidation: clearValidation
}
