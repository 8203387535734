var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: _vm.wrapperClass }, [
      _c("label", { class: _vm.labelClass, attrs: { for: _vm.name } }, [
        _vm._v("\n      " + _vm._s(_vm.label) + "\n    ")
      ]),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        class: _vm.inputClassName,
        attrs: {
          autocomplete: "off",
          type: _vm.type,
          id: _vm.name,
          "aria-describedby": _vm.describedby,
          placeholder: _vm.placeholder,
          readonly: _vm.readonly,
          min: _vm.min || false,
          max: _vm.max || false
        },
        domProps: { value: _vm.value },
        on: {
          input: _vm.onInput,
          focus: _vm.onFocus,
          blur: function($event) {
            _vm.onBlur($event)
          },
          keyup: [
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.$emit("keyupEnter", $event)
            },
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
              ) {
                return null
              }
              _vm.$emit("keyupEsc", $event)
            },
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete"
                ])
              ) {
                return null
              }
              _vm.$emit("keyupDelete", $event)
            }
          ]
        }
      })
    ]),
    _vm._v(" "),
    _vm.helpText
      ? _c(
          "small",
          {
            staticClass: "form-text text-muted help-text",
            staticStyle: { "margin-top": "-10px", "font-size": "80%" },
            attrs: { id: _vm.describedby }
          },
          [_vm._v("\n    " + _vm._s(_vm.helpText) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-17d604e6", { render: render, staticRenderFns: staticRenderFns })
  }
}