import Toastr from "toastr"
import { arrayMove } from "../../helpers"
import _ from 'lodash'

const ENDPOINT = "/flyer-template-manage.json"

// Initial state
const state = {
	bank_id: null,
    isBankAdmin: false,
    uid: null,
}
const mutations = {
	SET_BANK_ID(state, bank_id) {
		state.bank_id = bank_id
	},
    SET_IS_BANK_ADMIN(state, condition){
        state.isBankAdmin=condition;
    },
    SET_UID(state, uid){
        state.uid=uid;
    },
}

const actions = {
	initFlyerTemplateManager({ commit, dispatch, state }, info) {
		commit("SET_BANK_ID",info.bank_id);
        commit("SET_IS_BANK_ADMIN",info.isBankAdmin);
        commit("SET_UID",info.uid);
    },
	saveFlyerTemplate({ commit, dispatch, state }, item){

	}
}

export default {
	state,
	mutations,
	actions
}
