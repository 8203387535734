<template>
  <div class="app-wrapper">
    <div v-if="ready">
      <select
              class="select-lo"
              v-model="loid"
      >
        <option disabled value="">Please select one</option>
        <option
                v-for="member in members"
                :value="member.uid"
                :key="member.uid"
        >
          {{ member.first_name + " " + member.last_name }}
        </option>
      </select>
      <div v-if="api_mode=='ob'">
        <Tabs>
          <Tab name="Request">
            <vjsoneditor
                v-model="request"
                :options="jsonOptions"
                :plus="true"
            />
          </Tab>
          <Tab name="Common Fields">
            <div>
              <form>
                <fieldset class="commonFields">
                  <InputText
                      v-model="request.loanInformation.baseLoanAmount"
                      label="Loan Amount"
                      name="loan_amount"
                  />
                  <InputText
                      v-model="request.borrowerInformation.debtToIncomeRatio"
                      label="DTI"
                      name="dti"
                  />
                  <InputText
                      v-model="request.borrowerInformation.fico"
                      label="Credit Score"
                      name="loan_amount"
                  />
                  <InputText
                      v-model="request.loanInformation.loanPurpose"
                      label="Loan Purpose"
                      name="loan_purpose"
                  />
                  <InputText
                      v-model="request.loanInformation.automatedUnderwritingSystem"
                      label="AUS"
                      name="aus"
                  />
                  <InputText
                      v-model="request.loanInformation.leadSource"
                      label="Lead Source"
                      name="lead_source"
                  />
                  <InputText
                      v-model="request.loanInformation.desiredLockPeriod"
                      label="Lock Period"
                      name="lock_period"
                  />
                  <InputText
                      v-model="request.loanInformation.loanType"
                      label="Loan Type"
                      name="loan_type"
                  />
                  <InputText
                      v-model="request.loanInformation.loanTerms[0]"
                      label="Loan Term"
                      name="loan_term"
                  />
                  <InputText
                      v-model="request.loanInformation.amortizationTypes[0]"
                      label="Amortization Type"
                      name="amortization_type"
                  />
                  <InputText
                      v-model="request.propertyInformation.appraisedValue"
                      label="Property Value"
                      name="property_value"
                  />
                  <InputText
                      v-model="request.propertyInformation.county"
                      label="County"
                      name="county"
                  />
                  <InputText
                      v-model="request.propertyInformation.zipCode"
                      label="Zipcode"
                      name="zipcode"
                  />
                  <InputText
                      v-model="request.propertyInformation.state"
                      label="State"
                      name="state"
                  />
                  <InputText
                      v-model="request.propertyInformation.propertyStreetAddress"
                      label="Street"
                      name="street"
                  />
                </fieldset>
              </form>
            </div>
          </Tab>
        </Tabs>
      </div>
      <div v-else-if="api_mode=='mortech'">
        <Tabs>
          <Tab name="Request">
            <vjsoneditor
                v-model="request"
                :options="jsonOptions"
                :plus="true"
            />
          </Tab>
          <Tab name="Common Fields">
            <div>
              <form>
                <fieldset class="commonFields">
                  <InputText
                      v-model="request.loan_amount"
                      label="Loan Amount"
                      name="loan_amount"
                  />
                  <InputText
                      v-model="request.DTIPercent"
                      label="DTI"
                      name="dti"
                  />
                  <InputText
                      v-model="request.fico"
                      label="Credit Score"
                      name="loan_amount"
                  />
                  <InputText
                      v-model="request.loanpurpose"
                      label="Loan Purpose"
                      name="loan_purpose"
                  />
                  <InputText
                      v-model="request.lockindays"
                      label="Lock Period"
                      name="lock_period"
                  />
                  <InputText
                      v-model="request.loanProduct1"
                      label="Loan Product"
                      name="loan_term"
                  />
                  <InputText
                      v-model="request.downPayment"
                      label="Down Payment"
                      name="down_payment"
                  />
                  <InputText
                      v-model="request.propertyCounty"
                      label="County"
                      name="county"
                  />
                  <InputText
                      v-model="request.propertyZip"
                      label="Zipcode"
                      name="zipcode"
                  />
                  <InputText
                      v-model="request.propertyState"
                      label="State"
                      name="state"
                  />
                </fieldset>
              </form>
            </div>
          </Tab>
        </Tabs>
      </div>
      <div v-else-if="api_mode=='lender_price'">
        <Tabs>
          <Tab name="Request">
            <vjsoneditor
                v-model="request"
                :options="jsonOptions"
                :plus="true"
            />
          </Tab>
          <Tab name="Common Fields">
            <div>
              <form>
                <fieldset class="commonFields">
                  <InputText
                      v-model="request.criteria.loanAmount"
                      label="Loan Amount"
                      name="loan_amount"
                  />
                  <InputText
                      v-model="request.DTIPercent"
                      label="DTI"
                      name="dti"
                  />
                  <InputText
                      v-model="request.criteria.fico"
                      label="Credit Score"
                      name="loan_amount"
                  />
                  <InputText
                      v-model="request.criteria.loanPurpose"
                      label="Loan Purpose"
                      name="loan_purpose"
                  />
                  <InputText
                      v-model="request.lockindays"
                      label="Lock Period"
                      name="lock_period"
                  />
                  <InputText
                      v-model="request.termsCriteria[0]"
                      label="Loan Product"
                      name="loan_term"
                  />
                  <InputText
                      v-model="request.criteria.downPaymentAmount"
                      label="Down Payment"
                      name="down_payment"
                  />
                  <InputText
                      v-model="request.property.address.countyName"
                      label="County"
                      name="county"
                  />
                  <InputText
                      v-model="request.property.address.zip"
                      label="Zipcode"
                      name="zipcode"
                  />
                  <InputText
                      v-model="request.property.address.state"
                      label="State"
                      name="state"
                  />
                </fieldset>
              </form>
            </div>
          </Tab>
        </Tabs>
      </div>
      <div v-else-if="api_mode=='polly'">
        <Tabs>
          <Tab name="Request">
            <vjsoneditor
                v-model="request"
                :options="jsonOptions"
                :plus="true"
            />
          </Tab>
          <Tab name="Common Fields">
            <div>
              <form>
                <fieldset class="commonFields">
                  <InputText
                      v-model="request.loan.amount"
                      label="Loan Amount"
                      name="loan_amount"
                  />
                  <InputText
                      v-model="request.borrower.fico"
                      label="Credit Score"
                      name="credit_score"
                  />
                  <InputText
                      v-model="request.loan.purpose"
                      label="Loan Purpose"
                      name="loan_purpose"
                  />
                  <InputText
                      v-model="request.search.desiredLockPeriod"
                      label="Lock Period"
                      name="lock_period"
                  />
                  <InputText
                      v-model="request.search.loanTerms[0]"
                      label="Loan Term"
                      name="loan_term"
                  />
                  <InputText
                      v-model="request.property.county"
                      label="County"
                      name="county"
                  />
                  <InputText
                      v-model="request.property.countyFipsCode"
                      label="County FIPS"
                      name="county_fips"
                  />
                  <InputText
                      v-model="request.property.state"
                      label="State"
                      name="state"
                  />
                </fieldset>
              </form>
            </div>
          </Tab>
        </Tabs>
      </div>
      <div>
        <div class="button-wrapper">
          <div class="pull-right">
            <button @click="priceRequest">Price</button>
            <button style="margin-right:10px;" @click="copyText">Copy Request</button>
          </div>
          <div style="clear:both;"></div>
        </div>
        <div>
          <div v-if="ratesLoading">
            <LoaderPlaceholder type="circle" />
          </div>
          <div v-else>
            <div v-if="cards" class="col-sm-3 list-cards" v-for="(card, index) in cards" :key="index">
              <CalcCard
                :rate="card.rate"
                :price="card.price"
                :term="parseInt(card.amortizationTerm)"
                :loanAmount="getLoanAmount"
                :fee="card.fee"
                :principalAndInterest="card.principalAndInterest"
                :investor="card.investor"
              />
            </div>
            <div v-else>
              No results
            </div>
          </div>
        </div>
      </div>
      <div class="copy-wrapper">
        <input v-model="requestJSONString" type="text">
      </div>
    </div>
    <LoaderPlaceholder v-else type="circle" />
  </div>
</template>

<script>
import vjsoneditor from "v-jsoneditor"
import { Tabs, Tab } from "../components/Tabs"
import CalcCard from "../components/CalcCard"

export default {
  name: "PricingTool",
    components: {
      vjsoneditor,
      Tabs,
      Tab,
      CalcCard
    },
  async mounted() {
    if(log_id) {
      this.loading = true;
      this.init()
      this.loading = false;
    }
  },
  updated(){
      if(this.request && this.api_mode=="ob"){
        this.request.representativeFICO=this.request.borrowerInformation.fico;
        this.request.loanLevelDebtToIncomeRatio=this.request.borrowerInformation.debtToIncomeRatio;
      }
  },
  data() {
    return {
      jsonOptions: {
        height: "500px",
        plus: true
      },
      loading: true,
      request: null,
      rateflow: null,
      cards: null,
      ratesLoading: false,
      members: null,
      brand_id: null,
      loid: null,
      api_mode: null
    }
  },
  computed:{
    requestJSONString(){
      return JSON.stringify(this.request)
    },
    ready(){
        return this.request && this.members
    },
    getLoanAmount(){
      if(this.api_mode=="mortech"){
        return this.request.loan_amount
      }
      else if(this.api_mode=="ob"){
        return this.request.loanInformation.baseLoanAmount
      }
      else if(this.api_mode=="lender_price"){
        return this.request.criteria.loanAmount;
      }
      return 0;
    }
  },
  methods: {
    async init(){
      let log_entry=await BB.rateflow.log(log_id)
      this.brand_id=log_entry.bank_id
      this.rateflow=await BB.rateflow.get({brand_id:this.brand_id})
      this.loid=log_entry.loid
      this.request=log_entry.full_request
      this.api_mode = log_entry.type
      await this.getMembers()
    },
    async priceRequest(){
      this.ratesLoading=true
      let request =
      this.cards=await BB.rateflow.priceCustom({
        loid: this.loid,
        id: this.rateflow.id,
        rawRequest: this.request
      })
      this.ratesLoading=false
    },
    copyText(){
      /* Get the text field */
      var copyText = $(".copy-wrapper input")[0];

      if(!copyText.value){
        return;
      }

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      BB.Toastr.success("Request Copied")
    },
    async getMembers(){
      let result = await BB.brandManage.getMembers(this.brand_id)
      this.members=result.members
    }
  }
}
</script>

<style lang="scss">
  .label{
    color: #444;
  }
  .button-wrapper{
    margin: 10px 0px;
    button{
      cursor: pointer;
      float:right;
    }
  }
  .copy-wrapper{
    //display:none;
    position: absolute;
    top:-9999px;
  }
  .commonFields{
    width:300px;
  }
</style>
