<doc>
	Avatar List Item Vue.js component
</doc>

<template>
		<FlexBox class="item-wrapper">
			<Thumb
				fullRounded
				:src="item.picture"
				:alt="alt"
				class="item-image" />
			<div class="item-data">
				<h5 class="item-title" v-if="item.name">{{item.name}}</h5>
				<strong class="item-subtitle" v-if="item.subtitle">{{item.subtitle}}</strong>
				<a class="item-link" :href="link" v-if="item.email">{{item.email}}</a>
			</div>
		</FlexBox>
</template>

<script>
import Thumb from "../Thumb"

export default {
	name: "AvatarListItem",
	components: { Thumb: Thumb },
	props: {
		item: { type: Object, required: true }
	},
	computed: {
		link: function() {
			return `mailto:${this.item.email}`
		},
		alt: function() {
			return `${this.item.name} Thumb`
		}
	}
}
</script>

<style lang="scss" scoped>
.item-wrapper {
	border-bottom: 1px solid #e9e9e9;
	padding: 16px 0 20px;
}
.item-image {
	width: 40px;
	height: 40px !important;
}
.item-data {
	margin-left: 8px;
}
.item-title {
	color: #595959;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	margin-bottom: 10px;
}
.item-subtitle {
	color: #354052;
	margin-bottom: 10px;
	display: block;
}
.item-info-link {
	color: #abc0d3;
}
</style>
