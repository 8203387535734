<doc>
  Grid Vue.js component
</doc>

<template>
  <div :class="className"><slot /></div>
</template>

<script>
export default {
	name: "FlexBox",
	props: {
		size: { type: String, default: null },
		alignment: { type: String, default: null },
		justify: { type: String, default: null },
		wrap: { type: Boolean, default: false }
	},
	computed: {
		className: function() {
			const align = this.alignment ? ` align-items-${this.alignment}` : ""
			const justify = this.justify ? ` justify-content-${this.justify}` : ""
			const wrap = this.wrap ? " flex-wrap" : ""
			const size = this.size ? `-${this.size}` : ""
			return `d${size}-flex${align}${justify}${wrap}`
		}
	}
}
</script>

<style lang="scss" src="./FlexBox.scss" scoped>
</style>
