/**
 * Realtor API wrapper
 */

const EXTERNAL_ENDPOINT = "/manage-external-invites.json"
//const INTERNAL_ENDPOINT = "/manage-external-invites.json"

const bbInviteManage = {
	external: {
        get(id, callback) {
            if (!id) {
                console.log("external invite id missing")
                return new Promise((resolve, reject) => {
                    reject("error")
                })
            }
            return $.get(EXTERNAL_ENDPOINT, { id: id }, function(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            })
        }
	},
	internal: {

	}
}

export default bbInviteManage
