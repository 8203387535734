/**
 * Lead manage API wrapper
 */

const ENDPOINT = "/api/v1/tag-manager";

const bbTagManage = {
    get(params, callback = null) {
        if (params.id){
            return $.get(ENDPOINT+"/tags?id="+params.id, function(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            });
        }
        if (!params.list_name) {
            console.log("list_name required")
            if (callback && typeof callback === "function") {
                callback.call(this, "error")
            }
        }
        let q_str="?list_name="+params.list_name+( params.value ? "&value="+params.value : "")
        return $.get(ENDPOINT+"/tags"+q_str, function(data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
    add(tagData, callback) {
        const url = ENDPOINT
        if (!tagData.list_name || !tagData.value) {
            console.log("not enough data to create a tag")
            if (callback && typeof callback === "function") {
                callback.call(this, "error")
            }
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.ajax({
            url: url,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(tagData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
    association:{
        get(list_name = null, callback = null) {
            var params = {}
            if (list_name) {
                params = { list_name }
            }
            return $.get(ENDPOINT, params, function(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            })
        },
        add(tagAssociation, callback) {
            const url = ENDPOINT
            if (!tagAssociation.tag_id || !tagAssociation.item_id) {
                console.log("not enough data to create a tag")
                if (callback && typeof callback === "function") {
                    callback.call(this, "error")
                }
                return new Promise((resolve, reject) => {
                    reject("error")
                })
            }
            return $.ajax({
                url: url,
                type: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(tagAssociation),
                success(data) {
                    if (callback && typeof callback === "function") {
                        callback.call(this, data)
                    }
                },
                error(xhr, ajaxOptions, thrownError) {
                    if (callback && typeof callback === "function") {
                        callback.call(this, thrownError)
                    }
                }
            })
        },
        delete(params, callback) {
            const url = ENDPOINT
            if (!params.tag_id || !params.item_id) {
                console.log("not enough data to delete tag association")
                if (callback && typeof callback === "function") {
                    callback.call(this, "error")
                }
                return new Promise((resolve, reject) => {
                    reject("error")
                })
            }
            return $.ajax({
                url: url+"?"+$.param( params ),
                type: "DELETE",
                contentType: "application/json",
                dataType: "json",
                success(data) {
                    if (callback && typeof callback === "function") {
                        callback.call(this, data)
                    }
                },
                error(xhr, ajaxOptions, thrownError) {
                    if (callback && typeof callback === "function") {
                        callback.call(this, thrownError)
                    }
                }
            })
        },

    }
}

export default bbTagManage
