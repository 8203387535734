/**
 * Program Preference Manage API wrapper
 */
const ENDPOINT = "/program_preferences/manage.json"
const MAP_ENDPOINT = "/api/v1/profile-preferences-map"

const bbPreferencesManage = {
	get(params = {}, callback = null) {
		return $.get(ENDPOINT, params, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
	update(preferenceData, callback) {
		if (!preferenceData.id) {
			console.log("preference id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(preferenceData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
    add(preferenceData, callback) {
        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(preferenceData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
	mapGet(params = {}, callback = null) {
		if(!params.brand_id) {
			console.log("brand_id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		return $.get(MAP_ENDPOINT, params, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
	// 	preferenceData: {
	// 		"profile_preferences": [
	// 			"va": 1234,
	// 			"fha": 5678,
	// 		],
	// 		"brand_id": 7890,
	// 	}
	mapUpdate(preferenceData, callback) {
		if (!preferenceData.profile_preferences) {
			console.log("profile_preferences array missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		if(!preferenceData.brand_id) {
			console.log("brand_id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: MAP_ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(preferenceData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
}

export default bbPreferencesManage
