const arrayMove = (arr, new_index, old_index) => {
	if (old_index >= arr.length) {
		var k = old_index - arr.length + 1
		while (k--) {
			arr.push(undefined)
		}
	}
	arr.splice(old_index, 0, arr.splice(new_index, 1)[0])
	return arr // for testing
}

export default arrayMove
