var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FlexBox",
    { staticClass: "item-wrapper" },
    [
      _c("Thumb", {
        staticClass: "item-image",
        attrs: { fullRounded: "", src: _vm.item.picture, alt: _vm.alt }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "item-data" }, [
        _vm.item.name
          ? _c("h5", { staticClass: "item-title" }, [
              _vm._v(_vm._s(_vm.item.name))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.subtitle
          ? _c("strong", { staticClass: "item-subtitle" }, [
              _vm._v(_vm._s(_vm.item.subtitle))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.email
          ? _c("a", { staticClass: "item-link", attrs: { href: _vm.link } }, [
              _vm._v(_vm._s(_vm.item.email))
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-27c6ad1e", { render: render, staticRenderFns: staticRenderFns })
  }
}