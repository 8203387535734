/**
 * Subscription API wrapper
 */

const ENDPOINT = "/api/v1/dashboard-chat"
const ALLENDPOINT = "/api/v1/dashboard-chats"
const FIND_ENDPOINT = "/api/v1/dashboard-chat"

const bbDashboardChatConfig = {
  get(params, callback = null) {
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  getAll(params, callback = null) {
    return $.get(ALLENDPOINT, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(dashboardChat, callback) {
    if (!dashboardChat.id) {
      console.log("Chat Config id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(dashboardChat),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  add(dashboardChat, callback) {

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(dashboardChat),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  delete(id, callback) {
    return $.ajax({
      url: ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  find(params, callback = null) {
    return $.get(FIND_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbDashboardChatConfig
