import Vue from "vue"
import { ConfigEditorManager } from "./pages"

export default function() {
  if (!document.getElementById("config-editor-manager-app")) {
    return
  }

  // Vuejs setup
  new Vue({
    el: "#config-editor-manager-app",
    components: {
      ConfigEditorManager
    }
  })
}
