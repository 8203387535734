var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className },
    [
      _vm.isLoading
        ? _c("LoaderPlaceholder", {
            staticClass: "loader",
            attrs: { type: "circle" }
          })
        : _vm._e(),
      _vm._v(" "),
      this.type === "default"
        ? _c("DefaultImgUploader", {
            attrs: {
              id: _vm.id,
              editMode: _vm.editMode,
              label: _vm.label,
              previewAlt: _vm.previewAltComp,
              isLoading: _vm.isLoading,
              previewClass: _vm.previewClassComp,
              uploadPhoto: _vm.uploadPhoto,
              imgSrc: _vm.imgSrc,
              labelTop: _vm.labelTop,
              hasRemove: _vm.hasRemove,
              removePhoto: _vm.removePhotoLocal,
              isHeadshot: _vm.isHeadshot
            }
          })
        : this.type === "thumbPrev"
          ? _c("ThumbPrevImgUploader", {
              attrs: {
                id: _vm.id,
                editMode: _vm.editMode,
                fullRounded: _vm.fullRounded,
                size: _vm.size,
                label: _vm.label,
                uploadPhoto: _vm.uploadPhoto,
                imgSrc: _vm.imgSrc,
                preview: _vm.preview,
                hasError: _vm.showAlert || _vm.hasError.length
              }
            })
          : _vm._e(),
      _vm._v(" "),
      _vm.hasError
        ? _c("p", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.hasError))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("AlertModal", {
        attrs: { showAlert: _vm.showAlert, text: _vm.confirmTxt },
        on: { modalConfirm: _vm.modalConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6916a323", { render: render, staticRenderFns: staticRenderFns })
  }
}