import Vue from "vue"
import _find from "lodash/find"
import _remove from "lodash/remove"
import _forEach from "lodash/forEach"
import _get from "lodash/get"
import Toastr from "toastr"
import Moment from "moment"
import { arrayMove, isSameDay } from "../../helpers"

// Initial state
const state = function() {
  return {
    sort_by: "",
    max_items: 25,
    enable_pagination: true,
    disable_pagination: false,
    current_offset: 0,
    originalItems: null,
    totalItems: null,
    items: null,
    currentEditedItem: null,
    loid: null,
    showItemModal: false,
    showProgramDetailsModal: false,
    gridView: true,
    searchQuery: null,
    searchDateQuery: "",
    searchQueries: [],
    isEdit: false,
    smartListLoading: false
  }
}

const mutations = {
  SET_LOID(state, loid) {
    state.loid = loid
  },
  ADD_ITEM(state, item) {
    state.originalItems.push(item)
  },
  REMOVE_ITEM(state, index) {
    state.originalItems.splice(index, 1)
  },
  UPDATE_ITEM(state, { index, item }) {
    Vue.set(state.originalItems, index, item)
  },
  CURRENT_EDITED_ITEM(state, item) {
    state.currentEditedItem = item
  },
  UPDATE_ORIGINAL_ITEMS(state, updatedSets) {
    state.originalItems = updatedSets
  },
  REORDER_ORIGINAL_ITEMS(state, { oldIndex, newIndex }) {
    arrayMove(state.originalItems, oldIndex, newIndex)
  },
  UPDATE_TOTAL_ITEMS(state, updatedSets) {
    state.totalItems = updatedSets
  },
  REORDER_TOTAL_ITEMS(state, { oldIndex, newIndex }) {
    arrayMove(state.totalItems, oldIndex, newIndex)
  },
  TOGGLE_ITEM_MODAL(state, condition) {
    state.showItemModal = condition
  },
  TOGGLE_LINK_MODAL(state, condition) {
    state.showItemModal = condition
  },
  TOGGLE_LIST_VIEW(state, condition) {
    state.gridView = typeof condition == "boolean" ? condition : !state.gridView
  },
  ADD_QUERY(state, query) {
    state.searchQueries = state.searchQueries.concat(query)
  },
  REMOVE_QUERY(state, queries) {
    state.searchQueries = queries
  },
  RESET_QUERY(state) {
    state.searchQuery = null
  },
  SET_EDIT(state, condition) {
    state.isEdit = condition
  },
  FILTER_ITEMS(state, { query, filteredItems, isDateRange }) {
    if (isDateRange) {
      state.searchDateQuery = query
    } else {
      state.searchQuery = query
    }
    state.items = filteredItems
    state.disable_pagination = state.searchQueries.length > 0 ? true : false 
  },
  CLEAR_ALL_ITEMS(state) {
    state.items = null
  },
  SORT_BY(state, payload) {
    state.sort_by = payload
  },
  SET_CURRENT_OFFSET(state, payload) {
    state.current_offset = payload
  },
  SET_SMARTLIST_LOADING(state, payload) {
    state.smartListLoading = payload
  }
}

const actions = {
  removeItem({ commit }, { id, index }) {
    Toastr.success("Deleting item…")
    fetch(`/pricing-set-manage.json?id=${id}`, {
      credentials: "same-origin",
      method: "DELETE"
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        commit("REMOVE_ITEM", index)
        Toastr.success("Item Deleted")
      })
      .catch(err => {
        console.error(err)
      })
  },
  toggleModal({ commit, state }, { mutation, condition }) {
    const tempCondition =
      typeof condition == "boolean" ? condition : !state.showProgramDetailsModal
    commit(mutation, tempCondition)
    if (!tempCondition) {
      commit("CURRENT_EDITED_ITEM", null)
      commit("SET_EDIT", false)
    } else {
      commit("CURRENT_EDITED_ITEM", {})
    }
  },
  toggleItemModal({ dispatch, state }, condition) {
    if (state.showItemModal != condition) {
      dispatch("toggleModal", {
        mutation: "TOGGLE_ITEM_MODAL",
        condition: condition
      })
    }
  },
  showEditModal({ commit, dispatch }, { mutation, id }) {
    dispatch("getItemById", id).then(data => {
      commit("CURRENT_EDITED_ITEM", data)
    })
    commit(mutation, true)
  },
  showEditItemModal({ commit, dispatch }, id) {
    dispatch("showEditModal", { id: id, mutation: "TOGGLE_ITEM_MODAL" })
    commit("SET_EDIT", true)
  },
  updateOriginalItems({ commit, state }, payload) {
    commit("REORDER_ORIGINAL_ITEMS", payload)
    updateSetsOrder(state.originalItems).then(data => {
      this.dispatch("setDefaultSet")
    })
  },
  // Search actions
  addQuery({ commit, dispatch, state }, newQuery) {
    commit("ADD_QUERY", newQuery)
    commit("RESET_QUERY")

    dispatch("filteredItems", { searchKeys: state.searchQueries })
  },
  resetQuery({ commit, dispatch }) {
    commit("RESET_QUERY")
    dispatch("filteredItems", "")
  },
  removeQuery({ commit, dispatch, state }, query) {
    console.log("action removeQuery")
    // let newQueries = [].concat(state.searchQueries)
    // const index = _find(newQueries, query)

    // newQueries.splice(index, 1)
    let queries = [].concat(state.searchQueries)
    let deleteQuery = query

    let newQueries = queries.filter(querie => querie !== deleteQuery)
    commit("REMOVE_QUERY", newQueries)
    dispatch("filteredItems", { searchKeys: state.searchQueries })
  },
  filteredItems(
    { commit, state, dispatch },
    { query, isDateRange, searchKeys }
  ) {
    if (isDateRange) {
      dispatch("filteredDateItems", query)
      return
    }

    let matchedItems = []
    let items = state.totalItems != null ? state.totalItems : state.originalItems
    if (!searchKeys.length) {
      commit("FILTER_ITEMS", {
        query: "",
        filteredItems: items,
        isDateRange: false
      })
      return
    }
    searchKeys.forEach(query => {
      
      let currentItemsFiltered = items.filter(item => {
        let isTag = query.isTag
        if (isTag) {
          let foundTag = item.tags.find(
            tag => tag.text.toLowerCase() === query.text.toLowerCase()
          )

          if (foundTag) return item

          return false
        } else {
          var regex = RegExp(query.text, "i")
          if (regex.test(item.name)) return item

          return false
        }
      })

      matchedItems.push(...currentItemsFiltered)
    })

    let hash = {}
    let filteredItems = matchedItems.filter(current => {
      let exists = !hash[current.id]
      hash[current.id] = true
      return exists
    })

    commit("FILTER_ITEMS", {
      query: query ? query : "",
      filteredItems: filteredItems,
      isDateRange: false
    })
  },
  filteredDateItems({ commit, dispatch, state }, range) {
    let tempQuery = range
    const cleanRange = tempQuery.from == 0 && tempQuery.to == 0
    if (cleanRange) {
      commit("RESET_QUERY")
      tempQuery = ""
    }

    // this action is just for activity list
    try {
      if (range.from === range.to && range.to !== 0) {
        const date = new Date(range.from * 1000)
        const year = date.getFullYear()
        const month = date.getMonth()
        const day = date.getDate()

        const start = new Date(year, month, day, 0, 0, 0).getTime() / 1000

        const end = new Date(year, month, day, 23, 59, 59).getTime() / 1000

        range.from = start
        range.to = end
      }

      dispatch("loadByRange", range)
      commit("SET_SMARTLIST_LOADING", true)

      return
    } catch (error) {
      console.error(error)
    }

    var reduced = state.originalItems.reduce(function(filtered, set) {
      const time = set.timestamp | 0

      if (
        (range.from === range.to && isSameDay(range.from, time)) ||
        (time >= range.from && time <= range.to)
      ) {
        filtered.push(set)
      }
      return filtered
    }, [])

    commit("FILTER_ITEMS", {
      query: range,
      filteredItems:
        reduced.length == 0 && cleanRange ? state.originalItems : reduced,
      isDateRange: true
    })
  },
  clearAllItems({ commit }) {
    commit("CLEAR_ALL_ITEMS")
  },
  sortBy({ dispatch, commit, state }, payload) {
    commit("SORT_BY", payload)

    dispatch("getFlyerImages", { bank_id: state.bank_id })
  },
  setCurrentOffset({ commit }, payload) {
    commit("SET_CURRENT_OFFSET", payload)
  }
}

const getters = {
  currentItems: state => {
    
    let items = state.items || state.originalItems || []
    // filter duplicated users
    if(items[0] && items[0].nid){
      items = items.reduce((acc, current) => {
        const x = acc.find(item => item.nid === current.nid);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    }
    return items
  },
  isPagination: state => {
    if(state.disable_pagination == true){
      return false
    }
    return state.qtyItemsDB >= state.max_items
  }
}

function updateSetsOrder(updatedSets) {
  const data = {}
  data.new_set_weights = []
  updatedSets.forEach(function(set, index) {
    this.push({
      id: set.id,
      weight: index
    })
  }, data.new_set_weights)

  return fetch("/pricing-set-manage.json", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "same-origin",
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(data => {
      Toastr.success("Programs order updated")
    })
    .catch(err => {})
}

export default {
  state,
  getters,
  actions,
  mutations
}
