/**
 * County API wrapper
 */

const ENDPOINT = "/api/v1/county"

const bbCountyManage = {
  SEARCH_ENDPOINT: ENDPOINT + "?name_like=",
  search(term, callback) {
    if (!term) {
      console.log("term missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.get(ENDPOINT, { name_like: term }, function(data) {
      console.log(data)
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  get({ state, name }, callback) {
    if (!state || !name) {
      console.log("param missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.get(ENDPOINT, { name, state }, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  save(countyData, callback) {
    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(countyData),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  }
}

export default bbCountyManage
