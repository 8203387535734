<template>
	<section v-show="isActive"
		:aria-hidden="! isActive"
		class="tabs-component-panel"
		:id="hash"
		role="tabpanel"
	>
		<slot />
	</section>
</template>

<script>
export default {
	props: {
		id: { default: null },
		name: { type: String, required: true },
		prefix: { type: String, default: "" },
		suffix: { type: String, default: "" },
		isDisabled: { type: Boolean, default: false },
		linkHref: { type: String, default: "" }
	},
	data: () => ({
		isActive: false,
		isVisible: true
	}),
	computed: {
		header() {
			return this.prefix + this.name + this.suffix
		},
		hash() {
			if (this.isDisabled) {
				return "#"
			}
			return this.id ? "#" + this.id : "#" + this.name.toLowerCase().replace(/ /g, "-")
		}
	}
}
</script>

<style scoped>
.tabs-component-panel {
  height: calc(100% - 40px);
  overflow-y: scroll;
}
</style>