/**
  Organization API wrapper

	JSON endpoint: /org/manage.json
	GET /org/manage.json - list all orgs belonging to current user
	GET /org/manage.json?id={org_id} - returns org details and a list of brands that belong to the org
	POST /org/manage.json?id={org_id} - update org details. expects the same JSON model that GET returned
	GET /org/members.json?id={org_id} - returns list of members organized by brand {brand id is array key}
**/
const ENDPOINT = "/org/manage.json"
const ENDPOINT_MEMBERS = "/org/members.json"

const bbOrgManage = {
	get(params, callback = null) {
		return $.get(ENDPOINT, params, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
	getById(org_id, callback=null) {
		if (!org_id) {
			console.log("org id missing")
			return new Promise((resolve, reject) => {
				reject("error");
			})
		}
		return $.get(ENDPOINT, { id: org_id }, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
	update(orgData, callback) {
		if (!orgData.id) {
			console.log("org id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(orgData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
	getMembers(org_id, callback) {
		if (!org_id) {
			console.log("org id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		return $.get(ENDPOINT_MEMBERS, { id: org_id }, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	}
}
export default bbOrgManage
