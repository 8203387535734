var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "newPricingSetModal",
        showCancelBtn: false,
        isLoading: _vm.isLoading || _vm.initializing,
        okLabel: "Close",
        closeWhenOK: true
      },
      model: {
        value: _vm.showItemModal,
        callback: function($$v) {
          _vm.showItemModal = $$v
        },
        expression: "showItemModal"
      }
    },
    [
      _c(
        "Tabs",
        { on: { changed: _vm.tabChanged } },
        [
          _c(
            "Tab",
            { attrs: { name: "Install" } },
            [
              _vm.integration
                ? _c("Install", {
                    attrs: {
                      integration: _vm.integration,
                      onDelete: _vm.onDelete
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Settings" } },
            [
              _vm.showItemModal &&
              _vm.integration &&
              _vm.extra_settings &&
              _vm.selectData.availablePricingSets
                ? _c("Settings", {
                    attrs: {
                      integration: _vm.integration,
                      extraSettings: _vm.extra_settings,
                      selectData: _vm.selectData
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Status" } },
            [
              _vm.pollingActive && _vm.showItemModal
                ? _c("Status", { attrs: { integration: _vm.integration } })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Monitor" } },
            [
              _vm.integration
                ? _c("Monitor", { attrs: { integration: _vm.integration } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a373356e", { render: render, staticRenderFns: staticRenderFns })
  }
}