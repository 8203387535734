var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ckeditor", {
    attrs: { editor: _vm.editor, config: _vm.editorConfig },
    on: { input: _vm.onInput },
    model: {
      value: _vm.val,
      callback: function($$v) {
        _vm.val = $$v
      },
      expression: "val"
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0270e4d6", { render: render, staticRenderFns: staticRenderFns })
  }
}