var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.orgs, function(org, key) {
            return _c("tr", { key: key }, [
              _c("td", { staticStyle: { "font-weight": "500" } }, [
                _vm._v(_vm._s(org.name))
              ]),
              _vm._v(" "),
              !_vm.systemUsage[org.id]
                ? _c("td", { staticStyle: { "padding-left": "10px" } }, [
                    _vm._v("loading...")
                  ])
                : _c("td", { staticStyle: { "padding-left": "10px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.systemUsage[org.id] &&
                        _vm.systemUsage[org.id].breakdown &&
                        _vm.systemUsage[org.id].breakdown.leads
                          ? _vm.systemUsage[org.id].breakdown.leads.total
                          : 0
                      )
                    )
                  ]),
              _vm._v(" "),
              !_vm.systemUsage[org.id]
                ? _c("td", { staticStyle: { "padding-left": "10px" } })
                : _c("td", { staticStyle: { "padding-left": "10px" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.systemUsage[org.id] &&
                        _vm.systemUsage[org.id].breakdown &&
                        _vm.systemUsage[org.id].breakdown.emails
                          ? _vm.systemUsage[org.id].breakdown.emails.total
                          : 0
                      )
                    )
                  ]),
              _vm._v(" "),
              !_vm.systemUsage[org.id]
                ? _c("td", { staticStyle: { "padding-left": "10px" } })
                : _c("td", { staticStyle: { "padding-left": "10px" } }, [
                    _c("a", { attrs: { href: "/billing/" + org.id } }, [
                      _vm._v(
                        "$" +
                          _vm._s(
                            _vm.systemUsage[org.id]
                              ? _vm.formatNumber(_vm.systemUsage[org.id].total)
                              : 0
                          )
                      )
                    ])
                  ])
            ])
          })
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticStyle: { "font-weight": "500" } }, [
          _vm._v("Total Revenue:")
        ]),
        _vm._v(" $" + _vm._s(_vm.formatNumber(_vm.totalRevenue)) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticStyle: { "font-weight": "500" } }, [
          _vm._v("Total Leads:")
        ]),
        _vm._v(" " + _vm._s(parseInt(_vm.leadTotal)) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Org")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Lead Total")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Email Total")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Invoice Total")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7599e4f2", { render: render, staticRenderFns: staticRenderFns })
  }
}