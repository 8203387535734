var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c(
      "div",
      { staticClass: "program-details-img" },
      [
        _c("ProgramProfileThumbs", {
          attrs: { programProfiles: _vm.item.pricing_profiles }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "program-overlay" },
          [
            _c(
              "div",
              { staticClass: "drag-handler d-none d-lg-flex" },
              [
                _c("icon", { attrs: { name: "ellipsis-v" } }),
                _vm._v(" "),
                _c("icon", { attrs: { name: "ellipsis-v" } }),
                _vm._v(" "),
                _c("icon", { attrs: { name: "ellipsis-v" } })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "BaseButton",
              {
                staticClass: "btn-view-program",
                attrs: { outline: "" },
                on: { click: _vm.showModal }
              },
              [_vm._v("View Set")]
            ),
            _vm._v(" "),
            _c("SmartListItemOptions", {
              attrs: {
                gridView: _vm.gridView,
                itemIndex: _vm.itemIndex,
                itemId: _vm.item.id,
                onDragEnd: _vm.onDragEnd,
                isLast: _vm.isLast,
                cloneItem: _vm.cloneProgramSet,
                showEditItemModal: _vm.showEditItemModal,
                removeItem: _vm.removeItem,
                "options-list": ["edit", "delete"]
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "program-details" }, [
      _c("div", { staticClass: "program-details-title" }, [
        _vm._v(_vm._s(_vm.item.name))
      ]),
      _vm._v(" "),
      _vm.isDefault
        ? _c("div", { staticClass: "default-tag" }, [_vm._v("default")])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-05084c54", { render: render, staticRenderFns: staticRenderFns })
  }
}