var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      staticClass: "alert-modal",
      attrs: { small: "", okLabel: "OK", showCancelBtn: false },
      on: { ok: _vm.confirm },
      model: {
        value: _vm.showAlert,
        callback: function($$v) {
          _vm.showAlert = $$v
        },
        expression: "showAlert"
      }
    },
    [
      _c(
        "div",
        { class: _vm.iconClass },
        [_c("icon", { attrs: { name: _vm.iconName } })],
        1
      ),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.text))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3c6717b5", { render: render, staticRenderFns: staticRenderFns })
  }
}