var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("LoaderPlaceholder", {
            staticClass: "loader",
            attrs: { type: "circle" }
          })
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            !_vm.addingNew && !_vm.config
              ? _c("div", { staticClass: "d-flex form-inline" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "brand_id" } }, [
                      _vm._v("Brand:")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.brand_id,
                            expression: "brand_id"
                          }
                        ],
                        staticClass: "form-control input-sm",
                        attrs: { id: "brand_id" },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.brand_id = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.changeSelectedBrand
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Please select one")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.availableBrands, function(brand) {
                          return _c(
                            "option",
                            { key: brand.id, domProps: { value: brand.id } },
                            [_vm._v(_vm._s(brand.name))]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _vm.brand_id &&
                  _vm.availableWorkflows &&
                  !_vm.selectedConfigId
                    ? _c("div", { staticClass: "form-group ml-3" }, [
                        _c("label", [_vm._v("Workflow:")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedConfigId,
                                expression: "selectedConfigId"
                              }
                            ],
                            staticClass: "form-control input-sm",
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedConfigId = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.changeSelectedConfig
                              ]
                            }
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v("Please select one")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.availableWorkflows, function(workflow) {
                              return _c(
                                "option",
                                {
                                  key: workflow.id,
                                  domProps: { value: workflow.id }
                                },
                                [_vm._v(_vm._s(workflow.name))]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ])
              : _c("ConfigNameInput", {
                  attrs: { inUse: _vm.inUse, hasInUse: true },
                  on: { input: _vm.configChanged },
                  model: {
                    value: _vm.configName,
                    callback: function($$v) {
                      _vm.configName =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "configName"
                  }
                }),
            _vm._v(" "),
            _c("AddCancelBtn", {
              staticClass: "ml-auto",
              attrs: {
                onClick: _vm.addNew,
                isCancel: _vm.addingNew || !!_vm.config
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.isLoadingConfig
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("LoaderPlaceholder", {
                  staticClass: "loader",
                  attrs: { type: "circle" }
                })
              ],
              1
            )
          : _vm.config
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "form-inline d-flex p-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "brand_id" } }, [
                        _vm._v("Brand: ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.brand_id,
                              expression: "brand_id"
                            }
                          ],
                          staticClass: "form-control input-sm",
                          attrs: { id: "brand_id" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.brand_id = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { disabled: "", value: "" } }, [
                            _vm._v("Please select one")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.availableBrands, function(brand) {
                            return _c(
                              "option",
                              { key: brand.id, domProps: { value: brand.id } },
                              [_vm._v(_vm._s(brand.name))]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm.addingNew
                      ? _c("div", { staticClass: "form-group ml-3" }, [
                          _c("label", { attrs: { for: "copyFrom" } }, [
                            _vm._v("Copy from: ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.copyFrom,
                                  expression: "copyFrom"
                                }
                              ],
                              staticClass: "form-control input-sm",
                              attrs: { id: "copyFrom" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.copyFrom = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.copyFromWorkflow
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v("Please select one")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.allWorkflows, function(workflow) {
                                return _c(
                                  "option",
                                  {
                                    key: workflow.id,
                                    domProps: { value: workflow.id }
                                  },
                                  [_vm._v(_vm._s(workflow.name))]
                                )
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox ml-3 pt-1" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.isDefault,
                              expression: "isDefault"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.isDefault)
                              ? _vm._i(_vm.isDefault, null) > -1
                              : _vm.isDefault
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.isDefault,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.isDefault = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.isDefault = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.isDefault = $$c
                                }
                              },
                              _vm.configChanged
                            ]
                          }
                        }),
                        _vm._v("\n          Default")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-auto form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "selectedStep" }
                          },
                          [_vm._v("Add New Step: ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedStep,
                                expression: "selectedStep"
                              }
                            ],
                            staticClass: "form-control input-sm",
                            attrs: { id: "selectedStep" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedStep = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { disabled: "" },
                                domProps: { value: null }
                              },
                              [_vm._v("Add New Step")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.availableSteps, function(step) {
                              return _c("option", { key: step }, [
                                _vm._v(_vm._s(step))
                              ])
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "BaseButton",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedStep !== null,
                                expression: "selectedStep !== null"
                              }
                            ],
                            attrs: { size: "sm" },
                            on: { click: _vm.addNewStep }
                          },
                          [_vm._v("Add Step")]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "Tabs",
                    { attrs: { useUrlFragment: false } },
                    [
                      _c("Tab", { attrs: { name: "Config" } }, [
                        _c(
                          "div",
                          {
                            on: {
                              mouseover: _vm.addPreview,
                              mouseout: _vm.removePreview
                            }
                          },
                          [
                            _c("vjsoneditor", {
                              key: _vm.componentKey,
                              attrs: {
                                options: _vm.options,
                                plus: true,
                                height: _vm.height
                              },
                              on: { error: _vm.onError },
                              model: {
                                value: _vm.config,
                                callback: function($$v) {
                                  _vm.config = $$v
                                },
                                expression: "config"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("Tab", { attrs: { name: "Steps" } }, [
                        _c(
                          "ul",
                          { staticClass: "steps" },
                          [
                            _c(
                              "draggable",
                              {
                                on: { change: _vm.changeSteps },
                                model: {
                                  value: _vm.config.steps,
                                  callback: function($$v) {
                                    _vm.$set(_vm.config, "steps", $$v)
                                  },
                                  expression: "config.steps"
                                }
                              },
                              _vm._l(_vm.config.steps, function(step, index) {
                                return _c("li", { key: index }, [
                                  _c("i", {
                                    staticClass: "fa fa-bars dragBtn",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "listId" }, [
                                    _vm._v(_vm._s(step.id))
                                  ]),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fas fa-pencil-alt editBtn"
                                  }),
                                  _vm._v(" "),
                                  step.condition
                                    ? _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.tooltipContent(step),
                                            expression: "tooltipContent(step)"
                                          }
                                        ],
                                        staticClass: "fas fa-random"
                                      })
                                    : _vm._e()
                                ])
                              })
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("Tab", { attrs: { name: "Upload" } }, [
                        _c(
                          "div",
                          [
                            _vm.uploadUrl
                              ? _c("div", [
                                  _vm._v(
                                    "\n            Imgage URL:\n            "
                                  ),
                                  _c("div", { staticClass: "well well-sm" }, [
                                    _vm._v(_vm._s(_vm.uploadUrl))
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.config
                              ? _c("ImgUploader", {
                                  attrs: {
                                    previewAlt: "Uploaded Image",
                                    editMode: true,
                                    previewSrc: _vm.uploadUrl,
                                    previewClass: "bg-preview",
                                    label: "",
                                    uploadCallback: _vm.uploadCallback,
                                    labelTop: true,
                                    hasRemove: true,
                                    removePhoto: _vm.removePhoto,
                                    "use-s3": true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex pt-3" }, [
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c(
                          "BaseButton",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.changed ||
                                  (_vm.addingNew && _vm.brand_id),
                                expression: "changed || (addingNew && brand_id)"
                              }
                            ],
                            staticClass: "mr-2",
                            on: { click: _vm.saveConfig }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.addingNew ? "Add" : "Save") +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "BaseButton",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.config || _vm.addingNew,
                                expression: "config || addingNew"
                              }
                            ],
                            attrs: { outline: "" },
                            on: { click: _vm.deleteWorkflow }
                          },
                          [_vm._v("\n          Delete\n        ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "ce_tooltip",
          attrs: { id: _vm.tooltipID, role: "tooltip" }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-984d40f8", { render: render, staticRenderFns: staticRenderFns })
  }
}