<doc>
  Step Overview manual flyer form Vue.js component
</doc>

<template>
  <grid-row>
    <grid-column>
      <InputTextArea
        class="prop-overview__input"
        v-model="value.overview"
        label="Property Overview"
        name="overview"
        helperText
        :limit="500"
      />
    </grid-column>
  </grid-row>
</template>

<script>
export default {
  name: "FormStepOverview",
  props: {
    value: { type: Object },
    onInput: { type: Function }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.prop-overview__input textarea {
  min-height: 175px;
}
</style>
