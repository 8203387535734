<doc>
	Avatar List Vue.js component
</doc>

<template>
		<div>
			<AvatarListItem
				v-for="(item, index) in test"
				:item="item"
				:key="key(item)"
				v-if="item && index<limit"
		  />
		</div>
</template>

<script>
import AvatarListItem from "./AvatarListItem"

export default {
	name: "AvatarList",
	components: { AvatarListItem: AvatarListItem },
	props: {
		items: { type: Array, required: true },
		limit: { type: Number, default: Infinity }
	},
	computed: {
		test: function() {
			return this.items
		}
	},
	methods: {
		key: function(item) {
			if (item) {
				return item.uid || item.name
			}
			// Just in case the item is null
			return new Date().getTime()
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
