import Toastr from "toastr"
import _forEach from "lodash/forEach"

const actions = {
  getItemById({ commit, dispatch, state }, ID) {
    return BB.loManage.get(ID).then(data => {
      return data.lo
    })
  },
  saveMember({ commit, dispatch, state }, payload) {
    Toastr.success("Saving member…")
    const data = payload.data
    if (!data.loid) {
      BB.loManage
        .add(data)
        .then(response => {
          if (response.status !== "error") {
            Toastr.success("Member saved…")
          } else {
            if(response.message != null) {
              Toastr.error(response.message)
            }
            else {
              Toastr.error("There was an error, please try again.")
            }
          }
          commit("ADD_ITEM", data)
          dispatch("toggleItemModal", false)
        })
        .catch(err => {
          console.error(err)
          dispatch("toggleItemModal", false)
        })
    } else {
      BB.loManage
        .update(data)
        .then(response => {
          if (response.status !== "error") {
            // check if logo needs to be updated locally
            if (payload.data.brand_id) {
              const new_brand = this.state.organization.org.brands.find(
                brand => {
                  return brand.id == payload.data.brand_id
                }
              )
              if (new_brand.logo) payload.data.logo = new_brand.logo
            }
            dispatch("updateMemberLocal", payload.data)
            Toastr.success("Member saved…")
          } else {
            Toastr.error("There was an error, please try again.")
          }
          dispatch("toggleItemModal", false)
        })
        .catch(err => {
          console.error(err)
          dispatch("toggleItemModal", false)
        })
    }
  },
  toggleMemberPricing({ commit, dispatch, state }, payload) {
    Toastr.success("Updating pricing status…")
    const data = payload.data
    BB.loManage
      .activate(payload)
      .then(response => {
        if (response.status !== "error") {
          Toastr.success("Member saved…")
        } else {
          Toastr.error("There was an error, please try again.")
        }
        dispatch("toggleItemModal", false)
      })
      .catch(err => {
        console.error(err)
        dispatch("toggleItemModal", false)
      })
  },
  updateMemberLocal({ commit, dispatch, state }, payload) {
    let members = _.merge([], this.state.members.originalItems)
    let i = 0
    _forEach(members, member => {
      if (payload.loid == member.nid) {
        members[i] = _.merge(members[i], payload)
      }
      i++
    })
    this.commit("members/UPDATE_ORIGINAL_ITEMS", members, { root: true })
    //this.commit("members/UPDATE_MEMBERS",members)
  },
  getMemberLocal({ commit, dispatch, state }, loid) {
    let members = _.merge([], this.state.members.originalItems)
    let i = 0
    let result = {}
    _forEach(members, member => {
      if (loid == member.nid) {
        result = members[i]
      }
      i++
    })
    return result
  },
  async removeMember({ commit, dispatch, state }, loid){
    await BB.loManage.delete(loid);
    let index=this.state.members.originalItems.findIndex(member=>{
      return member.nid==loid
    })
    this.commit("members/REMOVE_ITEM", index)
    BB.Toastr.success("User Removed")
  }
}

export default {
  actions
}
