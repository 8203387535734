<template>
  <div class="basePagination">
    <ul class="basePagination__list-container">
      <li class="basePagination__list-item" @click="back">
        <i class="fas fa-chevron-left"></i>
      </li>
      <li
        class="basePagination__list-item"
        :class="{
          'basePagination__list-item--active': index === currentNumber
        }"
        v-for="(number, index) in pages"
        :key="index"
        @click="select(index)"
      >
        {{ number }}
      </li>
      <li class="basePagination__list-item" @click="next">
        <i class="fas fa-chevron-right"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    total: { type: Number, required: true },
    maxItemsPerPage: { type: Number, required: true },
    toOffset: { type: Number, required: true }
  },
  data() {
    return {
      currentNumber: 0,
      pages: 0,
      maxButtonsToShow: 10
    }
  },
  created() {
    const qtyPagesToShow = Math.ceil(this.total / this.maxItemsPerPage)
    let pages = []

    for (let i = 0; i < qtyPagesToShow; i++) {
      pages.push(i + 1)
    }

    this.pages = pages
  },
  methods: {
    select(index) {
      this.currentNumber = index
      this.submitEvent()
    },
    back() {
      if (this.currentNumber === 0) return false

      this.currentNumber = this.currentNumber - 1
      this.submitEvent()
    },
    next() {
      if (this.currentNumber === this.pages - 1) return false

      this.currentNumber = this.currentNumber + 1
      this.submitEvent()
    },
    submitEvent() {
      this.$emit("onPagination", {
        maxItemsPerPage: this.maxItemsPerPage,
        offset: this.toOffset * this.currentNumber
      })
    }
  }
}
</script>

<style scoped lang="scss" src="./BasePagination.scss"></style>
