var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "flex-box",
        { staticClass: "smartlist-toolbar" },
        [
          _c(
            "grid-column",
            {
              staticClass:
                "ml-auto p-sm-0 d-flex justify-content-end align-items-center"
            },
            [
              _vm.rangeFilter
                ? _c("DatePickerRange", {
                    class: _vm.dateClassName,
                    model: {
                      value: _vm.computedDateQuery,
                      callback: function($$v) {
                        _vm.computedDateQuery = $$v
                      },
                      expression: "computedDateQuery"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "transition",
                [
                  _c("BaseAutoComplete", {
                    attrs: {
                      listItem: _vm.filteredTags,
                      showListOptions: _vm.searchShowListOptions,
                      listOptions: _vm.listOptions,
                      name: "set-tag",
                      hideLabel: true,
                      placeHolder: this.placeholder,
                      showInput: this.showInput,
                      hidden: true,
                      float: true,
                      marTop: "0",
                      marBottom: "0",
                      paddLeft: true,
                      isMaterial: false,
                      fluidList: false
                    },
                    on: {
                      text: _vm.filterTags,
                      emptyText: _vm.reset,
                      submit: _vm.addFilter,
                      keyEsc: _vm.closeInput,
                      selectedItem: _vm.addTagToFilter,
                      changePlaceholderByType: _vm.changePlaceholderByType
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  staticClass: "btn-search",
                  attrs: { type: "button", theme: "link", btnIcon: "search" },
                  on: { click: _vm.toggleSearch }
                },
                [_c("span", { staticClass: "sr-only" }, [_vm._v("Search")])]
              ),
              _vm._v(" "),
              _vm.hasSort
                ? _c("sort", { attrs: { options: _vm.sortOptions } })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAddItem
                ? _c(
                    "base-button",
                    {
                      attrs: { type: "button", theme: "circle" },
                      on: { click: _vm.addItemAction }
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.addItemLabel))
                      ]),
                      _vm._v("\n        +\n      ")
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "flex-box",
        { staticClass: "smartlist-toolbar-filter" },
        _vm._l(_vm.searchQueries, function(query, index) {
          return _c("badge", {
            key: index,
            staticClass: "badge-filter",
            attrs: {
              pill: "",
              hasClose: "",
              label: query.text,
              showIcon: true,
              isTag: query.isTag,
              mr: 5
            },
            on: {
              removeBadge: function($event) {
                _vm.removeFilter(query)
              }
            }
          })
        })
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c5bf89c6", { render: render, staticRenderFns: staticRenderFns })
  }
}