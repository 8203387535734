<template>
  <div :class="className"><slot /></div>
</template>

<script>
export default {
  name: "Column",
  props: {
    size: { type: [Number, String, Array], default: null },
    order: { type: [Number, String], default: null }
  },
  computed: {
    className: function() {
      let size = "col"
      if (Array.isArray(this.size)) {
        size += `-${this.size.join(" col-")}`
      } else {
        size += this.size ? `-${this.size}` : ""
      }
      const order = this.order ? ` order-${this.order}` : ""
      return size + order
    }
  }
}
</script>

<style lang="scss" src="./Grid.scss" scoped></style>
