import Vue from "vue"
import initStore from "./store/bb-rates"
import { MyRates } from "./pages"

export default function() {
	if (!document.getElementById("vue-rates-app")) {
		return
	}
	const store = initStore()
	// initial items
	store.dispatch("initRates", { rateflow_id: rateflow_id })

	// Vuejs setup
	new Vue({
		el: "#vue-rates-app",
		store,
		components: {
			MyRates: MyRates
		}
	})
}
