var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SmartList", {
    attrs: {
      searchQuery: _vm.searchQuery,
      searchQueries: _vm.searchQueries,
      filteredItems: _vm.filteredItems,
      addQuery: _vm.addQuery,
      removeQuery: _vm.removeQuery,
      resetQuery: _vm.resetQuery,
      toggleListView: _vm.toggleListView,
      addItemAction: _vm.toggleItemModal,
      items: _vm.items,
      onDragEnd: _vm.onEnd,
      gridView: _vm.gridView,
      listType: "static_flyer",
      vuexNamespace: "staticFlyers",
      hasSort: true,
      searchShowListOptions: true
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-59d648fa", { render: render, staticRenderFns: staticRenderFns })
  }
}