var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.title
      ? _c("h3", [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")])
      : _vm._e(),
    _vm._v(" "),
    _c("p", { staticClass: "intro" }, [_vm._v(_vm._s(_vm.intro))]),
    _vm._v(" "),
    _c("div", { staticClass: "form-step" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-16f7382f", { render: render, staticRenderFns: staticRenderFns })
  }
}