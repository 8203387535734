var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("LoaderPlaceholder", {
            staticClass: "loader",
            attrs: { type: "circle" }
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _vm.disabled
            ? _c("div", { staticClass: "d-flex" }, [
                _c("span", { staticClass: "label label-danger" }, [
                  _vm._v("Disabled")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              !_vm.addingNew && !_vm.config
                ? _c("div", { staticClass: "d-flex form-inline" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "bank_id" } }, [
                        _vm._v("Brand:")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.bank_id,
                              expression: "bank_id"
                            }
                          ],
                          staticClass: "form-control input-sm",
                          attrs: { id: "bank_id" },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.bank_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.changeSelectedBrand
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { disabled: "", value: "" } }, [
                            _vm._v("Please select one")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.availableBrands, function(brand) {
                            return _c(
                              "option",
                              { key: brand.id, domProps: { value: brand.id } },
                              [_vm._v(_vm._s(brand.name))]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm.bank_id &&
                    _vm.availableRateflows &&
                    !_vm.selectedConfigId
                      ? _c("div", { staticClass: "form-group ml-3" }, [
                          _c("label", [_vm._v("Rateflow:")]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedConfigId,
                                  expression: "selectedConfigId"
                                }
                              ],
                              staticClass: "form-control input-sm",
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedConfigId = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.changeSelectedConfig
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v("Please select one")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.availableRateflows, function(
                                rateflow
                              ) {
                                return _c(
                                  "option",
                                  {
                                    key: rateflow.id,
                                    domProps: { value: rateflow.id }
                                  },
                                  [_vm._v(_vm._s(rateflow.name))]
                                )
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ])
                : _c("ConfigNameInput", {
                    attrs: { hasInUse: false },
                    on: { input: _vm.configChanged },
                    model: {
                      value: _vm.configName,
                      callback: function($$v) {
                        _vm.configName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "configName"
                    }
                  }),
              _vm._v(" "),
              _c("AddCancelBtn", {
                staticClass: "ml-auto",
                attrs: {
                  onClick: _vm.addNew,
                  isCancel: _vm.addingNew || !!_vm.config
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLoadingConfig
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("LoaderPlaceholder", {
                    staticClass: "loader",
                    attrs: { type: "circle" }
                  })
                ],
                1
              )
            : _vm.config
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "form-inline d-flex p-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "bank_id" } }, [
                          _vm._v("Brand: ")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.bank_id,
                                expression: "bank_id"
                              }
                            ],
                            staticClass: "form-control input-sm",
                            attrs: { id: "bank_id" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.bank_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v("Please select one")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.availableBrands, function(brand) {
                              return _c(
                                "option",
                                {
                                  key: brand.id,
                                  domProps: { value: brand.id }
                                },
                                [_vm._v(_vm._s(brand.name))]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group settingsTplSelect" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "pricing_engine_tpl" } },
                            [_vm._v("Settings Template: ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedEngineTpl,
                                  expression: "selectedEngineTpl"
                                }
                              ],
                              staticClass: "form-control input-sm",
                              attrs: { id: "pricing_engine_tpl" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedEngineTpl = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.addSettingsTpl
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v("Select settings tpl")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.engineTpls, function(engineTpl) {
                                return _c(
                                  "option",
                                  {
                                    key: engineTpl.value,
                                    domProps: { value: engineTpl.value }
                                  },
                                  [_vm._v(_vm._s(engineTpl.label))]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.selectedEngineTpl === "optimal_blue" ||
                      _vm.selectedEngineTpl === "loan_sifter"
                        ? _c(
                            "div",
                            { staticClass: "form-group sync-required-btn" },
                            [
                              _c("button", { on: { click: _vm.openPEModal } }, [
                                _vm._v("Options")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "checkbox ml-3 pt-1" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isDefault,
                                expression: "isDefault"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.isDefault)
                                ? _vm._i(_vm.isDefault, null) > -1
                                : _vm.isDefault
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.isDefault,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isDefault = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isDefault = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isDefault = $$c
                                  }
                                },
                                _vm.configChanged
                              ]
                            }
                          }),
                          _vm._v("\n          Default")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("vjsoneditor", {
                      attrs: {
                        options: _vm.options,
                        plus: true,
                        height: _vm.height
                      },
                      on: { error: _vm.onError },
                      model: {
                        value: _vm.config,
                        callback: function($$v) {
                          _vm.config = $$v
                        },
                        expression: "config"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex pt-3" }, [
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "BaseButton",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.changed ||
                                    (_vm.addingNew && _vm.bank_id),
                                  expression:
                                    "changed || (addingNew && bank_id)"
                                }
                              ],
                              staticClass: "mr-2",
                              on: { click: _vm.saveConfig }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.addingNew ? "Add" : "Save") +
                                  "\n        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "BaseButton",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.config || _vm.addingNew,
                                  expression: "config || addingNew"
                                }
                              ],
                              attrs: { outline: "" },
                              on: { click: _vm.deleteWorkflow }
                            },
                            [_vm._v("\n          Delete\n        ")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
          _vm._v(" "),
          _c(
            "BaseModal",
            {
              ref: "modal",
              attrs: {
                title: _vm.peModalTitle,
                id: "peModal",
                isLoading: _vm.isLoading,
                showCancelBtn: false,
                okLabel: "Close"
              },
              on: { ok: _vm.hidePEModal },
              model: {
                value: _vm.showPEModal,
                callback: function($$v) {
                  _vm.showPEModal = $$v
                },
                expression: "showPEModal"
              }
            },
            [
              _c("h5", [_vm._v("\n      Add New LO\n    ")]),
              _vm._v(" "),
              _c("p", { staticClass: "intro" }),
              _vm._v(" "),
              _c("div", [
                _c("button", { on: { click: _vm.clearOBCache } }, [
                  _vm._v("Clear OB Cache")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lo-search-wrapper" },
                [
                  _c("autocomplete", {
                    staticClass: "form-group material-input",
                    attrs: {
                      placeholder: "Search LO & Connect Pricing Engine",
                      "input-class": "form-control ob-lo-search",
                      resultsFormatter: _vm.acResultsFormatter,
                      resultsDisplay: _vm.acResultsDisplay,
                      source: _vm.obLOAutocomplete
                    },
                    on: { selected: _vm.selectOBLO }
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "selected-ob-los" },
                    _vm._l(_vm.obLOs, function(lo, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "selected-ob-lo" },
                        [
                          _c(
                            "div",
                            { staticClass: "selected-ob-lo-label col-sm-5" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(lo.firstName) +
                                  " " +
                                  _vm._s(lo.lastName) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedOBMembers[index],
                                    expression: "selectedOBMembers[index]"
                                  }
                                ],
                                ref: "obMemberSelect",
                                refInFor: true,
                                staticClass: "form-control input-sm",
                                attrs: { id: "obMember" + index },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.selectedOBMembers,
                                        index,
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function($event) {
                                      _vm.changeSelectedOBMember($event, index)
                                    }
                                  ]
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", value: "" } },
                                  [_vm._v("Please select one")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.members, function(member) {
                                  return _c(
                                    "option",
                                    {
                                      key: member.loid,
                                      domProps: { value: member.loid }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(member.name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-1" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.removeOBLO(index)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "selected-ob-lo-delete fas fa-trash"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" })
                        ]
                      )
                    })
                  ),
                  _vm._v(" "),
                  _vm.obLOs && _vm.obLOs.length > 0
                    ? _c("div", [
                        _c("button", { on: { click: _vm.addOBLOs } }, [
                          _vm._v("Add")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c("button", { on: { click: _vm.syncAll } }, [
                      _vm._v("Sync All")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-33107491", { render: render, staticRenderFns: staticRenderFns })
  }
}