<doc>
  Programs Vue.js component
</doc>

<template>
  <div>
    <Tabs :initialHash="initialHash">
      <Tab name="Getting Started">
        <mq-layout :mq="['sm', 'md']">
          <ProgramCarousel :toggleItemModal="toggleItemModal" />
        </mq-layout>
        <mq-layout mq="lg">
          <ProgramJumbotrom :toggleItemModal="toggleItemModal" />
        </mq-layout>
      </Tab>
      <Tab name="My Programs">
        <SmartList
          :searchQuery="searchQuery"
          :searchQueries="searchQueries"
          :filteredItems="filteredItems"
          :addQuery="addQuery"
          :removeQuery="removeQuery"
          :resetQuery="resetQuery"
          :toggleListView="toggleListView"
          :addItemAction="toggleItemModal"
          :items="items"
          :onDragEnd="onEnd"
          :gridView="gridView"
          listType="program"
        />
      </Tab>
    </Tabs>
    <ProgramModal />
    <ProgramDetailsModal />
  </div>
</template>

<script>
import _isNull from "lodash/isNull"
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import {
  ProgramJumbotrom,
  ProgramModal,
  ProgramDetailsModal,
  Tab,
  Tabs,
  Jumbotron,
  Programs,
  TheProgramsToolbar,
  SmartList,
  ProgramCarousel
} from "../components"

export default {
  name: "MyPrograms",
  components: {
    SmartList: SmartList,
    Tabs: Tabs,
    Tab: Tab,
    ProgramModal: ProgramModal,
    ProgramDetailsModal: ProgramDetailsModal,
    ProgramJumbotrom: ProgramJumbotrom,
    Programs: Programs,
    TheProgramsToolbar: TheProgramsToolbar,
    ProgramCarousel: ProgramCarousel
  },
  computed: Object.assign(
    {},
    mapState(["originalItems", "gridView", "searchQueries", "searchQuery"]),
    mapGetters(["currentItems"]),
    {
      initialHash: function() {
        return !_isNull(this.originalItems) && this.originalItems.length
          ? "my-programs"
          : ""
      },
      items: {
        get() {
          try {
            return this.currentItems
          } catch (e) {
            console.log(e)
          }
        },
        set(value) {
          /**
           * The "correct" way is commit the update here,
           * but vuedraggable seems to have issues with it.
           * We are doing it on the "onEnd" event
           **/
          // this.$store.commit("updateSets", value)
        }
      }
    }
  ),
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has("id")) {
      this.showEditProgramDetailsModal(urlParams.get("id"))
    }
  },
  methods: Object.assign(
    {},
    mapMutations({
      toggleListView: "TOGGLE_LIST_VIEW"
    }),
    mapActions([
      "filteredItems",
      "addQuery",
      "removeQuery",
      "resetQuery",
      "toggleItemModal",
      "showEditProgramDetailsModal",
      "updateOriginalItems"
    ]),
    {
      onEnd: function(oldIndex, newIndex) {
        this.updateOriginalItems({ oldIndex: oldIndex, newIndex: newIndex })
      }
    }
  )
}
</script>

<style lang="scss" scoped></style>
