<template>
    <div class="panel" :class="newQuoteHeader">
        <!-- Default panel contents -->
        <div class="panel-heading">
            {{ (label ? label : "Loan Option") }}
            <span class="value-arrow">
                <i v-if="comparison && comparison.relative_value > 0" class="fas fa-arrow-down"></i>
                <i v-else-if="comparison && comparison.relative_value < 0" class="fas fa-arrow-up"></i>
            </span>
        </div>
        <div class="panel-body">
            <h2>
                {{rate}}%
            </h2>
        </div>
        <!-- List group -->
        <ul class="list-group">
            <li class="list-group-item"><div class="label">Price</div>{{price}}</li>
            <li class="list-group-item"><div class="label">{{fee.title}}</div>${{fee.amount}}</li>
            <li v-if="loanAmount" class="list-group-item"><div class="label">Loan Amount</div>${{loanAmountFormatted}}</li>
            <li v-if="listPrice" class="list-group-item"><div class="label">Property Value</div>${{listPriceFormatted}}</li>
            <li class="list-group-item"><div class="label">P/I</div>${{principalAndInterest}}</li>
            <li v-if="false" class="list-group-item"><div class="label">Term</div>{{term}} year</li>
            <li v-if="type" class="list-group-item" :title="type"><div class="label">Type</div>{{shortenStr(type)}}</li>
            <li class="list-group-item" :title="investor"><div class="label">Investor</div>{{shortenStr(investor)}}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "CalcCard",
        props:{
            rate: {type: Number, required: true},
            price: {type: Number, required: true},
            fee: {type: Object, required: true},
            loanAmount: {type: Number},
            listPrice: {type: Number},
            term: {type: Number},
            armTerm: {type: String},
            type: {type: String},
            principalAndInterest: {type: Number, required: true},
            investor: {type: String, required: true},
            comparison: {type: Object},
            active: {type: Boolean},
            label: {type: String}
        },
        computed:{
            loanAmountFormatted(){
                return Number(this.loanAmount).toLocaleString()
            },
            listPriceFormatted(){
              return Number(this.listPrice).toLocaleString()
            },
            principleAndInterestFormatted(){
                return Number(this.principalAndInterest).toLocaleString()
            },
            feeAmount(){
                return Number(this.fee.amount).toLocaleString()
            },
            newQuoteHeader(){
                return ( this.active || (this.comparison && this.comparison.relative_value>0) ? "panel-success" : "panel-default")
            }
        },
        methods:{
            shortenStr(str){
                let maxLength=14;
                return (str.length > maxLength) ? str.substr(0, maxLength-1) + '...' : str;
            }
        }
    }
</script>

<style scoped>
    .comparison-info{
        margin-top: 20px;
    }
    .value-arrow{
        float: right;
    }
    .label{
      color: #666;
    }
</style>