<doc>
  Program carousel for mobile Vue.js component
</doc>

<template>
  <flickity ref="flickity" :options="flickityOptions" class="text-center flickity-wrapper">
    <div class="carousel-cell">
      <div class="carousel-cell-inner">
        <h1>Create, assign and save customized program sets to use in your marketing.</h1>
        <img
          src="/sites/all/themes/bankingbridge_ui/images/pricing-demo-1.png"
          alt="Program demo"
          style="margin: 15px 0 18px;"
        />
        <h2>1. Create a new program set</h2>
        <p
          class="text-center"
        >Start by clicking the round, bright orange “plus” button at the top-right corner of the page.</p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="carousel-cell-inner">
        <h1>Create, assign and save customized program sets to use in your marketing.</h1>
        <img
          src="/sites/all/themes/bankingbridge_ui/images/pricing-demo-2.png"
          alt="Program demo"
          style="margin: 25px 0 45px;"
        />
        <h2>2. Set your new program’s parameters</h2>
        <p
          class="text-center"
        >Prioritize your loan programs for each set and give it context with a "whitelist" filter. For example: you may want your FHA loan to appear first when marketing to a specific neighborhood.</p>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="carousel-cell-inner">
        <h1>Create, assign and save customized program sets to use in your marketing.</h1>
        <img
          src="/sites/all/themes/bankingbridge_ui/images/pricing-demo-3.png"
          alt="Program demo"
          style="margin-bottom: 15px;"
        />
        <h2>3. Use saved sets to populate media</h2>
        <p
          class="text-center"
        >Once you have a Program Set created, if a pricing is requested for property that matches your filter. That program set will determine the priority in which your loan programs are displayed.</p>

        <flex-box justify="center">
          <base-button
            type="button"
            outline
            class="mt-4"
            v-on:click="toggleItemModal"
          >Create my first set</base-button>
        </flex-box>
      </div>
    </div>
  </flickity>
</template>

<script>
import Flickity from "vue-flickity"

export default {
  name: "ProgramCarousel",
  components: {
    Flickity
  },
  props: {
    toggleItemModal: { type: Function, required: true }
  },
  data() {
    return {
      flickityOptions: {
        prevNextButtons: false,
        pageDots: true,
        wrapAround: false

        // any options from Flickity can be used
      }
    }
  }
}
</script>

<style lang="scss">
.flickity-wrapper {
  color: #0c0033;
  margin: 0 -15px;

  h1 {
    margin: 30px 0 15px;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  .carousel-cell {
    background: #fff;
    height: calc(100vh - 200px);
    width: 100%;
    &-inner {
      padding: 0 45px;
    }
    &::before {
      content: "";
      display: block;
      height: 8px;
      width: 100%;
      background: linear-gradient(63.84deg, #58bec8 0%, #29c9c5 100%);
    }
    img {
      max-width: 50%;
    }
  }

  .flickity-page-dots {
    .dot {
      background: #29c9c5;
    }
  }
}
</style>
