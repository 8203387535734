var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _vm.isLoading
        ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "btn-group dateRanges",
                  attrs: { role: "group", "aria-label": "..." }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      class: { active: _vm.rangeDays == 30 },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.setRange(30)
                        }
                      }
                    },
                    [_vm._v("30 days")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      class: { active: _vm.rangeDays == 7 },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.setRange(7)
                        }
                      }
                    },
                    [_vm._v("7 days")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      class: { active: _vm.rangeDays == 1 },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.setRange(1)
                        }
                      }
                    },
                    [_vm._v("1 day")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "vue-good-table",
                {
                  attrs: {
                    columns: _vm.columns,
                    rows: _vm.rows,
                    "row-style-class": _vm.rowStyleClassFn,
                    "pagination-options": _vm.pagination_options,
                    "search-options": {
                      enabled: true,
                      skipDiacritics: true
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "after"
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "viewDetails",
                                    on: {
                                      click: function($event) {
                                        _vm.showPanelFn(props.row)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-info-circle"
                                    })
                                  ]
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    "\n        "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [_vm._v('\n      styleClass="vgt-table striped" >\n      ')]
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("slideout-panel")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5b63e0a5", { render: render, staticRenderFns: staticRenderFns })
  }
}