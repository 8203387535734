var getTokens = function() {
    var tokens = [];
    var query = location.search;
    query = query.slice(1);
    query = query.split('&');
    $.each(query, function(i,value){    
        var token = value.split('=');   
        var key = decodeURIComponent(token[0]);     
        var data = decodeURIComponent(token[1]);
        tokens[key] = data;
    });
    return tokens;
};

var debounce = function(func, wait, immediate){
    var timeout
    return function() {
        var context = this,
            args = arguments
        var later = function() {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}
module.exports = {
    getTokens,
    debounce
}
