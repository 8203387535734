/**
 * Default modal data & titles text
 */

export default () => {
  return {
    isLoading: false,
    disableNext: true,
    disableSaveNext: true,
    currentStep: 0,
    showSubmit: false,
    showSaveNext: false,
    showNext: true,
    showBack: false,
    showAlert: false,
    isRealtorEdit: false,
    confirmTxt: "",
    isConfirmRealtor: false,
    form: {
      pictures: [],
      realtorData: {}
    },
    formFields: [
      {
        title: "Select flyer template",
        intro: "Customize by selecting a color scheme.",
        requiredFields: ["theme", "swatch"]
      },
      {
        title: "Choose Builder Mode",
        intro: "You can build a flyer using real-time listing data, or create one manually.",
        requiredFields: []
      },
      {
        title: "Set your parameters",
        intro: "Set up the realtor. Click \"Next\" to review the realtor parameters.",
        requiredFields: ["realtor"]
      },
      {
        title: "Set your parameters",
        intro: "Set up the parameters of your new custom loan set.",
        requiredFields: ["zipcode", "state", "county", "street_addr", "city"]
      },
      {
        title: "Set your parameters",
        intro: "Set up the parameters of your new custom loan set.",
        requiredFields: ["year_built", "property_type"]
      },
      {
        title: "Set your parameters",
        intro: "Set up the parameters of your new custom loan set.",
        requiredFields: [
          "listing_price",
          "sqft",
          "num_beds",
          "num_baths"
        ]
      },
      {
        title: "Set Property Overview",
        intro: "Set the property overview of this flyer.",
        requiredFields: ["overview"]
      },
      {
        title: "Upload Pictures",
        intro:
          "Upload up to 4 pictures to display in the flyer. Drag the thumbnails to set the order. Supported file types: jpeg, png. Must be less than 5MB.",
        requiredFields: ["pictures"]
      },
      {
        title: "",
        intro:
            "",
        requiredFields: []
      },
      {
        title: "Your new flyer is being created",
        intro: "This might take a moment…"
      }
    ]
  }
}
