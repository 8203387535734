<doc>
  Theme selector Vue.js component
</doc>

<template>
  <div class="fts" v-show="!isLoading">
    <grid-row>
      <grid-column v-for="template in templates" :key="template.id">
        <div
          :class="wrapperClassName(template.id)"
          v-on:click="setTemplate(template.id)"
        >
          <img class="fts__thumb" :src="template.preview_url" />
          <icon
            class="fts__check-icon"
            name="check"
            v-if="template.id === themeSelected"
          />
        </div>
      </grid-column>
    </grid-row>
    <div v-if="swatches" class="fts__color-selector">
      <div
        v-for="(swatch, index) in swatches"
        :key="index"
        :class="swatchClassName(index)"
        v-on:click="setSwatch(index)"
      >
        <span :style="`background-color: ${swatch.primaryColor}`"></span>
        <span
          :style="
            `background-color: ${
              swatch.secondaryColor
                ? swatch.secondaryColor
                : swatch.primaryColor
            }`
          "
        ></span>
        <icon
          class="fts__check-icon is-small"
          name="check"
          v-if="index === swatchSelected"
        />
      </div>
    </div>
    <LoaderPlaceholder v-if="isLoading" type="circle" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import _find from "lodash/find"
// TODO: this should be in a global scope, not with namespace
const { mapState, mapActions } = createNamespacedHelpers("manualListing")
export default {
  name: "ThemeSelector",
  props: {
    onThemeSelect: { type: Function, required: true },
    theme: { type: [Number, Boolean, String], default: false },
    swatch: { type: [Number, Boolean], default: false }
  },
  data: function() {
    return {
      swatchesold: null
    }
  },
  computed: Object.assign({}, mapState(["templates"]), {
    themeSelected: function() {
      return this.theme
    },
    swatchSelected: function() {
      return this.swatch
    },
    swatches: function() {
      let swatches = null
      if (this.theme && this.templates) {
        const template = _find(this.templates, ["id", this.theme])
        if (template && template.settings && template.settings.swatches) {
          swatches = template.settings.swatches
        }
      }
      return swatches
    },
    isLoading: function() {
      return !this.templates
    }
  }),
  methods: Object.assign({}, mapActions(["getTemplates"]), {
    setTemplate: function(template_id) {
      this.onThemeSelect(template_id, "theme")
      this.onThemeSelect(null, "swatch")
    },
    setSwatch: function(index) {
      this.onThemeSelect(index, "swatch")
    },
    wrapperClassName: function(template_id) {
      return `fts__option${
        template_id === this.themeSelected ? " is-selected" : ""
      }`
    },
    swatchClassName: function(index) {
      return `fts__option is-small is-swatch${
        index === this.swatchSelected ? " is-selected" : ""
      }`
    }
  }),
  created() {
    this.getTemplates()
  }
}
</script>

<style lang="scss" src="./ThemeSelector.scss" scoped></style>
