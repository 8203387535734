import Vue from "vue"
import Vuex from "vuex"
import smartlist from "../modules/smartlist"
import bbPricing from "../modules/bb-pricing"
import { createLogger } from "../../helpers"
import _merge from "lodash/merge"

const initStore = () => {
	Vue.use(Vuex)

	const debug = process.env.NODE_ENV !== "production"
	const states = _merge({}, smartlist.state(), bbPricing.state)
	const modules = _merge({}, smartlist, bbPricing)
	modules.state = states
	return new Vuex.Store({
		...modules,
		strict: debug,
		plugins: debug ? [createLogger()] : []
	})
}
export default initStore
