var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "importPeopleModal",
        isLoading: _vm.isLoading,
        large: true,
        "cancel-label": "Close",
        "ok-label": "Import"
      },
      on: { cancel: _vm.hideModal, ok: _vm.createAccounts },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c("div", { staticClass: "select-brands" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected_brand_id,
                expression: "selected_brand_id"
              }
            ],
            staticClass: "select-template",
            on: {
              change: [
                function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selected_brand_id = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.changeSelectedBrandID
              ]
            }
          },
          [
            _c("option", { attrs: { disabled: "", value: "" } }, [
              _vm._v("Please select one")
            ]),
            _vm._v(" "),
            _vm._l(_vm.brands, function(brand) {
              return _c(
                "option",
                { key: brand.name, domProps: { value: brand.id } },
                [_vm._v("\n        " + _vm._s(brand.name) + "\n      ")]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "progress" }, [
        _c(
          "div",
          {
            staticClass: "progress-bar",
            style: { width: _vm.progressPct + "%" },
            attrs: {
              role: "progressbar",
              "aria-valuenow": _vm.progressPct,
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }
          },
          [_vm.progressPct == 100 ? _c("span", [_vm._v("Complete")]) : _vm._e()]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("vue-good-table", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.rows,
              "pagination-options": _vm.pagination_options,
              "search-options": {
                enabled: true,
                skipDiacritics: true
              },
              styleClass: "vgt-table striped"
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function(props) {
                  return [
                    props.column.field == "email"
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(props.row.email) +
                              "\n          "
                          ),
                          !props.row.inProgress
                            ? _c("i", {
                                staticClass: "fas fa-check-circle pull-right",
                                class: {
                                  created: !!props.row.created,
                                  updated: !!props.row.updated,
                                  errored: !!props.row.errored
                                }
                              })
                            : _c("i", {
                                staticClass: "fas fa-sync-alt pull-right"
                              })
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(props.formattedRow[props.column.field]) +
                              "\n        "
                          )
                        ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a964e7f4", { render: render, staticRenderFns: staticRenderFns })
  }
}