<doc>
	Flexible component that can optionally extend the entire viewport
</doc>

<template>
  <div class="jumbotron">
    <h1 v-if="title" class="jumbotron-title">{{title}}</h1>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Jumbotron",
  props: {
    title: { type: String }
  }
}
</script>

<style lang="scss">
.jumbotron {
  background: #fff;
  padding: 40px 25px;
  position: relative;
  color: #0c0033;

  &::before {
    content: "";
    display: block;
    height: 7px;
    width: 100%;
    transform: scaleY(-1);
    border-radius: 0 0 4px 4px;
    background: linear-gradient(63.84deg, #58bec8 0%, #29c9c5 100%);
    position: absolute;
    left: 0;
    top: 0;
  }
  &-title {
    font-size: 30px;
    line-height: 1.8em;
    margin: 0 auto 45px;
    max-width: 777px;
    padding: 0 25px;
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  img {
    max-width: 100%;
  }

  [class^="col"] {
    &:first-child {
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      height: 65%;
      width: 1px;
      background: rgba(196, 196, 196, 0.5);
      display: block;
      position: absolute;
      left: 0;
      top: 16%;
    }
  }
}
</style>
