<template>
  <div class="editorContainer">
    <form
      id="integration-form"
      class="form"
      ref="integrationForm"
      v-on:submit.prevent
    >
      <fieldset class="generalSettings">
        <InputText
          v-model="integration.title"
          label="Title"
          name="title"
          @onBlur="updateField"
        />
        <InputText
          v-model="integration.url"
          label="URL"
          name="url"
          @onBlur="updateField"
        />

        <div class="integration-domains">
          <div v-if="integration.domains && integration.domains.length">
            <div
              v-for="(domain, index) in integration.domains"
              :key="domain.id || index"
              class="integration-domains__row"
            >
              <InputText
                v-model="domain.domain_pattern"
                label="Domain"
                :name="`domain-${domain.id}`"
                @onBlur="updateDomain(index)"
                placeholder="Ex. https://www.google.com"
                hideLabel
              />
              <BaseButton
                outline
                size="xs"
                class="btn btn-default integration-domains__remove"
                @click="removeDomain(domain, index)"
                btnIcon="minus"
              >
                <span class="sr-only">remove domain</span>
              </BaseButton>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-3 integration-domains__footer"
          >
            <small class="form-text text-muted">
              Enter base URL of site that will be integrated.
            </small>
            <BaseButton
              outline
              size="xs"
              class="btn btn-default add-domian-btn"
              @click="addDomain"
            >
              Add a new domain
            </BaseButton>
          </div>
        </div>
        <div>
          <InputText
            v-model="extraSettings.accent_color"
            label="Primary Color"
            name="accent_color"
            @onBlur="updateField"
            class="accent_color_field"
          />
          <span
            class="accent_color_preview"
            :style="{ 'background-color': extraSettings.accent_color }"
          ></span>
        </div>
        <div>
          <InputText
            v-model="extraSettings.secondary_accent_color"
            label="Secondary Color"
            name="secondary_accent_color"
            @onBlur="updateField"
            class="accent_color_field"
          />
          <span
            class="accent_color_preview"
            :style="{
              'background-color': extraSettings.secondary_accent_color
            }"
          ></span>
        </div>
        <div>
          <InputText
            v-model="extraSettings.ga_tag"
            label="Google Analytics Tag"
            name="ga_tag"
            @onBlur="updateField"
            class="ga_tag_field"
          />
          <div class="btn-group btn-group-xs ga-switch">
            <BaseButton
              :outline="extraSettings.ga_mode !== 'internal'"
              size="xs"
              @click="setGaMode('internal')"
            >
              Internal
            </BaseButton>
            <BaseButton
              :outline="extraSettings.ga_mode !== 'external'"
              size="xs"
              @click="setGaMode('external')"
            >
              External
            </BaseButton>
          </div>
        </div>
      </fieldset>
      <fieldset v-if="integration.type != 'api'">
        <ImgUploader
          v-if="integration.type != 'standalone' && integration.type != 'api'"
          previewAlt="Header Background Image"
          :editMode="true"
          :previewSrc="extraSettings.header_bg_img"
          previewClass="generalSettings__bg-preview"
          label="Header Background Image"
          :uploadCallback="uploadCallback"
          :labelTop="true"
          :hasRemove="true"
          :removePhoto="removePhoto"
        />
      </fieldset>
      <fieldset>
        <div class="checkboxField">
          <input
            id="mort_calc"
            type="checkbox"
            v-model="integration.app_settings.mort_calc"
            @change="updateField"
          />
          <label for="mort_calc">Enable Mortgage Calculator</label>
        </div>
        <div class="checkboxField">
          <input
            id="afford_calc"
            type="checkbox"
            v-model="integration.app_settings.afford_calc"
            @change="updateField"
          />
          <label for="afford_calc">Enable Affordability Calculator</label>
        </div>
        <div class="checkboxField">
          <input
            id="lead_workflow_enabled"
            type="checkbox"
            v-model="integration.app_settings.lead_workflow"
            @change="updateField"
          />
          <label for="lead_workflow_enabled">Enable Lead Workflow</label>
          <div
            class="selectWrapper"
            v-if="integration.app_settings.lead_workflow && availableWorkflows"
          >
            <select
              class="select-workflow"
              v-model="integration.app_settings.lead_workflow"
              @change="updateField"
              id="lead_workflow"
            >
              <option disabled value="">Please select one</option>
              <option
                v-for="wf in availableWorkflows"
                :value="wf.id"
                :key="wf.id"
              >
                {{ wf.name }}
              </option>
            </select>
            <div
              v-if="
                integration.app_settings.lead_workflow &&
                  integration.app_settings.lead_workflow !== true
              "
              class="selectEditLink"
            >
              <a
                target="_blank"
                :href="
                  '/bb_lead_workflow_admin?id=' +
                    integration.app_settings.lead_workflow
                "
                >edit</a
              >
            </div>
          </div>
        </div>
        <div class="checkboxField">
          <input
            id="contactFormEnable"
            type="checkbox"
            @change="updateField"
            v-model="extraSettings.contactForm"
          />
          <label for="contactFormEnable">Enable Contact Form</label>
          <div
            class="selectWrapper"
            v-if="
              extraSettings &&
                extraSettings.contactForm &&
                availableContactForms
            "
          >
            <select
              class="select-contact-form"
              v-model="extraSettings.contactForm"
              @change="updateField"
              id="contactForm"
            >
              <option disabled value="">Please select one</option>
              <option
                v-for="cf in availableContactForms"
                :value="cf.id"
                :key="cf.id"
              >
                {{ cf.name }}
              </option>
            </select>
            <div
              v-if="
                extraSettings.contactForm && extraSettings.contactForm !== true
              "
              class="selectEditLink"
            >
              <a
                target="_blank"
                :href="'/contact_form_admin?id=' + extraSettings.contactForm"
                >edit</a
              >
            </div>
          </div>
        </div>
        <div class="checkboxField">
          <input
            id="pricing"
            type="checkbox"
            v-model="integration.app_settings.pricing"
            @change="updateField"
          />
          <label for="pricing">Enable Pricing Cards</label>
          <div class="selectWrapper" v-if="integration.app_settings.pricing">
            <div v-if="availablePricingSets !== null">
              <select
                  class="select-contact-form"
                  v-model="integration.app_settings.pricing_set_id"
                  @change="updateField"
                  id="pricing_set_id"
              >
                <option disabled value="">Please select one</option>
                <option
                    v-for="ps in availablePricingSets"
                    :value="ps.id"
                    :key="ps.id"
                >
                  {{ ps.name }}
                </option>
              </select>
              <div
                  v-if="integration.app_settings.pricing_set_id"
                  class="selectEditLink"
              >
                <a
                    target="_blank"
                    :href="
                  '/my-programs?id=' +
                    integration.app_settings.pricing_set_id +
                    '#my-programs'
                "
                >edit</a
                >
              </div>
            </div>
            <div v-else>Loading...</div>
          </div>
        </div>
        <div class="checkboxField">
          <input
              id="globalExitPopUpEnable"
              type="checkbox"
              v-model="extraSettings.globalExitPopUpEnable"
              @change="updateField"
          />
          <label for="globalExitPopUpEnable">Enable Global Exit Popup</label>
        </div>
      </fieldset>
      <fieldset v-if="integration.type != 'api'">
        <div class="checkboxField">
          <input
            id="loReviews"
            type="checkbox"
            v-model="extraSettings.loReviews"
            @change="updateField"
          />
          <label for="loReviews">Enable LO Reviews</label>
        </div>
        <div class="checkboxField">
          <input
            id="brandReviews"
            type="checkbox"
            v-model="extraSettings.brandReviews"
            @change="updateField"
          />
          <label for="brandReviews">Enable Brand Reviews</label>
        </div>
        <div class="checkboxField">
          <input
            id="rateTable"
            type="checkbox"
            v-model="extraSettings.rateTable"
            @change="updateField"
          />
          <label for="rateTable">Enable Rate Table</label>
        </div>
        <div class="checkboxField">
          <input
            id="aboutBoxes"
            type="checkbox"
            v-model="extraSettings.aboutBoxes"
            @change="updateField"
          />
          <label for="aboutBoxes">Enable About Boxes</label>
        </div>
      </fieldset>
      <fieldset v-if="integration.type == 'api'"></fieldset>
      <fieldset class="advancedWrapper">
        <button @click="expandAdvanced" class="toggleBtn">Advanced</button>
        <div class="advancedSettings" v-if="advancedExpanded">
          <div class="checkboxField">
            <input
                id="expose_api"
                type="checkbox"
                v-model="disable_api"
                @change="updateField"
            />
            <label for="expose_api">Disable API</label>
          </div>
          <div class="checkboxField">
            <input
                id="disableCalcCards"
                type="checkbox"
                v-model="extraSettings.disableCalcCards"
                @change="updateField"
            />
            <label for="disableCalcCards">Disable Loan Calc Cards</label>
          </div>
          <div
            class="selectWrapper"
            v-if="integration && integration.owner && availableOwners"
          >
            <div v-if="changingOwner">
              <label for="owner">Select Owner</label>
              <select
                class="select-owner"
                v-model="integration.owner"
                @change="updateField"
                id="owner"
              >
                <option disabled value="">Please select one</option>
                <option
                  v-for="o in availableOwners"
                  :value="o.uid"
                  :key="o.uid"
                >
                  {{ o.name }}
                </option>
              </select>
            </div>
            <div v-else>
              <button id="changeOwner" @click="toggleChangingOwner()">
                Change Owner
              </button>
            </div>
          </div>
          <div>
            <InputText
              v-model="extraSettings.postUrl"
              label="POST Url"
              name="postUrl"
              @onBlur="updateField"
            />
          </div>
          <div>
            <div v-if="hasUTM">
              URL params being tracked:
              <ul>
                <li v-for="item in extraSettings.customParams">
                  {{ item.key }} <span class="removeParam" @click="removeParam(item.key)"><icon name="trash" /></span>
                </li>
              </ul>
              <input-text name="url-param-key" label="Param Key" v-model="newParam" /><button @click="addURLParam">Add</button>
            </div>
            <div v-else>
              <button @click="addUTMTracking">Add UTM Tracking</button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import md5 from "js-md5"
import Vue from "vue"
import ImgUploader from "../ImgUploader"

export default {
  name: "Settings",
  props: {
    integration: { type: Object },
    extraSettings: { type: Object },
    selectData: { type: Object }
  },
  components: {
    ImgUploader
  },
  data() {
    return {
      hash: null,
      availableWorkflows: null,
      availableContactForms: null,
      availableOwners: null,
      availablePricingSets: null,
      currentEditedDomain: null,
      changingOwner: false,
      activeComponent: null,
      advancedExpanded: false,
      utmTracker: 0,
      newParam: ""
    }
  },
  async mounted() {
    this.availableOwners = this.selectData.availableOwners
    this.availableContactForms = this.selectData.availableContactForms
    this.availableWorkflows = this.selectData.availableWorkflows
    this.availablePricingSets = this.selectData.availablePricingSets
  },
  created() {
    this.hash = this.getHash()
  },
  computed: {
    hasUTM() {
      this.utmTracker
      return (
        this.extraSettings &&
        this.extraSettings.customParams &&
        this.extraSettings.customParams.findIndex(item => {
          return item.name == "utm_campaign"
        })
      )
    },
    disable_api: {
      get() {
        // if the value is undefined, it means the API is enabled
        if(this.extraSettings.expose_api === undefined) return false
        return !this.extraSettings.expose_api;
      },
      set(value) {
        this.extraSettings.expose_api = !value;
      }
    }
  },
  methods: {
    uploadCallback(logo_fid, filename, url) {
      this.extraSettings.header_bg_img = url
      this.sendUpdate("header_bg_img")
    },
    removePhoto() {
      this.extraSettings.header_bg_img = null
      this.sendUpdate("header_bg_img")
    },
    onInput(val, $event) {
      console.log(val)
    },
    submitForm() {},
    async removeDomain(domain, index) {
      let domains = [...this.integration.domains]
      domains.splice(index, 1)
      Vue.set(this.integration, "domains", domains)

      // if it was just added but not edited is empty and it was not
      // saved thus we don't the API call
      if (!domain.id) {
        return
      }
      let response = null
      try {
        response = await BB.integration.deleteDomain(domain.id)
        if (response && response.status == "updated") {
          BB.Toastr.success("Domain Removed")
          this.hash = this.getHash()
        }
      } catch (e) {
        BB.Toastr.error("Something went wrong, reload the page")
        return
      }
    },
    addDomain() {
      const newDomain = [{ domain_pattern: "" }]
      const val = this.integration.domains
        ? [...this.integration.domains, ...newDomain]
        : newDomain

      Vue.set(this.integration, "domains", val)
    },
    async updateDomain(domainIndex) {
      this.currentEditedDomain = this.integration.domains[domainIndex]
      this.currentEditedDomain.index = domainIndex
      this.sendUpdate("domain")
    },
    async updateField(e) {
      let field = e.event ? e.event.srcElement.id : e.srcElement.id
      this.sendUpdate(field)
    },
    async sendUpdate(field) {
      if (this.hash == this.getHash()) return
      let flag = false
      let primarySettings = ["title", "url", "owner"]
      let appSettings = [
        "afford_calc",
        "lead_workflow",
        "mort_calc",
        "pricing",
        "pricing_set_id"
      ]
      let extraSettings = [
        "accent_color",
        "secondary_accent_color",
        "ga_tag",
        "ga_mode",
        "aboutBoxes",
        "rateTable",
        "customParams",
        "loReviews",
        "brandReviews",
        "contactForm",
        "header_bg_img",
        "postUrl",
        "disableCalcCards",
        "globalExitPopUpEnable",
        "expose_api"
      ]
      let updateData = {
        id: this.integration.id,
        owner: this.integration.owner,
        integration_type: this.integration.type
      }

      if (field == "domain") {
        updateData[field] = Object.assign({}, this.currentEditedDomain)
        delete updateData[field].index
        flag = true
      }
      if (field == "lead_workflow_enabled") {
        updateData[updateData.integration_type] = {}
        updateData[updateData.integration_type]["lead_workflow"] = this
          .integration.app_settings.lead_workflow
          ? 1
          : 0
        flag = true
      }
      if (field == "contactFormEnable") {
        updateData[updateData.integration_type] = {}
        updateData[updateData.integration_type][
          "contactForm"
        ] = this.extraSettings.contactForm
        flag = true
      }
      if (primarySettings.indexOf(field) >= 0) {
        console.log("primarySettings change")
        updateData[field] = this.integration[field]
        flag = true
      }
      if (appSettings.indexOf(field) >= 0) {
        console.log("appSettings change")
        updateData[this.integration.type] = {}
        if (
          this.integration.app_settings[field] === 0 ||
          this.integration.app_settings[field] === 1
        )
          this.integration.app_settings[field] = parseInt(
            this.integration.app_settings[field]
          )
        else if (
          this.integration.app_settings[field] === true ||
          this.integration.app_settings[field] === false
        )
          this.integration.app_settings[field] = this.integration.app_settings[
            field
          ]
            ? 1
            : 0
        updateData[this.integration.type][
          field
        ] = this.integration.app_settings[field]
        flag = true
      }
      if (extraSettings.indexOf(field) >= 0) {
        console.log("extraSettings change")
        updateData[this.integration.type] = {}
        //if(this.extraSettings[field]==true || this.extraSettings[field]==false)this.extraSettings[field]=( this.extraSettings[field] ? 1 : 0 )
        updateData[this.integration.type][field] = this.extraSettings[field]
        flag = true
      }
      if (!flag) {
        return
      }
      let response = null
      try {
        response = await BB.integration.update(updateData)
        if (response && response.status === "updated") {
          BB.Toastr.success("Integration Updated")
          this.hash = this.getHash()
        } else if (response && response.status == "success") {
          if (field === "domain" && response.domain_id) {
            BB.Toastr.success("Domain Added")
            const domains = [...this.integration.domains]
            domains[this.currentEditedDomain.index].id = response.domain_id
            Vue.set(this.integration, "domains", domains)
          } else {
            BB.Toastr.success("Integration Updated")
          }
          this.hash = this.getHash()
        }
        this.currentEditedDomain = null
      } catch (e) {
        BB.Toastr.error("Something went wrong, reload the page")
        return
      }
    },
    getHash() {
      return md5(
        JSON.stringify({
          integration: this.integration,
          extraSettings: this.extraSettings
        })
      )
    },
    async setGaMode(val) {
      Vue.set(this.extraSettings, "ga_mode", val)
      this.sendUpdate("ga_mode")
    },
    toggleChangingOwner() {
      this.changingOwner = true
    },
    expandAdvanced() {
      this.advancedExpanded = !this.advancedExpanded
    },
    async removeParam(key){
      // remove the key from the customParams array
      let customParams = this.extraSettings.customParams.filter(item => item.key !== key)
      let updateConfig = {
        id: this.integration.id,
        owner: this.integration.owner,
        integration_type: this.integration.type
      }
      updateConfig[this.integration.type] = {
        customParams
      }
      let result = null
      try {
        result = await BB.integration.update(updateConfig)
      } catch (ex) {
        BB.Toastr.error("Something went wrong")
      }
      if (result) {
        this.extraSettings.customParams = customParams
        BB.Toastr.success("Integration Updated")
      }
    },
    async addURLParam(){
      // add the new param to the customParams array
      let customParams = this.extraSettings.customParams
      // if newParam is empty, don't add it
      if(!this.newParam) return
      customParams.push({
        key: this.newParam,
        scope: "lead"
      })
      let updateConfig = {
        id: this.integration.id,
        owner: this.integration.owner,
        integration_type: this.integration.type
      }
      updateConfig[this.integration.type] = {
        customParams
      }
      let result = null
      try {
        result = await BB.integration.update(updateConfig)
      } catch (ex) {
        BB.Toastr.error("Something went wrong")
      }
      if (result) {
        this.newParam = ""
        this.extraSettings.customParams = customParams
        BB.Toastr.success("Integration Updated")
      }
    },
    async addUTMTracking() {
      let updateConfig = {
        id: this.integration.id,
        owner: this.integration.owner,
        integration_type: this.integration.type
      }
      updateConfig[this.integration.type] = {
        customParams: [
          {
            key: "utm_*",
            scope: "lead"
          },
          {
            key: "gclid",
            scope: "lead"
          }
        ]
      }
      let result = null
      try {
        result = await BB.integration.update(updateConfig)
      } catch (ex) {
        BB.Toastr.error("Something went wrong")
      }
      if (result) {
        this.utmTracker++ // this is only used to force the computed property to update
        this.extraSettings.customParams = [
          ...updateConfig[this.integration.type].customParams
        ]
        BB.Toastr.success("Integration Updated")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accent_color_field {
  width: 100%;
  display: inline-block;
  input.form-control {
    background-color: transparent;
  }
}
.accent_color_preview {
  width: 40px;
  height: 20px;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  margin-top: 30px;
}
.ga_tag_field {
  width: 100%;
  display: inline-block;
}
.ga-switch {
  position: absolute;
  right: 20px;
  margin-top: 30px;
}
.checkboxField {
  input {
    width: 1.2em;
    height: 1.2em;
    vertical-align: text-bottom;
    background: transparent;
    cursor: pointer;
  }
  label {
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
  }
  margin-bottom: 5px;
  .selectWrapper {
    display: inline-block;
    font-size: 12px;
    line-height: 1em;
  }
}

.integration-domains__footer {
  margin-top: -8px;
}

.integration-domains__row {
  position: relative;
}
.integration-domains__remove.btn.btn-xs {
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 6px;
  border-radius: 50%;
  padding: 0 0.25rem 0.1rem;
  display: none;
}
.integration-domains__row:hover .integration-domains__remove.btn.btn-xs {
  display: block;
}
.selectEditLink {
  display: inline-block;
}
#changeOwner {
  cursor: pointer;
}
.generalSettings {
  margin-bottom: 20px;
}

.advancedSettings {
  padding-left: 10px;
  margin-top: 10px;
  border-left: 1px solid #2acac5;
}
.advancedWrapper {
  margin-top: 20px;
}
.toggleBtn {
  font-size: 12px;
  cursor: pointer;
}
.removeParam{
  &:hover{
    font-size: 12px;
  }
  margin-left: 5px;
  font-size: 10px;
}
</style>
