var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapperClassName, style: _vm.styles },
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.imgSrc || !_vm.preview || _vm.editMode,
              expression: "!imgSrc || !preview || editMode"
            }
          ],
          staticClass: "img-uploader__label",
          attrs: { for: _vm.idLocal }
        },
        [
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.editMode,
                expression: "!editMode"
              }
            ],
            staticClass: "img-uploader__icon"
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editMode,
                  expression: "!editMode"
                }
              ],
              staticClass: "img-uploader__label-txt"
            },
            [_vm._v(_vm._s(_vm.label))]
          ),
          _vm._v(" "),
          _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editMode,
                  expression: "editMode"
                }
              ],
              staticClass: "img-uploader__edit-btn",
              attrs: { size: "sm", outline: "" },
              on: { click: function() {} }
            },
            [_vm._v("Replace")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "sr-only",
        attrs: {
          id: _vm.idLocal,
          type: "file",
          accept: "image/png, image/jpeg"
        },
        on: { change: _vm.uploadPhoto }
      }),
      _vm._v(" "),
      _vm.imgSrc && _vm.preview
        ? _c("Thumb", {
            attrs: {
              src: _vm.imgSrc,
              type: _vm.fullRounded ? "thumb" : "preview",
              size: _vm.size,
              fullRounded: _vm.fullRounded
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-14b91172", { render: render, staticRenderFns: staticRenderFns })
  }
}