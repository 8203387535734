var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "flickity",
    {
      ref: "flickity",
      staticClass: "text-center flickity-wrapper",
      attrs: { options: _vm.flickityOptions }
    },
    [
      _c("div", { staticClass: "carousel-cell" }, [
        _c("div", { staticClass: "carousel-cell-inner" }, [
          _c("h1", [
            _vm._v(
              "Create, assign and save customized program sets to use in your marketing."
            )
          ]),
          _vm._v(" "),
          _c("img", {
            staticStyle: { margin: "15px 0 18px" },
            attrs: {
              src:
                "/sites/all/themes/bankingbridge_ui/images/pricing-demo-1.png",
              alt: "Program demo"
            }
          }),
          _vm._v(" "),
          _c("h2", [_vm._v("1. Create a new program set")]),
          _vm._v(" "),
          _c("p", { staticClass: "text-center" }, [
            _vm._v(
              "Start by clicking the round, bright orange “plus” button at the top-right corner of the page."
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "carousel-cell" }, [
        _c("div", { staticClass: "carousel-cell-inner" }, [
          _c("h1", [
            _vm._v(
              "Create, assign and save customized program sets to use in your marketing."
            )
          ]),
          _vm._v(" "),
          _c("img", {
            staticStyle: { margin: "25px 0 45px" },
            attrs: {
              src:
                "/sites/all/themes/bankingbridge_ui/images/pricing-demo-2.png",
              alt: "Program demo"
            }
          }),
          _vm._v(" "),
          _c("h2", [_vm._v("2. Set your new program’s parameters")]),
          _vm._v(" "),
          _c("p", { staticClass: "text-center" }, [
            _vm._v(
              'Prioritize your loan programs for each set and give it context with a "whitelist" filter. For example: you may want your FHA loan to appear first when marketing to a specific neighborhood.'
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "carousel-cell" }, [
        _c(
          "div",
          { staticClass: "carousel-cell-inner" },
          [
            _c("h1", [
              _vm._v(
                "Create, assign and save customized program sets to use in your marketing."
              )
            ]),
            _vm._v(" "),
            _c("img", {
              staticStyle: { "margin-bottom": "15px" },
              attrs: {
                src:
                  "/sites/all/themes/bankingbridge_ui/images/pricing-demo-3.png",
                alt: "Program demo"
              }
            }),
            _vm._v(" "),
            _c("h2", [_vm._v("3. Use saved sets to populate media")]),
            _vm._v(" "),
            _c("p", { staticClass: "text-center" }, [
              _vm._v(
                "Once you have a Program Set created, if a pricing is requested for property that matches your filter. That program set will determine the priority in which your loan programs are displayed."
              )
            ]),
            _vm._v(" "),
            _c(
              "flex-box",
              { attrs: { justify: "center" } },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "mt-4",
                    attrs: { type: "button", outline: "" },
                    on: { click: _vm.toggleItemModal }
                  },
                  [_vm._v("Create my first set")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-16475f05", { render: render, staticRenderFns: staticRenderFns })
  }
}