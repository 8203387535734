<template>
  <ConfigEditorManager
    type="Chat Config"
    :getConfig="getConfig"
    :getAllConfig="getAllConfig"
    :addConfig="addConfig"
    :deleteConfig="deleteConfig"
    :updateConfig="updateConfig"
  />
</template>

<script>
import { bbDashboardChatConfig } from "../../bb-api"
import ConfigEditorManager from "../ConfigEditorManager"

export default {
  name: "DashboardChatManager",
  components: {
    ConfigEditorManager
  },
  methods: {
    getAllConfig() {
      return bbDashboardChatConfig.getAll({}, res => res)
    },
    getConfig(id, callBack) {
      bbDashboardChatConfig.get({ id }, config => {
        callBack(config)
      })
    },
    addConfig(payload, callBack) {
      // validate payload before saving
      if (!payload.name) return BB.Toastr.error("Please complete the name.")
      if (!payload.brand_id) return BB.Toastr.error("Please select brand.")

      bbDashboardChatConfig.add(payload, function(res) {
        callBack(res)
      })
    },
    updateConfig(payload, callBack) {
      bbDashboardChatConfig.update(payload, (res) => {
        callBack(res)
      })
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.getAllConfig()
    },
    async deleteConfig(id) {
      await bbDashboardChatConfig.delete(id)
    }
  }
}
</script>

<style lang="scss"></style>
