var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "jumbotron",
      {
        staticClass: "text-center",
        attrs: {
          title:
            "Loan Program Sets help you select relevant loan programs for specific marketing scenarios"
        }
      },
      [
        _c(
          "flex-box",
          [
            _c("grid-column", [
              _c("img", {
                staticStyle: { margin: "15px 0 18px" },
                attrs: {
                  src:
                    "/sites/all/themes/bankingbridge_ui/images/pricing-demo-1.png",
                  alt: "Program demo"
                }
              }),
              _vm._v(" "),
              _c("h2", [_vm._v("1. Create a new program set")]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "Start by clicking the round, bright orange “plus” button at the top-right corner of the page."
                )
              ])
            ]),
            _vm._v(" "),
            _c("grid-column", [
              _c("img", {
                staticStyle: { margin: "25px 0 45px" },
                attrs: {
                  src:
                    "/sites/all/themes/bankingbridge_ui/images/pricing-demo-2.png",
                  alt: "Program demo"
                }
              }),
              _vm._v(" "),
              _c("h2", [_vm._v("2. Set your new program’s parameters")]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  'Prioritize your loan programs for each set and give it context with a "whitelist" filter.  For example: you may want your FHA loan to appear first when marketing to a specific neighborhood.'
                )
              ])
            ]),
            _vm._v(" "),
            _c("grid-column", [
              _c("img", {
                staticStyle: { "margin-bottom": "15px" },
                attrs: {
                  src:
                    "/sites/all/themes/bankingbridge_ui/images/pricing-demo-3.png",
                  alt: "Program demo"
                }
              }),
              _vm._v(" "),
              _c("h2", [_vm._v("3. Use saved sets to poputlate media")]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "Once you have a Program Set created, if a quote is requested for a property that matches your filter, that program set will determine the priority in which your loan programs are displayed.  "
                )
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "flex-box",
          { attrs: { justify: "center" } },
          [
            _c(
              "base-button",
              {
                staticClass: "mt-4",
                attrs: { type: "button", outline: "" },
                on: { click: _vm.toggleItemModal }
              },
              [_vm._v("Create my first set")]
            )
          ],
          1
        )
      ],
      1
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-28559156", { render: render, staticRenderFns: staticRenderFns })
  }
}