var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        id: "newLinksModal",
        isLoading: _vm.isLoading,
        showCancelBtn: false,
        okLabel: "Close"
      },
      on: { ok: _vm.hideModal },
      model: {
        value: _vm.showLinkDetailsModal,
        callback: function($$v) {
          _vm.showLinkDetailsModal = $$v
        },
        expression: "showLinkDetailsModal"
      }
    },
    [
      _c("h5", [_vm._v("\n\t\tThis is the header\n\t")]),
      _vm._v(" "),
      _c("p", { staticClass: "intro" }, [_vm._v("This is the intro")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-66b277dc", { render: render, staticRenderFns: staticRenderFns })
  }
}