<doc>
	Program profiles Modal Vue.js component
</doc>

<template>
    <draggable
        v-model="scenarios"
        :options="{
				chosenClass: 'sortable-chosen',
				filter: '.program-set-placeholder',
				handle: '.profile-title'
			}"
        @end="onEnd"
        v-if="scenarios">
      <transition-group class="d-flex draggable-wrapper" tag="div" style="flex-wrap: wrap;">
        <GridColumn
            class="draggable"
            :size="['sm-4', 'lg-3']"
            v-for="(scenario, index) in scenarios"
            :item="scenario"
            :key="scenario.id"
            :data-program-id="scenario.id">
          <Scenario :scenario="scenario" :index="index" :select-scenario="selectScenario" />
        </GridColumn>
      </transition-group>
    </draggable>
</template>

<script>
import draggable from "vuedraggable"
import Scenario from "./Scenario"
export default {
	name: "ScenariosList",
	components: {
    Scenario,
		draggable
	},
  computed:{
    scenarios: {
      get() {
        return this.data ? this.data : this.initialScenarios
      },
      set(value) {
        this.data=value
      }
    }
  },
  data(){
    return {
      data: null
    }
  },
  props: {
    saveList: { type: Function, required: true },
    initialScenarios: { type: Array, required: true },
    selectScenario: {type: Function, required: false },
    clearScenario: {type: Function, required: false }
  },
  updated(){
    // if(!this.scenarios && this.initialScenarios){
    //   this.scenarios=[this.initialScenarios[0]]
    //   debugger
    // }
  },
  mounted(){
  },
	methods: {
		onEnd: function(evt) {
			if (evt.newIndex !== evt.oldIndex) {
				this.saveList(this.scenarios)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
#newPricingSetModal {
	h5 {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.intro {
		margin-bottom: 25px;
	}
}
.sortable-ghost {
	.profile-program {
		border-color: #fff;
		position: relative;
		&::before {
			content: "";
			display: block;
			position: absolute;
			background: #fff;
			width: 100%;
			height: 100%;
			z-index: 10;
			left: -1px;
			top: -1px;
		}
	}
}
</style>
