var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-wrapper d-flex align-items-center" },
    [
      _c(
        "div",
        {
          staticClass:
            "activity-icon-wrapper d-inline-flex justify-content-center align-items-center"
        },
        [
          _c("icon", {
            staticClass: "activity-icon",
            attrs: { name: _vm.activityIcon }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "activity list-view d-flex align-items-center" },
        [
          _c(
            "div",
            { staticClass: "activity-details-img" },
            [
              _vm.item.type === "pair"
                ? _c("Thumb", {
                    staticClass: "avatar",
                    attrs: {
                      fullRounded: "",
                      src: _vm.imgSrc,
                      alt: "User Avatar"
                    }
                  })
                : _vm.imgSrc
                  ? _c("img", {
                      attrs: { src: _vm.imgSrc, alt: "activity image" }
                    })
                  : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "activity-details" }, [
            _c("div", { staticClass: "activity-details-title" }, [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
              _c(
                "span",
                {
                  staticClass: "info-btn-wrapper",
                  on: { click: _vm.onTitleClick }
                },
                [_c("fa-icon", { attrs: { name: "info-circle" } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "activity-details-meta" }, [
              _vm._v(_vm._s(_vm.details))
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-da51de8e", { render: render, staticRenderFns: staticRenderFns })
  }
}