import Vue from "vue"
import Vuex from "vuex"
import smartlist from "../modules/smartlist"
import bbManager from "../modules/bb-manager"
import bbActivity from "../modules/bb-activity"
import bbMembers from "../modules/bb-members"
import bbStaticFlyers from "../modules/bb-static-flyers"
import tags from "../modules/tags"
import { createLogger } from "../../helpers"
import _merge from "lodash/merge"

const initStore = () => {
  Vue.use(Vuex)

  const debug = process.env.NODE_ENV !== "production"

  const states = _merge({}, smartlist.state(), bbManager.state, tags.state)
  const modulesManager = _merge({}, smartlist, bbManager, tags)
  modulesManager.state = states

  const activity_states = _merge(
    {},
    smartlist.state(),
    bbActivity.state,
    tags.state
  )
  const modulesActivity = _merge({}, smartlist, bbActivity, tags)
  modulesActivity.state = activity_states

  const members_states = _merge({}, smartlist.state(), tags.state)
  const modulesMembers = _merge({}, smartlist, bbMembers, tags)
  modulesMembers.state = members_states

  const staticFlyersStates = _merge(
    {},
    smartlist.state(),
    bbStaticFlyers.state,
    tags.state
  )
  const modulesStaticFlyers = _merge({}, smartlist, bbStaticFlyers, tags)
  modulesStaticFlyers.state = staticFlyersStates

  return new Vuex.Store({
    modules: {
      organization: {
        namespaced: true,
        ...modulesManager
      },
      activity: {
        namespaced: true,
        ...modulesActivity
      },
      members: {
        namespaced: true,
        ...modulesMembers
      },
      staticFlyers: {
        namespaced: true,
        ...modulesStaticFlyers
      }
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
  })
}

export default initStore
