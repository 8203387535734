var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.wrapperClass }, [
    _c("label", { class: _vm.labelClass, attrs: { for: _vm.name } }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
    ]),
    _vm._v(" "),
    _c("textarea", {
      ref: "input",
      class: _vm.inputClassName,
      attrs: {
        type: _vm.type,
        id: _vm.name,
        "aria-describedby": _vm.describedby,
        placeholder: _vm.placeholder,
        maxlength: this.limit > 0 ? this.limit : false
      },
      domProps: { value: _vm.value },
      on: {
        input: _vm.onInput,
        focus: _vm.onFocus,
        blur: _vm.onBlur,
        keyup: [
          function($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            _vm.$emit("keyupEnter", $event)
          },
          function($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
            ) {
              return null
            }
            _vm.$emit("keyupEsc", $event)
          }
        ]
      }
    }),
    _vm._v(" "),
    _vm.limit > 0 && _vm.showLimit
      ? _c(
          "small",
          { class: _vm.textLimitClass },
          [
            _c("icon", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.charactersLeft <= 0,
                  expression: "charactersLeft <= 0"
                }
              ],
              attrs: { name: "exclamation-circle" }
            }),
            _vm._v(
              "\n    " + _vm._s(_vm.charactersLeft) + " characters left\n  "
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.helpText
      ? _c(
          "small",
          {
            staticClass: "form-text text-muted",
            attrs: { id: _vm.describedby }
          },
          [_vm._v("\n    " + _vm._s(_vm.helpText) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.helperText
      ? _c("span", { staticClass: "overview-tooltip" }, [
          _vm._v(
            "Flyer will display at most 500 characters then appends ellipsis. The landing page will display the full text."
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-488b5413", { render: render, staticRenderFns: staticRenderFns })
  }
}