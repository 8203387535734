import Install from "./Install"
import Monitor from "./Monitor"
import Settings from "./Settings"
import Status from "./Status"

export {
    Install,
    Monitor,
    Settings,
    Status
}