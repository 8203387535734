var Chart = require("chart.js");

var chart_data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [{
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
    }]
}

var line_data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "My First dataset",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: "rgba(75,192,192,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [65, 59, 80, 0, 56, 55, 40],
        }
    ]
}
var line_options={ 
    //Boolean - If we show the scale above the chart data           
    scaleOverlay : false,
    
    //Boolean - If we want to override with a hard coded scale
    scaleOverride : true,
    
    //** Required if scaleOverride is true **
    //Number - The number of steps in a hard coded scale
    scaleSteps : 14,
    //Number - The value jump in the hard coded scale
    scaleStepWidth : 5,
    //Number - The scale starting value
    scaleStartValue : 55,
    //String - Colour of the scale line 
    scaleLineColor : "rgba(20,20,20,.7)",
    
    //Number - Pixel width of the scale line    
    scaleLineWidth : 1,

    //Boolean - Whether to show labels on the scale 
    scaleShowLabels : true,
    
    //Interpolated JS string - can access value
    scaleLabel : "<%=value%>",
    
    //String - Scale label font declaration for the scale label
    scaleFontFamily : "'Arial'",
    
    //Number - Scale label font size in pixels  
    scaleFontSize : 12,
    
    //String - Scale label font weight style    
    scaleFontStyle : "normal",
    
    //String - Scale label font colour  
    scaleFontColor : "#666",    
    
    ///Boolean - Whether grid lines are shown across the chart
    scaleShowGridLines : true,
    
    //String - Colour of the grid lines
    scaleGridLineColor : "rgba(0,0,0,.3)",
    
    //Number - Width of the grid lines
    scaleGridLineWidth : 1, 
    
    //Boolean - Whether the line is curved between points
    bezierCurve : true,
    
    //Boolean - Whether to show a dot for each point
    pointDot : true,
    
    //Number - Radius of each point dot in pixels
    pointDotRadius : 5,
    
    //Number - Pixel width of point dot stroke
    pointDotStrokeWidth : 1,
    
    //Boolean - Whether to show a stroke for datasets
    datasetStroke : true,
    
    //Number - Pixel width of dataset stroke
    datasetStrokeWidth : 2,
    
    //Boolean - Whether to fill the dataset with a colour
    datasetFill : true,
    
    //Boolean - Whether to animate the chart
    animation : false,

    //Number - Number of animation steps
    animationSteps : 60,
    
    //String - Animation easing effect
    animationEasing : "easeOutQuart",

    //Function - Fires when the animation is complete
    onAnimationComplete : null
}

var bar = function(target){
    var bar_chart = new Chart(target, {
        type: 'bar',
        data: chart_data,
        responsive: true,
        maintainAspectRatio: false,
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }
    });
}

var line = function(target){
    var line_chart = new Chart(target, {
        type: 'line',
        data: line_data,
        responsive: true,
        maintainAspectRatio: false,
        options: line_options
    });
}

var chartActions = function(){
    $(".w_chart_btn").on("click", function(){
        var thisContent = $(this).parent().siblings(".w_content");
        var thisChart = $(this).parent().siblings(".w_chart");

        $(this).siblings(".btn").removeClass("active");
        $(this).addClass("active");
        
        if( $(this).hasClass("active") ) {
            thisContent.slideUp(); 
            thisChart.slideDown(); 
        }else{
            thisContent.slideDown(); 
            thisChart.slideUp(); 
        }
    }) 

    $(".w_list_btn").on("click", function(){
        var thisContent = $(this).parent().siblings(".w_content");
        var thisChart = $(this).parent().siblings(".w_chart");

        $(this).siblings(".btn").removeClass("active");
        $(this).addClass("active");
        
        if( $(this).hasClass("active") ) {
            thisContent.slideDown(); 
            thisChart.slideUp(); 
        }else{
            thisContent.slideUp(); 
            thisChart.slideDown(); 
        }
    }) 
}


exports.chartActions = chartActions;
exports.line = line;
exports.bar = bar;

// -----------------------------------------
//console.log("bb-charts <-" );