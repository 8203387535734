/**
 * Brands API wrapper
 */
const ENDPOINT = "/bank/manage.json"
const ENDPOINT_MEMBERS = "/bank/members.json"
const REVIEWS_ENDPOINT = "/api/v1/reviews/brand"

const bbBrandManage = {
	get(id = null, callback = null) {
		var params = {}
		if (id) {
			params = { id: id }
		}
		return $.get(ENDPOINT, params, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
    add(brandData, callback) {
        if (!brandData.name) {
            console.log("brand name missing")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }

        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(brandData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
	update(brandData, callback) {
		if (!brandData.id) {
			console.log("brand id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}

		return $.ajax({
			url: ENDPOINT,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(brandData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
	getMembers(brand_id, callback) {
		if (!brand_id) {
			console.log("brand id missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		return $.get(ENDPOINT_MEMBERS, { id: brand_id }, function(data) {
			if (callback && typeof callback === "function") {
				callback.call(this, data)
			}
		})
	},
	moveMember(loid, new_brand_id, callback) {
		const url = "/lo-manage.json"
		if (!loid) {
			console.log("loid is missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		if (!new_brand_id) {
			console.log("new_brand_id is missing")
			return new Promise((resolve, reject) => {
				reject("error")
			})
		}
		const moveMemberData = {
			loid: loid,
			bank_nid: new_brand_id
		}
		return $.ajax({
			url: url,
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(moveMemberData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
	},
	importMember(row, brand_id, callback){
        const url = "/api/v1/import-lo-member"
        if (!brand_id) {
            console.log("brand_id is missing")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        let postData={
        	row,
			brand_id
		}
        return $.ajax({
            url: url,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(postData),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
	},
    reviews(params, callback){
        if (!params.id && !params.place_id && !params.zillow_brand_name && !params.experience_id) {
            console.log("brand id, zillow_brand_name or place_id required")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.get(REVIEWS_ENDPOINT, params, function(data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
    find(params, callback = null) {
        return $.get(ENDPOINT, params, function(data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
}

export default bbBrandManage
