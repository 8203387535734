const loader = `<div class="bb-loading__loader"></div>`

const show = $parent => {
	const $loader = $(loader)
	$parent.addClass("bb-loading__parent").append($loader)
	$loader.addClass("bb-loading__loader__on")
}

const hide = () => {
	$(".bb-loading__loader").fadeOut(function() {
		$(this)
			.parent()
			.removeClass("bb-loading__parent")
		$(this).remove()
	})
}

module.exports = {
	show: show,
	hide: hide
}
