var baseRoute = "/sites/all/themes/bankingbridge_ui/";
var Toastr = require('toastr');

var bbSocial = {
    connection : {
        //  getInfo = {
        //      uid
        //      type
        //  }
        get : function(getInfo,callback){
            $.get('/bb-sc-manage.json?uid='+getInfo.uid+"&type="+getInfo.type,function(data){
                if (callback && typeof(callback) === "function") {callback.call(this,data);}
            });
        },
        //  updateInfo = {
        //      uid
        //      type
        //      status
        //  }
        manage : function(info,callback){
            if(!info.uid || !info.type || !info.status){
                return null;
            }
            var data={
                uid:info.uid,
                type:info.type,
                status:info.status
            };
            var url="/bb-sc-manage.json";
            $.ajax({
                url: url,
                type: 'POST',
                contentType:'application/json',
                dataType:'json',
                data: JSON.stringify(data),
                success: function(data){
                    if (callback && typeof(callback) === "function") {callback.call(this,data);}
                    console.log(data);
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    //On error do this
                    if (xhr.status == 200) {

                    }
                    else {

                    }
                }
            });            
        },
    },
    facebook : {
        auth : function(){
            FB.login(
                function(){
                    $('.facebook-login-button').addClass('hide');
                    $('.fb-connect .bg-success').removeClass('hide');                    
                }
            );
        },
        deauth : function(callback){
            FB.api('/me/permissions', 'DELETE', function(data){
                console.log(data);
                if (callback && typeof(callback) === "function") {callback.call(this,data);}
            });
        },
        hasPermissions : function(permissions,callback){
            FB.api('/me/permissions', function(response){
                if(response.data){
                    response.data.forEach(function(element1){
                        permissions.forEach(function(element2,index){
                            if(element1.permission==element2 && element1.status=="granted"){
                                permissions.splice(index, 1);
                            }
                        });
                    });
                    if(permissions.length>0){
                        if (callback && typeof(callback) === "function") {callback.call(this,false);}
                    }
                    if (callback && typeof(callback) === "function") {callback.call(this,true);}
                }
                else{
                    console.log('something went wrong while checking for permissions');
                }
            });
        },
        post : function(info,callback){
            var share_info={};
            const use_open_graph=false;
            share_info={
                method: 'share',
                href: info.share_url
            };
            if(use_open_graph){
                share_info={
                    method: 'share_open_graph',
                    action_type: 'og.shares',
                    action_properties: JSON.stringify({
                        object : {
                            'og:url': info.share_url, // your url to share
                            'og:title': info.title,
                            'og:description': info.description,
                            'og:image': info.image_url,
                            'og:image:width': 1200,
                            'og:image:height': 630
                        }
                    })
                };
            }

            FB.ui(share_info, function(response){
                console.log(response);
                if(!response.error_code){
                    console.log("was shared");
                    var url='/fb-share-record-manage.json';
                    var postData=new Object();
                    postData.id=info.share_id;
                    postData.status=1;
                    postData.post_id=response.id;
                    Toastr.success("Your listing share has been posted."); 
                    
//                    var url='/bb-share-record-manage.json';
//                    $.ajax({
//                        url: url,
//                        type: 'POST',
//                        contentType:'application/json',
//                        dataType:'json',
//                        data: JSON.stringify(postData),
//                       success: function(data){
//                            //On ajax success do this
//                            if (callback && typeof(callback) === "function") {callback.call(this,data);}
//                            console.log("fb share record was updated");
//                        },
//                        error: function(xhr, ajaxOptions, thrownError) {
//                            //On error do this
//                            if (xhr.status == 200) {
//                                //alert(ajaxOptions);
//                            }
//                            else {
//            //                    alert(xhr.status);
//            //                    alert(thrownError);
//                            }
//                        }
//                    });                    
                }
                else{
                    BB.social.delete(info.share_id, function(data) {});
                }
            });
        },
        onLoad : function(){
            FB.getLoginStatus(function(response) {
                if(response.status==="connected"){
                    $('.facebook-login-button').addClass('hide');
                    $('.fb-connect .bg-success').removeClass('hide');
                    if($('body').hasClass('bb-is-masq')){
                        BB.social.connection.manage({uid: $('body').data('user_id'),status: 1,type: 'facebook'},function(){});
                    }
                }
                else{
                    if($('body').hasClass('bb-is-masq')){
                        BB.social.connection.manage({uid: $('body').data('user_id'),status: 0,type: 'facebook'},function(){});
                    }
                    $('.facebook-login-button').removeClass('hide');
                    $('.fb-connect .bg-success').addClass('hide');
                }
            });
        },
        isConnected : function(callback){
            FB.getLoginStatus(function(response) {                
                if(response.status==="connected"){
                    if (callback && typeof(callback) === "function") {callback.call(this,true);}
                }
                else{
                    if (callback && typeof(callback) === "function") {callback.call(this,false);}
                }
            });
        },
        prepare : function(nid,callback){
            return jQuery.get("/listing_flyer/share_img_callback/facebook/"+nid, function( data ) {
                $('#social_preview_modal .social-post').removeClass('hide');
                $('#social_preview_modal .loader').addClass('hide');
                data.nid=nid;
                if (callback && typeof(callback) === "function") {callback(data);}
            });
        }
    },
    linkedin : {
        auth : async function(){
            let params = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=400,height=400,left=100,top=100";
            let url=await $.get("/bb-linkedin.json?url=true")
            window.open(url,"linkedin",params);
        },
        post : async function(post_info,callback){
		let shareData={
			resource: "share",
			data: {
				url: post_info.payload.content["submitted-url"],
				image_url: post_info.payload.content["submitted-image-url"],
				title: post_info.payload.content.title,
				subject: "Test Subject",
				description: post_info.payload.comment
			}
		}
		let data=await $.ajax({
			url: "/bb-linkedin.json",
			type: "POST",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(shareData),
			success(data) {
				if (callback && typeof callback === "function") {
					callback.call(this, data)
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				if (callback && typeof callback === "function") {
					callback.call(this, thrownError)
				}
			}
		})
		return data;
        },
        onLoad : async function(){
            //show linkedin button is user is not authorized
            if(!await this.isConnected()){
                $('.linkedin-login-button').removeClass('hide');
                if($('body').hasClass('bb-is-masq')){
                    BB.social.connection.manage({uid: $('body').data('user_id'),status: 0,type: 'linkedin'},function(){});                    
                }
            }
            else{
                if($('body').hasClass('bb-is-masq')){
                    BB.social.connection.manage({uid: $('body').data('user_id'),status: 1,type: 'linkedin'},function(){});                    
                }
                $('.linkedin-connect .bg-success').removeClass('hide');
            }
            //console.log('linkedin has loaded');            
        },
        getProfileData : function(){
            console.log('linkedin has authenticated');          
        },
        isConnected : async function(callback){
		let data=await $.get("/bb-linkedin.json")
		if(!data.id){
			if (callback && typeof callback === "function")callback(false);
			return false;
		}
		else{
			if (callback && typeof callback === "function")callback(true);
			return true;
		}
            //if (callback && typeof(callback) === "function") {callback.call(this,IN.User.isAuthorized());}
        },
        prepare : function(nid,callback){
            jQuery.get("/listing_flyer/share_img_callback/linkedin/"+nid, function( data ) {
                data.payload= {
                    comment: data.description, 
                    visibility: {
                      code: "anyone"
                    },
                    content: {
                        title:data.title,
                        //"description":description,
                        'submitted-url':data.share_url,
                        'submitted-image-url':data.image_url
                    }
                };
                $('#social_preview_modal .social-post').removeClass('hide');
                $('#social_preview_modal .loader').addClass('hide');
                if (callback && typeof(callback) === "function") {callback.call(this,data);}
            });
        }
    },
    delete: function(share_id,callback){
        if(!share_id){
            if (callback && typeof(callback) === "function") {callback.call(this,false);}
        }
        $.ajax({
            url: '/bb-share-record-manage.json?id='+share_id,
            type: 'DELETE',
            contentType:'application/json',
            dataType:'json',
            success: function(data){
                //On ajax success do this
                console.log("fb share record was deleted");
                if (callback && typeof(callback) === "function") {callback.call(this,data);}
            },
            error: function(xhr, ajaxOptions, thrownError) {
                //On error do this
                if (xhr.status == 200) {
                    //alert(ajaxOptions);
                }
                else {
         //                    alert(xhr.status);
         //                    alert(thrownError);
                }
            }
        });        
    }
}

module.exports = bbSocial;

// module.exports = myListingsMap;

// -----------------------------------------
//console.log("bb-maps <-" );

