<template>
  <div v-if="isLoading" class="text-center">
    <LoaderPlaceholder class="loader" type="circle" />
  </div>
  <div v-else id="rate_table_admin" class="rt-admin-wrapper">
    <button
      :class="{ cancel: addingNew }"
      class="btn btn-circle pull-right"
      @click="addNew"
      type="button"
    >
      <div class="btn__inner">+</div>
    </button>

    <div id="addNewForm" v-if="addingNew">
      <input v-model="configName" type="text" placeholder="name" />
      <label for="brand-selector">Brand:</label>
      <select class="selectBrand" v-model="brandId" id="brand-selector">
        <option disabled value="">Please select one</option>
        <option
          v-for="brand in availableBrands"
          :value="brand.id"
          :key="brand.id"
        >
          {{ brand.name }}
        </option>
      </select>
    </div>
    <div v-else>
      <select
        v-if="availableConfig.length > 0"
        class="selectConfig"
        v-model="selectedConfigId"
        @change="changeSelectedConfig"
      >
        <option disabled value="">Please select one</option>
        <option
          v-for="select in availableConfig"
          :value="select.id"
          :key="select.id"
        >
          {{ select.name }}
        </option>
      </select>
      <div v-else>
        <p>Please create a new config</p>
      </div>
    </div>

    <div v-if="config" class="editor-wrapper">
      <div v-if="isLoadingConfig" class="text-center">
        <LoaderPlaceholder class="loader" type="circle" />
      </div>
      <vjsoneditor
        v-else
        v-model="config"
        :plus="true"
        :options="options"
        :height="height"
        @error="onError"
      />

      <div class="rt-admin-wrapper__footer">
        <div class="setDefault">
          <input
            type="checkbox"
            id="isDefault"
            v-model="isDefault"
            @change="defaultChanged"
          />
          <label>Default</label>
        </div>
      </div>

      <div class="pull-right">
        <button
          v-if="changed && !addingNew"
          class="btn btn-modern mr-2"
          @click="saveConfig"
        >
          Save
        </button>
        <button
          v-if="addingNew && configName && brandId"
          class="btn btn-modern mr-2"
          @click="addConfig"
        >
          Add
        </button>

        <button
          v-if="config || addingNew"
          class="btn btn-modern btn-flat"
          @click="deleteConfig"
        >
          Delete
        </button>
      </div>
    </div>
    <div id="prev-wrapper" role="tooltip"></div>
  </div>
</template>

<script>
import vjsoneditor from "v-jsoneditor"
import bbRateTableConfig from "../../bb-api/rateTable.js"
import { createPopper } from "@popperjs/core"

let vm

export default {
  name: "RateTableManagerStandalone",
  components: {
    vjsoneditor
  },
  data() {
    return {
      isLoading: true,
      isLoadingConfig: true,
      config: null,
      height: "400px",
      changed: false,
      selectedConfigId: "",
      addingNew: false,
      configName: null,
      isDefault: false,
      brandId: "",
      availableBrands: null,
      availableConfig: [],
      options: {
        onChangeText(configJson) {
          vm.changed = true
        }
      }
    }
  },
  watch: {
    config() {
      $("#lead-workflow-manage").on("click", function() {
        vm.previewImg()
      })
      this.previewImg()
    }
  },
  async mounted() {
    vm = this
    // Get the available brands to fill the dropdown
    await this.getAvailableBrands()
    // Get the config id from the url(if any)
    const urlParams = new URLSearchParams(window.location.search)
    // If there is a config id in the url, load it
    if (urlParams.has("id")) {
      this.selectedConfigId = urlParams.get("id")
      this.getConfig(this.selectedConfigId)
    }
    // get the available configs
    this.getAllConfig()
  },
  methods: {
    defaultChanged() {
      this.changed = true
    },
    previewImg() {
      let popperInstance
      setTimeout(function() {
        $(".jsoneditor-url").off("mouseover")
        $(".jsoneditor-url").off("mouseout")
        $(".jsoneditor-url").on("mouseover", function(e) {
          var previewWrapper = document.getElementById("prev-wrapper")

          previewWrapper.innerHTML = `<img style="max-width:100%" src="${$(
            this
          ).html()}"><div id="prev-wrapper__arrow" data-popper-arrow></div>`
          popperInstance = createPopper(e.target, previewWrapper, {
            placement: "top"
          })
        })
        $(".jsoneditor-url").on("mouseout", function(e) {
          popperInstance.destroy()
        })
      }, 500)
    },
    onError() {
      console.error("Editor error")
    },
    onChange() {
      this.changed = true
    },
    getAllConfig() {
      bbRateTableConfig.get({}, res => {
        // Check if there are any configs
        if (Array.isArray(res)) {
          this.availableConfig = res
          if (!this.selectedConfigId) {
            // If there is no selected config find if there is a default config
            this.availableConfig.forEach(config => {
              if (config.is_default == "1") {
                this.selectedConfigId = config.id
                this.changeSelectedConfig(this.selectedConfigId)
              }
            })
          }
        } else {
          this.availableConfig = []
        }
        this.isLoading = false
      })
    },
    getConfig(id) {
      this.isLoadingConfig = true
      this.changed = false
      bbRateTableConfig.get({ id }, config => {
        // Check if it was not found
        if (config.id !== 0) {
          this.brandId = config.brand_id || ""
          this.isDefault = config.is_default === "1"
          this.configName = config.name
          this.config = config
        } else {
          this.selectedConfigId = ""
        }

        this.isLoadingConfig = false
      })
    },
    addConfig() {
      const payload = {
        name: this.configName,
        brand_id: this.brandId,
        details: this.config.details,
        is_default: this.isDefault ? 1 : 0
      }

      // validate payload before saving
      if (!payload.name) return BB.Toastr.error("Please complete the name.")
      if (!payload.brand_id) return BB.Toastr.error("Please select brand.")

      this.changed = false

      bbRateTableConfig.add(payload, function({ id }) {
        vm.addingNew = false
        vm.selectedConfigId = id
        vm.changeSelectedConfig()
        vm.saveCallback("Config added successfully.")
      })
    },
    saveConfig() {
      const saveCallback = this.saveCallback
      const payload = {
        id: this.selectedConfigId,
        brand_id: this.config.brandId,
        name: this.config.name,
        details: this.config.details,
        is_default: this.isDefault ? 1 : 0
      }

      this.changed = false

      bbRateTableConfig.update(payload, function() {
        saveCallback("Config saved successfully.")
      })
    },
    saveCallback(msg) {
      BB.Toastr.success(msg)
      this.getAllConfig()
    },
    clearForm(getAll = true) {
      this.changed = false
      this.brandId = ""
      this.config = null
      this.selectedConfigId = ""
      this.configName = ""
      window.history.pushState({}, null, "")
      if (getAll) {
        this.addingNew = false
        this.getAllConfig()
      }
    },
    async getAvailableBrands() {
      let brands= await BB.brandManage.find({})
      // sort brands by name
      this.availableBrands = brands.sort((a, b) => a.name.localeCompare(b.name))
    },
    changeSelectedConfig() {
      window.history.pushState({}, null, "?id=" + this.selectedConfigId)
      this.getConfig(this.selectedConfigId)
    },
    addNew() {
      if (this.addingNew) {
        this.addingNew = false
        this.config = null
      } else {
        this.addingNew = true
        this.clearForm(false)
        this.getConfig(null)
      }
    },
    async deleteConfig() {
      if (this.addingNew) {
        this.clearForm()
        return
      }
      if (confirm("Do you really want to delete this?")) {
        await bbRateTableConfig.delete(this.selectedConfigId)
        this.clearForm()
      }
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
