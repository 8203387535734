var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rich-text-editor form-group material-input typed" },
    [
      _c("label", { staticClass: "input-label", attrs: { for: _vm.name } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
      ]),
      _vm._v(" "),
      _c("Editor", {
        on: { input: _vm.onInput },
        model: {
          value: _vm.val,
          callback: function($$v) {
            _vm.val = $$v
          },
          expression: "val"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-51a2ef0c", { render: render, staticRenderFns: staticRenderFns })
  }
}