/**
 * Brands API wrapper
 */
 const ENDPOINT = "/api/v1/org-brand-manage"

 const bbOrgBrandManage = {
     get(params, callback = null) {
         return $.get(ENDPOINT, function(data) {
             if (callback && typeof callback === "function") {
                 callback.call(this, data)
             }
         })
     }
 }
 
 export default bbOrgBrandManage
 