import Vue from "vue"
import { LeadRateComparison } from "./pages"

export default function() {
    if (!document.getElementById("vue-lead-rate-comparison-app")) {
        return
    }
    // Vuejs setup
    var app=new Vue({
        el: "#vue-lead-rate-comparison-app",
        components: {
            LeadRateComparison
        }
    })
}

