<doc>
  Step Pricing manual flyer form Vue.js component
</doc>

<template>
  <div>
    <pricing-set-tool :loan-info="loanInfo" :pricing-config="pricingConfig" :set-pricing="setPricing" :toggle-parent-btns="toggleParentBtns" />
  </div>
</template>

<script>
import {PricingSetTool} from "../../ListingPricingSetTool"

export default {
  name: "FormStepPricing",
  components: {
    PricingSetTool
  },
  data: function() {
    return {

    }
  },
  props: {
    setPricing: { type: Function, required: true },
    pricingConfig: { type: Object, required: true },
    loanInfo: { type: Object, required: true },
    toggleParentBtns: { type: Function }
  },
  mounted() {
  },
  updated(){

  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
