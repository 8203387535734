<doc>
  Step parameters manual flyer form Vue.js component
</doc>

<template>
  <div>
    <grid-row>
      <grid-column size="6">
        <InputText
          type="number"
          v-model.number="value.listing_price"
          label="List Price"
          name="listing_price"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="6">
        <InputText
          type="number"
          v-model.number="value.hoa"
          label="HOA Fee"
          name="hoa"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>
    <grid-row>
      <grid-column size="6">
        <InputText
          min="0"
          type="number"
          v-model.number="value.sqft"
          label="SqFt"
          name="sqft"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="6">
        <InputText
          min="0"
          label="Beds"
          name="num_beds"
          v-model.number="value.num_beds"
          type="number"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>
    <grid-row>
      <grid-column size="6">
        <InputText
          min="0"
          label="Baths"
          name="num_baths"
          v-model.number="value.num_baths"
          type="number"
          v-on:input="onInput"
        />
      </grid-column>
      <grid-column size="6">
        <InputText
          min="0"
          label="Half Baths"
          name="num_half_baths"
          v-model.number="value.num_half_baths"
          type="number"
          v-on:input="onInput"
        />
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
export default {
  name: "FormStepParams2",
  props: {
    value: { type: Object },
    onInput: { type: Function }
  }
}
</script>

<style lang="scss" scoped></style>
