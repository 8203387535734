<doc>
	Add/edit Companies Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    v-model="showModal"
    :title="modalTitle"
    id="importPeopleModal"
    @cancel="hideModal"
    :isLoading="isLoading"
    :large="true"
    cancel-label="Close"
    @ok="createAccounts"
    ok-label="Import"
  >
    <div class="select-brands">
      <select
        class="select-template"
        v-model="selected_brand_id"
        @change="changeSelectedBrandID"
      >
        <option disabled value="">Please select one</option>
        <option v-for="brand in brands" :value="brand.id" :key="brand.name">
          {{ brand.name }}
        </option>
      </select>
    </div>
    <div class="progress">
      <div class="progress-bar" role="progressbar" :style="{ width: progressPct + '%' }" :aria-valuenow="progressPct" aria-valuemin="0" aria-valuemax="100">
        <span v-if="progressPct == 100">Complete</span>
      </div>
    </div>
    <div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="pagination_options"
        :search-options="{
          enabled: true,
          skipDiacritics: true
        }"
        styleClass="vgt-table striped"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'email'">
            {{ props.row.email }}
            <i
              v-if="!props.row.inProgress"
              class="fas fa-check-circle pull-right"
              :class="{ created: !!props.row.created, updated: !!props.row.updated, errored: !!props.row.errored }"
            ></i>
            <i v-else class="fas fa-sync-alt pull-right"></i>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../BaseModal"
import { VueGoodTable } from "vue-good-table"

export default {
  name: "CompanyModal",
  components: {
    BaseModal,
    VueGoodTable
  },
  props: {
    showModal: { type: Boolean, default: false },
    importData: { type: Array, required: true },
    hideModal: { type: Function, required: true }
  },
  data() {
    return {
      pagination_options: {
        enabled: true,
        mode: "records",
        perPage: 25,
        position: "bottom",
        perPageDropdown: [25, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All"
      },
      columns: [
        {
          label: "First Name",
          field: "first_name"
        },
        {
          label: "Last Name",
          field: "last_name"
        },
        {
          label: "NMLS",
          field: "nmls"
        },
        {
          label: "Email",
          field: "email"
        }
      ],
      rows: [],
      loaded: false,
      brands: [],
      selected_brand_id: null,
      progress: 0
    }
  },
  async updated() {
    if (this.importData) {
      this.rows = this.importData
      this.brands = this.$store.state.organization.org.brands
    }
    if (this.brands.length == 0) {
      this.brands = this.$store.state.organization.org.brands
    }
  },
  mounted() {},
  computed: Object.assign(
    {},
    {
      modalTitle() {
        return "Import Loan Officers"
      },
      isLoading() {
        return !this.importData
      },
      progressPct() {
        return Math.round((this.progress / this.rows.length) * 100)
      }
    }
  ),
  watch: {
    showModal(newVal, oldVal) {
      if (!newVal && oldVal) {
        // modal is being closed after being open
        this.resetModal()
      }
    }
  },
  methods: {
    async createAccount(row) {
      if (row.brand_id && row.brand_id != this.selected_brand_id) {
        console.log("This LO already belongs to a different brand")
        return
      }
      let index = this.rows.findIndex(item => item.nmls == row.nmls)
      // must use this.$set() to set nested values and maintain reactivity
      this.$set(this.rows[index], "inProgress", true)
      try{
        await BB.brandManage.importMember(row, this.selected_brand_id)
      }
      catch(ex){
        console.log("error importing member: "+row.fullname)
        this.$set(this.rows[index], "errored", true)
      }
      this.$set(this.rows[index], "inProgress", false)
    },
    async createAccounts() {
      if (!this.rows) return
      if (!this.selected_brand_id) {
        BB.Toastr.error("Select a brand first")
        return
      }
      for (let i = 0; i < this.rows.length; i++) {
        await this.createAccount(this.rows[i])
        this.rows[i].updated = true
        this.progress++;
      }
    },
    resetModal() {
      this.rows = []
      this.loaded = false
      this.progress = 0
      this.selected_brand_id = null
    },
    changeSelectedBrandID(e) {}
  }
}
</script>

<style lang="scss">
@import "../../../node_modules/vue-good-table/dist/vue-good-table.css";
.created {
  color: blue;
}
.updated{
  color: green;
}
.errored{
  color: red;
}
.select-brands {
  margin-bottom: 10px;
}
</style>
