<doc>
  Button Vue.js component
</doc>

<template>
  <a v-if="isLink" :class="className" :href="href">
    <icon v-if="btnIcon" :name="btnIcon"></icon>
    <slot />
  </a>
  <button
    v-else
    :class="className"
    v-on:click="$emit('click', $event)"
    :type="type"
  >
    <icon v-if="btnIcon" :name="btnIcon"></icon>
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    theme: { type: String, default: "primary" },
    type: { type: String, default: "button" },
    isLink: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    btnIcon: { type: String, default: null },
    size: { type: String, default: null },
    href: { type: String, default: "#" }
  },
  computed: {
    className() {
      let outline = this.outline ? "outline-" : ""
      let size = this.size ? ` btn-${this.size}` : ""
      return `btn btn-${outline}${this.theme}${size}`
    }
  }
}
</script>

<style lang="scss" src="./BaseButton.scss" scoped></style>
