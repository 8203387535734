var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.editMode && _vm.labelTop
      ? _c("label", { attrs: { for: "img-file" } }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "img-uploader__prev" }, [
      _vm.hasRemove && _vm.imgSrc
        ? _c(
            "span",
            { staticClass: "btn-close", on: { click: _vm.removePhoto } },
            [_c("icon", { attrs: { name: "times" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imgSrc,
            expression: "imgSrc"
          }
        ],
        ref: "preview",
        class: _vm.previewClass,
        attrs: { src: _vm.imgSrc, alt: _vm.previewAlt }
      })
    ]),
    _vm._v(" "),
    _vm.editMode
      ? _c("div", { staticClass: "file-input" }, [
          !_vm.labelTop
            ? _c("label", { attrs: { for: "img-file" } }, [
                _vm._v(_vm._s(_vm.label))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: {
              id: _vm.idLocal,
              type: "file",
              accept: "image/png, image/jpeg, image/svg+xml"
            },
            on: { change: _vm.uploadPhoto }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-292620ef", { render: render, staticRenderFns: staticRenderFns })
  }
}