var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPair
    ? _c("div", { staticClass: "pair-wrapper" }, [
        _c("div", { class: _vm.className + " thumb-pair-wrapper" }, [
          _c("img", { attrs: { src: _vm.src[0], alt: _vm.alt[0] } })
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.className + " thumb-pair-wrapper" }, [
          _c("img", { attrs: { src: _vm.src[1], alt: _vm.alt[1] } })
        ])
      ])
    : _c("img", {
        class: _vm.className,
        style: _vm.styles,
        attrs: { src: _vm.src, alt: _vm.alt }
      })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5453deb0", { render: render, staticRenderFns: staticRenderFns })
  }
}