<doc>
	Brands list Vue.js component
</doc>

<template>
  <LoaderPlaceholder v-if="isLoading" type="circle" />
  <SmartList
    v-else
    :searchQuery="searchQuery"
    :searchQueries="searchQueries"
    :filteredItems="filteredItems"
    :addQuery="addQuery"
    :removeQuery="removeQuery"
    :resetQuery="resetQuery"
    :toggleListView="toggleListView"
    :addItemAction="toggleItemModal"
    :items="items"
    :onDragEnd="onEnd"
    :gridView="gridView"
    listType="organization"
    vuexNamespace="organization"
  />
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { SmartList } from "../SmartList"

const {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} = createNamespacedHelpers("organization")

export default {
  name: "ManagerBrands",
  components: {
    SmartList: SmartList
  },
  updated(){
  },
  computed: Object.assign(
    {},
    mapState(["org", "gridView", "searchQueries", "searchQuery"]),
    mapGetters(["currentItems", "isLoading"]),
    {
      items: {
        get() {
          try {
            return this.currentItems
          } catch (e) {
            console.log(e)
          }
        },
        set(value) {
          /**
           * The "correct" way is commit the update here,
           * but vuedraggable seems to have issues with it.
           * We are doing it on the "onEnd" event
           **/
          // this.$store.commit("updateSets", value)
        }
      }
    }
  ),
  methods: Object.assign(
    {},
    mapMutations({
      toggleListView: "TOGGLE_LIST_VIEW"
    }),
    mapActions([
      "filteredItems",
      "addQuery",
      "removeQuery",
      "resetQuery",
      "toggleItemModal",
      "updateOriginalItems"
    ]),
    {
      onEnd: function(oldIndex, newIndex) {
        this.updateOriginalItems({ oldIndex: oldIndex, newIndex: newIndex })
      }
    }
  )
}
</script>

<style lang="scss" scoped></style>
