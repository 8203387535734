<template>
  <div id="lead-workflow-manage" class="notLoaded" v-if="availableBrands">
    <button
      :class="{ cancel: addingNew || config }"
      class="btn btn-circle pull-right"
      @click="addNew"
      type="button"
    >
      <div class="btn__inner">+</div>
    </button>
    <div>
      <div v-if="!addingNew && !brandId">
        <label>Brand:</label>
        <select
          class="selectBrand"
          v-model="brandId"
          @change="changeSelectedBrand"
        >
          <option disabled value="">Please select one</option>
          <option
            v-for="brand in availableBrands"
            :value="brand.id"
            :key="brand.id"
            >{{ brand.name }}</option
          >
        </select>
      </div>
      <select
        v-if="brandId && availableWorkflows && !selectedConfigId"
        class="selectConfig"
        v-model="selectedConfigId"
        @change="changeSelectedConfig"
      >
        <option disabled value="">Please select one</option>
        <option
          v-for="workflow in availableWorkflows"
          :value="workflow.id"
          :key="workflow.id"
          >{{ workflow.name }}</option
        >
      </select>
    </div>
    <div v-if="config" style="margin-top:20px;">
      <div>
        <span id="inUse" :class="{ active: inUse }"></span>
        <span class="workflowName"
          ><input type="text" v-model="configName"
        /></span>
        <div
          v-if="config"
          style="padding: 5px;background: gainsboro;display: inline-block;border-radius: 5px;"
        >
          <select
            class="selectStep"
            v-model="selectedStep"
            @change="changeSelectedStep"
          >
            <option disabled value=""></option>
            <option v-for="step in availableSteps" :key="step">{{
              step
            }}</option>
          </select>
          <button @click="addNewStep">Add New Step</button>
        </div>
        <div style="margin-bottom:10px;">
          <span>Brand: </span>
          <select class="selectBrand" v-model="brandId">
            <option disabled value="">Please select one</option>
            <option
              v-for="brand in availableBrands"
              :value="brand.id"
              :key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
          <div style="display:inline-block;" v-if="addingNew">
            <span>Copy from: </span>
            <select
              class="selectWorkflowCopy"
              v-model="copyFrom"
              @change="copyFromWorkflow"
            >
              <option disabled value="">Please select one</option>
              <option
                v-for="workflow in allWorkflows"
                :value="workflow.id"
                :key="workflow.id"
                >{{ workflow.name }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <Tabs>
        <Tab name="Config">
          <div>
            <vjsoneditor
              v-model="config"
              :options="options"
              :plus="true"
              :height="height"
              @error="onError"
              :key="componentKey"
            />
          </div>
          <div class="footer">
            <div class="setDefault">
              <input type="checkbox" id="isDefault" v-model="isDefault" />
              <label>Default</label>
            </div>
          </div>
        </Tab>
        <Tab name="Steps">
          <ul class="steps">
            <draggable v-model="config.steps" @change="changeSteps">
              <li v-for="(step, index) in config.steps" :key="index">
                <i class="fa fa-bars dragBtn" aria-hidden="true"></i
                ><span class="listId">{{ step.id }}</span
                ><i class="fas fa-pencil-alt editBtn"></i
                ><i
                  v-tooltip="tooltipContent(step)"
                  v-if="step.condition"
                  class="fas fa-random"
                ></i>
              </li>
            </draggable>
          </ul>
        </Tab>
        <Tab name="Upload">
          <div>
            <div v-if="uploadUrl">
              {{ uploadUrl }}
            </div>
            <ImgUploader
              v-if="config"
              previewAlt="Uploaded Image"
              :editMode="true"
              :previewSrc="uploadUrl"
              previewClass="bg-preview"
              label=""
              :uploadCallback="uploadCallback"
              :labelTop="true"
              :hasRemove="true"
              :removePhoto="removePhoto"
              :use-s3="true"
            />
          </div>
        </Tab>
        <Tab name="Delete">
          <button @click="deleteWorkflow">Delete</button>
        </Tab>
      </Tabs>
      <button
        v-if="changed || (addingNew && configName && brandId)"
        id="saveConfig"
        class="btn btn-default pull-right"
        @click="saveConfig"
      >
        Save
      </button>
    </div>
    <div id="prev-wrapper" role="tooltip"></div>
  </div>
</template>

<script>
import vjsoneditor from "v-jsoneditor"
import md5 from "js-md5"
import { Tabs, Tab } from "../Tabs"
import draggable from "vuedraggable"
import ImgUploader from "../ImgUploader"
import { VTooltip } from "v-tooltip"
import { createPopper } from "@popperjs/core"

const logical_operators = ["and", "or"]

export default {
  name: "LeadWorkflowManagerStandalone",
  components: {
    vjsoneditor,
    Tab,
    Tabs,
    draggable,
    ImgUploader
  },
  data() {
    return {
      config: null,
      height: "400px",
      changed: false,
      origHash: null,
      selectedConfigId: null,
      addingNew: false,
      configName: null,
      isDefault: false,
      brandId: null,
      selectedStep: null,
      inUse: false,
      options: {
        onChangeText(configJson) {}
      },
      availableSteps: ["buttons", "list", "slider", "text", "lo_select"],
      availableBrands: null,
      availableWorkflows: null,
      allWorkflows: null,
      componentKey: 0,
      uploadUrl: "",
      copyFrom: null
    }
  },
  directives: {
    tooltip: VTooltip
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has("brand_id")) {
      this.brandId = urlParams.get("brand_id")
      await this.getAvailableWorkflows()
    } else {
      //await this.getAvailableBrands()
    }
    await this.getAvailableBrands()
    //this.getConfig();
    if (urlParams.has("id")) {
      this.selectedConfigId = urlParams.get("id")
      await this.getConfig(this.selectedConfigId)
      this.addingNew = false
    }
    $("#lead-workflow-manage").removeClass("notLoaded")
  },
  watch: {
    config() {
      const that = this
      $("#lead-workflow-manage").on("click", function() {
        that.previewImg()
      })
      this.previewImg()
    }
  },
  updated() {
    if (this.origHash != this.getHash()) {
      this.changed = true
    } else {
      this.changed = false
    }
  },
  methods: {
    previewImg() {
      let popperInstance
      setTimeout(function() {
        $(".jsoneditor-url").off("mouseover")
        $(".jsoneditor-url").off("mouseout")
        $(".jsoneditor-url").on("mouseover", function(e) {
          var previewWrapper = document.getElementById("prev-wrapper")

          previewWrapper.innerHTML = `<img style="max-width:100%" src="${$(
            this
          ).html()}"><div id="prev-wrapper__arrow" data-popper-arrow></div>`
          popperInstance = createPopper(e.target, previewWrapper, {
            placement: "top"
          })
        })
        $(".jsoneditor-url").on("mouseout", function(e) {
          popperInstance.destroy()
        })
      }, 500)
    },
    uploadCallback(info) {
      if (window.CDN_HOST) {
        let urlParts = new URL(info.url)
        this.uploadUrl = window.CDN_HOST + urlParts.pathname
      } else {
        this.uploadUrl = info.url
      }
    },
    removePhoto() {
      this.uploadUrl = ""
    },
    onError() {
      console.log("error")
    },
    async getConfig(id) {
      const workflow = await BB.leadWorkflow.get({ id })

      this.config = workflow.config_json
      this.isDefault = workflow.is_default === 1
      if (id) {
        const res = await BB.leadWorkflow.checkIsActive({ id })
        this.inUse = res.status === "active"
      }
      this.configName = workflow.name ? workflow.name : "New Workflow"
      this.brandId = workflow.brand_id
      const hash = this.getHash()
      this.origHash = hash
    },
    async saveConfig(e) {
      if (!this.validateConfig()) {
        return
      }
      let payload = {}
      let key = "update"
      if (this.addingNew) {
        key = "add"
        payload = {
          name: this.configName,
          config: this.config,
          brand_id: this.brandId,
          is_default: this.isDefault ? 1 : 0
        }
      } else {
        payload = {
          id: this.selectedConfigId,
          config: this.config,
          brand_id: this.brandId,
          is_default: this.isDefault ? 1 : 0
        }
        if (this.configName) {
          payload.name = this.configName
        }
      }

      const json = await BB.leadWorkflow[key](payload)

      console.log(json)
      if (json.status === "success") {
        BB.Toastr.success("Configuration Saved")
        if (this.addingNew && json.workflow_id) {
          this.config.id = json.workflow_id
        }
        this.changed = false
        this.origHash = this.getHash()
        this.addingNew = false
      }
    },
    getHash() {
      return md5(
        JSON.stringify({
          config: this.config,
          default: this.isDefault,
          name: this.configName,
          brandId: this.brandId
        })
      )
    },
    changeSelectedConfig(data) {
      //console.log(data)
      //console.log(this.selectedConfigId);
      this.getConfig(this.selectedConfigId)
      window.history.pushState({}, null, "?id=" + this.selectedConfigId)
    },
    copyFromWorkflow() {
      this.config = JSON.parse(
        this.allWorkflows.find(item => item.id == this.copyFrom).config_json
      )
    },
    async addNew(e) {
      if (this.addingNew || this.config) {
        this.clearWorkflow()
      } else {
        await this.getAllWorkflows({ limit: 1000 })
        this.addingNew = true
        this.getConfig(null)
      }
    },
    clearWorkflow() {
      this.addingNew = false
      this.config = null
      this.brandId = null
      this.selectedConfigId = null
      this.inUse = false
      window.history.pushState({}, null, "/bb_lead_workflow_admin")
    },
    validateConfig() {
      let flag = true
      let violation = null
      // iterate through existing steps
      for (let i = 0; i < this.config.steps.length; i++) {
        let step = this.config.steps[i]
        if (step.condition && flag) {
          let conditionList = this.flattenConditions(step.condition)
          // check if one of the steps has an ID that matches the condition
          Object.keys(conditionList).forEach(conditionID => {
            if (
              this.config.steps
                .slice(0, i)
                .findIndex(stp => stp.id == conditionID) < 0
            ) {
              flag = false
              violation = {
                condition: conditionID,
                step_id: step.id
              }
            }
          })
        }
      }
      if (violation && violation.step_id && violation.condition) {
        BB.Toastr.error(
          "Step " +
            violation.step_id +
            " has invalid condition: " +
            violation.condition
        )
      }
      return flag
    },
    async syncDefaults() {
      let defaultConfig = await BB.leadWorkflow.get({})
      let currentConfig = this.config
      Object.keys(defaultConfig).forEach(key => {
        switch (key) {
          case "steps":
            break
          case "disclaimers":
            break
          default:
            if (!currentConfig[key]) {
              console.log(key + " is missing and needs to be added")
              currentConfig[key] = defaultConfig[key]
            }
            break
        }
      })
      return
    },
    changeSelectedStep(step) {},
    async getAvailableBrands() {
      let brands= await BB.brandManage.find({})
      // sort brands by name
      this.availableBrands = brands.sort((a, b) => a.name.localeCompare(b.name))
    },
    async getAvailableWorkflows() {
      if (!this.brandId) return
      let result = await BB.leadWorkflow.find({ brand_id: this.brandId })
      this.availableWorkflows = result.workflows
    },
    async getAllWorkflows() {
      let result = await BB.leadWorkflow.find({})
      this.allWorkflows = result.workflows
    },
    addNewStep() {
      if (!this.selectedStep) return
      let type = this.selectedStep
      let config = Object.assign({}, this.config)
      switch (type) {
        case "list":
          config.steps.push({
            id: "employment_status",
            type: "list",
            title: "What is your employment status?",
            items: [
              {
                label: "Employed",
                value: "employed"
              },
              {
                label: "Not Employed",
                value: "not_employed"
              },
              {
                label: "Military",
                value: "military"
              },
              {
                label: "Retired",
                value: "retired"
              },
              {
                label: "Self Employed",
                value: "self_employed"
              }
            ]
          })
          break
        case "buttons":
          config.steps.push({
            id: "foreclosure_bankruptcy",
            type: "buttons",
            title: "Have you had a bankruptcy or foreclosure in last 4 years?",
            items: [
              {
                icon:
                  "https://cdndev.bankingbridge.com/workflow/images/good.svg",
                label: "No",
                value: "no"
              },
              {
                icon:
                  "https://cdndev.bankingbridge.com/workflow/images/bankruptcy.svg",
                label: "Bankruptcy",
                value: "bankruptcy"
              },
              {
                icon:
                  "https://cdndev.bankingbridge.com/workflow/images/foreclosure.svg",
                label: "Foreclosure",
                value: "foreclosure"
              }
            ]
          })
          break
        case "slider":
          config.steps.push({
            id: "household_income",
            type: "slider",
            title: "Annual Household Income",
            range_start: 30000,
            range_end: 400000,
            point_size: 10000,
            step: 10000,
            prefix: "$"
          })
          break
        case "location":
          config.steps.push({
            id: "location",
            type: "location_autocomplete",
            title: "In what city is the property located?",
            placeholder: "Enter City, State, Zip, etc..."
          })
          break
        case "lo_select":
          config.steps.push({
            id: "loid",
            type: "lo_select",
            title: "Are You Already Working With A Loan Officer?"
          })
          break
      }
      this.config = config
      BB.Toastr.success(
        type.charAt(0).toUpperCase() + type.slice(1) + " Step Added"
      )
      this.selectedStep = null
    },
    changeSteps(info) {
      console.log(
        "item " + info.moved.oldIndex + " was moved to " + info.moved.newIndex
      )
      console.log(info.moved.element)
      this.saveConfig()
      this.componentKey += 1
    },
    flattenConditions(condition, op, condList) {
      op = op ? op : "and"
      condList = condList ? condList : {}
      for (const key in condition) {
        if (logical_operators.indexOf(key) > -1) {
          this.flattenConditions(condition[key], key, condList)
        } else {
          condList[key] = condition[key]
        }
      }
      return condList
    },
    tooltipContent(step) {
      return this.buildConditionStr(step.condition, "", "")
    },
    buildConditionStr(condition, op, str) {
      op = op ? op : "and"
      str = str ? str : ""
      for (const key in condition) {
        if (logical_operators.indexOf(key) > -1) {
          str +=
            "(" +
            this.buildConditionStr(condition[key], key, "") +
            ") " +
            op.toUpperCase() +
            " "
        } else {
          str +=
            this.evaluateConditional(key, condition[key]) +
            " " +
            op.toUpperCase() +
            " "
        }
      }
      str =
        str.substring(str.length - 5) === " AND "
          ? str.substring(0, str.length - 5)
          : str
      str =
        str.substring(str.length - 4) === " OR "
          ? str.substring(0, str.length - 4)
          : str
      return str
    },
    evaluateConditional(key, value) {
      let op = "eq"
      let val = ""
      if (Array.isArray(value)) {
        val = value[0]
        op = value[1] ? value[1] : op
      } else {
        val = value
      }
      if (op == "gt") return key + " greater than " + val
      if (op == "gte") return key + " greater than or equal to " + val
      if (op == "lt") return key + " less than " + val
      if (op == "lte") return key + " less than or equal to " + val
      if (op == "!eq") return key + " not equal " + val
      if (op == "eq") return key + " equals " + val
      if (op == "!isset") return key + " is not empty"
      if (op == "isset") return key + " is empty"
      if (!op) return key + " equals " + val
    },
    async changeSelectedBrand() {
      await this.getAvailableWorkflows()
    },
    async deleteWorkflow() {
      if (this.inUse) {
        BB.Toastr.warning("You cannot delete a workflow that is in use.")
        return
      }
      if (confirm("Do you really want to delete this?")) {
        await BB.leadWorkflow.delete(this.selectedConfigId)
        this.clearWorkflow()
      }
    }
  }
}
</script>

<style lang="scss">
#prev-wrapper {
  background: #333;
  color: white;
  font-weight: bold;
  padding: 8px;
  font-size: 13px;
  border-radius: 4px;
  max-width: 200px;
  position: absolute;
  top: -9999px;
}
#prev-wrapper__arrow,
#prev-wrapper__arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#prev-wrapper__arrow {
  visibility: hidden;
}

#prev-wrapper__arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#prev-wrapper[data-popper-placement^="top"] > #prev-wrapper__arrow {
  bottom: -4px;
}

#prev-wrapper[data-popper-placement^="bottom"] > #prev-wrapper__arrow {
  top: -4px;
}

#prev-wrapper[data-popper-placement^="left"] > #prev-wrapper__arrow {
  right: -4px;
}

#prev-wrapper[data-popper-placement^="right"] > #prev-wrapper__arrow {
  left: -4px;
}

#lead-workflow-manage {
  margin-top: 20px;
}

#lead-workflow-manage .jsoneditor-poweredBy {
  display: none;
}
#lead-workflow-manage .max-btn {
  right: 7px;
  top: 6px;
}
#saveConfig {
  margin-top: 5px;
}
#saveConfig.changed {
  display: block;
}
.jsoneditor-tree {
  background: white;
}
#addNewForm input {
  padding: 2px 5px;
}
#addNewForm input,
#addNewForm select {
  font-size: 16px;
}
.btn-circle {
  background: #f1673e;
  border-radius: 25px;
  color: #fff;
  font-size: 20px;
  width: 44px;
  margin-top: -10px;

  &:hover,
  &:focus {
    color: #fff;
  }

  .btn__inner {
    transform: rotate(0);
    transition: transform ease-out 0.1s;
  }

  &.cancel .btn__inner {
    transform: rotate(-45deg);
  }
}
.notLoaded {
  display: none;
}
.setDefault {
  float: left;
}
.workflowName {
  padding: 10px 20px 10px 0px;
  outline: none;
  height: 60px;
  display: inline-block;
  width: 400px;
  input {
    background: transparent;
    border: none;
    width: 100%;
    font-size: 30px;
    outline: none;
    font-family: "Roboto";
    font-weight: lighter;
    &:focus {
      border-bottom: 1px solid #c2c2c6;
    }
  }
}
ul.steps {
  li {
    background: lightslategray;
    color: white;
    padding: 10px 0px 10px 10px;
    margin-bottom: 5px;
    font-size: 14px;
    width: 300px;
    .dragBtn {
      cursor: grab;
    }
    .listId {
      margin-left: 10px;
      margin-right: 10px;
    }
    .editBtn {
      float: right;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
img.bg-preview {
  width: 200px;
}
.btn-close {
  left: 200px !important;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
#inUse {
  height: 10px;
  width: 10px;
  background: red;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 5px;
  &.active {
    background: green;
  }
}
</style>
