var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      staticClass: "confirm-modal",
      attrs: {
        small: "",
        title: _vm.title,
        okLabel: "OK",
        showCancelBtn: _vm.showCancelBtn
      },
      on: { ok: _vm.confirm, cancel: _vm.cancel },
      model: {
        value: _vm.confirmModel,
        callback: function($$v) {
          _vm.confirmModel = $$v
        },
        expression: "confirmModel"
      }
    },
    [_c("p", [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bb2cc0de", { render: render, staticRenderFns: staticRenderFns })
  }
}