<template>
  <div class="container-fluid">
    <div v-if="threads" class="row">
      <!-- Left Sidebar: Threads List -->
      <div :class="['col-sm-4', { 'd-none': !showList && isMobile }]">
        <h5 class="mt-3">Threads</h5>
        <div class="input-group mb-3">
          <select class="form-select" v-model="selectedBrand" @change="filterThreads">
            <option value="">All Brands</option>
            <option v-for="brand in brandList" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
          </select>
          <span @click="toggleSettingsModal" class="settings-btn"><v-icon name="cog"/></span>
        </div>
        <ul class="list-group threads-list">
          <li
              v-for="thread in filteredThreads"
              :key="thread._id"
              class="list-group-item list-group-item-action"
              @click="selectThread(thread)"
              :class="{ active: selectedThread && selectedThread._id === thread._id }"
          >
            {{ threadName(thread) }} - {{ getLastMessageTimestamp(thread) }}
            <span class="status-indicator" :class="getThreadStatus(thread)"><v-icon :name="getThreadStatus(thread)==='takeover' ? 'user-circle' : (getThreadStatus(thread)==='converted' ? 'check-circle' : 'circle')" /></span>
          </li>
        </ul>
      </div>

      <!-- Right Sidebar: Messages -->
      <div :class="['col-sm-8', { 'd-none': showList && isMobile }]">
        <div v-if="selectedThread">
          <div>
            <button class="chat-back-btn" @click="clickBack"><v-icon name="arrow-left" /></button>
            <div class="options-dropdown btn-group">
              <button type="button" class="dropdown-toggle thread-options" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <v-icon name="ellipsis-v" />
              </button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li><a href="#" @click="deleteThread">Delete</a></li>
                <li v-if="selectedThread.status === 'takeover' || selectedThread.status === 'active'"><a href="#" @click="takeOverThreadToggle">{{selectedThread.status == "takeover" ? "Release" : "Takeover"}}</a></li>
                <li role="separator" class="divider"></li>
                <li><a class="disabled">Calendar <v-icon :name="getCalendarIcons.integration.icon" :class="['pull-right','calendar-status-icon', getCalendarIcons.integration.color]" /></a></li>
                <li><a class="disabled">Webhook <v-icon :name="getCalendarIcons.webhook.icon" :class="['pull-right','calendar-status-icon', getCalendarIcons.webhook.color]" /></a></li>
                <!-- <li><a href="#">Something else here</a></li>
                <li><a href="#">Separated link</a></li> -->
              </ul>
            </div>
            <div style="clear: both;"></div>
          </div>
          <Chat :messages="selectedThread.messages" :events="selectedThread.events" :send-message="sendMessage" />
        </div>
        <div v-else>
          <p>Select a thread to view messages.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading">
        <p>Loading...</p>
      </div>
      <div v-else>
        <p>No threads found.</p>
      </div>
    </div>
    <BaseModal
        ref="modal"
        v-model="showSettingsModal"
        title="Chat Settings"
        id="settings-modal"
        :showCancelBtn="false"
        :isLoading="modalIsLoading"
        okLabel="Close"
        :closeWhenOK="true"
    >
      <Tabs>
        <Tab name="Calendly">
          <div>
            <div id="accordion" v-if="calendlyIntegrations">
              <div v-for="integration in calendlyIntegrations" :key="integration._id" class="panel">
                <div class="panel-heading" :id="'heading'+integration._id">
                  <h5 class="mb-0">
                    <div class="panel-btn" data-toggle="collapse" :data-target="'#collapse'+integration._id" aria-expanded="true" :aria-controls="'collapse'+integration._id">
                      {{integration.lo.name}} - {{integration.lo.bank}}
                    </div>
                  </h5>
                </div>
                <div :id="'collapse'+integration._id" class="collapse" :aria-labelledby="'heading'+integration._id" data-parent="#accordion">                  <div class="panel-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <ul>
                          <li><strong>LO:</strong> {{integration.lo.name}}</li>
                          <li><strong>Email:</strong> {{integration.user.email}}</li>
                          <li><strong>Timezone:</strong> {{integration.user.timezone}}</li>
                          <li><strong>Scheduling URL:</strong> <a :href="integration.user.scheduling_url" target="_blank">{{integration.user.scheduling_url}}</a></li>
                          <li><strong>Webhook:</strong> <v-icon :name="(integration.webhook_uri ? 'check-circle' : 'times-circle')"/></li>
                        </ul>
                      </div>
                      <div class="col-sm-2">
                        <!-- <div class="panel-btn"><v-icon name="sync"></v-icon></div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab name="Senders">
          <div>
            <div id="accordion" v-if="threadSenders">
              <div v-for="sender in threadSenders" :key="sender._id" class="panel">
                <div class="panel-heading" :id="'heading-'+sender._id">
                  <h5 class="mb-0">
                    <div class="panel-btn" data-toggle="collapse" :data-target="'#collapse-'+sender._id" aria-expanded="true" :aria-controls="'collapse-'+sender._id">
                      {{(sender.brand_name ? sender.brand_name : "System Default" )}} - {{sender.provider}}
                    </div>
                  </h5>
                </div>
                <div :id="'collapse-'+sender._id" class="collapse" :aria-labelledby="'heading-'+sender._id" data-parent="#accordion">
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <ul>
                          <li><strong>Api Key:</strong> {{(sender.api_key ? truncateString(sender.api_key,10) : "")}}</li>
                          <li><strong>External ID:</strong> {{(sender.external_id ? sender.external_id : "")}}</li>
                          <li><strong>Phone Numbers:</strong></li>
                          <li v-for="(phone, index) in sender.outgoing_phone_numbers" :key="index" class="phone-number-list-item"><span :class="index==0 ? 'default' : ''">{{phone}}</span></li>
                        </ul>
                      </div>
                      <div class="col-sm-2">
                        <!-- <div class="panel-btn"><v-icon name="sync"></v-icon></div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>

    </BaseModal>
  </div>
</template>

<script>

import Chat from "../components/Chat/index.vue";
import Icon from "vue-awesome/components/Icon"
import BaseModal from "../components/BaseModal/index.vue";
import {Tab, Tabs} from "../components";
import {Monitor, Settings, Status} from "../components/IntegrationSettings";

export default {
  name: "ChatAdmin",
  components: {
    Settings, Monitor, Status, Tabs, Tab,
    BaseModal,
    Chat,
    'v-icon': Icon
  },
  props: {
  },
  data() {
    const vm = this
    return {
      loading: false,
      threads: null,
      selectedThread: null,
      selectedThreadCalendarIntegration: null,
      lastActivity: Date.now(),
      pollingThread: false,
      showList: true,
      windowWidth: window.innerWidth,
      dropdownOpen: false,
      selectedBrand: null,
      filteredThreads: [],
      pollingThreads: false,
      showSettingsModal: false,
      modalIsLoading: false,
      calendlyIntegrations: [],
      threadSenders: []
    }
  },
  async mounted() {
    this.loading = true;
    await this.getThreads();
    this.filteredThreads = this.threads
    this.loading = false;
    this.pollSelectedThread();
    window.addEventListener('resize', this.updateWindowWidth);
    window.addEventListener('mousemove', this.updateLastActivity);
    window.addEventListener('keydown', this.updateLastActivity);
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768;
    },
    brandList() {
      // Get unique brands {id, name} from threads
      const brands = this.threads.map(thread => {
        return {
          id: thread.brand_id,
          name: thread.loan_officer.brand_name
        }
      });
      return brands.filter((brand, index, self) =>
        index === self.findIndex((t) => (
          t.id === brand.id && t.name === brand.name
        ))
      );
    },
    getCalendarIcons() {
      if(!this.selectedThreadCalendarIntegration){
        return {
          integration: {icon: "times-circle", color: "red"},
          webhook: {icon: "times-circle", color: "red"}
        }
      }
      return {
        integration: {icon: this.selectedThreadCalendarIntegration ? "check-circle" : "times-circle", color: this.selectedThreadCalendarIntegration ? "green" : "red"},
        webhook: {icon: this.selectedThreadCalendarIntegration.webhook_uri ? "check-circle" : "times-circle", color: this.selectedThreadCalendarIntegration.webhook_uri ? "green" : "red"}
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
    window.removeEventListener('mousemove', this.updateLastActivity);
    window.removeEventListener('keydown', this.updateLastActivity);
  },
  methods: {
    updateLastActivity() {
      this.lastActivity = Date.now();
    },
    truncateString(str, length) {
      return str.length > length ? str.substring(0, length) + "..." : str;
    },
    sendMessage(message){
      if(message){
        BB.smsThread.reply({
          thread_id: this.selectedThread['_id'],
          message
        })
      }

    },
    threadName(thread){
      // if first_name + last_name is over 16 characters, add elipses
      let fullName = thread.first_name + " " + thread.last_name
      if(fullName.length > 20){
        return fullName.substring(0, 20) + "..."
      }
      return fullName
    },
    clickBack() {
      this.showList = true;
      this.selectedThread = null;
      this.selectedThreadCalendarIntegration = null;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    getThreadStatus(thread){
      if(thread.status=="disabled"){
        return "disabled"
      }
      else if(thread.status=="converted"){
        return "converted"
      }
      else if(thread.status=="deleted"){
        return "deleted"
      }
      else if(thread.status=="takeover"){
        return "takeover"
      }
      else if(thread.status=="scheduled"){
        return "scheduled"
      }
      // if last message was more than 48 hours ago
      else if(thread.messages && (new Date(thread.messages[thread.messages.length-1].timestamp) < new Date(Date.now() - 48 * 60 * 60 * 1000))){
        if(thread.messages.length>2){
          return "stale-active"
        }
        return "stale"
      }
      // if thread has all messages from system
      else if(thread.messages && thread.messages.length>0 && thread.messages.every(message => message.sender=="system")) {
        return "initial"
      }
      return "active";
    },
    async filterThreads(e) {
      if(this.selectedBrand){
        this.filteredThreads = this.threads.filter(thread => thread.brand_id === this.selectedBrand)
      }
      else{
        this.filteredThreads = this.threads
      }
    },
    async getThreads(){
      // single thread schema:
      //   "loan_officer": {
      //       "first_name": "USA Mortgage",
      //       "last_name": "Team",
      //       "email": "ameyer@dasacquisition.com",
      //       "phone": "",
      //       "nmls": "1586340",
      //       "brand_name": "USA Mortgages",
      //       "app_link": "https://headquarters.usa-mortgage.com/?loanapp&siteid=2728641155&lar=admin&workFlowId=39510",
      //       "brand_nmls": "3232",
      //       "calendar_link": "",
      //       "loid": 1930
      //   },
      //   "quote_info": {
      //       "loan_amount": 251989,
      //       "loan_purpose": "purchase",
      //       "property_type": "single_family_home",
      //       "zipcode": "",
      //       "credit_score": 780,
      //       "military_eligible": ""
      //   },
      //   "_id": "66bf94fce87e5e45321cd46f",
      //   "item_id": "45",
      //   "brand_id": 366184,
      //   "first_name": "Don",
      //   "last_name": "Trump Jr",
      //   "email": "dtrump@aol.com",
      //   "phone": "+18435090505",
      //   "dashboard_link": "https://l.bbridge.io/WAHn",
      //   "type": "subscription",
      //   "send_initial_summary": true,
      //   "status": "active",
      //   "messages": [
      //       {
      //           "message": "Hey, it's Katie with USA Mortgages. Thank you for your recent online submission! Here is a loan summary of your recent quote.\n\nI’ve also put together your custom rate dashboard to view rates 24/7.\n\nCheck it out: https://l.bbridge.io/WAHn\n\nNMLS 1586340, Text STOP to OptOut.\n\nIs this your first home or have you purchased before?\n",
      //           "timestamp": "2024-08-16T18:06:00.813Z",
      //           "sender": "system",
      //           "status": "sent",
      //           "_id": "66bf9509e87e5e45321cd471",
      //           "notes": []
      //       },
      //     ...
      //     ]
      //   }
      let params={}
      if(brand_id){
        params['brand_id']=brand_id
      }
      let threadResults = await BB.smsThread.find(params)
      if(threadResults.status==="error"){
        return
      }
      // filter out threads with no messages
      threadResults = threadResults.filter(thread => thread.status==="scheduled" || thread.messages.length > 0);
      // sort threads by most recent message in descending order, if there are no messages, use createdAt
      threadResults.sort((a, b) => {
        const dateA = a.messages[a.messages.length - 1] ? new Date(a.messages[a.messages.length - 1].timestamp) : new Date(a.createdAt);
        const dateB = b.messages[b.messages.length - 1] ? new Date(b.messages[b.messages.length - 1].timestamp) : new Date(b.createdAt);
        return dateB - dateA;
      });

      this.threads=threadResults
    },
    getLastMessageTimestamp(thread){
      // if there are no messages, use createdAt
      const date = thread.messages.length > 0 ? new Date(thread.messages[thread.messages.length - 1].timestamp) : new Date(thread.createdAt);
      return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }).replace(',', ' @');
    },
    async refreshSelectedThread(){
      if(!this.selectedThread){
        return
      }
      let result=null;
      if(this.selectedThread.type=="subscription"){
        result = await BB.smsThread.get({
          subscription_id: this.selectedThread.item_id,
        })
      }
      else if(this.selectedThread.type=="lead"){
        result = await BB.smsThread.get({
          lead_id: this.selectedThread.item_id,
        })
      }
      if(result.status==="error"){
        return
      }
      if(result['_id']==this.selectedThread['_id']){
        this.selectedThread = result
        // update thread list
        this.threads = this.threads.map(thread => {
          if(thread['_id']==this.selectedThread['_id']){
            return this.selectedThread
          }
          return thread
        })
        this.filterThreads()
      }
    },
    selectThread(thread) {
      console.log("selecting thread: ",thread)
      this.showList = false;
      this.selectedThread = thread;
      this.getThreadCalendarIntegration()
    },
    async deleteThread() {
      if(this.selectedThread){
        await BB.smsThread.delete(this.selectedThread['_id'])
        this.selectedThread = null;
        await this.getThreads();
        this.showList=true
      }
    },
    async getThreadCalendarIntegration(){
      if(this.selectedThread){
        // {
        //   "_id": "66f01b2fb93af7324df39957",
        //     "api_key": "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzI1OTY5NTU1LCJqdGkiOiIwNjllZTM5OC02MjYzLTQ0MzAtODk0My03OTI1MDhhYTNiOWUiLCJ1c2VyX3V1aWQiOiJlZDg2OTIzYy0wOTU4LTRlNDUtOWY4ZS0wNmIwY2FkYTc1MDUifQ.EVa1DjhjRquX_66pimQpdtNcURNnq0cfbC0tuR1b0BomZGkzBgU3X8ABnCHS7L7carzTX4UxYKYjSW48oyQRFg",
        //     "user": {
        //   "avatar_url": null,
        //       "created_at": "2024-08-05T12:07:46.468Z",
        //       "current_organization": "https:\/\/api.calendly.com\/organizations\/76514f59-60f7-4caf-b6c1-6bb46c3a6239",
        //       "email": "jimmy@bankingbridge.com",
        //       "name": "Jimmy King",
        //       "resource_type": "User",
        //       "scheduling_url": "https:\/\/calendly.com\/jimmy-bankingbridge",
        //       "slug": "jimmy-bankingbridge",
        //       "timezone": "America\/New_York",
        //       "updated_at": "2024-09-10T11:57:12.894Z",
        //       "uri": "https:\/\/api.calendly.com\/users\/ed86923c-0958-4e45-9f8e-06b0cada7505",
        //       "_id": "66f01b2fb93af7324df39958"
        // },
        //   "loid": 2380,
        //     "webhook_uri": "https:\/\/api.calendly.com\/webhook_subscriptions\/055f0c37-d294-4f5c-8775-db8715305920",
        //     "event_type_uri": "https:\/\/api.calendly.com\/event_types\/036acacf-d4fa-48c8-bebc-45c251b1713a",
        //     "__v": 0
        // }
        let result = await BB.smsThread.getCalendarIntegration({
          thread_id: this.selectedThread['_id']
        })
        this.selectedThreadCalendarIntegration = result
      }
    },
    async takeOverThreadToggle() {
      if(this.selectedThread){
        if(this.selectedThread.status=="takeover"){
          await BB.smsThread.update({
            thread_id: this.selectedThread['_id'],
            status: "active"
          })
          this.selectedThread.status="active"
        }
        else{
          await BB.smsThread.update({
            thread_id: this.selectedThread['_id'],
            status: "takeover"
          })
          this.selectedThread.status="takeover"
        }
        // update selectedThread in threads list
        this.threads = this.threads.map(thread => {
          if(thread['_id']==this.selectedThread['_id']){
            return this.selectedThread
          }
          return thread
        })
        this.filterThreads()
      }
    },
    async getNewMessages(params){
      let result = BB.smsThread.getNewMessages(params)
          // [
          //   {
          //     "thread_id": "66f1b5cfe8b91e0e5955a58f",
          //     "new_messages": [
          //       {
          //         "message": "hi, are you still there?",
          //         "timestamp": "2024-10-01T15:37:47.294Z",
          //         "sender": "user",
          //         "status": "sent",
          //         "_id": "66fc174b0ef45caf876d3398"
          //       },
          //       {
          //         "message": "Yes, I'm here! How can I assist you further with your mortgage needs today? <!!>{\"purchase_readiness\":4,\"reasoning\":\"User re-initiated contact, indicating continued interest or need for assistance but no specific action indicated yet.\",\"action\":\"\"}",
          //         "timestamp": "2024-10-01T15:37:47.316Z",
          //         "sender": "system",
          //         "status": "sent",
          //         "_id": "66fc174b0ef45caf876d339a"
          //       }
          //     ]
          //   },
          // ]
      return result;
    },
    async pollSelectedThread() {
      while(true){
        // Poll the selected thread for new messages while there is a selected thread and there has been activity within the last 3 minutes
        if(this.selectedThread && (Date.now() - this.lastActivity < 180000)){
          await this.refreshSelectedThread()
        }
        else{
          console.log("Refresh disabled.")
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    },
    async toggleSettingsModal(){
      this.showSettingsModal = !this.showSettingsModal
      // modal was closed we are opening it
      if(this.showSettingsModal){
        this.modalIsLoading=true
        let results=await BB.smsThread.getCalendarIntegrations()
        let brands={}
        // iterate over results and get lo for each
        for(let i=0; i<results.length; i++){
          results[i].lo = (await BB.loManage.get(results[i].loid)).lo
          brands[results[i].bank_nid] = results[i].bank
        }
        this.calendlyIntegrations=results
        results = await BB.smsThread.getSenders()
        // iterate over results and get brand for each
        for(let i=0; i<results.length; i++){
          if(brands[results[i].brand_id]){
            results[i].brand_name = brands[results[i].brand_id]
          }
          else{
            results[i].brand_name = (await BB.brandManage.get(results[i].brand_id)).name
          }
        }
        this.threadSenders=results
        this.modalIsLoading=false
      }
      // modal was open we are closing it
      else{
        this.modalIsLoading=false
      }
    },
    async pollThreads() {
      // poll for new messages across all threads every 10 seconds while there has been activity within the last 3 minutes
      while(true) {
        if (Date.now() - this.lastActivity < 180000) {
          // check for new messages
          let result=await this.getNewMessages()
          // update threads with new messages, if there are new messages associated with a thread that we dont already have, get the thread and add it to the threads list
          if(result.length>0){
            for(let i=0; i<result.length; i++){
              let thread = this.threads.find(thread => thread['_id'] == result[i].thread_id)
              if(thread){
                thread.messages = thread.messages.concat(result[i].new_messages)
              }
              else{
                let newThread = await BB.smsThread.get({
                  thread_id: result[i].thread_id
                })
                this.threads.push(newThread)
              }
            }
            this.filterThreads()
          }

        } else {
          console.log("Refresh disabled.")
        }
        await new Promise(resolve => setTimeout(resolve, 10000));
      }

    }
  }
}
</script>

<style scoped>
.list-group-item.active {
  background-color: #5C52C0;
  border: 1px solid #5C52C0;
}
.chat-back-btn {
  cursor: pointer;
  border: none;
  background: transparent;
  display: none; /* Hide by default */
}
.options-dropdown{
  padding: 10px;
  .thread-options{
    background: transparent;
    border: none;
    color: #000;
    cursor: pointer;
  }
  &.open{
    .thread-options{
      box-shadow: none !important;
    }
  }
}
@media (max-width: 767px) {
  .chat-back-btn {
    display: inline-block; /* Show on mobile devices */
    margin-top:8px;
  }
  .col-sm-4.d-none {
    display: none !important; /* Hide left sidebar on mobile when showList is false */
  }
  .col-sm-8.d-none {
    display: none !important; /* Hide right sidebar on mobile when showList is true */
  }
}
.options-dropdown{
  float: right;
  margin-top:0px;
}
.threads-list{
  li.list-group-item{
    cursor: pointer;
    padding: 10px 5px !important;
  }
}
.status-indicator{
  float: right;
  &.active{
    color: #8BC34A;
  }
  &.converted{
    color: #8BC34A;
  }
  &.disabled{
    color: #F44336;
  }
  &.initial{
    // color: #FFC107;
    color: #4DD0E1;
  }
  &.stale{
    color: #CFD8DC;
  }
  &.stale-active{
    color: #DCEDC8;
  }
  &.takeover{
    color: #4CAF50;
  }
  &.deleted{
    color: #F44336;
  }
  &.scheduled{
    color: #FFC107;
  }
}
.dropdown-menu{
  li{
    a{
      &.disabled{
        &:hover{
          background-color: transparent;
        }
      }
      .calendar-status-icon{
        &.red{
          color: #F44336;
        }
        &.green{
          color: #4CAF50;
        }
      }
    }
  }
}
.settings-btn{
  .fa-icon{
    cursor: pointer;
  }
  margin-left: 10px;
}
.panel-btn{
  cursor: pointer;
}
.phone-number-list-item{
  margin-left: 10px;
  .default{
    font-weight: bold;
    color: #0f4878;
  }
}

</style>