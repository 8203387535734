import Vue from "vue"
import initStore from "./store/bb-manager"
import { OrganizationManager } from "./pages"

export default function(LOID) {
  if (!document.getElementById("org-manager-app")) {
    return
  }
  const store = initStore()
  // initial items
  store.dispatch("organization/initManager")
  store.commit("organization/SET_LOID", LOID)

  // Vuejs setup
  new Vue({
    el: "#org-manager-app",
    store,
    components: {
      OrganizationManager: OrganizationManager
    }
  })
}
