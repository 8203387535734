const ENDPOINT = "/api/v1/tag-manager"
const GET_ASSOCIATIONS = '?list_name='
const GET_TAGS = 'tags?list_name='

const bbStaticFlyerTags = {
    get(params, callback = null) {
        let url
        switch (params.type) {
            case 'getAssociations':
                url = `${ENDPOINT}/${GET_ASSOCIATIONS}${params.resource}`
                break;
            case 'getTags':
                url = `${ENDPOINT}/${GET_TAGS}${params.resource}`
                break;
        }

        return $.get(url, {}, function (data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
    add(params, callback) {
        return $.ajax({
            url: ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(params),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    }
}

export default bbStaticFlyerTags
