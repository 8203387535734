var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Overview" } },
            [_c("ManagerOverview")],
            1
          ),
          _vm._v(" "),
          _c("Tab", { attrs: { name: "Brands" } }, [_c("ManagerBrands")], 1),
          _vm._v(" "),
          _c("Tab", { attrs: { name: "People" } }, [_c("ManagerPeople")], 1),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Activity" } },
            [_c("CompanyActivity")],
            1
          ),
          _vm._v(" "),
          _vm.showStaticFlyers
            ? _c(
                "Tab",
                { attrs: { name: "Static Flyers" } },
                [_c("ManagerStaticFlyers")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdmin
            ? _c("Tab", { attrs: { name: "Status" } }, [_c("OrgStatus")], 1)
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("CompanyModal"),
      _vm._v(" "),
      _c("CompanyPeopleModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-63fdc4d7", { render: render, staticRenderFns: staticRenderFns })
  }
}