var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CardToggle",
    { attrs: { title: _vm.titlePeople } },
    [
      _vm._l(_vm.members, function(group, key) {
        return _c(
          "div",
          { key: key },
          [_c("AvatarList", { attrs: { items: group.members, limit: 5 } })],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "FlexBox",
        { staticClass: "btn-wrapper", attrs: { justify: "between" } },
        [
          _c(
            "BaseButton",
            {
              attrs: {
                outline: "",
                type: "button",
                size: "sm",
                clickHandler: function() {}
              }
            },
            [_vm._v("\n      + Add People\n    ")]
          ),
          _vm._v(" "),
          _c(
            "BaseButton",
            {
              attrs: {
                isLink: "",
                type: "button",
                size: "sm",
                theme: "link",
                href: "#people"
              }
            },
            [
              _vm._v(
                "\n      View all " + _vm._s(_vm.membersCount) + " people\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-154d5ac6", { render: render, staticRenderFns: staticRenderFns })
  }
}