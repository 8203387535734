const logical_operators = ["and", "or"]

const addNewStepToArray = (steps, type) => {
  let newSteps = [...steps]

  switch (type) {
    case "list":
      newSteps.push({
        id: "employment_status",
        type: "list",
        title: "What is your employment status?",
        items: [
          {
            label: "Employed",
            value: "employed"
          },
          {
            label: "Not Employed",
            value: "not_employed"
          },
          {
            label: "Military",
            value: "military"
          },
          {
            label: "Retired",
            value: "retired"
          },
          {
            label: "Self Employed",
            value: "self_employed"
          }
        ]
      })
      break
    case "buttons":
      newSteps.push({
        id: "foreclosure_bankruptcy",
        type: "buttons",
        title: "Have you had a bankruptcy or foreclosure in last 4 years?",
        items: [
          {
            icon: "https://cdndev.bankingbridge.com/workflow/images/good.svg",
            label: "No",
            value: "no"
          },
          {
            icon:
              "https://cdndev.bankingbridge.com/workflow/images/bankruptcy.svg",
            label: "Bankruptcy",
            value: "bankruptcy"
          },
          {
            icon:
              "https://cdndev.bankingbridge.com/workflow/images/foreclosure.svg",
            label: "Foreclosure",
            value: "foreclosure"
          }
        ]
      })
      break
    case "slider":
      newSteps.push({
        id: "household_income",
        type: "slider",
        title: "Annual Household Income",
        range_start: 30000,
        range_end: 400000,
        point_size: 10000,
        step: 10000,
        prefix: "$"
      })
      break
    case "location":
      newSteps.push({
        id: "location",
        type: "location_autocomplete",
        title: "In what city is the property located?",
        placeholder: "Enter City, State, Zip, etc..."
      })
      break
    case "lo_select":
      newSteps.push({
        id: "loid",
        type: "lo_select",
        title: "Are You Already Working With A Loan Officer?"
      })
      break
  }

  return newSteps
}

const evaluateConditional = (key, value) => {
  let op = "eq"
  let val = ""
  if (Array.isArray(value)) {
    val = value[0]
    op = value[1] ? value[1] : op
  } else {
    val = value
  }
  if (op == "gt") return key + " greater than " + val
  if (op == "gte") return key + " greater than or equal to " + val
  if (op == "lt") return key + " less than " + val
  if (op == "lte") return key + " less than or equal to " + val
  if (op == "!eq") return key + " not equal " + val
  if (op == "eq") return key + " equals " + val
  if (op == "!isset") return key + " is empty"
  if (op == "isset") return key + " is not empty"
  if (!op) return key + " equals " + val
}

const buildConditionStr = (condition, op = "", str = "") => {
  op = op ? op : "and"
  str = str ? str : ""
  for (const key in condition) {
    if (logical_operators.indexOf(key) > -1) {
      str +=
        "(" +
        buildConditionStr(condition[key], key) +
        ") " +
        op.toUpperCase() +
        " "
    } else {
      str +=
        evaluateConditional(key, condition[key]) + " " + op.toUpperCase() + " "
    }
  }
  str =
    str.substring(str.length - 5) === " AND "
      ? str.substring(0, str.length - 5)
      : str
  str =
    str.substring(str.length - 4) === " OR "
      ? str.substring(0, str.length - 4)
      : str
  return str
}

const flattenConditions = (condition, op, condList) => {
  op = op ? op : "and"
  condList = condList ? condList : {}
  for (const key in condition) {
    if (logical_operators.indexOf(key) > -1) {
      flattenConditions(condition[key], key, condList)
    } else {
      condList[key] = condition[key]
    }
  }
  return condList
}

const validateConfig = steps => {
  let flag = true
  let violation = null
  // iterate through existing steps
  for (let i = 0; i < steps.length; i++) {
    let step = steps[i]
    if (step.condition && flag) {
      let conditionList = flattenConditions(step.condition)
      // check if one of the steps has an ID that matches the condition
      Object.keys(conditionList).forEach(conditionID => {
        if (steps.slice(0, i).findIndex(stp => stp.id == conditionID) < 0) {
          flag = false
          violation = {
            condition: conditionID,
            step_id: step.id
          }
        }
      })
    }
  }
  if (violation && violation.step_id && violation.condition) {
    BB.Toastr.error(
      "Step " +
        violation.step_id +
        " has invalid condition: " +
        violation.condition
    )
  }
  return flag
}

export {
  addNewStepToArray,
  buildConditionStr,
  flattenConditions,
  validateConfig
}
