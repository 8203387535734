<template>
    <div>
        hello
    </div>
</template>


<script>
    export default {
        name: 'PricingDetailsPanel',
        components:{},
        async mounted(){

        },
        data(){
            return {
            };
        },
        methods:{
        }
    };
</script>