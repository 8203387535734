<doc>
	Thumb profiles Vue.js component
</doc>

<template>
	<div class="d-flex profile-thumbs" style="flex-wrap: wrap;">
		<GridColumn
			:size="sizes"
			v-for="(profile, index) in profiles"
			:item="profile"
			:key="profile.id"
			:data-program-id="profile.id">
				<ProgramProfile :program="profile" :index="index" :isThumb="true" />
		</GridColumn>
</div>
</template>

<script>
import { mapState } from "vuex"
import ProgramProfile from "./ProgramProfile"
import _orderBy from "lodash/orderBy"
import _isArray from "lodash/isArray"
export default {
	name: "ProgramProfileThumbs",
	props: { programProfiles: { type: [Object, Array], required: true } },
	components: {
		ProgramProfile: ProgramProfile
	},
	computed: Object.assign({}, mapState(["gridView"]), {
		sizes() {
			let sizes = ["6"]
			if (this.gridView) {
				sizes.push("lg-4")
			}
			return sizes
		},
		profiles() {
			if (_isArray(this.programProfiles)) {
				return this.programProfiles.slice(0, 9)
			}
			return _orderBy(Object.values(this.programProfiles).slice(0, 9), "weight")
		}
	})
}
</script>

<style lang="scss">
.profile-thumbs {
	background: #eff7f9;
	padding: 5px 2px 0;
	border-radius: 4px 4px 0 0;
	overflow: hidden;
	height: 196px;

	.list-view & {
		width: 68px;
		height: 68px;
		padding: 3px 1px 0;
	}

	[class^="col-"] {
		padding-left: 3px;
		padding-right: 3px;
		.list-view & {
			padding-left: 1px;
			padding-right: 1px;
		}
	}
}
</style>
