var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config-name" }, [
    _c("label", { staticClass: "config-name__label sr-only" }, [
      _vm._v(_vm._s(_vm.label))
    ]),
    _vm._v(" "),
    _vm.hasInUse
      ? _c("span", { class: [{ active: _vm.inUse }, "config-name__in-use"] })
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "config-name__input" }, [
      _c("input", {
        attrs: { disabled: _vm.disabled, type: "text" },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            _vm.$emit("input", $event.target.value)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5c5e9c2f", { render: render, staticRenderFns: staticRenderFns })
  }
}