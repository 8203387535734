var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Tabs",
    [
      _c("Tab", { attrs: { name: "Rate Table" } }, [_c("RateTableManager")], 1),
      _vm._v(" "),
      _c(
        "Tab",
        { attrs: { name: "Lead Workflow" } },
        [_c("LeadWorkflowManager")],
        1
      ),
      _vm._v(" "),
      _c("Tab", { attrs: { name: "Rateflow" } }, [_c("RateflowManager")], 1),
      _vm._v(" "),
      _c(
        "Tab",
        { attrs: { name: "Contact Form" } },
        [_c("ContactFormManager")],
        1
      ),
      _vm._v(" "),
      _c("Tab", { attrs: { name: "County Admin" } }, [_c("CountyAdmin")], 1),
      _vm._v(" "),
      _c(
        "Tab",
        { attrs: { name: "Chat Config" } },
        [_c("DashboardChatManager")],
        1
      ),
      _vm._v(" "),
      _c(
        "Tab",
        { attrs: { name: "Subscription" } },
        [_c("SubscriptionManager")],
        1
      ),
      _vm._v(" "),
      _c("Tab", { attrs: { name: "Billing" } }, [_c("BillingManager")], 1),
      _vm._v(" "),
      _c("Tab", { attrs: { name: "Rate Cards" } }, [_c("RateCardsManager")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e1361d8a", { render: render, staticRenderFns: staticRenderFns })
  }
}