<doc>
  Confirm Modal Vue.js component
  Props:
    - showConfirm: boolean to show or hide modal
    - title: String value for the title (optional)
    - text: String value for the content (required)
    - v-on:confirm callback for the confirm action
    - v-on:cancel callback for the cancel action
</doc>

<template>
  <BaseModal
    class="confirm-modal"
    small
    ref="modal"
    v-model="confirmModel"
    :title="title"
    @ok="confirm"
    @cancel="cancel"
    okLabel="OK"
    :showCancelBtn="showCancelBtn"
  >
    <p>{{ text }}</p>
  </BaseModal>
</template>

<script>
import BaseModal from "../BaseModal"
export default {
  name: "ConfirmModal",
  props: {
    showConfirm: { type: Boolean },
    title: { type: String },
    text: { type: String, required: true },
    showCancelBtn: { type: Boolean, default: true }
  },
  computed: {
    confirmModel: {
      get() {
        return this.showConfirm
      },
      set(value) {
        if (!value) {
          this.$emit("modalCancel")
        }
      }
    }
  },
  components: { BaseModal: BaseModal },
  methods: {
    cancel() {
      this.$emit("modalCancel")
    },
    confirm: function() {
      this.$emit("modalConfirm")
    }
  },
  mounted: function() {
    document.body.appendChild(this.$el)
  }
}
</script>

<style lang="scss" scoped></style>
