var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Tabs",
        { attrs: { initialHash: _vm.initialHash } },
        [
          _c(
            "Tab",
            { attrs: { name: "Static Flyers" } },
            [
              _vm.isBankAdmin
                ? _c("div", [
                    _c("label", [_vm._v("Brand:")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.brandId,
                            expression: "brandId"
                          }
                        ],
                        staticClass: "selectBrand",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.brandId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.changeSelectedBrand
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Please select one")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.availableBrands, function(brand) {
                          return _c(
                            "option",
                            {
                              domProps: { value: brand.id },
                              model: {
                                value: _vm.brandId,
                                callback: function($$v) {
                                  _vm.brandId = $$v
                                },
                                expression: "brandId"
                              }
                            },
                            [_vm._v(_vm._s(brand.name))]
                          )
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("StaticFlyerList")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Manual Listings" } },
            [_c("ManualListingList")],
            1
          ),
          _vm._v(" "),
          _vm.listing_access
            ? _c("Tab", {
                attrs: {
                  name: "Shared Listings",
                  linkHref: "/my-shared-listings/grid"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("ManualListingModal"),
      _vm._v(" "),
      _c("StaticFlyerModal"),
      _vm._v(" "),
      _c("StaticFlyerPrintModal"),
      _vm._v(" "),
      _c("ManualListingPrintModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-703386c0", { render: render, staticRenderFns: staticRenderFns })
  }
}