<doc>
  Flyer template manager Vue.js page
</doc>

<template>
  <div>
    <button
      v-bind:class="{ cancel: editorActive }"
      class="btn btn-circle pull-right"
      @click="addNew"
      type="button"
    >
      <div>+</div>
    </button>
    <div v-if="!editorActive && available_templates">
      <!-- <div class="row">
        <div class="col-md-12" style="margin-top:20px;">
          <select
            class="select-template"
            v-model="template_id"
            @change="changeSelectedTemplate"
          >
            <option disabled value="">Please select one</option>
            <option
              v-for="tpl in available_templates"
              :value="tpl.id"
              :key="tpl.id"
            >
              {{ tpl.name }}
            </option>
          </select>
        </div>
      </div> -->
      <div v-if="tableLoading">
        Loading ...
      </div>
      <div v-else class="row">
        <div class="col-md-12">
          <vue-good-table
            :columns="headers"
            :rows="templateData"
            :search-options="{
              enabled: true,
              searchFn: tableSearch,
              trigger: 'enter'
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'active'">
              </span>
              <span v-if="props.column.field == 'preview'">
                <img width="200px" :src="props.row.preview"/>
              </span>
              <span v-else-if="props.column.field == 'brandPermissions'">
                <ul v-for="item in props.row.orgNames">
                  {{item}}
                </ul>
                <ul v-for="item in props.row.brandNames" style="display:none">
                  {{item}}
                </ul>
              </span>
              <span v-else-if="props.column.field == 'edit'">
                <button v-on:click="configSelectedTemplate(props.row.id)">View/Edit</button>
              </span>
              <span v-else-if="props.column.field == 'update'">
                <button v-on:click="updateBrandPermissions(props.row.id)">Update Brand Permissions</button>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
      <FlyerTemplateModal
        ref="modal"
        v-show="isModalVisible"
        :title=flyerTitle
        :orgAndBrandNames="this.orgAndBrandNames"
        @cancel="cancel" />
    </div>
    <div v-else-if="editorActive">
      <div v-if="loading" style="text-align:center; margin-top:30px;">
        <div class="loader loader-circle tt-loading"></div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-xs-9">
            <div class="templateName">
              <input v-model="name" type="text" />
            </div>
          </div>
          <div class="col-xs-3" style="text-align: right;">
            <select class="select-type" v-model="type">
              <option disabled value="">Please select one</option>
              <option value="flyer" key="flyer">flyer</option>
              <option value="share" key="share">share</option>
              <option value="quote_flyer" key="quote_flyer">quote_flyer</option>
            </select>
          </div>
        </div>
        <Split :gutterSize="5">
          <SplitArea class="leftPane" :size="leftWidth" :minSize="100">
            <div>
              <iframe v-bind:class="type" :srcdoc="preview"></iframe>
            </div>
          </SplitArea>
          <SplitArea class="rightPane" :size="rightWidth">
            <div class="tabWrapper">
              <div
                @click="toggleDrawer"
                class="drawer-tab"
                v-bind:class="{ active: drawerToggleActive }"
              >
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
            <div class="paneHeader" :class="{ changed: changed }">
              Source Code
            </div>
            <div class="content">
              <form
                id="template-form"
                class="form"
                ref="templateForm"
                v-on:submit.prevent
              >
                <fieldset>
                  <codemirror
                    ref="myCm"
                    :value="template"
                    @input="onCmCodeChange"
                    :options="cmOptions"
                  >
                  </codemirror>
                </fieldset>
              </form>
            </div>
          </SplitArea>
        </Split>
        <div class="activeCheckbox">
          <input type="checkbox" id="checkbox" v-model="active" />
          <label for="checkbox">Active</label>
        </div>
        <button class="submitBtn btn btn-modern" @click="save">Save</button>
        <button class="submitBtn btn btn-modern purple" @click="convert">
          <span v-if="!convert_in_progress">Convert</span>
          <span v-else
            ><div class="loader loader-circle tt-loading"></div
          ></span>
        </button>
        <div v-if="result">
          <div class="panel panel-default" style="margin-top:60px;">
            <div class="panel-heading">
              <h3 class="panel-title">PDF Ready</h3>
            </div>
            <div class="panel-body">
              <a target="_blank" :href="result.file"
                >{{ name }} {{ dateStr }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Loading ...
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { InputTextArea, DropDown } from "../components/Forms"
import Dropdown from "../components/Dropdown"
import { codemirror } from "vue-codemirror"
import Mustache from "mustache"
import { Split, SplitArea } from "vue-split-panel"
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import "codemirror/mode/javascript/javascript.js"
import "codemirror/mode/htmlmixed/htmlmixed.js"
import { Tabs, Tab } from "../components"
import md5 from "js-md5"
import { VueGoodTable } from "vue-good-table"

// styleSelectedText
import "codemirror/addon/selection/mark-selection.js"
import "codemirror/addon/search/searchcursor.js"
// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js"
import "codemirror/addon/search/matchesonscrollbar.js"
import "codemirror/addon/search/searchcursor.js"
import "codemirror/addon/search/match-highlighter.js"
// keyMap
import "codemirror/mode/clike/clike.js"
import "codemirror/addon/edit/matchbrackets.js"
import "codemirror/addon/comment/comment.js"
import "codemirror/addon/dialog/dialog.js"
import "codemirror/addon/search/searchcursor.js"
import "codemirror/addon/search/search.js"
import "codemirror/keymap/sublime.js"
// foldGutter
import "codemirror/addon/fold/brace-fold.js"
import "codemirror/addon/fold/comment-fold.js"
import "codemirror/addon/fold/foldcode.js"
import "codemirror/addon/fold/foldgutter.js"
import "codemirror/addon/fold/indent-fold.js"
import "codemirror/addon/fold/markdown-fold.js"
import "codemirror/addon/fold/xml-fold.js"
import FlyerTemplateModal from "./FlyerTemplateModal.vue"

const ENDPOINT =
  "https://us-east1-bankingbridge-155517.cloudfunctions.net/function-2"

let timeout
export default {
  name: "FlyerTemplateManager",
  data() {
    return {
      loading: true,
      template_id: null,
      available_templates: null,
      selected_template: null,
      template: "",
      preview: "",
      name: "Untitled",
      pricing_tpl: "",
      type: "flyer",
      editorActive: false,
      cmOptions: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        xmlFold: true,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        theme: "base16-dark",
        mode: "text/html"
      },
      result: null,
      hashvar: true,
      rightWidth: 50,
      leftWidth: 50,
      drawerToggleActive: false,
      tplData: {
        loan_officer: {},
        listing: {},
        realtor: {},
        sms_number: "(855) 880-6113",
        sms_id: "@g0b",
        qr_img: "https://l.bbridge.io/4R6p.svg",
        pricingHTML: "",
        settings: {},
        swatch: 0
      },
      convert_in_progress: false,
      pricingData: null,
      changed: false,
      original_hash: null,
      quote: null,
      templateData: [],
      headers: [
        {
          label: "Active",
          field: "active",
          width: "6%"
        },
        {
          label: "Preview",
          field: "preview",
          width: "15%"
        },
        {
          label: "Name",
          field: "name",
          width: "25%"
        },
        {
          label: "Brand Permissions",
          field: "brandPermissions",
          width: "20%"
        },
        {
          label: "View/Edit",
          field: "edit",
          width: "9%"
        },
        {
          label: "Update Brand Permissions",
          field: "update",
          width: "20%"
        }
      ],
      isModalVisible: false,
      flyerTitle: "",
      orgs: null,
      brands: null,
      orgPermissionMap: null,
      brandPermissionMap: null,
      orgAndBrandNames: null,
      tableLoading: true
    }
  },
  watch: {},
  async mounted() {
    this.available_templates = await BB.template.get({})
    let _this = this

    this.template_id = tplId ? tplId : null

    let { lo } = await this.setLO(loid)
    this.tplData.loan_officer = this.formatLoanOfficer(lo)

    let listings = await this.setListing(listing_id)
    this.tplData.listing = listings[0]

    let realtor = await this.setRealtor(realtor_id)
    this.tplData.realtor = realtor

    let pricingData = await this.setPricing({
      loid: lo.nid,
      list_price: this.tplData.listing.listing_price
    })
    this.pricingData = pricingData ? pricingData.scenarios.results : null
    this.quote = pricingData ? pricingData.quote : null

    this.tplData.listing = this.formatListingData(this.tplData.listing)
    
    if (_this.template_id) {
      this.configApp()
    }

    //await this.renderTpl(this.template)
    //console.log("step 5")
    _this.loading = false
    var templatePermissions = await BB.templatePermissions.get({})
    this.orgAndBrandNames = await BB.orgBrandManage.get({})
    this.orgAndBrandNamesMap = new Map()
    for(const element of this.orgAndBrandNames) {
      this.orgAndBrandNamesMap.set(element.nid, element.title)
    }
    let orgPermissionMap = new Map()
    for(const row of templatePermissions) {
      let tempArray = row.brand_id != 0 ? [row.brand_id] : [0]
      let orgToBrandMap = !orgPermissionMap.get(row.template_id) ? new Map() : orgPermissionMap.get(row.template_id)
      if(orgToBrandMap.get(row.org_id) === undefined || orgToBrandMap.get(row.org_id).length == 0 ) {
        orgToBrandMap.set(row.org_id, tempArray)
      } else {
        orgToBrandMap.set(row.org_id, orgToBrandMap.get(row.org_id).concat(tempArray))
      }
      orgPermissionMap.set(row.template_id, orgToBrandMap)
    }
    this.orgPermissionMap = orgPermissionMap
    for(const template of this.available_templates) {
      let orgIdMap = orgPermissionMap.get(template.id)
      let orgNamesArray = []
      let orgIdArray = []
      let brandNamesArray = []
      let brandIdArray = []
      if(orgIdMap != null) {
        for(let [key, value] of orgIdMap.entries()) {
          let orgName = this.orgAndBrandNamesMap.get(key)
          orgIdArray.push(key)
          key != 0 ? orgNamesArray.push(orgName) : orgNamesArray.push("All Orgs")
          for(const v of value) {
            if(v != 0) {
              brandIdArray.push(v)
              let brandName = this.orgAndBrandNamesMap.get(v)
              brandNamesArray.push(brandName)
            } else if(orgName) {
              brandNamesArray.push("All Brands of " + orgName)
            }
          }
        }
      } else {
        orgIdArray.push(0)
        orgNamesArray.push("All Orgs")
      }
      
      var tempTemplate = {
        preview: template.preview_url,
        name: template.name,
        id: template.id,
        orgNames: orgNamesArray,
        brandNames: brandNamesArray,
        orgIds: orgIdArray,
        brandIds: brandIdArray,
        active: template.active == 0 ? "No" : "Yes"
      }
      this.templateData.push(tempTemplate);
    }
    _this.tableLoading = false
  },
  components: {
    InputTextArea,
    codemirror,
    Tabs,
    Tab,
    Split,
    SplitArea,
    DropDown,
    Dropdown,
    VueGoodTable,
    FlyerTemplateModal
},
  computed: {
    dateStr() {
      var date = new Date()
      return date.toLocaleString()
    }
  },
  updated() {
    if (this.template) {
      let new_hash = this.getHash()
      if (this.original_hash && this.original_hash != new_hash) {
        this.changed = true
      } else {
        this.changed = false
      }
    }
  },
  methods: {
    onInput(e) {},
    convert() {
      let post_body = {
        type: this.type == "share" ? "puppetShare" : this.type,
        html: this.preview
      }
      let vm = this
      this.convert_in_progress = true
      $.ajax({
        url: ENDPOINT,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(post_body),
        success(data) {
          console.log(data)
          vm.result = data
          vm.convert_in_progress = false
          if (vm.template_id) {
            BB.template.update({
              id: vm.template_id,
              preview_url: data.file.replace(".pdf", "-preview.jpeg")
            })
          }
        },
        error(xhr, ajaxOptions, thrownError) {
          console.log("error")
          vm.convert_in_progress = false
        }
      })
    },
    save() {
      if (!this.template_id) {
        BB.template
          .add({
            template_html: this.template,
            name: this.name,
            type: this.type,
            active: this.active ? 1 : 0
          })
          .then(data => {
            if (data.template_id) {
              BB.Toastr.success("Saved")
              this.original_hash = this.getHash()
              this.changed = false
              this.template_id = data.template_id
            }
          })
      } else {
        BB.template
          .update({
            id: this.template_id,
            template_html: this.template,
            name: this.name,
            type: this.type,
            active: this.active ? 1 : 0
          })
          .then(data => {
            if ((data.status = "success")) {
              BB.Toastr.success("Saved")
              this.original_hash = this.getHash()
              this.changed = false
              console.log(data)
            }
          })
      }
    },
    getHash() {
      return md5(
        JSON.stringify({
          template: this.template,
          name: this.name,
          type: this.type,
          active: this.active ? 1 : 0
        })
      )
    },
    onCmReady() {},
    onCmFocus() {},
    async onCmCodeChange(tpl) {
      this.template = tpl
      // debounce
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.renderTpl()
      }, 1000)
    },
    onResize(e) {
      console.log(e)
    },
    toggleDrawer() {
      if (this.drawerToggleActive) {
        this.rightWidth = 50
        this.leftWidth = 50
        this.drawerToggleActive = false
      } else {
        this.rightWidth = 20
        this.leftWidth = 80
        this.drawerToggleActive = true
      }
    },
    setLO(loid) {
      return BB.loManage.get(loid)
      //this.tplData.loan_officer=data.lo
    },
    setListing(listing_id) {
      if(listing_id){
        return BB.listing.get({
          "_id": listing_id
        })
      }
      return BB.listing.get({
        generalSearch: true,
        offset: listingOffset ? listingOffset : 5
      })
    },
    setRealtor(rid) {
      return BB.realtorManage.get(rid)
    },
    async setPricing(info) {
      let scenarios = await BB.rateflow.price({
        list_price: info.list_price,
        loid: info.loid
      })
      if(!scenarios.results[0] || !scenarios.results[0].rateflow_log_id){
        return null;
      }
      return {
        scenarios,
        quote: await BB.rateflow.log(scenarios.results[0].rateflow_log_id)
      }
    },
    loadTemplate(id) {
      return BB.template.get({ id })
    },
    async renderTpl() {
      const tpl = this.template
      this.tplData.pricingHTML = this.renderPricingTpl()
      let html
      try {
        html = Mustache.to_html(tpl, this.tplData)
        this.preview = html
      } catch (err) {
        console.log(err)
      }
      console.log("in renderTpl...")
    },
    renderPricingTpl() {
      let cards = []
      if (!this.pricingData) return
      for (var i = 0; i < 4; i++) {
        let card = this.pricingData[i]
        cards[i] = {
          apr: Number(card.apr).toFixed(3),
          closing_cost: Number(card.closing_cost).toLocaleString(),
          down_payment: Number(
            Math.round(card.dpp * card.list_price)
          ).toLocaleString(),
          loan_amount: Number(
            Math.round(card.list_price - card.dpp * card.list_price)
          ).toLocaleString(),
          label: card.label,
          list_price: Number(card.list_price).toLocaleString(),
          mi: card.mi,
          monthly_payment: Number(
            Math.round(Number(card.mi) + card.pi_monthly)
          ).toLocaleString(),
          pricing_tpl_id: card.pricing_tpl_id,
          rate: Number(card.rate).toFixed(3),
          rateflow_log_id: card.rateflow_log_id,
          term: card.term / 12,
          timestamp: card.timestamp,
          umip: card.umip
        }
      }
      let tplData = {
        cards,
        extraMonthly: 315
      }
      let html
      try {
        html = Mustache.to_html(this.pricing_tpl, tplData)
        return html
      } catch (err) {
        console.log(err)
      }
      return
    },
    formatListingData(listing) {
      for (const [key, value] of Object.entries(listing)) {
        switch (key) {
          case "pub_remarks":
            const max_length = 300
            listing[key] =
              value.length > max_length
                ? value.substring(0, max_length) +
                  "... Scan the QR code for more information."
                : value
            break
          case "listing_price":
            listing[key] = Number(value).toLocaleString()
            break
          default:
            listing[key] = value
            break
        }
      }
      if (listing.pictures) listing.num_pictures = listing.pictures.length
      return listing
    },
    formatLoanOfficer(loan_officer) {
      for (const [key, value] of Object.entries(loan_officer)) {
        switch (key) {
          default:
            loan_officer[key] = value
            break
        }
      }
      loan_officer.title = loan_officer.title
        ? loan_officer.title
        : "Loan Officer"
      return loan_officer
    },
    setURLParam(id) {
      if (!id) return
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.set("id", id)
      window.location.search = urlParams
    },
    removeURLParam(key) {
      let sourceURL = window.location.href
      var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString =
          sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : ""
      if (queryString !== "") {
        params_arr = queryString.split("&")
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0]
          if (param === key) {
            params_arr.splice(i, 1)
          }
        }
        rtn = rtn + "?" + params_arr.join("&")
      }
      window.history.replaceState(null, null, rtn)
    },
    closeTemplate() {
      let close = true
      if (this.changed) {
        close = confirm("Warning: All unsaved changes will be lost")
      }
      if (close) {
        this.editorActive = false
        this.template_id = null
        this.selected_template = null
        this.removeURLParam("id")
        this.template = null
        this.preview = ""
        this.name = "Untitled"
        this.type = ""
      }
    },
    addNew() {
      if (this.editorActive) {
        this.closeTemplate()
      } else {
        this.editorActive = true
      }
    },
    async changeSelectedTemplate() {
      if (!this.template_id) return
      //this.setURLParam(this.template_id);
      this.configApp()
    },
    async configApp() {
      if (this.template_id) {
        console.log("load tpl: " + this.template_id)
        this.template_id = this.template_id
        let template = await this.loadTemplate(this.template_id)
        this.name = template.name
        this.type = template.type
        this.template = template.template_html ? template.template_html : ""
        this.pricing_tpl = template.pricing_tpl ? template.pricing_tpl : ""
        this.tplData.settings = template.settings ? template.settings : ""
        this.tplData.icons = template.icons ? template.icons : ""
        this.active = template.active == "1" ? template.active : 0
      }
      await this.renderTpl()
      this.original_hash = this.getHash()
      this.editorActive = true
    },
    async configSelectedTemplate(id) {
      console.log("load tpl: " + id)
      id = id
      this.template_id = id
      let template = await this.loadTemplate(id)
      this.name = template.name
      this.type = template.type
      this.template = template.template_html ? template.template_html : ""
      this.pricing_tpl = template.pricing_tpl ? template.pricing_tpl : ""
      this.tplData.settings = template.settings ? template.settings : ""
      this.tplData.icons = template.icons ? template.icons : ""
      this.active = template.active == "1" ? template.active : 0
      await this.renderTpl()
      this.original_hash = this.getHash()
      this.editorActive = true
    },
    async updateBrandPermissions(id) {      
      let templateObject = this.templateData.find(obj => {
        return obj.id === id
      })
      this.flyerTitle = templateObject.name
      this.isModalVisible = true
      this.$refs.modal.onModalClicked(templateObject)
    },
    tableSearch(row, col, cellValue, searchTerm) {
      const orgs = row.orgNames.map(name => {
        return name.toLowerCase()
      })
      const brands = row.brandNames.map(name => {
        return name.toLowerCase()
      })
      const search = searchTerm.toLowerCase()
      const name = row.name.toLowerCase()

      const orgMatch = orgs.find(element => {
        if (element.includes(search)) {
          return true;
        }
      });

      const brandMatch = brands.find(element => {
        if (element.includes(search)) {
          return true;
        }
      });

      if(name == search) {
        return row
      }
      if(orgMatch || brandMatch || orgs.includes("all orgs")) {
        return row
      }
      if(searchTerm == "") {
        return cellValue
      }
      return null
    },
    cancel() {
      this.isModalVisible = false
    },
    async debounce(callback, limit) {
      let timeout
      return () => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          callback()
        }, limit)
      }
    }
  }
}
</script>

<style lang="scss">
#vue-manage-flyer-templates-app {
  //margin-top:20px;
}
.drawer-tab {
  position: relative;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 6px 0 0 6px;
  text-align: center;
  line-height: 40px;
  border-right: none;
  z-index: 1000;
  top: 10px;
  left: -42px;
  border: 1px solid #dedddc;
  cursor: pointer;
}
.tabWrapper {
  position: absolute;
}
.drawer-tab.active i {
  transform: rotate(180deg);
}
.split {
  height: 100vh !important;
}
iframe {
  min-width: 850px;
  height: 1050px;
  &.share {
    min-width: 1200px;
    height: 700px;
  }
  &.quote_flyer {
    min-width: 1200px;
    height: 1552px;
  }
}
.CodeMirror {
  height: 100vh;
}
.submitBtn {
  margin-top: 10px;
  float: right;
}
.leftPane {
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}
.leftPane::-webkit-scrollbar {
  display: none;
}
.rightPane {
  overflow: hidden;
}
.rightPane .content {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.paneHeader {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  padding: 5px 0px;
  min-width: 200px;
  max-width: 100%;
  &.changed {
    background-color: #ff8f00;
    color: white;
  }
}
.btn-modern.purple {
  background: linear-gradient(0deg, #7e57c2 0%, #9879ce 100%);
  border: 1px solid #7e57c2;
  margin-right: 5px;
  &:hover {
    background: linear-gradient(0deg, #7e57c2 0%, #b29ada 100%);
    border: 1px solid #7e57c2;
  }
  span {
    width: 48.78px;
    height: 19px;
    display: block;
    position: relative;
  }
}
.btn .loader {
  border-top: 0.125em solid rgba(255, 255, 255, 0.5);
  border-right: 0.125em solid rgba(255, 255, 255, 0.5);
  border-bottom: 0.125em solid rgba(255, 255, 255, 0.5);
  border-left: 0.125em solid white;
  font-size: 18px;
  position: absolute;
  top: 0px;
  right: 14px;
}
.templateName {
  padding: 10px 20px;
  outline: none;
  height: 60px;
  display: block;
  input {
    background: transparent;
    border: none;
    width: 100%;
    font-size: 30px;
    outline: none;
    font-family: "Roboto";
    font-weight: lighter;
    &:focus {
      border-bottom: 1px solid #c2c2c6;
    }
  }
}
.drop-down.tplType {
  margin-top: 10px !important;
}
.activeCheckbox {
  float: left;
  margin-top: 10px;
  margin-left: 20px;
}
.close-tpl {
  font-size: 26px;
  margin-top: 15px;
  cursor: pointer;
}
.btn-circle {
  background: #f1673e;
  border-radius: 25px;
  color: #fff;
  font-size: 20px;
  width: 44px;
  position: absolute;
  top: -60px;
  right: 15px;
  &:hover {
    color: #fff;
  }
  &:focus {
    color: #fff;
  }
}
.select-type {
  margin: 20px 10px 0px 0px;
}
button.cancel div {
  transform: rotate(-45deg);
}
@import "../../node_modules/codemirror/lib/codemirror.css";
@import "../../node_modules/codemirror/addon/dialog/dialog.css";
@import "../../node_modules/codemirror/addon/fold/foldgutter.css";
@import "../../node_modules/codemirror/theme/base16-dark.css";
@import "../../node_modules/vue-good-table/dist/vue-good-table.css";
</style>
