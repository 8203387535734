var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      class: { cancel: _vm.isCancel },
      attrs: { theme: "circle" },
      on: { click: _vm.onClick }
    },
    [_c("div", { staticClass: "btn__inner" }, [_vm._v("+")])]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ecf14a5", { render: render, staticRenderFns: staticRenderFns })
  }
}