var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [_vm._v("Pricing Status")]),
    _vm._v(" "),
    _vm.org && _vm.org.brands && _vm.org.brands.length > 0
      ? _c(
          "ul",
          _vm._l(_vm.org.brands, function(brand) {
            return _c(
              "li",
              [
                _vm._v(_vm._s(brand.name)),
                _c("icon", {
                  class: _vm.statusClassName(brand),
                  attrs: { name: "circle" }
                })
              ],
              1
            )
          })
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-613de2fa", { render: render, staticRenderFns: staticRenderFns })
  }
}