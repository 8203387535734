<doc>
	Company Activity Details Modal Vue.js component
</doc>

<template>
  <BaseModal
    ref="modal"
    v-model="showItemModal"
    :title="modalTitle"
    id="newPricingSetModal"
    @ok="hideModal"
    okLabel="Close"
    :showCancelBtn="false"
    :isLoading="isLoading"
  >
    <div v-if="item.details && item.details.lead">
      <div class="lead-details-wrapper">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <span class="detail-label">Name</span>
            <div>
              {{ item.details.lead.first_name }}
              {{ item.details.lead.last_name }}
            </div>
          </li>
          <li class="list-group-item">
            <span>Email</span>
            <div>{{ item.details.lead.email }}</div>
          </li>
          <li class="list-group-item">
            <span>Phone</span>
            <div>{{ item.details.lead.phone }}</div>
          </li>
          <li class="list-group-item">
            <span>Date/Time</span>
            <div>{{ item.details.lead.date_time }}</div>
          </li>
        </ul>
        <ul class="list-group list-group-flush">
          <Fragment v-for="(value, key) in leadDetails" :key="key">
            <li
              class="list-group-item"
              v-if="
                key !== 'down_payment' ||
                  leadDetails.loan_purpose === 'purchase'
              "
            >
              <span v-if="value">{{ formatKey(key, value) }}</span>
              <div>{{ formatValue(key, value) }}</div>
            </li>
          </Fragment>
        </ul>
      </div>
      <div v-if="item.details && item.details.lead.details.quote_id">
        <LoaderPlaceholder v-if="!quoteData" type="circle" />
        <div class="row" v-else>
          <div class="col-md-4" v-for="(card, index) in cards" :key="index">
            <div class="panel panel-default">
              <!-- Default panel contents -->
              <div class="panel-heading">
                {{ card.amortizationTerm }} yr {{ card.amortizationType }}
              </div>
              <div class="panel-body">
                <h2>{{ card.rate }}%</h2>
              </div>

              <!-- List group -->
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="label">Price</div>
                  <span>{{ card.price }}</span>
                </li>
                <li class="list-group-item">
                  <div class="label">Loan Amount</div>
                  <div>
                    <span>$</span
                    >{{
                      Number(
                        quoteData.full_request &&
                          quoteData.full_request.loanInformation
                          ? quoteData.full_request.loanInformation
                              .baseLoanAmount
                          : quoteData.full_request.loan_amount
                      ).toLocaleString()
                    }}
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="label">{{ card.fee.title }}</div>
                  <div>
                    <span>$</span>{{ Number(card.fee.amount).toLocaleString() }}
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="label">P/I</div>
                  <div>
                    <span>$</span
                    >{{ Number(card.principalAndInterest).toLocaleString() }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import BaseModal from "../BaseModal"

const { mapState, mapActions } = createNamespacedHelpers("activity")
export default {
  name: "CompanyActivityDetailsModal",
  components: {
    BaseModal
  },
  props: {
    item: { type: Object, required: true },
    showModal: { type: Boolean, required: true }
  },
  data() {
    return {
      isLoading: false,
      quoteData: null,
      cards: null
    }
  },
  updated() {},
  watch: {
    "item.details.lead.details.quote_id"() {
      let vm = this
      vm.quoteLoading = true
      let data = {}
      async function run() {
        //console.log("sync call...");
        if (vm.item.details.lead.details.quote_id) {
          vm.quoteData = await BB.rateflow.log(
            vm.item.details.lead.details.quote_id
          )
          vm.cards = vm.quoteData.cards.filter((item, i) => {
            return i < 4
          })
        }
        //console.log(data);
      }
      run()
      /*
            BB.rateflow.log(this.item.details.lead.details.quote_id).then(data=>{
                vm.quoteData=data;
            })
*/
    }
  },
  mounted() {
    //this.showItemModal=this.showModal;
  },
  computed: Object.assign({}, mapState(["loid", "showDetailsModal"]), {
    modalTitle() {
      return `Activity Details`
    },
    showItemModal: {
      set(val) {
        this.toggleDetailsModal(val)
      },
      get() {
        return this.showModal
      }
    },
    leadDetails() {
      let result = {}
      let keys = Object.keys(this.item.details.lead.details)
      for (var i = 0; i < keys.length; i++) {
        if (this.item.details.lead.details[keys[i]])
          result[keys[i]] = this.item.details.lead.details[keys[i]]
      }
      return result
    }
  }),
  methods: Object.assign({}, mapActions(["toggleDetailsModal", "saveBrand"]), {
    hideModal() {
      this.toggleDetailsModal(false)
      //this.quoteData=null;
    },
    formatValue(key, value) {
      if (key == "location" || value.state) {
        return this.ucwords(value.city) + ", " + this.ucwords(value.state)
      }
      if (key == "parent_url") {
        return value
      }
      if (key.includes("utm_")) {
        return value
      }
      return this.ucwords(value.toString().replace(/_/g, " "))
    },
    formatKey(key, value) {
      // check if key contains utm_
      if (key.includes("utm_")) {
        return key
      }
      return this.ucwords(key.toString().replace(/_/g, " "))
    },
    ucwords(str) {
      return (str + "").replace(/^([a-z])|\s+([a-z])/g, function($1) {
        return $1.toUpperCase()
      })
    }
  })
}
</script>

<style lang="scss">
.lead-details-wrapper {
  margin-top: 20px;
}
.lead-details-wrapper .list-group-item span {
  font-weight: 500;
  width: 150px;
  display: inline-block;
}
.label {
  color: #585858;
  padding-left: 0px;
}
.fa-icon {
  color: #aaa;
}
.panel-heading {
  font-weight: 600;
  color: #777;
}
.list-group-item {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 8px;
}
</style>
