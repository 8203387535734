var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "draggable",
        {
          class: _vm.className,
          attrs: {
            options: {
              chosenClass: "sortable-chosen",
              filter: ".program-set-placeholder",
              handle: ".drag-handler"
            },
            move: _vm.onMove
          },
          on: { end: _vm.onEnd, start: _vm.onStart },
          model: {
            value: _vm.localItems,
            callback: function($$v) {
              _vm.localItems = $$v
            },
            expression: "localItems"
          }
        },
        [
          _c(
            "transition-group",
            { staticClass: "d-flex draggable-wrapper", attrs: { tag: "div" } },
            [
              _vm.hasAddItem
                ? _c(
                    "GridColumn",
                    {
                      key: "placeholder",
                      staticClass: "program-set-placeholder d-none d-lg-block",
                      attrs: { size: _vm.colSizes }
                    },
                    [
                      _c("SmartListAddItem", {
                        attrs: {
                          label: _vm.addItemLabel,
                          addItemAction: _vm.addItemAction,
                          listType: _vm.listType
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.noItems
                ? _c(
                    "GridColumn",
                    {
                      key: "msg",
                      staticClass: "program-set-placeholder d-none d-lg-block",
                      attrs: { size: _vm.colSizes }
                    },
                    [
                      _c("h3", { key: "msg", staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.noResultMsg))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.localItems, function(item, index) {
                return _c(
                  "GridColumn",
                  {
                    key: _vm.listType + "-" + index,
                    class: _vm.colClassName(index, _vm.items, _vm.listType),
                    attrs: {
                      size: _vm.colSizes,
                      item: item,
                      "data-set-id":
                        item.id ||
                        item.nid ||
                        item.event_id ||
                        item.rateflow_log_id
                    }
                  },
                  [
                    _vm.listType == "program"
                      ? _c("ProgramsDetails", {
                          attrs: {
                            item: item,
                            itemIndex: index,
                            onDragEnd: _vm.onDragEnd,
                            isLast: _vm.isLast(index)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.listType == "activity"
                      ? _c("CompanyActivityDetails", {
                          attrs: {
                            onItemClick: _vm.onItemClick,
                            item: item,
                            itemIndex: index
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.listType == "organization"
                      ? _c("CompanyDetails", {
                          attrs: {
                            item: item,
                            itemIndex: index,
                            onDragEnd: _vm.onDragEnd,
                            isLast: _vm.isLast(index)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.listType == "rates"
                      ? _c("RatesDetails", {
                          attrs: {
                            item: item,
                            itemIndex: index,
                            onDragEnd: _vm.onDragEnd,
                            isLast: _vm.isLast(index)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.listType == "static_flyer"
                      ? _c("StaticFlyerDetails", {
                          attrs: {
                            item: item,
                            itemIndex: index,
                            onDragEnd: _vm.onDragEnd,
                            isLast: _vm.isLast(index)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.listType == "manual_flyer"
                      ? _c("ManualListingDetails", {
                          attrs: {
                            item: item,
                            itemIndex: index,
                            onDragEnd: _vm.onDragEnd,
                            isLast: _vm.isLast(index)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.listType == "member"
                      ? _c("PeopleDetails", {
                          attrs: {
                            item: item,
                            itemIndex: index,
                            onDragEnd: _vm.onDragEnd,
                            toggleMemberPricing: _vm.toggleMemberPricing,
                            isLast: _vm.isLast(index)
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPagination
        ? _c("BasePagination", {
            attrs: {
              total: _vm.qtyItemsDB,
              maxItemsPerPage: _vm.max_items,
              toOffset: _vm.max_items,
              vuexNamespace: _vm.vuexNamespace
            },
            on: { onPagination: _vm.reloadItems }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bb1ff13c", { render: render, staticRenderFns: staticRenderFns })
  }
}