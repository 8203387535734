var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "badges",
      on: { mouseenter: _vm.handleMouseEnter, mouseleave: _vm.handleMouseLeave }
    },
    [
      _c(
        "div",
        {
          class:
            "\n      badges__inner\n       " +
            (_vm.limit > 0 && _vm.items.length > _vm.limit && _vm.showAll
              ? "is-expanded"
              : "") +
            "\n       " +
            (_vm.toggleable ? "is-toggleable" : "")
        },
        [
          _vm._l(_vm.items, function(item, index) {
            return _vm.showAll || index < _vm.limit
              ? _c("Badge", {
                  key: index,
                  attrs: {
                    label: item.text,
                    isTag: _vm.isTag,
                    showIcon: _vm.showIcon,
                    hasClose: _vm.hasClose,
                    pill: true,
                    mr: 4,
                    mb: 4
                  },
                  on: {
                    removeBadge: function($event) {
                      _vm.deleteBadgeById(item.id)
                    }
                  }
                })
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.limit > 0 && _vm.items.length > _vm.limit && !_vm.showAll
            ? _c("span", [
                _vm._v(_vm._s(_vm.items.length - _vm.limit) + " tags +")
              ])
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-49a25cd0", { render: render, staticRenderFns: staticRenderFns })
  }
}