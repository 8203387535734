<doc>
  Thumbnail Vue.js component
</doc>

<template>
  <div v-if="isPair" class="pair-wrapper">
    <div :class="`${className} thumb-pair-wrapper`">
      <img :src="src[0]" :alt="alt[0]" />
    </div>
    <div :class="`${className} thumb-pair-wrapper`">
      <img :src="src[1]" :alt="alt[1]" />
    </div>
  </div>
  <img v-else :src="src" :alt="alt" :class="className" :style="styles" />
</template>

<script>
import "vue-awesome/icons"
export default {
  name: "Thumb",
  props: {
    src: {
      type: [String, Array],
      required: true,
      default: "/sites/all/modules/listing_flyer/images/placeholder.png"
    },
    size: { type: [String, Number], default: 0 },
    alt: { type: [String, Array], default: "" },
    fullRounded: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false },
    isResponsive: { type: Boolean, default: false },
    type: { type: String, default: "thumb" }
  },
  computed: {
    isPair: function() {
      return typeof this.src !== "string"
    },
    className: function() {
      let className
      if (this.type === "preview") {
        className = `img-preview${
          this.isResponsive ? " img-preview--responsive" : ""
        }`
      } else {
        className = "img-thumbnail"
        if (this.fullRounded) {
          className += " full-rounded"
        }
        if (this.noBorder) {
          className += " no-border"
        }
      }
      return className
    },
    styles: function() {
      const styles = {}
      const size = this.size | 0
      if (size > 0) {
        styles.width = `${size}px`
        styles.height = `${size}px`
      }
      return styles
    }
  }
}
</script>

<style lang="scss" src="./Thumb.scss" scoped></style>
