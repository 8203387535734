<doc>
	Rate details Vue.js component
</doc>

<template>
	<div :class="className" v-if="pricing[0]">
		<div class="rate-details">
			<div class="rate-details-title">
				{{item.label}}<span class="chart-btn-wrapper" v-on:click="showChartModal(item)"><icon class="chart-btn" name="chart-line"></icon></span>
        <span class="profile-pref-label" v-if="item.profile_preference">{{formatProfilePreference(item.profile_preference)}}</span>
			</div>
			<div class="rate-details-info">
				<div class="rates">
					<div
						class="rate-row"
						v-for="(pricingItem, index) in pricing"
						v-if="index < 3 && pricingItem.rate"
						v-bind:class="{ selected : isSelected(pricingItem) }"
						v-on:click="showModal({item,pricingItem})">
						<div class="dt col-rates">Rate: <strong>{{pricingItem.rate}}%</strong></div>
	  					<div class="dd col-rates">{{pricingItem.price}}</div>
					</div>

				</div>
				<div class="meta">
					<div class="row-mate d-flex">
						<div class="col-meta-label">Investor:</div>
		  			<div class="col-meta">{{item.log.formatted_pricing[0].investor}}</div>
		  		</div>
		  		<div class="row-mate d-flex">
						<div class="col-meta-label">Downpayment:</div>
		  			<div class="col-meta">{{dpp}}%(${{dp}})</div>
		  		</div>
				</div>
			</div>
		</div>
		<div class="rate-number">
			<strong>{{rateNumber}}</strong>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import SmartListItemOptions from "../SmartList/SmartListItemOptions"
import _ from "lodash"
import "vue-awesome/icons/chart-line"


export default {
	name: "RatesDetails",
	components: {
		SmartListItemOptions: SmartListItemOptions
	},
	props: {
      /*
          item schema: {
            "umip": 0.0215,
            "apr": "6.176",
            "rate": "5.750",
            "price": "98.875",
            "pts": 1.125,
            "fee": {
                "title": "Discount Points",
                "amount": 2563
            },
            "dpp": 0.1,
            "term": 180,
            "list_price": 250000,
            "loan_amount": 225000,
            "mi": 0,
            "label": "VA 10% DOWN 15 YEAR",
            "closing_cost": 2563,
            "zipcode": "84106",
            "city": "Salt Lake City",
            "county": "Salt Lake",
            "state": "UT",
            "profile_preference": "fifteen_year_va_ldp",
            "national_average": 0,
            "tag": "15p_va_mdp_hcs",
            "pricing_tpl_id": 366551,
            "rateflow_log_id": 91663,
            "pi_monthly": 1908.5937837538038
        },
     */
		item: { type: Object, required: true },
		itemIndex: { type: Number, required: true },
		isLast: { type: Boolean, default: false },
		onDragEnd: { type: Function }
	},
	computed: Object.assign({}, mapState(["gridView"]), {
		rateNumber() {
			let num = this.itemIndex + 1 + ""
			return num.padStart(2, "0")
		},
		className: function() {
			let className = "rate"
			if (!this.gridView) {
				className += " list-view d-flex"
			}
			if (this.$mq == "sm" || this.$mq == "md") {
				className += " not-desktop"
			}
			return className
		},
		pricing:function(){
		    var _pricing=_.slice(this.item.log.formatted_pricing,0,3);
            var reorderedPricing=_.orderBy(_pricing,['rate']);
			return reorderedPricing;
		},
		dp: function(){
			const dp = this.item.dpp * this.item.list_price
			return Math.round(dp).toLocaleString()
		},
		dpp: function(){
            const dpp = this.item.dpp * 100
            if(dpp%1!==0){
                return dpp.toFixed(2);
			}
			return dpp;
        },
	}),
	methods: Object.assign({}, mapActions(["toggleRateModal","toggleChartModal", "showEditItemModal", 'setCurrentRate', 'setChart']), {
		showModal(input) {
      const rateInfo=_.find(input.item.log.formatted_pricing, function(part) { return part.hash==input.pricingItem.hash; });
			this.setCurrentRate({"fullcard":input.item,"rateInfo":rateInfo})
			this.toggleRateModal(true)
		},
    showChartModal(input) {
        this.setChart(input.pricing_tpl_id)
        this.toggleChartModal(true)
    },
    formatProfilePreference(profile_preference){
      return profile_preference.replace(/_/g, ' ').toUpperCase();
    },
    isSelected:function(pricingItem){
			if(this.item.log.formatted_pricing[0].hash==pricingItem.hash){
				return true;
			}
			return false;
    }
  })
}
</script>

<style lang="scss" scoped>
.rate {
	border-radius: 4px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
	margin: 15px 0;
  position: relative;

  &-row{
  	cursor: pointer
  }

	@include media-breakpoint-down(sm) {
		.rate-details{
			width: 100%
		}
	}
	@include media-breakpoint-up(sm) {
		&.list-view {
			margin: 0 0 10px;
			padding: 0;
			background: #fff;

			.rate-details {
				padding: 0;
				display: flex;
				align-items: center;
				width: 100%;

				&-title {
					margin-bottom: 0;
					padding: 32px 15px 0;
					min-width: 225px;
					height: 100%;
				}

				&-info {
					display: flex;
					width: 100%;
					.meta{
						display: flex;
						width: 100%;
	    			justify-content: space-between;

						.row-mate {
							border-top: 0;
							border-left: 1px solid #CBD6E3;
	     				flex-direction: column;
	     				justify-content: space-evenly;
	     				width: 50%;
	     				padding: 0 15px;
	     				margin-top: 12px;

	     				.col-meta {
	     					text-align: left
	     				}
						}
					}
				}
			}

			.rates {
				padding: 14px 14px 2px;
				max-width: 400px;
			}
		}
	}

	&-details {
		background: #fff;
		padding: 20px 16px 22px;

		&-title {
			color: #44B666;
			font-size: 16px;
			font-weight: bold;
			text-align: center;
			background-color: #F0F8FB;
			padding: 25px 20px 20px
		}
		.col-rates {
	    width: 49%;
    	display: inline-block;
		}
		&-meta {
			color: #abc0d3;
			font-size: 11px;
			a {
				color: #abc0d3;
				font-size: 12px;
			}
		}
	}

	&-number {
		color: #fff;
		font-weight: 400;
		left: -1px;
		top: -1px;
		width: 35px;
		height: 35px;
		position: absolute;
		line-height: 35px;
		strong {
			position: relative;
			z-index: 5;
			display: block;
			font-size: 16px;
	    margin-top: 1px;
    	margin-left: 10px;
		}
		&:before {
			position: absolute;
	    top: -5px;
    	left: -26px;
			content: "";
			width: 0;
			height: 0;
			border-left: 42px solid transparent;
			border-right: 42px solid transparent;
			border-bottom: 42px solid #35a5ba;
			transform: rotate(-45deg);
			z-index: 1;
		}
	}
}
.rates {
	font-size: 12px;
	padding: 20px 0 14px;
	text-transform: uppercase;
	.dt {
		color: #7B8BA5;
		margin-bottom: 10px;
	}

	.dd {
		color: #354052;
		font-weight: 400;
		margin-bottom: 10px
	}
	.selected{
		.dd, .dt{
			color: #44B666;
		}
	}
}
.meta {
	color: #7B8BA5;
	text-transform: uppercase;
	font-size: 12px;
	overflow: hidden;


	.row-mate {
    border-top: 1px solid #CBD6E3;
    padding-top: 12px;
    margin-bottom: 12px;
    justify-content: space-between;
	}
	.col-meta {
  	text-align: right;
	}

	.dt {
		padding-left: 0
	}
	.dd {
		font-weight: 400;
		padding-right: 0
	}
}
.rate-row{
	cursor:pointer;
}
.profile-pref-label{
  bottom: 7px;
  right: 7px;
  border: none;
  border-radius: 5px;
  height: auto;
  width: auto;
  font-size: 10px;
  padding: 2px 7px;
  background-color: #7ccc94;
  color: #fff;
  position: absolute;
  font-weight: 500;
}

</style>
