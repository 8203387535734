<doc>
  Dropdown Vue.js component

   - Options example:
     const options = [
          {
            icon: 'fas fa-sort-numeric-up',
            label: "option 1",
            action: () => {
              console.log("Option 1 selected")
            }
          },
          {
            icon: 'fas fa-sort-numeric-down',
            label: "option 2",
            action: () => {
              console.log("Option 2 selected")
            }
          }
        ]
</doc>

<template>
  <div :class="dd_class">
    <BaseButton
      :size="size"
      class="dropdown-toggle custom-margin-top"
      :aria-haspopup="haspopup"
      :aria-expanded="expanded"
      v-on:click="toggle"
      theme="link"
    >
      <i class="fas fa-filter"></i>
    </BaseButton>
    <div :class="ddm_class" aria-labelledby="dropdownMenuButton">
      <a
        v-for="(option, index) in options"
        :key="index"
        class="dropdown-item"
        :class="{ 'dopdown-item--active': index === currentIndex }"
        :style="index === currentIndex ? styles : null"
        v-on:click="triggerOption(option, index)"
      >
        <i :class="option.icon"></i> {{ option.label }}
      </a>
    </div>
    <div v-show="showDropdown" class="dropdown-overlay" v-on:click="toggle" />
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    size: { type: String, default: "" },
    options: { type: Array, required: true }
  },
  data() {
    return {
      haspopup: false,
      expanded: false,
      showDropdown: false,
      currentIndex: 4,
      styles: {
        color: "#fff",
        backgroundColor: "#5dbec7"
      }
    }
  },
  computed: {
    dd_class: function() {
      let className = "dropdown"
      if (this.showDropdown) {
        className += " show"
      }
      return className
    },
    ddm_class: function() {
      let className = "dropdown-menu"
      if (this.showDropdown) {
        className += " show"
      }
      return className
    }
  },
  methods: {
    toggle() {
      this.showDropdown = !this.showDropdown
    },
    triggerOption(option, index) {
      this.currentIndex = index
      option.action(option)
      this.toggle()
    }
  }
}
</script>

<style lang="scss" src="./Dropdown.scss" scoped></style>
