const Toastr = require("toastr")
const Mustache = require("mustache")
import Vue from "vue"
import Autocomplete from 'v-autocomplete'

$(document).ready(function() {
    if ($("#get-status-form").length) {
        const trackerStatusTpl = $("#trackerStatusTpl").html()
        const listingNodeInfoTpl = $("#listingNodeInfoTpl").html()

        var getStatusForm = new Vue({
            el: "#get-status-form",
            data: {
                form: {
                    mls_sc: "",
                    mls_id: "",
                    state: "",
                }
            },
            methods: {
                getRealtorStatus: function(e) {
                    e.preventDefault()
                    if (!this.realtor_username) {
                        Toastr.warning("Fill out realtor username")
                        return null
                    }
                },
                getStatus: function(e) {
                    e.preventDefault();
                    if (!this.form.mls_sc || !this.form.mls_id) {
                        Toastr.warning("All fields are required")
                        return null
                    }
                    $("#listing_import_status").modal("show")
                    $.get(
                        "/admin-inspect-listing.json?mls_sc=" +
                        this.form.mls_sc +
                        "&mls_id=" +
                        this.form.mls_id,
                        function(data) {
                            if (data.status == "error") {
                                $("#errorMsg").removeClass("hide")
                                $(".loader").addClass("hide")
                                return null
                            }
                            if (data.tracker.status != "missing") {
                                var status_msg = "success";
                                //initialize last seen to current time.
                                var last_seen=Date.now()/1000;
                                if (data.tracker.status == "closed") {
                                    var status_msg = "danger";
                                    //only use the last seen timestamp from the listing tracker if the tracker is closed
                                    last_seen=data.tracker.data.last_seen;
                                }
                                const tplData = {
                                    first_seen: formatDate(data.tracker.data.first_seen),
                                    last_seen: formatDate(data.tracker.data.last_seen),
                                    time_on_site: (last_seen - data.tracker.data.first_seen) / 3600 * 24,
                                    tracker_status: data.tracker.status,
                                    status_msg: status_msg
                                }
                                const rendered = Mustache.render(trackerStatusTpl, tplData)
                                $("#tracker-status").html(rendered)
                            } else {
                                const tplData = {
                                    first_seen: null,
                                    last_seen: null,
                                    time_on_site: null,
                                    tracker_status: 'missing',
                                    status_msg: "warning"
                                }
                                const rendered = Mustache.render(trackerStatusTpl, tplData)
                                $("#tracker-status").html(rendered)
                            }
                            if (data.listing_node) {
                                var system_listing_status = "open"
                                var date_closed = ""
                                if (
                                    typeof data.listing_node.lf_date_closed["und"] !== "undefined" &&
                                    data.listing_node.lf_date_closed.und[0].value
                                ) {
                                    system_listing_status = "closed"
                                    date_closed = formatDate(data.listing_node.lf_date_closed.und[0].value)
                                }
                                var status_msg = "success"
                                if (system_listing_status == "closed") {
                                    var status_msg = "danger"
                                }
                                const tplData2 = {
                                    listing_node: data.listing_node,
                                    created: formatDate(data.listing_node.created),
                                    status: system_listing_status,
                                    status_msg: status_msg
                                }
                                if (date_closed) {
                                    tplData2.date_closed = date_closed
                                }
                                $("#listing_import_status").data("node_id",data.listing_node.nid);
                                const rendered2 = Mustache.render(listingNodeInfoTpl, tplData2)
                                $("#listing-node-info").html(rendered2)
                            } else {
                                $("#listing-node-info").html("Listing has not been imported.")
                            }
                            $(".loader").addClass("hide")
                            $(".status-content").removeClass("hide")
                            $(".listhubSection").removeClass("hide")
                            if (data.listing_data) {
                                $("#listing_import_status").removeClass('hide');
                                var viewer = new InspectorJSON({
                                    element: "listing-inspect-json",
                                    json: data.listing_data.listhub_listing
                                })
                            } else {
                                $("#listing-inspect-json").html("<div>Listing is not in Listhub</div>")
                            }
                        }
                    )
                }
            }
        });
        $("#listing_import_status").on("hidden.bs.modal", function(e) {
            $(".loader").removeClass("hide")
            $("#errorMsg").addClass("hide")
            $(".status-content").addClass("hide")
            $(".tracker-status").html("")
            $(".listhubSection").addClass("hide")
            $("#listing-inspect-json").html("")
        })
    }

    if ($("#realtor-status-form").length) {
        Vue.use(Autocomplete);
        var getRealtorStatusForm = new Vue({
            el: "#realtor-status-form",
            data: {
                form: {
                    realtor_username: ""
                },
                item:null,
                items:null,
                template:Vue.component('greeter',
                    {
                        template: `
                            <div>
                                <span>{{ item.first_name }} {{ item.last_name }}</span>
                            </div>                        
                        `,
                        props: ['item']
                    }
                )
            },
            methods: {
                getListings: function(e) {
                    e.preventDefault()
                    if (!this.item.uid) {
                        Toastr.warning("Select a valid realtor")
                        return null
                    }
                    $.get('/api/v1/match-listings?rid='+this.item.uid).then(function(data){
                        if(data.status="success" && data.listings_updated){
                            Toastr.success(data.listings_updated+" listings were added to "+this.form.realtor_username);
                            return null;
                        }
                        Toastr.warning("No changes made");
                    });
                    console.log(this.item);
                },
                updateItems: function(text){
                    if(!text){
                        this.items=[];
                        return null;
                    }
                    var _this=this;
                    $.get('/api/v1/realtor-autocomplete?uname='+text).then(function(data){
                        _this.items=data;
                    });
                },
                getLabel: function(item){
                    if (item) {
                        if(item.first_name && item.last_name){
                            return item.first_name+" "+item.last_name;
                        }
                        else{
                            debugger;
                        }
                    }
                    return ''
                },
                change: function(text){
                    if(text.length==0){
                        this.items=null;
                    }
                    return null;
                }
            }
        });
    }
    $('.updateListing').click(function(e){
        let nid=$("#listing_import_status").data("node_id");
        $.get('/listing_flyer/update_listhub_listing/'+nid).then(function(data){
            if(data[0].status=='error'){
                Toastr.error('Listing not updated');
                return null;
            }
            Toastr.success('Listing updated');
        });
    });
})

function formatDate(timestamp) {
    var t = new Date(timestamp * 1000)
    const dateTime = t.toLocaleDateString() + " " + t.toLocaleTimeString()
    return dateTime
}
