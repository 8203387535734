 var baseRoute = "/sites/all/themes/bankingbridge_ui/";
//var baseRoute = "/sites/dev.bankingbridge.com/themes/bankingbridge-ui/";
// const gMapsKey = "AIzaSyD7iMMl6caMKICxEgDqKCr7HvHObGygYNo";
// var map, mapsapi = require( "google-maps-api" )( gMapsKey );
// var MarkerWithLabel = require('markerwithlabel')(google.maps);


var detailMap = function() {
    var iconBase = baseRoute+"images/location-icon.png";
    var map;
    var loc=new Object;
    loc.lat=parseFloat($('.bbLocationInfo').data('geo_lat'));
    loc.lng=parseFloat($('.bbLocationInfo').data('geo_long'));
    var address=$('.bbLocationInfo').data('address');

    if(!loc.lat){
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode( { 'address': address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                loc=results[0].geometry.location
       
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
                return;
            }
            var mapOptions= {
                center: loc,
                zoom: 15,
                draggable: false,
                scrollwheel: false,
                panControl: false,
            }
            map = new google.maps.Map(document.getElementById('map_listing_detail'), mapOptions);
            var marker = new google.maps.Marker({
                map: map,
                position: loc
            });
            marker.setIcon(iconBase);
        });
    }

//                google.maps.event.addListener(marker, 'click', function() {
//                    window.open("http://maps.google.com?q=<?php print urlencode($link_part) ?>&ll="+this.position.k+","+this.position.D);
//                });         
}

module.exports = detailMap;

// module.exports = myListingsMap;

// -----------------------------------------
//console.log("bb-maps <-" );

