<doc>
	Company activity detials details Vue.js component
</doc>

<template>
  <div class="activity-wrapper d-flex align-items-center">
    <div class="activity-icon-wrapper d-inline-flex justify-content-center align-items-center">
      <icon class="activity-icon" :name="activityIcon"></icon>
    </div>
    <div class="activity list-view d-flex align-items-center">
      <div class="activity-details-img">
        <Thumb
          v-if="item.type === 'pair'"
          fullRounded
          :src="imgSrc"
          alt="User Avatar"
          class="avatar"
        />
        <img v-else-if="imgSrc" :src="imgSrc" alt="activity image" />
      </div>
      <div class="activity-details">
        <div class="activity-details-title">
          {{title}}
          <span class="info-btn-wrapper" @click="onTitleClick">
            <fa-icon name="info-circle" />
          </span>
        </div>
        <div class="activity-details-meta">{{details}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Thumb from "../Thumb"
import Moment from "moment"
import "vue-awesome/icons/ellipsis-v"
import "vue-awesome/icons/user-friends"
import "vue-awesome/icons/share-alt"
import "vue-awesome/icons/pencil-alt"
import "vue-awesome/icons/star"
import "vue-awesome/icons/file-alt"
import "vue-awesome/icons/plus"
import FaIcon from "vue-awesome/components/Icon"

export default {
  name: "CompanyActivityDetails",
  components: {
    FaIcon,
    Thumb: Thumb
  },
  props: {
    item: { type: Object, required: true },
    itemIndex: { type: Number, required: true },
    onItemClick: { type: Function }
  },
  mounted() {},
  computed: {
    title: function() {
      const item = this.item
      let title
      switch (item.type) {
        case "pair":
          title = `${item.lo.name} is now paired with ${item.details.realtor.name}`
          break
        case "share":
          title = `${item.lo.name} shared ${item.details.listing.fullstreetaddress} on ${item.details.share_type} with ${item.details.realtor.name}`
          break
        case "flyer":
          title = `${item.lo.name} create a flyer for ${item.details.listing.fullstreetaddress} with ${item.details.realtor.name}`
          break
        case "lead":
          title = `${item.lo.name} got a new lead ${
            item.details.source_details
              ? "from embed: " + item.details.source_details.title
              : ""
          }`
          break
      }
      return title
    },
    details: function() {
      return Moment.unix(this.item.timestamp).calendar(null, {
        sameElse: "On MMM D, YYYY"
      })
    },
    imgSrc: function() {
      let imgSrc
      switch (this.item.type) {
        case "pair":
          imgSrc = [this.item.lo.picture, this.item.details.realtor.picture]
          break
        case "lead":
          imgSrc = this.item.lo.picture
          break
        case "flyer":
          imgSrc = this.item.details.listing.picture
            ? this.item.details.listing.picture[0]
            : null
          break
        case "share":
          imgSrc = this.item.details.listing.picture
            ? this.item.details.listing.picture[0]
            : null
          break
      }
      return imgSrc
    },
    activityIcon: function() {
      let activityIcon = ""
      switch (this.item.type) {
        case "pair":
          activityIcon = "user-friends"
          break
        case "share":
          activityIcon = "share-alt"
          break
        case "edit":
          activityIcon = "pencil-alt"
          break
        case "favorite":
          activityIcon = "star"
          break
        case "flyer":
          activityIcon = "file-alt"
          break
        case "lead":
          activityIcon = "user-plus"
          break
        default:
          activityIcon = "plus"
      }
      return activityIcon
    }
  },
  methods: {
    onTitleClick() {
      this.onItemClick(this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity {
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
  margin: 0 0 10px;
  padding: 8px 10px 8px 15px;
  height: 84px;
  background: #fff;
  width: 100%;

  .thumb-pair-wrapper {
    width: 42px;
    margin: 0 auto;
    height: 42px;
  }
  img {
    display: block;
    max-width: 42px;
    object-fit: cover;
    margin: 0 auto;
    height: 42px;
    &.avatar {
      width: 42px;
    }
  }
  &-details {
    padding: 0 10px 0 18px;
    &-img {
      background: #fff;
    }
    &-title {
      color: #595959;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 9px;
    }
    &-meta {
      color: #abc0d3;
      font-size: 11px;
      text-transform: uppercase;
      a {
        color: #abc0d3;
        font-size: 12px;
      }
    }
  }

  &-icon {
    color: #fff;
    font-size: 16px;
    position: relative;
    z-index: 3;
    top: -4px;

    &-wrapper {
      margin-right: 12px;
      position: relative;
      width: 39px;
      height: 94px;
      &::before {
        content: "";
        background: #cbd6e3;
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: calc(50% - 1px);

        .last-item & {
          height: 50%;
        }
      }
      &::after {
        content: "";
        background: #2acac5;
        height: 36px;
        width: 36px;
        border-radius: 100px;
        display: block;
        position: absolute;
        z-index: 2;
        top: calc(50% - 22px);
        left: 0;
      }
    }
  }
  .info-btn-wrapper {
    position: absolute;
    margin-left: 5px;
    .fa-icon {
      cursor: pointer;
    }
  }
}
</style>
