var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading"
            }
          ]
        },
        [
          !_vm.showRealtorForm
            ? _c(
                "div",
                [
                  _c("CustomDropdown", {
                    attrs: {
                      placeholder: "Search for a realtor...",
                      name: "realtor",
                      options: _vm.realtorList,
                      reducer: "id",
                      onInput: _vm.onInput,
                      addOption: _vm.toggleRealtorFields
                    },
                    model: {
                      value: _vm.currentVal,
                      callback: function($$v) {
                        _vm.currentVal = $$v
                      },
                      expression: "currentVal"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("\n        Or\n        "),
                    _c(
                      "span",
                      {
                        staticClass: "add-new-realtor",
                        on: {
                          click: function($event) {
                            _vm.toggleRealtorFields(true)
                          }
                        }
                      },
                      [_vm._v("\n          create a new one\n        ")]
                    )
                  ])
                ],
                1
              )
            : _c("FormRealtor", {
                attrs: {
                  editMode: _vm.isEdit,
                  onInputStates: _vm.onInputStates,
                  enableSaveNext: _vm.enableSaveNext,
                  disableSaveNext: _vm.disableSaveNext
                },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e83237a", { render: render, staticRenderFns: staticRenderFns })
  }
}