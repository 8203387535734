import { formatToPhone } from "../../helpers"

const props = {
  value: { type: [String, Number], default: null },
  label: { type: String, default: null, required: true },
  name: { type: String, default: null, required: true },
  type: { type: String, default: "text" },
  placeholder: { type: String, default: null },
  describedby: { type: String, default: null },
  helpText: { type: String, default: null },
  size: { type: String, default: null },
  hideLabel: { type: Boolean, default: false },
  isMaterial: { type: Boolean, default: true },
  inputClass: { type: String, default: "" },
  labelClassName: { type: String, default: "" },
  isPhone: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  min: { type: [Boolean, Number, String], default: false },
  max: { type: [Boolean, Number, String], default: false }
}

const methods = {
  setFocus() {
    this.$refs.input.focus()
  },
  onFocus() {
    this.$refs.input.parentElement.classList.add("focus")
    this.$emit("onFocus", { type: "onFocus" })
  },
  onBlur($event) {
    this.$refs.input.parentElement.classList.remove("focus")
    this.$emit("onBlur", { type: "onBlur", event: $event })
  },
  onInput($event) {
    let val = $event.target.value
    if (this.isPhone) {
      val = formatToPhone($event)
    }
    this.$emit("input", val, $event)
    this.valuCheck(val)
  },
  valuCheck(val) {
    const $input = this.$refs.input
    let parent = $input.parentElement
    if (val == "") {
      parent.classList.remove("typed")
    } else {
      parent.classList.add("typed")
    }
  }
}

const mounted = function() {
  this.valuCheck(this.$refs.input.value)
}
const updated = function() {
  this.valuCheck(this.$refs.input.value)
}

export { props, methods, mounted, updated }
