/**
 * Rate Table API wrapper
 */

const ENDPOINT = "/api/v1/rate-cards-config"
const FIND_ENDPOINT = "/api/v1/rate-cards-configs"

const bbRateCardsConfig = {
  get(params, callback = null) {
    return $.get(ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(config, callback) {
    if (!config.id) {
      console.log("Rate Cards Config id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(config),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  /*
        {
            "name":"Home Page Rate Cards",
            "brand_id":366184,
            "details":{
              "tabs":[
                "thirty_year",
                "fifteen_year",
                "va",
                "fha"
              ]
            },
            "is_default":0
        }
    */
  add(config, callback) {
    if (!config.brand_id || !config.name) {
      console.log("brand_id is required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(config),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  delete(id, callback) {
    return $.ajax({
      url: ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  find(params, callback = null) {
    return $.get(FIND_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbRateCardsConfig
