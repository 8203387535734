<doc>
  Loader placeholder component
</doc>

<template>
  <div>
    <div
      v-if="type == 'placeholder'"
      :style="styles"
      class="loading-placeholder loading-placeholder--animated-background"
    ></div>
    <div v-else class="d-flex align-items-center loader-wrapper">
      <div
        class="loader vertical-align-middle loader-circle"
        data-type="default"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderPlaceholder",
  props: {
    styles: {
      type: Object,
      default: () => {
        return { width: "100%", height: "20px" }
      }
    },
    type: { type: String, default: "placeholder" }
  }
}
</script>

<style lang="scss">
.loading-placeholder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  &--animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    width: 100%;
    position: relative;
  }
}
.loader-wrapper {
  height: 100vh;

  .modal-body-inner & {
    height: auto;
  }
}

.county-save-loader {
  .loader-wrapper {
    height: 100%;
  }
}
</style>
