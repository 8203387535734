/**
 * Subscription API wrapper
 */

const ENDPOINT = "/integration-manage.json"
const FIND_ENDPOINT = "/api/v1/integrations"

const bbIntegration = {
  get(id, callback = null) {
    return $.get(ENDPOINT, { id }, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  },
  update(integrationConfig, callback) {
    if (!integrationConfig.id) {
      console.log("Integration Config id missing")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    if (!integrationConfig.integration_type) {
      console.log("integration type is required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }

    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(integrationConfig),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  add(integrationConfig, callback) {
    if (!integrationConfig.integration_type) {
      console.log("integration type is required")
      return new Promise((resolve, reject) => {
        reject("error")
      })
    }
    return $.ajax({
      url: ENDPOINT,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(integrationConfig),
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  delete(id, callback) {
    return $.ajax({
      url: ENDPOINT + "?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success(data) {
        if (callback && typeof callback === "function") {
          callback.call(this, data)
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        if (callback && typeof callback === "function") {
          callback.call(this, thrownError)
        }
      }
    })
  },
  deleteDomain: function(id, callback) {
    return $.ajax({
      url: "/api/v1/app-domains?id=" + id,
      type: "DELETE",
      contentType: "application/json",
      dataType: "json",
      success: function(res) {
        if (callback && typeof callback === "function") {
          callback.call(this, res)
        }
      }
    })
  },
  find(params, callback) {
    return $.get(FIND_ENDPOINT, params, function(data) {
      if (callback && typeof callback === "function") {
        callback.call(this, data)
      }
    })
  }
}

export default bbIntegration
