var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["autoComplete", this.listClasses] }, [
    _c(
      "div",
      {
        class: [
          "autoComplete__input-container",
          _vm.inputContainerTag,
          _vm.inputContainerPaddLeft
        ]
      },
      [
        _vm.optionTag ? _c("icon", { attrs: { name: "tag" } }) : _vm._e(),
        _vm._v(" "),
        _c("InputText", {
          ref: "search",
          staticClass: "autoComplete__input",
          style: this.inputStyle,
          attrs: {
            isMaterial: this.isMaterial,
            label: this.label,
            name: this.name,
            hideLabel: this.hideLabel,
            placeholder: this.placeHolder
          },
          on: {
            keyupEnter: _vm.submit,
            onFocus: _vm.focusInput,
            onBlur: _vm.focusInput,
            keyupDelete: _vm.clear,
            keyupEsc: _vm.resetAll
          },
          model: {
            value: _vm.text,
            callback: function($$v) {
              _vm.text = $$v
            },
            expression: "text"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.showAutocomplete
      ? _c(
          "ul",
          {
            class: [
              "autoComplete__list-container",
              this.floatList,
              this.isFluidList
            ]
          },
          [
            _vm.showListOptions && _vm.listOptions.length > 0
              ? _c("li", { class: ["autoComplete__list-options-title"] }, [
                  _c("div", [_vm._v("Search Options")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.loadItems, function(item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: [
                    "autoComplete__list-item",
                    "autoComplete__list-item--" + item.icon
                  ],
                  on: {
                    click: function($event) {
                      _vm.selectedItem(item)
                    }
                  }
                },
                [
                  _c("icon", { attrs: { name: item.icon } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "autoComplete__list-text" }, [
                    _vm._v(_vm._s(item.text))
                  ])
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      ref: "inputHidden",
      staticClass: "sr-only",
      attrs: { type: "text" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ecec19d", { render: render, staticRenderFns: staticRenderFns })
  }
}