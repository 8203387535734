import Vue from "vue"
import { IntegrationSettingsModal } from "./pages"
const Toastr = require("toastr")
const Mustache = require("mustache")
const bbForms = require("./bb-validate.js")
const bbLoading = require("./bb-loading-indicator.js")
let deletingIntegration = false
let $body

// integrations API sdk
const bbIntegrations = {
    add: function() {},
    update: function(data, callback) {
        if(!data.id || !data.owner){
            return callback.call(this, {status: "error"});
        }
        $.ajax({
            url: "/integration-manage.json",
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            success: function(res) {
                if (callback && typeof callback === "function") {
                    callback.call(this, res)
                }
            }
        })
    },
    delete: function(id, callback) {
        $.ajax({
            url: "/integration-manage.json?id=" + id,
            type: "DELETE",
            contentType: "application/json",
            dataType: "json",
            success: function(res) {
                if (callback && typeof callback === "function") {
                    callback.call(this, res)
                }
            }
        })
    },
    deleteDomain: function(id, callback) {
        return $.ajax({
            url: "/api/v1/app-domains?id=" + id,
            type: "DELETE",
            contentType: "application/json",
            dataType: "json",
            success: function(res) {
                if (callback && typeof callback === "function") {
                    callback.call(this, res)
                }
            }
        })
    }
}

// api keys API sdk
const bbApiKeys = {
    add(apiKeyInfo, callback) {
        return $.ajax({
            url: "/aws_api/manage.json",
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(apiKeyInfo),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
    get: function(params, callback) {
        return $.ajax({
            url: "/aws_api/manage.json",
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(params),
            success: function(res) {
                if (callback && typeof callback === "function") {
                    callback.call(this, res)
                }
            }
        })
    },
    delete: function(id, callback) {
        return $.ajax({
            url: "/aws_api/manage.json?id=" + id,
            type: "DELETE",
            contentType: "application/json",
            dataType: "json",
            success: function(res) {
                if (callback && typeof callback === "function") {
                    callback.call(this, res)
                }
            }
        })
    }
}

var debounce = require("lodash/debounce");

const syncForm = formElement => {
    var formURL = formElement.data("url")
    var group = formElement.data("group")
    var postData = {}
    postData.id = formElement.closest(".modal").data("integration_id")
    postData.owner = formElement.closest(".modal").data("uid")
    if (formElement.data("integration_type")) {
        postData.integration_type = formElement.data("integration_type")
    }
    formElement.find("input").each(function(i) {
        if ($(this).attr("type") == "checkbox") {
            var postDataPart = {}
            //make sure all other click events are
            $(this).click(function() {
                postDataPart[$(this).attr("name")] = 0
                if ($(this).is(":checked")) {
                    postDataPart[$(this).attr("name")] = 1
                }
                if (group) {
                    postData[group] = postDataPart
                } else {
                    postData=Object.assign(postData,postDataPart)
                }
                let elem=this
                bbIntegrations.update(postData, function(data) {
                    Toastr.success("Integration Updated")
                    console.log(data)
                })
                //TODO: post new val
            })
        } else if ($(this).attr("type") == "text") {
            var postDataPart = {}
            $(this).change(
                debounce(function() {
                    const newVal = $.trim($(this).val())
                    $(this).val(newVal)
                    if($(this).attr("name")=="domain"){
                        postData[$(this).attr("name")]={
                            value: newVal
                        }
                        if($(this).data("id"))postData[$(this).attr("name")]['id']=$(this).data("id");
                    }
                    else{
                        postDataPart[$(this).attr("name")] = newVal
                    }
                    if (group) {
                        postData[group] = postDataPart
                    } else {
                        postData=Object.assign(postData,postDataPart)
                    }
                    bbLoading.show($(this).parent())
                    if(postDataPart.accent_color)$(".accent_preview").css("background-color",postDataPart.accent_color);
                    if(postDataPart.secondary_accent_color)$(".secondary_accent_preview").css("background-color",postDataPart.secondary_accent_color);
                    let elem=this
                    bbIntegrations.update(postData, function(data) {
                        bbLoading.hide()
                        if(data.domain_id){
                            $(elem).data("id",data.domain_id)
                        }
                        Toastr.success("Integration Updated")
                        console.log(data)
                    })
                }, 500)
            )
        }
    })
    formElement.find("select").each(function(i) {
        $(this).change(function(value) {
            var postDataPart = {}
            postDataPart[$(this).attr("name")] = $(this).val()
            if (group) {
                postData[group] = postDataPart
            } else {
                postData = postDataPart
            }
            bbIntegrations.update(postData, function(data) {
                console.log(data)
                Toastr.success("Integration Updated")
            })
        })
    })
}

const initEditBtn = () => {
    $body.on("click", '[data-action="edit-integration"]', function(e) {
        e.preventDefault()
        if (!$(this).data("id")) {
            return
        }
        // Vuejs setup
        $("#vueIntegrationModal").data("id", $(this).data("id"))
        vueApp.$refs.integrationSettingsModal.openModal($(this).data("id"))

    })
}



const initPreviewBtn = () => {
    $body.on("click", '[data-action="preview-integration"]', async function(e) {
        // preview integration modal tpl
        const $this = $(this)
        const previewIntegrationModalTpl = $("#preview-integration-modal-tpl").html()
        $('body').append(previewIntegrationModalTpl)
        let intResult=await BB.integration.get($(this).data('id'))
        let newPagePreviewLink = `<a style="color: white;margin-left:10px;top:3px;" href="/lo-landing-page?key=`+$(this).data('app_key')+`" target="_blank"><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>`
        let modalTitle=( intResult && intResult.integrations && intResult.integrations.title ? intResult.integrations.title : "Integration")+" (Preview)";
        // Show modal
        $("#previewFrame").attr('src','/lo-landing-page?key='+$(this).data('app_key'));
        $("#previewIntegrationModal").modal("show")
        const app_key = $this.data("app_key")
        $("#previewIntegrationModal").data("app_key", app_key)

        $("#previewIntegrationModal .modal-title").html(modalTitle+newPagePreviewLink);

        $("#previewIntegrationModal").on("hidden.bs.modal", function() {

            //On hide remove the modal
            $("#previewIntegrationModal").remove()
        });
        $('.toggleResize').click(function(e){
            e.preventDefault();
            if($("#previewIntegrationModal .modal-dialog").hasClass('modal-lg')){
                $("#previewIntegrationModal .modal-dialog").removeClass('modal-lg');
                $("#previewIntegrationModal .modal-dialog").addClass('modal-md');
                $(this).html('Desktop');
            }
            else{
                $("#previewIntegrationModal .modal-dialog").addClass('modal-lg');
                $("#previewIntegrationModal .modal-dialog").removeClass('modal-md');
                $(this).html('Mobile');
            }
        });
    })
}

const initSettingsTab = () => {
    const boomtownIntegrationTpl = $("#boomtown-integration-tpl").html()
    const widgetIntegrationTpl = $("#widget-integration-tpl").html()
    const standaloneIntegrationTpl = $("#standalone-integration-tpl").html()
    const buttonIntegrationTpl = $("#button-integration-tpl").html()
    const apiIntegrationTpl = $("#api-integration-tpl").html()
    const editIntegrationTpl = $("#edit-integration-tpl").html()
    $body.on("click", ".settingsTab", function(e) {
        e.preventDefault()
        const integration_id = $("#editIntegrationModal").data("integration_id")
        $(".active .inner-tab-content .integration-settings, .active .inner-tab-content .app-settings").empty()
        $.get("/integration-manage.json?id=" + integration_id, function(data) {
            data.integrations.app_settings.afford_calc_checked = data.integrations.app_settings.afford_calc == 1
            data.integrations.app_settings.mort_calc_checked = data.integrations.app_settings.mort_calc == 1
            data.integrations.app_settings.lead_workflow_checked = data.integrations.app_settings.lead_workflow >= 1
            data.integrations.app_settings.pricing_checked = data.integrations.app_settings.pricing == 1
            if (data.integrations.app_settings.settings) {
                var extra_settings = JSON.parse(data.integrations.app_settings.settings)
                if (extra_settings.accent_color) {
                    data.integrations.app_settings.accent_color = extra_settings.accent_color;
                }
                if (extra_settings.secondary_accent_color) {
                    data.integrations.app_settings.secondary_accent_color = extra_settings.secondary_accent_color;
                }
                if (extra_settings.header_bg_img) {
                    data.integrations.app_settings.header_bg_img = extra_settings.header_bg_img;
                }
                if (extra_settings.lead_share) {
                    data.integrations.app_settings.lead_share = extra_settings.lead_share;
                }
                if (extra_settings.ga_tag) {
                    data.integrations.app_settings.ga_tag = extra_settings.ga_tag;
                }
                if (extra_settings.ga_mode && extra_settings.ga_tag) {
                    data.integrations.app_settings.ga_external = ( extra_settings.ga_mode=="external" ? 1 : 0 );
                    data.integrations.app_settings.ga_internal = ( extra_settings.ga_mode=="internal" ? 1 : 0 );
                }
                data.integrations.app_settings.autoSlide_checked = extra_settings.autoSlide == 1
                data.integrations.app_settings.aboutBoxes_checked = extra_settings.aboutBoxes == 1
                data.integrations.app_settings.leadCapture_checked = extra_settings.leadCapture == 1
                data.integrations.app_settings.loReviews_checked = extra_settings.loReviews == 1
                data.integrations.app_settings.brandReviews_checked = extra_settings.brandReviews == 1
                data.integrations.app_settings.rateTable_checked = extra_settings.rateTable == 1
                data.integrations.app_settings.contactForm_checked = extra_settings.contactForm >= 1
            }

            let tpl_bottom
            if (data.integrations.type == "boomtown") {
                tpl_bottom = boomtownIntegrationTpl
            } else if (data.integrations.type == "widget") {
                tpl_bottom = widgetIntegrationTpl
            } else if (data.integrations.type == "standalone") {
                tpl_bottom = standaloneIntegrationTpl
            } else if (data.integrations.type == "button") {
                tpl_bottom = buttonIntegrationTpl
            } else if(data.integrations.type == "api"){
                tpl_bottom = apiIntegrationTpl
            }
            const rendered_bottom = Mustache.render(tpl_bottom, data.integrations.app_settings)
            const rendered_top = Mustache.render(editIntegrationTpl, data.integrations)
            $(".active .loader").addClass("hide")
            $("#settings .integration-settings").html(rendered_top)
            $("#settings .app-settings").html(rendered_bottom)
            if($("#inputOwner").length && data.integrations.owner){
                $("#inputOwner").val(data.integrations.owner)
            }
            if(extra_settings){
                if(extra_settings.accent_color)$(".accent_preview").css("background-color",extra_settings.accent_color);
                if(extra_settings.secondary_accent_color)$(".secondary_accent_preview").css("background-color",extra_settings.secondary_accent_color);
            }
            $("#editIntegrationModal .select-pricing-set").val(data.integrations.app_settings.pricing_set_id)
            $("#editIntegrationModal .select-lead-share").val(data.integrations.app_settings.lead_share)
            syncForm($("#editIntegrationModal ." + data.integrations.type + "-edit"))
            syncForm($("#editIntegrationModal .edit-integration-basic"))
            $("#editIntegrationModal .enable-pricing").click(function(e) {
                $("#editIntegrationModal .select-pricing-wrapper").toggleClass("hide", !$(this).is(":checked"))
            })
            $("#editIntegrationModal #lead_workflow").click(function(e) {
                $("#editIntegrationModal .selectConfig").toggleClass("hide", !$(this).is(":checked"))
                //$("#editIntegrationModal .selectConfig").trigger('change');
            })
            if(data.integrations.app_settings.lead_workflow_checked) {
                $("#editIntegrationModal .selectConfig").val(data.integrations.app_settings.lead_workflow);
            }
            $("#editIntegrationModal #contactForm").click(function(e) {
                $("#editIntegrationModal .selectForm").toggleClass("hide", !$(this).is(":checked"))
                //$("#editIntegrationModal .selectConfig").trigger('change');
            })
            if(data.integrations.app_settings.contactForm_checked) {
                $("#editIntegrationModal .selectForm").val(extra_settings.contactForm);
            }
        })
    })
}

var statusTimer=null;

const initStatusTab = () => {
    const integrationStatusTpl = $("#integration-status-tpl").html()

    $body.on("click", ".statusTab", function(e) {
        e.preventDefault()
        $("#status .inner-tab-content").data("last_request",null);
        $("#status .inner-tab-content").data("requests",null);
        const integration_id = $("#editIntegrationModal").data("integration_id")
        $("#status .inner-tab-content").empty();
        let paramStr=""
        let getRequests=(paramStr)=>{
            $.get("/api/v1/embed-requests?integration_id=" + integration_id + "&limit=25"+paramStr, function(data) {
                $(".active .loader").addClass("hide")
                if(data.length>0) {
                    let existing_requests=$("#status .inner-tab-content").data("requests")
                    if(existing_requests && existing_requests.length>0)data=data.concat(existing_requests)
                    $("#status .inner-tab-content").data("requests",data)
                    let requests = [];
                    $("#status .inner-tab-content").data("last_request", data[0].timestamp)
                    data.forEach(request => {
                        requests.push(Object.assign(request, {
                            dateTime: new Date(request.timestamp * 1000).toLocaleString(),
                            validated: (request.validated == "1" ? true : false)
                        }))
                    });
                    const rendered_tab_content = Mustache.render(integrationStatusTpl, {requests})
                    $(".active .loader").addClass("hide")
                    $("#status .inner-tab-content").html(rendered_tab_content)
                }
            })
        }
        getRequests()
        statusTimer = setInterval(function(){
            let paramStr=""
            if($("#status .inner-tab-content").data("last_request")){
                paramStr="&min_timestamp="+$("#status .inner-tab-content").data("last_request")
            }
            getRequests(paramStr)
        }, 2000);
    })
}

const initMonitorTab = () => {
    const integrationMonitorTpl = $("#integration-monitor-tpl").html()

    $body.on("click", ".monitorTab", async function(e) {
        e.preventDefault()
        $("#monitor .inner-tab-content").empty()
        let intResult=await BB.integration.get($("#editIntegrationModal").data("integration_id"))
        let result=await BB.embedMonitor.get({app_key:intResult.integrations.app_key})
        let entries=result.entries ? result.entries.map(entry=>{
            entry.datetime=new Date(entry.timestamp * 1000).toLocaleString()
            return entry
        }) : null
        $body.on("click", "#editIntegrationModal .thumbnail img", function(evt) {
            if($(this).hasClass("enlarged")){
                $(this).removeClass("enlarged")
            }
            else{
                $(this).addClass("enlarged")
            }
        })
        $("#monitor .inner-tab-content").html(Mustache.render(integrationMonitorTpl, {app_key: intResult.integrations.app_key, monitor: result.monitor, entries}))
        $(".active .loader").addClass("hide")
    })
}

const initMonitorCreateBtn = () => {
    $body.on("click", "button.createMonitor", async function(e) {
        e.preventDefault()
        let values={}
        $(this).form().serializeArray().forEach(field=>{
            values[field.name]=field.value;
        })
        //console.log(values);

        if(!values.url){
            BB.Toastr.error("URL is required")
            return
        }
        $("#monitor .monitorContent").empty()
        $(".active .loader").removeClass("hide")
        let emResult=await BB.embedMonitor.add({
            app_key: $(this).data("app_key"),
            config: values,
            check_in: true
        });
        //console.log(emResult);
        //$("#monitor .inner-tab-content").html(Mustache.render(integrationMonitorTpl, {app_key: intResult.integrations.app_key, monitor: result.monitor, entries}))
        $(".active .loader").addClass("hide")
    })
}

const initDeleteBtn = () => {
    $body.on("click", "#editIntegrationModal .modal-delete-btn", function(e) {
        deletingIntegration = true
        e.preventDefault()
        const id = $(this)
            .closest(".modal")
            .data("integration_id")
        bbIntegrations.delete(id, function(data) {
            deletingIntegration = false
            console.log(data)
            Toastr.success("Integration removed")
            $('[data-integration-id="' + id + '"]').slideUp()
        })
    })
}

const initGaSwitch = () => {
    $body.on("click", ".ga-switch button", function(e) {
        $(".ga-switch button").each(function(index,elem){
            $(elem).removeClass("active")
        })
        if(!$("#inputGATag").val())return null;
        $(this).addClass("active");
        let ga_mode="internal";
        if($(this).hasClass("external"))ga_mode="external";
        let id = $(this).closest(".modal").data("integration_id")
        let group = $(this).closest("form").data("group")
        let owner = $(this).closest(".modal").data("uid")

        let postData={
            id,
            owner,
            integration_type: group
        };
        postData[group]={
            ga_mode
        }
        bbIntegrations.update(postData, function () {
            Toastr.success("Integration updated")
        });
    })
}

const initAddUrlBtn = () => {
    $body.on("click", "#editIntegrationModal .add-url", function(e) {
        e.preventDefault()
        $('.url-list-wrapper').append(`<div class="input-wrapper"><input type="text" class="form-control" id="inputUrl" name="domain" aria-describedby="url" value=""><i class="fa fa-minus-circle" aria-hidden="true"></i></div>`)
        syncForm($('.url-list-wrapper'))
    })
    $body.on("click", "#editIntegrationModal .fa-minus-circle", function(e) {
        e.preventDefault()
        let id=$($(this).siblings()[0]).data("id");
        $(this).parent().remove();
        if(id) {
            bbIntegrations.deleteDomain(id, function () {
                Toastr.success("Domain removed")
            });
        }
    })


}

let vueApp=null;

const initVueApp = () => {
    vueApp = new Vue({
        el: "#integration-list-vue-app",
        components: {
            IntegrationSettingsModal,
        },
        methods:{}
    })
}

const initQuickStatus = async () => {
    for(let i=0; i<$(".network-table__row").length; i++){
        let elem=$(".network-table__row")[i];
        $.get("/api/v1/embed-requests?integration_id=" + $(elem).data("integrationId")+"&quick_status=true",function(data){
            $(elem).addClass(data.status)
        })
        $.get("/api/v1/embed-stats?id=" + $(elem).data("integrationId")+"&type=pageviews",function(data){
            $(elem).find(".badge").html(data.pageviews.toLocaleString());
        })
    }

}

$(document).ready(function() {
    if ($(".page-integrations").length || $(".page-brand-integrations").length) {
        $body = $("body")
        const integrationTpl = $("#integration-tpl").html()

        $('.standalone-menu li').click(function(e){
            e.preventDefault();
            if(!$(this).data("is_standalone")){
                //clicked full
                //hide checkmark on other button
                $('[data-is_standalone="true"] span').addClass('hide');
                //show checkmark on this button
                $(this).find('span').removeClass('hide');
                $("#newIntegrationModal").data("is_standalone", false);
            }
            else{
                //clicked stand alone
                //hide checkmark on other button
                $('[data-is_standalone="false"] span').addClass('hide');
                //show checkmark on this button
                $(this).find('span').removeClass('hide');
                $("#newIntegrationModal").data("is_standalone", true);
            }
        });

        $('[data-action="next"]').click(function(e) {
            e.preventDefault()
            const step = $("#newIntegrationModal").data("step")
            /*if (step == 2 && $(this).data("integration_type") == "button") {
                saveIntegration(integrationTpl)
            } else {*/
            const formElement = $(".step-" + step + " form")
            if (formElement.length) {
                if (bbForms.validate(formElement)) {
                    return null;
                }

                const currentFormData = BB.getFormObject(formElement.serializeArray())
                let newData = {}
                newData[formElement.data("form_type")] = currentFormData
                const mData = Object.assign({}, $("#newIntegrationModal").data("mdata"), newData)
                $("#newIntegrationModal").data("mdata", mData)
            }
            $(".step-" + step).addClass("hide")
            if ($(this).data("integration_type")) {
                $("#newIntegrationModal").data("integration_type", $(this).data("integration_type"))
                $("." + $(this).data("integration_type")).removeClass("hide")
                $("." + $(this).data("integration_type")).addClass("active")
            }
            $(".step-" + (step + 1)).removeClass("hide")
            $("#newIntegrationModal").data("step", step + 1)
            //}
        });
        $('[data-action="prev"]').click(function(e) {
            e.preventDefault()
            const step = $("#newIntegrationModal").data("step")
            $(".step-" + step).addClass("hide")
            $(".step-" + (step - 1)).removeClass("hide")
            $("#newIntegrationModal").data("step", step - 1)
        });
        $('[data-action="save"]').click(function(e) {
            e.preventDefault()
            saveIntegration(integrationTpl)
        });

        $("#newIntegrationModal").on("shown.bs.modal", function() {
            $(".modal-step").addClass("hide")
            $(".step-1").removeClass("hide")
            $("#newIntegrationModal").data('is_standalone',false);
        })
        $("#newIntegrationModal").on("hidden.bs.modal", function() {
            $("#newIntegrationModal form").each(function() {
                $(this)[0].reset()
            })
            $("#newIntegrationModal").removeData()
            $("#newIntegrationModal").data('is_standalone',false);
            $('[data-is_standalone="false"] span').trigger('click');
            $("#newIntegrationModal").data("step", 1)
            $(".modal-step").addClass("hide")
            $(".btn-set").addClass("hide")
            bbForms.clearValidation()
        })

        // Init edit button
        initEditBtn()
        // Init edit button
        initPreviewBtn()
        // Init settings tab button
        initSettingsTab()
        // Init status tab button
        initStatusTab()
        // Init monitor tab button
        initMonitorTab()
        // Init create embed monitor button
        initMonitorCreateBtn()
        initVueApp()
        // get/set integration quick status
        initQuickStatus()
        // Init Add url button
        initAddUrlBtn()
        // init GA switch
        initGaSwitch()
        // Init delete modal button
        initDeleteBtn()
    }
    $("#apiKeyModal").on("shown.bs.modal", function() {
        $("#apiKeyModal .loader-overlay").removeClass('hide');
        bbApiKeys.get()
        .then(data=>{
            $("#apiKeyModal .loader-overlay").addClass('hide');
            if(!data[0]){
                $("#apiKeyModal .stage-0").removeClass('hide');
            }
            else{
                $("button.delete-key").removeClass('hide');
                let securePass="";
                for (let i = 0; i < data[0].api_key.length; i++) {
                    securePass+="•"
                }
                $('textarea.api-key').val(securePass);
                $('textarea.api-key-hidden').val(data[0].api_key);
                $('button.confirm-delete-key').data({id:data[0].id})
                $("#apiKeyModal .stage-1").removeClass('hide');
            }
        })
    })
    $("#apiKeyModal").on("hidden.bs.modal", function() {
        $("#apiKeyModal .loader-overlay").addClass('hide');
        $("#apiKeyModal .stage-0").addClass('hide');
        $("#apiKeyModal .stage-1").addClass('hide');
    })
    $(".delete-key").click(function(e){
        $("#apiKeyModal .stage-1").addClass('hide')
        $("#apiKeyModal .stage-0").addClass('hide')
        $("#apiKeyModal .stage-delete").removeClass('hide')
    });
    $(".confirm-delete-key").click(function(e){
        if($(".confirm-delete-key-input").val()=="DELETE"){
            bbApiKeys.delete($(this).data("id")).then(
                data=>{
                    if(data.status=="success"){
                        $("#apiKeyModal .stage-1").addClass('hide')
                        $("#apiKeyModal .stage-0").removeClass('hide')
                        $("#apiKeyModal .stage-delete").addClass('hide')
                        $(".genApiKey").removeClass("hide")
                        $(".delete-key").addClass("hide")
                    }
                    else{
                        BB.Toastr.error("API key not deleted")
                    }
                }
            );
        }
    })
    $(".genApiKey").click(function(e) {
        e.preventDefault();
        $(this).addClass('hide');
        $("#apiKeyModal .loader-overlay").removeClass('hide');
        bbApiKeys.add({
            loid:$('body').data("user_id")
        }).then(
            data=>{
                $("#apiKeyModal .loader-overlay").addClass('hide');
                if(data.status=="success"){
                    $('textarea.api-key').val(data.result.api_key);
                    $("#apiKeyModal .stage-1").removeClass('hide');
                }
                else{
                    Toastr.error("API key not generated")
                    $(".genApiKey").removeClass('hide');
                }
            }
        );
    })

})

const saveIntegration = integrationTpl => {
    $("#newIntegrationModal").data("complete", 1)
    const step = $("#newIntegrationModal").data("step")
    const formElement = $(".step-" + step + " .active form")
    if (formElement.length) {
        let currentFormData = BB.getFormObject(formElement.serializeArray())
        let newData = {}
        newData[formElement.data("form_type")] = currentFormData
        newData['owner']=$('[data-action="save"]').data("loid");
        const mData = Object.assign({}, $("#newIntegrationModal").data("mdata"), newData)
        mData.owner=$('[data-action="save"]').data("loid");
        $("#newIntegrationModal").data("mdata", mData)
    }
    var integration_type=$("#newIntegrationModal").data("integration_type");
    if($("#newIntegrationModal").data("is_standalone")){
        integration_type="standalone";
    }
    let createData = Object.assign({}, $("#newIntegrationModal").data("mdata"), {
        integration_type
    });

    Toastr.success("Saving…")

    $.ajax({
        url: "/integration-manage.json",
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(createData),
        success: function(res) {
            console.log(res)
            let integration=res.result.integration;
            integration.disable_preview=( res.result.integration && res.result.integration.type=="api" ? true : false )
            const rendered = Mustache.render(integrationTpl, integration)
            $(".integrationList").append(rendered)
            $('[data-integration-id="' + integration.id + '"]').slideDown()
            Toastr.clear()
            Toastr.success("Integration saved")
            //TODO: add integration to page
        },
        error: function(xhr, ajaxOptions, thrownError) {
            Toastr.clear()
            Toastr.error("There was an error saving the integration, please try again.")
        }
    })
}

module.exports = bbIntegrations
