var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "dropDown", staticClass: "drop-down" },
    [
      _c("label", { class: _vm.labelClass, attrs: { for: _vm.name } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
      ]),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        class: _vm.inputClassName,
        attrs: {
          id: _vm.name,
          "aria-describedby": _vm.describedby,
          placeholder: _vm.placeholder,
          type: "text",
          readonly: ""
        },
        domProps: { value: _vm.options[_vm.value] },
        on: { focus: _vm.onFocusLocal, blur: _vm.onBlur }
      }),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible"
            }
          ]
        },
        _vm._l(_vm.options, function(option, key) {
          return _c(
            "li",
            {
              key: key,
              class: _vm.value === key ? "selected" : "",
              on: {
                click: function($event) {
                  _vm.change($event, key)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(option) + "\n    ")]
          )
        })
      ),
      _vm._v(" "),
      _c("icon", {
        staticClass: "caret",
        attrs: { name: "caret-down" },
        on: { click: _vm.toggle }
      }),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "dropdown-overlay",
        on: {
          click: function($event) {
            _vm.toggle($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d6c5849c", { render: render, staticRenderFns: staticRenderFns })
  }
}