var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.test, function(item, index) {
      return item && index < _vm.limit
        ? _c("AvatarListItem", { key: _vm.key(item), attrs: { item: item } })
        : _vm._e()
    })
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0bddc71b", { render: render, staticRenderFns: staticRenderFns })
  }
}